import { postnoAuth, post, get, deletes } from '../../service'

export default class MenuAppNewsApi {
  // 获取列表
  static pageList (params = undefined) {
    return post('/service/magnet/list', params)
  }

  // 新增/修改
  static saveRow (params = undefined) {
    return post('/service/magnet/save', params)
  }

  // 删除
  static deleteRow (params = undefined) {
    return get('/service/magnet/deleteById/'+params.key)
  }

  // 显示位置
  static getPosition (params = undefined) {
    return get('/service/magnet/findMagnetCate', params)
  }
}
