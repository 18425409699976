import { deletes, put, post,get } from '../../service'
import apiUrl from './api-url'

export default class NavService {
  static pageList(params = undefined) {
    return post(apiUrl.getPageList, params) 
  }
  static addRow(params = undefined) {
    return put(apiUrl.insertInfo, params)
  }
  static editRow(params = undefined)  {
    return put(apiUrl.updateInfo, params)
  }
  static deleteRow(params = undefined) {
    return post(apiUrl.deleteInfo, params)
  }
  static getPos(params = undefined){
    return get(apiUrl.getPos, params)
  }
 }