<template>
  <d2-container>
    <!--工具条-->
    <el-row style="height:100%;">
      <el-col :offset="1" :span="9" style="height:100%;overflow-y:auto;">
        <!--树形菜单列表-->
        <el-tree
          class="filter-tree nodes-style"
          :data="treeData"
          node-key="id"
          default-expand-all
          :expand-on-click-node="true"
          :props="defaultProps"
          :default-expanded-keys="defaultExpandedNode"
          highlight-current
          :render-content="renderContent"
          @node-click="editMenuInfo"
          ref="tree"
        >
        </el-tree>
        <!--树形菜单列表 end-->
      </el-col>
      <el-col :offset="1" :span="12">
        <!--列表-->
        <el-card style="margin-left: 30px;">
          <el-form
            :model="menuData"
            label-width="80px"
            :rules="rules"
            ref="formData"
            v-loading="listLoading"
            style="margin-top:20px"
          >
            <el-form-item v-show="addMenuButtonVisible">
              <div class="grid-content bg-purple">
                <el-button
                  type="primary"
                  size="mini"
                  @click.native="addEditSubmit"
                  :loading="addEditSubmitLoading"
                  >保存机构</el-button
                >
              </div>
            </el-form-item>
            <el-form-item v-show="!addMenuButtonVisible">
              <div class="grid-content bg-purple">
                <el-button
                  type="primary"
                  size="mini"
                  @click.native="addEditForm"
                  >新增子机构</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click.native="updateEditSubmit"
                  :loading="updateEditSubmitLoading"
                  >修改机构</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click.native="delNewSubMenu"
                  >删除机构</el-button
                >
              </div>
            </el-form-item>
            <el-form-item label="唯一标识" prop="id" v-show="false">
              <el-input v-model="menuData.id" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="行政区划" prop="adminDivisionName">
              <division-address
                :valueAdd="menuData.adminDivisionName"
                @on-change="handleChange"
              ></division-address>
            </el-form-item>
            <el-form-item label="上级代码" prop="pid">
              <el-input
                v-model="menuData.pid"
                placeholder=""
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="机构名" prop="orgName">
              <el-input v-model="menuData.orgName" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="机构代码" prop="orgCode">
              <el-input v-model="menuData.orgCode" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="是否启用" prop="status">
              <el-switch
                v-model="menuData.switch"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="启用"
                inactive-text="禁用"
                @change="handleSwitch"
              >
              </el-switch>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </d2-container>
</template>

<script>
//组织管理相关的接口Api
import {
  getOrgTree,
  orgSave,
  delOrgId
} from "@/model/modules/organazationinfo";
import {
  menuList,
  addSysMenu,
  editSysMenu,
  dicList
} from "@/model/modules/menu";
import divisionAddress from "./components/divisionAddress";
export default {
  name: "systemManageMenuInfo",
  components: { divisionAddress },
  data() {
    return {
      addEditFormLoading: false, //
      listLoading: false, // 载入tableData
      updateEditSubmitLoading: false, // 修改按钮点击后
      addEditSubmitLoading: false,
      addMenuButtonVisible: false, // 新增界面是否显示
      menuValidaStatus: 1, // 启用：1，禁用：0
      defaultExpandedNode: ["-1"], // 默认展开节点的key数组
      // formData: {}, // 提交
      rules: {
        // 表单验证
        adminDivisionName: [
          { message: "请选择行政区划", required: true, trigger: "blur" }
        ],
        orgName: [{ required: true, message: "请填写机构名", trigger: "blur" }],
        orgCode: [
          { required: true, message: "请填写机构代码", trigger: "blur" }
        ]
      },
      iconList: [],
      queryData: {
        // 查询
        pageNum: Number,
        pageSize: Number,
        fConstellation: "", // 星座
        fStartAge: "", // 年龄范围最小值
        fEndAge: "", // 年龄范围最大值
        fName: "" // 姓名
      },
      queryResult: [
        // { // 查询返回结果
        // resCode: '001',
        // validStat: '启用',
        // resCateg: '系统资源',
        // fResName: '定义系统资源',
        // fResBuy: '否',
        // price: '1.0'
        // }
      ],
      treeData: [],
      defaultProps: {
        children: "children",
        label: "orgName"
      },
      menuData: {} // 表单数据
    };
  },
  computed: {
    addEditTitle() {
      return this.currentFormDataIndex > -1 ? "编辑" : "新增";
    }
  },
  methods: {
    // 查询
    getListData() {
      let orgArr = [];
      this.listLoading = true;
      getOrgTree()
        .then(response => {
          response.data.body.map((item, index) => {
            let obj = (orgArr[index] = {});
            obj.label = item.orgName;
            obj.id = item.id;
            obj.switch = item.status === 1 ? false : true;
            obj.orgCode = item.orgCode;
            obj.orgName = item.orgName;
            obj.children = item.children;
          });
          this.treeData = orgArr;
          this.listLoading = false;
        })
        .catch(response => {
          console.error(response);
          this.listLoading = false;
          this.$message.error("操作失败1");
        });
    },
    //
    renderContent(h, { node, data, store }) {
      if (data.validaStatus === 0) {
        return (
          <span class="custom-tree-node">
            <el-tag size="small" type="danger">
              禁用
            </el-tag>
            &nbsp;&nbsp;
            <span>{node.label}</span>
          </span>
        );
      } else {
        return (
          <span class="custom-tree-node">
            <span>{node.label}</span>
          </span>
        );
      }
    },
    handleChange(e) {
      this.menuData.adminDivisionName = e[e.length - 1];
    },
    handleSwitch(value) {
      console.log(value);
      if (value) {
        this.menuData.status = 1;
      } else {
        this.menuData.status = 0;
      }
    },
    // 树形菜单节点点击
    editMenuInfo(data, node, _node) {
      console.log(data, node, _node, "xxccvvbb===");
      if (data.id !== "new") {
        this.addMenuButtonVisible = false;
      } else {
        this.addMenuButtonVisible = true;
      }
      this.menuData = {
        ...data,
        switch: data.status == 1 ? true : false
      };
      // 清空表单的验证
      this.$refs["formData"].clearValidate();
    },
    // 修改菜单
    updateEditSubmit() {
      console.log(this.menuData, " this.menuData this.menuData");
      if (this.$refs.tree.getCurrentNode()) {
        this.$refs.formData.validate(valid => {
          if (!valid) return;
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.updateEditSubmitLoading = true;
            orgSave(this.menuData)
              .then(response => {
                let result = response.data.data;
                if (response.data.code == 0) {
                  this.updateEditSubmitLoading = false;
                  this.$message({
                    message: "操作成功",
                    type: "success",
                    duration: 1500
                  });
                  this.getListData();
                } else {
                  this.updateEditSubmitLoading = false;
                  this.$message.error("操作失败");
                }
              })
              .catch(response => {
                this.updateEditSubmitLoading = false;
                console.error(response);
                this.$message.error("操作失败");
              });
          });
        });
      } else {
        this.$message({
          message: "请先选择节点再进行操作",
          type: "warning",
          duration: 1500
        });
      }
    },
    // 新增子菜单表单
    addEditForm() {
      // if (this.$refs.tree.getNode('new')) {
      //   this.$message({ message: '已有新建节点', type: 'warning', duration: 1500 })
      //   this.$refs.tree.setCurrentNode(this.$refs.tree.getNode('new'))
      //   return
      // }
      if (this.$refs.tree.getCurrentNode()) {
        this.addMenuButtonVisible = true;
        let selNode = this.$refs.tree.getCurrentNode();
        this.menuData = {
          pid: selNode.id,
          adminDivisionName: ""
        };
      } else {
        this.$message({
          message: "请先选择节点再进行操作",
          type: "warning",
          duration: 1500
        });
      }
    },
    // 新增 添加请求
    addEditSubmit() {
      this.$refs.formData.validate(valid => {
        if (!valid) return;
        this.$confirm("确认提交吗？", "提示", {}).then(() => {
          this.addEditSubmitLoading = true;
          let _data = this.menuData;
          let para = {
            orgCode: _data.orgCode,
            orgName: _data.orgName,
            pid: _data.pid,
            adminDivisionName: _data.adminDivisionName
          };
          orgSave(para)
            .then(response => {
              if (response.data.code == 0) {
                let selNode = this.$refs.tree.getCurrentNode();
                let newNode = {
                  id: "new",
                  pid: response.data.body.pid || "",
                  label: response.data.body.orgName,
                  orgCode: response.data.body.orgCode,
                  orgName: response.data.body.orgName,
                  adminDivisionName: response.data.body.adminDivisionName
                };
                this.$refs.tree.append(newNode, selNode);
                this.addEditSubmitLoading = false;
                this.addMenuButtonVisible = false;
                this.$message({
                  message: "保存成功",
                  type: "success",
                  duration: 1500
                });
                // 清除新建空节点信息
                this.$refs.tree.remove(this.$refs.tree.getNode("new"));
                this.getListData();
              }
            })
            .catch(response => {
              this.addEditSubmitLoading = false;
              this.delNewSubMenu();
              this.$message.error("操作失败");
            });
        });
      });
    },
    delNewSubMenu() {
      if (this.$refs.tree.getCurrentNode()) {
        // 获得当前节点data
        console.log(this.$refs.tree.getCurrentNode().id, "0000000======");
        // 获得当前节点Node   console.log(this.$refs.tree.currentNode.node)
        let selNode = this.$refs.tree.getCurrentNode();
        //
        //
        this.menuData = this.$refs.tree.getCurrentNode();
        this.addMenuButtonVisible = false;
        this.$confirm("确认删除吗？", "提示", {}).then(() => {
          delOrgId(`/service/Org/id/${selNode.id}`).then(res => {
            console.log(res, "respxlpsplsac=======");
            if (res.data.code == 0) {
              this.$refs.tree.remove(selNode.id);
              this.getListData();
              this.$message.success("成功删除一级组织");
              this.menuData = {}
            }
          });
        });
      } else {
        this.$message({
          message: "请先选择节点再进行操作",
          type: "warning",
          duration: 1500
        });
      }
    },
    init() {
      this.getListData();
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<!-- <style scoped lang="scss">
  .align-center {
    text-align: center;
  }
  .align-right {
    text-align: right;
  }
  textarea {
    font-family: Arial;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 40px;
  }
  .nodes-style {
    font-size: 15px;
  }
</style> -->
