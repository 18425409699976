<template>
  <d2-container>
    <!--工具条-->
    <el-row style="height: 100%">
      <el-col :offset="1" :span="9" style="height: 100%; overflow-y: auto">
        <!--树形菜单列表-->
        <el-tree
          class="filter-tree nodes-style"
          :data="treeData"
          :props="defaultProps"
          node-key="menuCode"
          default-expand-all
          :expand-on-click-node="true"
          :default-expanded-keys="defaultExpandedNode"
          highlight-current
          :render-content="renderContent"
          @node-click="editMenuInfo"
          ref="tree"
        >
        </el-tree>
        <!--树形菜单列表 end-->
      </el-col>
      <el-col :offset="1" :span="12">
        <!--列表-->
        <el-card style="margin-left: 30px">
          <el-form
            :model="menuData"
            label-width="80px"
            :rules="rules"
            ref="formData"
            v-loading="listLoading"
            style="margin-top: 20px"
          >
            <el-form-item v-show="addMenuButtonVisible">
              <div class="grid-content bg-purple">
                <el-button
                  type="primary"
                  @click.native="addEditSubmit"
                  :loading="addEditSubmitLoading"
                  >保存菜单信息</el-button
                >
                <!-- <el-button type="primary" @click.native="delNewSubMenu"
                  >删除菜单信息</el-button
                > -->
              </div>
            </el-form-item>
            <el-form-item v-show="!addMenuButtonVisible">
              <div class="grid-content bg-purple">
                <el-button type="primary" @click.native="addEditForm"
                  >新增子菜单</el-button
                >
                <el-button
                  type="primary"
                  @click.native="updateEditSubmit"
                  :loading="updateEditSubmitLoading"
                  >修改菜单信息</el-button
                >
                <el-button
                  v-if="menuData.status == 0"
                  @click="toggleSysMenuStatus(menuData)"
                  :class="{ 'el-button--success': menuData.status == 0 }"
                  >启用</el-button
                >
                <el-button
                  v-else-if="menuData.status == 1"
                  @click="toggleSysMenuStatus(menuData)"
                  :class="{ 'el-button--danger': menuData.status == 1 }"
                  >禁用</el-button
                >
                <el-button type="danger" @click.native="delMenu">删除菜单</el-button>
              </div>
            </el-form-item>
            <el-form-item label="上级菜单" prop="parentMenuCodeStr">
              <el-input
                v-model="menuData.parentMenuCodeStr"
                placeholder=""
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="菜单代码" prop="menuCode">
              <el-input v-model="menuData.menuCode" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="菜单名称" prop="menuName">
              <el-input v-model="menuData.menuName" placeholder=""></el-input>
            </el-form-item>
            <!-- <el-form-item label="图标" prop="micon">
              <el-select
                v-model="menuData.micon"
                placeholder="请选择"
                size="medium"
              >
                <el-option
                  v-for="item in iconList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px"
                    ><i :class="item.value"></i
                  ></span>
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="资源路径" prop="menuUrl">
              <el-input v-model="menuData.menuUrl" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="菜单排序" prop="orderNo">
              <el-input v-model="menuData.orderNo" placeholder=""></el-input>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </d2-container>
</template>

<script>
import { menuList, addSysMenu, delMenu } from '@/model/modules/menu'
export default {
  name: 'systemManageMenuInfo',
  data () {
    //  菜单名称检查
    var cheakName = (rule, value, callback) => {
      // if (value && (!(/^[0-9A-Za-z_\-\u4e00-\u9fa5]+$/).test(value))) {
      //   callback(new Error('请输入文字'))
      // } else
      if (!value) {
        callback(new Error('请输入菜单名称'))
      } else {
        callback()
      }
    }
    // 序号数字检验
    var cheakIndex = (rule, value, callback) => {
      if (value && !/^[1-9]\d*$/.test(value)) {
        callback(new Error('请输入数字'))
      } else if (!value) {
        callback(new Error('请输入菜单排序'))
      } else {
        callback()
      }
    }
    return {
      API: {
        prefix: '/TMtSysMenuController',
        getMenuList: '/getMenuList', // get菜单列表
        insertSysMenu: '/insertTMtSysMenu', // 新增资源
        updateSysMenu: '/updateTMtSysMenu', // 编辑
        updateSysMenuStatus: '/updateTMtSysMenuStatus' // 禁用
      },
      addEditFormLoading: false, //
      listLoading: false, // 载入tableData
      updateEditSubmitLoading: false, // 修改按钮点击后
      addEditSubmitLoading: false,
      addMenuButtonVisible: false, // 新增界面是否显示
      menuValidaStatus: 1, // 启用：1，禁用：0
      defaultExpandedNode: ['-1'], // 默认展开节点的key数组
      // formData: {}, // 提交
      rules: {
        // 表单验证
        menuCode: [{ required: true, message: '请输入菜单代码', trigger: 'blur' }],
        menuName: [{ validator: cheakName, required: true, trigger: 'blur' }],
        micon: [{ required: true, message: '请选择图标', trigger: 'blur' }],
        menuUrl: [{ required: true, message: '请填写资源路径', trigger: 'blur' }],
        orderNo: [
          {
            required: true,
            message: '请填写菜单排序',
            trigger: 'blur',
            validator: cheakIndex
          }
        ]
      },
      iconList: [],
      queryData: {
        // 查询
        pageIndexB1: Number,
        pageSize: Number,
        fConstellation: '', // 星座
        fStartAge: '', // 年龄范围最小值
        fEndAge: '', // 年龄范围最大值
        fName: '' // 姓名
      },
      queryResult: [
        // { // 查询返回结果
        // resCode: '001',
        // validStat: '启用',
        // resCateg: '系统资源',
        // fResName: '定义系统资源',
        // fResBuy: '否',
        // price: '1.0'
        // }
      ],
      treeData: [],
      defaultProps: {
        id: 'id',
        children: 'children',
        label: 'menuName'
      },
      menuData: {} // 表单数据
    }
  },
  computed: {
    addEditTitle () {
      return this.currentFormDataIndex > -1 ? '编辑' : '新增'
    }
  },
  methods: {
    // 查询
    getListData () {
      this.listLoading = true
      menuList()
        .then((response) => {
          this.treeData = response.data.body
          this.listLoading = false
        })
        .catch((response) => {
          console.error(response)
          this.listLoading = false
          this.$message.error('操作失败')
        })
    },
    //
    renderContent (h, { node, data, store }) {
      if (data.validaStatus === 0) {
        return (
          <span class="custom-tree-node">
            <el-tag size="small" type="danger">
              禁用
            </el-tag>
            &nbsp;&nbsp;
            <span>{node.label}</span>
          </span>
        )
      } else {
        return (
          <span class="custom-tree-node">
            <span>{node.label}</span>
          </span>
        )
      }
    },
    // 树形菜单节点点击
    editMenuInfo (data, node, _node) {
      if (this.newNode !== undefined) {
        this.$refs.tree.remove(this.$refs.tree.getNode(''))
        this.newNode = undefined
      }
      this.addMenuButtonVisible = false
      this.menuData = {...data} 
      if (data.parentMenuCode !== '') {
        this.menuData.parentMenuCodeStr = this.$refs.tree.getNode(data.parentMenuCode).data.menuName
      }
      // 清空表单的验证
      this.$refs.formData.clearValidate()
    },
    //  菜单启用/禁用 处理请求结果
    updateSysMenuValidStatus (val) {
      const _val = Number(val.status) === 1 ? 0 : 1
      const _message = Number(val.status) === 1 ? '已禁用' : '已启用'
      // eslint-disable-next-line no-undef
      this.menuData.status = _val
      addSysMenu(val)
        .then((response) => {
          if (response.data.code === 0) {
            this.$message.info(_message)
            this.getListData()
          }
        })
        .catch((response) => {
          console.error(response)
          this.$message.error('操作失败')
        })
    },
    // 菜单启用/禁用 请求
    toggleSysMenuStatus (_val) {
      if (Number(_val.status) === 1) {
        this.$confirm('确认禁用吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.updateSysMenuValidStatus(_val)
          })
          .catch(() => {
            // return
          })
      } else {
        if (this.$refs.tree.currentNode.node.parent.data.status === 0) {
          this.$message({
            message: '父级菜单已禁用，操作失败',
            type: 'danger',
            duration: 1500
          })
          return
        }
        this.$confirm('确认启用吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.updateSysMenuValidStatus(_val)
          })
          .catch(() => {
            // return
          })
      }
    },
    // 修改菜单
    updateEditSubmit () {
      if (this.$refs.tree.getCurrentNode()) {
        this.$refs.formData.validate((valid) => {
          if (!valid) return
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            this.updateEditSubmitLoading = true
            const _data = this.menuData
            addSysMenu(_data)
              .then((response) => {
                if (response.data.code === 0) {
                  this.updateEditSubmitLoading = false
                  this.$message({
                    message: '操作成功',
                    type: 'success',
                    duration: 1500
                  })
                  this.getListData()
                } else {
                  this.updateEditSubmitLoading = false
                  this.$message.error('操作失败')
                }
              })
              .catch((response) => {
                this.updateEditSubmitLoading = false
                console.error(response)
                this.$message.error('操作失败')
              })
          })
        })
      } else {
        this.$message({
          message: '请先选择节点再进行操作',
          type: 'warning',
          duration: 1500
        })
      }
    },
    // 新增子菜单表单
    addEditForm () {
      const selNode = this.$refs.tree.getCurrentNode()
      if (selNode) {
        this.addMenuButtonVisible = true
        this.newNode = {
          menuCode: '',
          parentMenuCode: selNode.menuCode,
          menuName: '',
          menuUrl: '',
          orderNo: ''
        }
        this.menuData = this.newNode
        this.menuData.parentMenuCodeStr = selNode.menuName
        this.$refs.tree.append(this.newNode, selNode)
      } else {
        this.$message({
          message: '请先选择节点再进行操作',
          type: 'warning',
          duration: 1500
        })
      }
    },
    // 新增 添加请求
    addEditSubmit () {
      this.$refs.formData.validate((valid) => {
        if (!valid) return
        this.$confirm('确认提交吗？', '提示', {}).then(() => {
          this.addEditSubmitLoading = true
          const _data = this.menuData
          const para = {
            menuCode: _data.menuCode,
            parentMenuCode: _data.parentMenuCode,
            menuName: _data.menuName,
            menuUrl: _data.menuUrl,
            orderNo: _data.orderNo
          }
          addSysMenu(para)
            .then(async (response) => {
              if (response.data.code === 0) {
                this.addEditSubmitLoading = false
                this.addMenuButtonVisible = false
                this.$message({
                  message: '保存成功',
                  type: 'success',
                  duration: 1500
                })
                this.getListData()
                await this.$store.dispatch('d2admin/menu/generateRoutes')
                this.$router.addRoutes(accessRoutes)
              } else {
                this.addEditSubmitLoading = false
                this.addMenuButtonVisible = false
                this.$message.error('操作失败')
                if (this.newNode !== undefined) {
                  this.$refs.tree.remove(this.$refs.tree.getNode(''))
                  this.newNode = undefined
                }
              }
            })
            .catch((response) => {
              this.addEditSubmitLoading = false
              this.addMenuButtonVisible = false
              console.error(response)
              this.$message.error('操作失败')
            })
        })
      })
    },
    // 删除菜单
    delMenu () {
      this.$confirm('确认删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
          const para = {
            id: this.menuData.id
          }
          delMenu(para)
          .then((response) => {
            if (response.data.code === 0) {
              this.$message({
                message: '删除成功',
                type: 'success',
                duration: 1500
              })
              this.getListData()
            } else {
              this.$message.error('操作失败')
            }
          })
          .catch((response) => {
            console.error(response)
            this.$message.error('操作失败')
          })
      }).catch(() => {})
    }
  },
  mounted () {
    this.getListData()
  }
}
</script>

<!-- <style scoped lang="scss">
  .align-center {
    text-align: center;
  }
  .align-right {
    text-align: right;
  }
  textarea {
    font-family: Arial;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 40px;
  }
  .nodes-style {
    font-size: 15px;
  }
</style> -->
