<template>
  <div>
    <el-dialog
      :title="titleSrc"
      :visible.sync="dialogFormVisible"
      v-bind="$attrs"
    >
      <!--查询条件配置组件-->
      <search-config
        ref="searchConfig"
        :configData="config"
        :formSet="form"
        :rules="rules"
        :colspan="12"
        :btnColspan="5"
        :labelWidth="'120px'"
      />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onEditSubmit">提交</el-button>
        <el-button type="default" @click="onClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SearchConfig from "@/views/components/searchConfigForm";
export default {
  name: "addSub",
  components: {
    SearchConfig
  },
  props: {
    config: Array,
    form: Object,
    rules: Object,
    titleSrc: String
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      allObjTags: [],
      obj: "",
      ratio: ""
    };
  },
  methods: {
    showDialog(e = {}) {
      this.form = e;
      this.dialogFormVisible = true;
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key.toString();
    },
    onEditSubmit() {
      this.$refs.searchConfig.$children[0].validate(valid => {
        if (!valid) return;
        this.$confirm("确认提交吗？", "提示", {}).then(() => {
          this.$emit("on-edit-submit", { form: this.form, visible: false });
          this.onClose();
        });
      });
    },
    onClose() {
      this.dialogFormVisible = false;
    }
  }
};
</script>
<style scoped>
.mt20 {
  margin-top: 20px;
}
</style>
