<template>
    <div>
        <el-dialog
            :title="titleCode"
            :visible.sync="dialogFormVisible"
            v-bind="$attrs"
        >
            <free-table 
                border
                :data="data"
                :column="slotColumn"
                :columnsProps="columnsProps"
                @selection-change="handleSelectionChange"
                pagination
                :auto-scroll="false"
                :pageSizes="pagination.pageSizes"
                :total.sync="pagination.total"
                :page.sync="pagination.pageNum"
                :limit.sync="pagination.pageSize"
                @pagination="getList">
            </free-table>
        </el-dialog>
    </div>
</template>

<script>
    import FreeTable from "@/views/components/FreeTable"
    export default {
        data(){
            return {
                dialogFormVisible:false,
                slotColumn: [
                    { label: '序号', prop: 'index',type:'index',width:50 },
                    { label: 'id', prop: 'id' },
                    { label: '防伪码', prop: 'code'},
                    { label: '查询时间', prop: 'queryTime'},
                    { label: '用户id', prop: 'userid'},
                    { label: '查询名次', prop: 'count'},
                    { label: '经纬度', prop: 'longitude'},
                    { label: '省', prop: 'province'},
                    { label: '市', prop: 'city'},
                    { label: '市', prop: 'district'}
                ],
                data:[
                    {
                       id:';devlv',
                        code:"okdovkv",
                        queryTime:545455,
                        userid:477,
                        count:777,
                        longitude:84848,
                        province:"okokwe",
                        city:"oeoeo"

                    }
                ],
                columnsProps: {
                    width: 'auto',
                    showOverflowTooltip: true
                },
                // 页码
                pagination: {
                    pageNum: 1,
                    pageSize: 10,
                    pageSizes: [10, 20, 50, 100, 2],
                    total: 1
                },
            }
        },
        props:{
            titleCode:String
        },
        components:{
            FreeTable
        },
        methods:{
            showDialog(e){
                this.dialogFormVisible = true
                //this.data = e
            },
            //开始生成
            handleCreating(){
                this.isSliderVisible = true
            },
            //停止
            onStopCreate(){
                this.isSliderVisible = false

            },
            onClose(){
                this.dialogFormVisible = false
            },
            // 选中
            handleSelectionChange(val) {
            console.log('选中:', val)
            },
            // 分页选择器切换事件
            getList (e) {
                this.pagination.pageNum = e.page
                this.pagination.pageSize = e.limit
            
            },
        }
    }
</script>

<style lang="scss" scoped>
.flex_wrap{
    display:flex;
    .flex_item{
        flex:1;
        .color_red{
          color:red;
          margin-right:10px;  
        }
    }
}
.dis_margin{
    margin-bottom:22px;
    font-size:14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .flex_label{
        flex:0 0 100px;
        width:100px;
    }
    .flex_label1{
        flex:0 0 50px;
        margin-left: 10px;
    }
    .flex_label2{
        flex:0 0 100px;
        margin-left: 10px;
    }
}   
    
</style>