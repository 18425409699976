/**
 * 审核设置
 */

import { get } from '../../service'
import apiUrl from './api-url'

export default class{
   // 获取状态
   static loadState() {
      return get(apiUrl.urlGetState)
   }
   // 更改状态
   static loadChangeState(params = undefined) {
      return get(apiUrl.urlChangeState + params)
   }
}