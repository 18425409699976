import { deletes, put, post, postnoAuth } from '../../service'

export default class ActivityList {
  // 获取列表
  static pageList(params = undefined) {
    return post('/service/activity/list', params) 
  }

  // 新增/修改
  static save(params = undefined) {
    return post('/service/activity/save', params)
  }
 
  // 删除
  static delete (params = undefined) {
    console.log(params)
    return deletes('/service/activity/remove/'+params.id)
  }
  
 }