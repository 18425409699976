<template>
  <div>
    <el-dialog
      :title="titleSrc"
      :visible.sync="dialogFormVisible"
      v-bind="$attrs"
    >
      <!--查询条件配置组件-->
      <search-config-form-one
        ref="searchConfig"
        :configData="config"
        :formSet="form"
        :rules="rules"
        :colspan="24"
        :btnColspan="5"
        :labelWidth="'90px'"
        @on-avatar-success="handleAvatarSuccess"
      />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onEditSubmit">提交</el-button>
        <el-button type="default" @click="onClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SearchConfig from "@/views/components/searchConfigForm/searchConfigFormOne.vue";
export default {
  name: "addSub",
  components: {
    SearchConfig
  },
  props: {
    config: Array,
    form: Object,
    rules: Object,
    titleSrc: String
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      allObjTags: [],
      obj: "",
      ratio: ""
    };
  },
  methods: {
    showDialog(e = {}) {
      this.form = e;
      this.dialogFormVisible = true;
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key.toString();
    },
    onEditSubmit() {
      this.$refs.searchConfig.$children[0].validate(valid => {
        if (!valid) return;
        let indexNum = this.form.fLinkUrl.indexOf('#####')
        let linkObj
        if (indexNum === -1) {
          linkObj = {
            name: this.form.fLinkUrl,
            params: { id: '' },
            query: { id: '' }
          }
        } else {
          linkObj = {
            name: this.form.fLinkUrl.substring(0, indexNum),
            params: { id: this.form.fLinkUrl.substring(indexNum + 5) },
            query: { id: this.form.fLinkUrl.substring(indexNum + 5) }
          }
        }
        this.form.fLinkJson = JSON.stringify(linkObj)
        this.$confirm("确认提交吗？", "提示", {}).then(() => {
          this.$emit("on-edit-submit", { form: this.form, visible: false });
          this.onClose();
        });
      });
    },
    onClose() {
      this.dialogFormVisible = false;
    },
    handleAvatarSuccess(e) {
      console.log(e,"nivnenvnei===")
      e.origin.fPicUrl = e.res[0].filepath
    },
    beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      }
    }
};
</script>
<style>
.mt20 {
  margin-top: 20px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
