<template>
  <div>
    <!-- 搜索 -->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="4"
      :labelWidth="'90px'"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>

    <!-- 按钮 -->
    <div style="margin-bottom:20px;">
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="toDetail(type)"
      >
        新增
      </el-button>
    </div>

    <!-- 表格 -->
    <free-table
      border
      :data="data"
      :column="slotColumn"
      :columnsProps="columnsProps"
      pagination
      :auto-scroll="false"
      :pageSizes="pagination.pageSizes"
      :total.sync="pagination.total"
      :page.sync="pagination.pageNum"
      :limit.sync="pagination.pageSize"
      @pagination="getList"
    >
      <template v-slot:type="{ row }">
        {{ row.typeOne }} {{ row.typeTwo }}
      </template>
      <template v-slot:ctm="{ row }">
        {{ formData(row.ctm) }}
      </template>
      <template v-slot:bookSort="{ row }">
        <el-input
          v-model="row.bookSort"
          type="number"
          @change="onChangeClick(row, row.bookSort)"
          @keyup.enter.native="$event.target.blur()"
        ></el-input>
      </template>
      <template v-slot:caseSort="{ row }">
        <el-input
          v-model="row.caseSort"
          type="number"
          @change="onChangeClick(row, row.caseSort)"
          @keyup.enter.native="$event.target.blur()"
        ></el-input>
      </template>
      <template v-slot:drugsSort="{ row }">
        <el-input
          v-model="row.drugsSort"
          type="number"
          @change="onChangeClick(row, row.drugsSort)"
          @keyup.enter.native="$event.target.blur()"
        ></el-input>
      </template>
    </free-table>
  </div>
</template>

<script>
// 引入组件
import freeTable from "@/views/components/FreeTable";
import searchConfig from "@/views/components/searchConfigForm";

// 引入接口
import KnowledgeBaseApi from "@/model/modules/modCms_konwledgeBase/index";

// 引入方法
import { formData, selectFormatData } from "@/utils/tool";

export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  components: {
    searchConfig,
    freeTable
  },
  data() {
    return {
      // 搜索
      searchConfigData: [],
      formSetData: {},
      types_ys: [],
      types_ya: [],
      types_yy: [],

      searchConfigData_ys: [
        {
          label: "",
          placeholder: "请输入书籍名称，作者姓名",
          type: "input",
          prop: "name"
        },
        {
          label: "所属分类",
          placeholder: "请输入",
          type: "select",
          options: [],
          prop: "classify"
        }
      ],
      searchConfigData_ya: [
        {
          label: "",
          placeholder: "请输入医案名称，医师姓名",
          type: "input",
          prop: "name"
        },
        {
          label: "所属分类",
          placeholder: "请选择",
          type: "select",
          options: [],
          prop: "classify"
        }
      ],
      searchConfigData_yy: [
        {
          label: "",
          placeholder: "请输入医药名称",
          type: "input",
          prop: "name"
        },
        {
          label: "所属分类",
          placeholder: "请选择",
          type: "select",
          options: [],
          prop: "classify"
        }
      ],

      formSetData_ys: {
        classify: "",
        name: "",
        type: "1",
        pageIndexB1: 1,
        pageSize: 10
      },
      formSetData_ya: {
        classify: "",
        name: "",
        type: "2",
        pageIndexB1: 1,
        pageSize: 10
      },
      formSetData_yy: {
        classify: "",
        name: "",
        type: "3",
        pageIndexB1: 1,
        pageSize: 10
      },

      // 表格
      data: [],
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [1, 10, 20, 50, 100],
        total: 0
      },
      slotColumn: [],
      slotColumn_ys: [
        { label: "书籍ID", prop: "bookId" },
        { label: "书籍名称", prop: "bookName" },
        { label: "所属分类", prop: "type" },
        { label: "创建时间", prop: "ctm", slotScope: true },
        { label: "列表排序", prop: "bookSort", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: "200px",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.toDetail(this.type, scope.row.id);
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row);
                  }}
                >
                  删除
                </el-button>
              </div>
            );
          }
        }
      ],
      slotColumn_ya: [
        { label: "医案ID", prop: "caseNumStr" },
        { label: "医案名称", prop: "caseName" },
        { label: "医师", prop: "doctorName" },
        { label: "所属分类", prop: "type" },
        { label: "创建时间", prop: "ctm", slotScope: true },
        { label: "列表排序", prop: "caseSort", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: "200px",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.toDetail(this.type, scope.row.id);
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row);
                  }}
                >
                  删除
                </el-button>
              </div>
            );
          }
        }
      ],
      slotColumn_yy: [
        { label: "药材ID", prop: "drugsNumStr" },
        { label: "药材名称", prop: "drugsName" },
        { label: "所属分类", prop: "type", slotScope: true },
        { label: "创建时间", prop: "ctm", slotScope: true },
        { label: "列表排序", prop: "drugsSort", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: "200px",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.toDetail(this.type, scope.row.id);
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row);
                  }}
                >
                  删除
                </el-button>
              </div>
            );
          }
        }
      ]
    };
  },
  mounted() {
    this.getTypes();

    if (this.type == "YS") {
      this.searchConfigData = this.searchConfigData_ys;
      this.formSetData = this.formSetData_ys;
      this.slotColumn = this.slotColumn_ys;
    } else if (this.type == "YA") {
      this.searchConfigData = this.searchConfigData_ya;
      this.formSetData = this.formSetData_ya;
      this.slotColumn = this.slotColumn_ya;
    } else if (this.type == "YY") {
      this.searchConfigData = this.searchConfigData_yy;
      this.formSetData = this.formSetData_yy;
      this.slotColumn = this.slotColumn_yy;
    }

    this.getPageList();
  },
  methods: {
    formData,
    selectFormatData,

    // 获取分类
    async getTypes() {
      if (this.type == "YS") {
        let types_ys = await KnowledgeBaseApi.getDicListByCateCode("BOOK_TYPE");
        if (types_ys.data.code == 0) {
          this.types_ys = selectFormatData(
            types_ys.data.body.content,
            "id",
            "itemName"
          );
          this.searchConfigData_ys[1].options = this.types_ys;
        } else {
          console.log(types_ys.data.msg);
        }
      } else if (this.type == "YA") {
        let types_ya = await KnowledgeBaseApi.getDicListByCateCode(
          "MEDICAL_CASE_TYPE "
        );
        if (types_ya.data.code == 0) {
          this.types_ya = selectFormatData(
            types_ya.data.body.content,
            "id",
            "itemName"
          );
          this.searchConfigData_ya[1].options = this.types_ya;
        } else {
          console.log(types_ya.data.msg);
        }
      } else if (this.type == "YY") {
        let types_yy = await KnowledgeBaseApi.getDrugsCateTrees();
        if (types_yy.data.code == 0) {
          this.types_yy = selectFormatData(types_yy.data.body, "id", "name");
          this.searchConfigData_yy[1].options = this.types_yy;
        } else {
          console.log(types_yy.data.msg);
        }
      }
    },

    // 获取列表
    getPageList() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      KnowledgeBaseApi.pageList(this.formSetData).then(res => {
        if (res.data.code == 0) {
          this.data = res.data.body.content;
          this.pagination.total = res.data.body.total;
        }
      });
    },

    // 点击查询按钮
    onQuery() {
      this.pagination.pageNum = 1;
      this.getPageList();
    },

    // 点击新增按钮
    onAdd() {
      this.$router.push({
        path: "/modCms/knowledgeBase/add",
        query: {
          type: this.type,
          id: ""
        }
      });
    },

    // 点击删除
    handleDelete(row) {
      this.$confirm("确认删除吗？", "提示", {}).then(() => {
        let params = {
          id: row.id,
          type: this.type
        };
        KnowledgeBaseApi.deleteRow(params).then(response => {
          if (response.data.code == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
              duration: 1500
            });
          }
          this.getPageList();
        });
      });
    },

    // 跳转到详情页面
    toDetail(type = undefined, id = undefined) {
      this.$router.push({
        path: "/modCms/knowledgeBase/add",
        query: {
          type: type,
          id: id
        }
      });
    },

    // 分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getPageList();
    },

    // 监听排序输入框改变
    onChangeClick(row, value) {

      if (!/^\d+$/.test(Number(value)) || value == "") {
        this.$message({
          type: "error",
          message: "请输入0或正整数"
        });
        return;
      } else {
        this.$confirm("确定修改吗 ?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          let params = {
            id: row.id,
            weight: value,
            type: this.type
          };

          KnowledgeBaseApi.changeWeight(params)
            .then(res => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功",
                  duration: 1500
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                  duration: 1500
                });
              }
              this.getPageList();
            })
            .catch(err => {
              console.log(err);
            });
        });
      }
    }
  }
};
</script>
