<template>
  <d2-container>
    <!--查询条件配置组件-->
    <!-- <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="5"
      :labelWidth="'70px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config> -->
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        新增磁贴
      </el-button>
    </div>
    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:position="{ row }">
          {{getPositionZh(row.position)}}
        </template>
        <template v-slot:cover="{ row }">
          <div @click="showImg(row)" style="margin-top: 7px; cursor:pointer">
            <img :src="row.cover" width="30" height="20" class="image">
          </div>
        </template>
        <template v-slot:beginTmLabel="{ row }">
          {{timeConvert(row.beginTm, 1)}}
        </template>
        <template v-slot:endTmLabel="{ row }">
          {{timeConvert(row.endTm, 1)}}
        </template>
        <template v-slot:ctm="{ row }">
          {{timeConvert(row.ctm, 1)}}
        </template>
      </free-table>
    </div>
    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
    />
    <el-dialog class="el-dialog-col-2" :visible.sync="dialogVisible" :close-on-click-modal="true">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </d2-container>
</template>
<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'
// 引入页面层级组件
import AddSub from './components/addSub'
// 引入接口
import MenuAppMagnetApi from '@/model/modules/menuAppMagnet/index'

export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
    FreeTable,
    AddSub
  },
  data () {
    return {
      showPos: [], //显示位置
      titleSrc: '',
      searchConfigData: [],
      form: {
        title: '',
        bgColor: '',
        weight: '',
        url: '',
        beginTmLabel: 0,
        endTmLabel: 0,
        magnetCountStyle: '',
        content: '',
        position: '',
        cover: ''
      },
      config: [
        {
          label: '标题',
          type: 'input',
          prop: 'title'
        },
        {
          label: '背景颜色',
          type: 'colorInput',
          prop: 'bgColor'
        },
        {
          label: '排序',
          type: 'numberInput',
          prop: 'weight'
        },
        {
          label: '跳转链接',
          type: 'input',
          prop: 'url'
        },
        {
          label: '开始时间',
          type: 'timePicker',
          prop: 'beginTmLabel'
        },
        {
          label: '结束时间',
          type: 'timePicker',
          prop: 'endTmLabel'
        },
        {
          label: '磁贴数量类型',
          type: 'input',
          prop: 'magnetCountStyle'
        },
        {
          label: '内容',
          type: 'input',
          prop: 'content'
        },
        {
          label: '显示位置',
          type: 'select',
          prop: 'position',
          options: []
        },
        {
          label: '封面',
          type: 'uploadAvatar',
          prop: 'cover'
        }
      ],
      data: [], // 表格数据渲染
      slotColumn: [
        { label: '序号', type: 'index' },
        { label: '标题', prop: 'title'},
        { label: '封面', prop: 'cover', slotScope: true},
        { label: '排序', prop: 'weight' },
        { label: '开始时间', prop: 'beginTmLabel', slotScope: true },
        { label: '结束时间', prop: 'endTmLabel', slotScope: true },
        { label: '显示位置', prop: 'position',slotScope: true },
        { label: '上传时间', prop: 'ctm', slotScope: true, sort: true },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope)
                  }}
                >
                  编辑
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row)
                  }}
                >
                  删除
                </el-button>
              </div>
            )
          }
        }
      ],
      formSetData: {
        // 查询搜索内容
        pageIndexB1: 1,
        pageSize: 10,
      },
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [3, 5, 10],
        total: 1
      },
      dialogImageUrl: '',
      dialogVisible: false,
      // 表单验证
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        bgColor: [
          { required: true, message: '请输入背景颜色', trigger: 'blur' }
        ],
        weight: [
          { required: true, message: '请输入排序号', trigger: 'blur' }
        ],
        // magnetCountStyle: [
        //   {required: true, message: '请输入磁贴数量类型', trigger: 'blur'}
        // ],
        url: [
          {required: true, message: '请输入跳转链接', trigger: 'blur'}
        ],
        beginTmLabel: [
          {required: true, message: '请输入开始时间', trigger: 'blur'}
        ],
        endTmLabel: [
          {required: true, message: '请输入结束时间', trigger: 'blur'}
        ],
        endTmLabel: [
          {required: true, message: '请输入结束时间', trigger: 'blur'}
        ],
        position: [
          {required: true, message: '请选择显示位置', trigger: 'blur'}
        ],
        // content: [
        //   {required: true, message: '请输入内容', trigger: 'blur'}
        // ],
        cover: [
          {required: true, message: '请选择图片', trigger: 'blur'}
        ],
      }
    }
  },

  computed: {
    getPositionZh(){
      return function(str){
        let res = ''
        for(var i=0; i<this.showPos.length; i++){
          if(this.showPos[i].value == str){
            res = this.showPos[i].label
          }
        }
        return res
      }
    },
    timeConvert() {//num:0 YYYY-MM-DD  num:1  YYYY-MM-DD hh:mm:ss // timestamp:时间戳 
      return function(timestamp, num) {
        if (timestamp === 0) {
          return '-'
        } else {
          timestamp = timestamp.length === 10  ? timestamp*1000 : timestamp;
          let date = new Date(timestamp);
          let y = date.getFullYear();  
          let m = date.getMonth() + 1;  
          m = m < 10 ? ('0' + m) : m;  
          let d = date.getDate();  
          d = d < 10 ? ('0' + d) : d;  
          let h = date.getHours();
          h = h < 10 ? ('0' + h) : h;
          let minute = date.getMinutes();
          let second = date.getSeconds();
          minute = minute < 10 ? ('0' + minute) : minute;  
          second = second < 10 ? ('0' + second) : second; 
          if(num==0){
            return y + '-' + m + '-' + d;  
          }else{
            return y + '-' + m + '-' + d +' '+ h +':'+ minute +':' + second;  
          }
        }
      }
    }
  },

  mounted () {
    this.getDataList()
    this.getPosition()
  },
  methods: {
    //  图片放大
    showImg (row) {
      this.dialogImageUrl = row.cover
      this.dialogVisible = true
    },

    // 请求接口 获取显示位置
    getPosition(){
      MenuAppMagnetApi.getPosition().then(res=>{
        if(res.data.code == 0){
          this.showPos = res.data.body.map(item => {
            let obj = {}
            obj.label = item.name
            obj.value = item.position
            return obj
          })
        }else{
          console.log(res.data.msg)
        }
      })
      .catch(err=>{
        console.log(err)
      })
    },

    // 初始化表格渲染
    async getDataList () {
      this.formSetData.pageIndexB1 = this.pagination.pageNum
      this.formSetData.pageSize = this.pagination.pageSize
      const res = await MenuAppMagnetApi.pageList(this.formSetData)
      this.data = res.data.body.content
      this.pagination.total = res.data.body.total * 1
    },

    // 提交编辑表格行信息
    onEditSubmit (e) {
      console.log()
      if ((this.titleSrc == '磁贴修改')) {
        MenuAppMagnetApi.saveRow(e.form)
          .then(response => {
            if (!response.data.code == 0) return
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 1500
            })
            this.getDataList()
          })
          .catch(response => {
            console.error(response)
            this.$message.error('修改失败')
          })
      } else if (this.titleSrc == '磁贴添加') {
        MenuAppMagnetApi.saveRow(e.form)
          .then(response => {
            if (!response.data.code == 0) return
            this.$message({
              message: '添加成功',
              type: 'success',
              duration: 1500
            })
            this.getDataList()
          })
          .catch(response => {
            console.error(response)
            this.$message.error('添加失败')
          })
      }
    },
    // 打开编辑弹窗
    openDialog ({ row }) {
      this.form = {
        ...row
      }

      this.$refs.addSubDialog.showDialog(this.form)
      this.config[8].options = this.showPos
      this.titleSrc = '磁贴修改'
    },

    onAdd () {
      this.$refs.addSubDialog.showDialog(this.form)
      this.config[8].options = this.showPos
      this.titleSrc = '磁贴添加'
      this.form = {
        title: '',
        bgColor: '',
        weight: '',
        url: '',
        beginTmLabel: 0,
        endTmLabel: 0,
        magnetCountStyle: '',
        content: '',
        position: '',
        cover: ''
      }
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '提示', {}).then(() => {
        MenuAppMagnetApi.deleteRow({ key: row.id })
          .then(response => {
            if (!response.data.code == 0) return
            this.$message({
              message: '删除成功',
              type: 'success',
              duration: 1500
            })
            this.getDataList()
          })
          .catch(response => {
            console.error(response)
            this.$message.error('删除失败')
          })
      })
    },

    // 表格查询按钮
    onQuery () {
      this.pagination.pageNum = 1
      this.getDataList()
    },
    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.getDataList()
    },

    handleQueryFocus(){},
    handleFocus(){}
  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
