<template>
  <d2-container>
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        增加分类
      </el-button>
    </div>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:t="{ row }">
           <el-switch
            v-model="row.t"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="handleSwitchFocus(row)"
          >
          </el-switch>
        </template>
      </free-table>
    </div>

    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :form="form"
      :config="config"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
    />
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'
// 引入页面层级组件
import AddSub from './components/addSub'

// 引入接口

export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
    FreeTable,
    AddSub
  },
  data () {
    return {
      titleSrc: '新增分类',

      // 表格数据渲染
      data: [
        {
          q: 'q',
          w: 'w',
          e: 'e',
          r: 'r',
          t: 't'
        }
      ],
      slotColumn: [
        { label: 'id', prop: 'q' },
        { label: '分类名称', prop: 'w' },
        { label: '排序', prop: 'e' },
        { label: '缩略图', prop: 'r' },
        { label: '是否显示', prop: 't', slotScope: true },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '370px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onEditClick(scope.row)
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onDeleteClick(scope.row)
                  }}
                >
                  删除
                </el-button>
              </div>
            )
          }
        }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },

      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      },

      // 新增课程
      form: {
        
      },
      config: [
        [
          {
            colSpan: 12,
            label: '分类名称',
            type: 'input',
            prop: ''
          },
          {
            colSpan: 12,
            label: '排序',
            type: 'input',
            prop: ''
          },
        ],
        [
          {
            colSpan: 24,
            label: '选择图片',
            type: 'uploadAvatar',
            prop: ''
          }
        ],
      ],
      // 表单验证
      rules: {
        
      }
    }
  },

  mounted () {
  },

  methods: {
    // 查询组件 下拉框
    handleQueryFocus() {

    },

    // 表格查询按钮
    onQuery () {
      this.pagination.pageNum = 1
    },

    // 分页选择器切换事件
    getList (e) {
      // this.pagination.pageNum = e.page
      // this.pagination.pageSize = e.limit
    },

    // 是否显示
    handleSwitchFocus(e) {

    },

    // 新增课程
    onAdd () {
      this.form = {}
      this.$refs.addSubDialog.showDialog(this.form)
      this.titleSrc = '新增分类'
    },

    // 提交编辑表格行信息
    onEditSubmit (e) {
      
    },

    // 编辑
    onEditClick(e) {
      const form = {
        ...e
      } 
      this.$refs.addSubDialog.showDialog(form)
      this.titleSrc = '修改分类'
    },
    // 删除
    onDeleteClick(e) {

    },
  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>