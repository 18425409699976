<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="5"
      :labelWidth="'70px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:type="{ row }">
          {{ getLabelBySelectValue(config[0][1].options, row.type) }}
        </template>

        <template v-slot:status="{ row }">
          <el-switch
            v-model="row.status"
            :active-value="1"
            :inactive-value="0"
            @change="changeSwitch(row)"
          />
        </template>
      </free-table>
    </div>
    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
    />
    <el-dialog
      class="el-dialog-col-2"
      :visible.sync="dialogVisible"
      :close-on-click-modal="true"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </d2-container>
</template>
<script>
// 引入查询条件配置组件
import SearchConfig from "@/views/components/searchConfigForm";
import FreeTable from "@/views/components/FreeTable";

// 引入页面层级组件
import AddSub from "./components/addSub";

// 引入接口
import MedalApi from "@/model/modules/medal/index";

// 引入方法
import { getLabelBySelectValue } from "@/utils/tool";

export default {
  name: "medal",
  components: {
    SearchConfig,
    FreeTable,
    AddSub
  },
  data() {
    return {
      titleSrc: "",
      searchConfigData: [
        {
          label: "勋章名称",
          placeholder: "请选择",
          type: "input",
          prop: "keyword"
        }
      ],
      form: {
        name: "",
        type: "",
        getCondition: "",
        greyImg: "",
        highlightImg: ""
      },
      config: [
        [
          {
            colSpan: 12,
            label: "勋章名称",
            type: "input",
            prop: "name"
          },
          {
            colSpan: 12,
            label: "勋章类型",
            type: "select",
            prop: "type",
            options: [
              {
                label: "成就勋章",
                value: 0
              },
              {
                label: "学习勋章",
                value: 1
              }
            ]
          }
        ],
        [
          {
            colSpan: 24,
            label: "获取条件",
            type: "input",
            prop: "getCondition"
          }
        ],
        [
          {
            colSpan: 12,
            label: "勋章置灰",
            type: "uploadAvatar",
            prop: "greyImg"
          },
          {
            colSpan: 12,
            label: "勋章高亮",
            type: "uploadAvatar",
            prop: "highlightImg"
          }
        ]
      ],
      data: [], // 表格数据渲染
      slotColumn: [
        { label: "勋章名称", prop: "name" },
        { label: "勋章类型", prop: "type", slotScope: true },
        { label: "获取条件", prop: "getCondition" },
        { label: "隐藏", prop: "status", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  编辑
                </el-button>
              </div>
            );
          }
        }
      ],
      formSetData: {
        // 查询搜索内容
        pageIndexB1: 1,
        pageSize: 10,
        keyword: ""
      },
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [3, 5, 10],
        total: 1
      },
      dialogImageUrl: "",
      dialogVisible: false,
      // 表单验证
      rules: {
        name: [{ required: true, message: "请输入勋章名称", trigger: "blur" }],
        type: [
          { required: true, message: "请选择勋章类型", trigger: "change" }
        ],
        getCondition: [
          { required: true, message: "请输入获取条件", trigger: "blur" }
        ],
        greyImg: [{ required: true, message: "请选择图片", trigger: "change" }],
        highlightImg: [
          { required: true, message: "请选择图片", trigger: "change" }
        ]
      }
    };
  },

  mounted() {
    this.getDataList();
  },
  methods: {
    getLabelBySelectValue,

    // 监听switch 二次确认之后改变开关状态
    changeSwitch(row) {
      let cur_status = row.status
      row.status = row.status == 1 ? 0 : 1

      this.$confirm('是否修改？', '提示', {})
        .then(res => {
          row.status = cur_status
        this.saveRow(row);
        })
    },

    // 调用接口 保存勋章
    saveRow(row) {
      MedalApi.saveRow(row)
        .then(res => {
          // console.log(res)
          if (res.data.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500
            });
            this.getDataList();
          }
        })
        .catch(res => {
          console.error(response);
          this.$message.error("操作失败");
        });
    },

    // 初始化表格渲染
    async getDataList() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      const res = await MedalApi.pageList(this.formSetData);
      this.data = res.data.body.content;
      this.pagination.total = res.data.body.total * 1;
    },

    // 提交编辑表格行信息
    onEditSubmit(e) {
      this.saveRow(e.form);
    },

    // 打开编辑弹窗
    openDialog({ row }) {
      this.form = {
        ...row
      };

      this.$refs.addSubDialog.showDialog(row);
      this.titleSrc = "勋章修改";
    },

    // 表格查询按钮
    onQuery() {
      this.pagination.pageNum = 1;
      this.getDataList();
    },

    // 分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getDataList();
    },

    handleQueryFocus() {},
    handleFocus() {}
  }
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
