import { deletes, post, get } from '../../service'

export default class MenuShowApi {
  static pageList (params = undefined) {
    return post('/service/appShow/all', params)
  }

  static saveRow (params = undefined) {
    return post('/service/appShow/save', params)
  }
 }
