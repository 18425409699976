/**
 * 充值管理
 */
import { post, get } from '../../service'

export default class DepositApi {
  // 获取充值列表
  static pageList(params = undefined) {
    return post('/service/order/mngDepositList', params)
  }

  // 获取充值总额
  static getDepositSum(params = undefined) {
    return get('/service/order/mngDepositSum', params)
  }

  // 获取操作记录列表
  static operateList(params = undefined) {
    return post('/service/user/reChargeKkcList', params)
  }
}


