<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="5"
      :labelWidth="'70px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        新增平台幻灯片
      </el-button>
    </div>
    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:imgUrl="{ row }">
          <div @click="showImg(row)" style="margin-top: 7px; cursor: pointer">
            <img :src="row.imgUrl" width="30" height="20" class="image" />
          </div>
        </template>
        <template v-slot:ctm="{ row }">
          {{ timeConvert(row.ctm, 1) }}
        </template>
      </free-table>
    </div>
    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
    />
    <el-dialog
      class="el-dialog-col-2"
      :visible.sync="dialogVisible"
      :close-on-click-modal="true"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </d2-container>
</template>
<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'
// 引入页面层级组件
import AddSub from './components/addSub'
// 引入接口
import MenuAppSlidesApi from '@/model/modules/powerpoint/index'

export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
    FreeTable,
    AddSub
  },
  data () {
    return {
      showPos: [], //显示位置
      titleSrc: '',
      searchConfigData: [
        {
          label: '显示位置',
          formItemId: 0,
          placeholder: '请选择',
          type: 'select',
          prop: 'showPos',
          options: []
        }

      ],
      form: {
        bgColor: "",
        carouselName: "",
        imgUrl: "",
        linkUrl: "",
        showPos: "",
        weight: '',
      },
      config: [
        {
          label: 'banner名称',
          type: 'input',
          prop: 'carouselName'
        },
        {
          label: '排序',
          type: 'numberInput',
          prop: 'weight'
        },
        {
          label: '跳转链接',
          type: 'input',
          prop: 'linkUrl'
        },
        {
          label: '显示位置',
          type: 'select',
          prop: 'showPos',
          options: []
        },
        {
          label: '背景颜色',
          type: 'colorInput',
          prop: 'bgColor'
        },
        {
          label: 'banner图片',
          type: 'uploadAvatar',
          prop: 'imgUrl'
        }
      ],
      data: [], // 表格数据渲染
      slotColumn: [
        { label: '显示位置', prop: 'showPos'},
        { label: '排序', prop: 'weight' },
        { label: '名称', prop: 'carouselName' },
        { label: '跳转链接', prop: 'linkUrl' },
        { label: '图片', prop: 'imgUrl', slotScope: true },
        { label: '上传时间', prop: 'ctm', slotScope: true, sort: true },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope)
                  }}
                >
                  编辑
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row)
                  }}
                >
                  删除
                </el-button>
              </div>
            )
          }
        }
      ],
      formSetData: {
        // 查询搜索内容
        pageIndexB1: 1,
        pageSize: 10,
        showPos: ''
      },
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [3, 5, 10],
        total: 1
      },
      dialogImageUrl: '',
      dialogVisible: false,
      // 表单验证
      rules: {
        carouselName: [
          { required: true, message: '请输入标题名称', trigger: 'blur' }
        ],
        bgColor: [
          { required: true, message: '请输入背景颜色', trigger: 'blur' }
        ],
        weight: [
          { required: true, message: '请输入排序号', trigger: 'blur' }
        ],
        linkUrl: [
          {required: true, message: '请输入链接地址', trigger: 'blur'}
        ],
        showPos: [
          {required: true, message: '请选择显示位置', trigger: 'blur'}
        ],
        imgUrl: [
          {required: true, message: '请选择图片', trigger: 'blur'}
        ],
      }
    }
  },

  computed: {
    timeConvert() {//num:0 YYYY-MM-DD  num:1  YYYY-MM-DD hh:mm:ss // timestamp:时间戳 
      return function(timestamp, num) {
        if (timestamp === 0) {
          return '-'
        } else {
          timestamp = timestamp.length === 10  ? timestamp*1000 : timestamp;
          let date = new Date(timestamp);
          let y = date.getFullYear();  
          let m = date.getMonth() + 1;  
          m = m < 10 ? ('0' + m) : m;  
          let d = date.getDate();  
          d = d < 10 ? ('0' + d) : d;  
          let h = date.getHours();
          h = h < 10 ? ('0' + h) : h;
          let minute = date.getMinutes();
          let second = date.getSeconds();
          minute = minute < 10 ? ('0' + minute) : minute;  
          second = second < 10 ? ('0' + second) : second; 
          if(num==0){
            return y + '-' + m + '-' + d;  
          }else{
            return y + '-' + m + '-' + d +' '+ h +':'+ minute +':' + second;  
          }
        }
      }
    }
  },

  mounted () {
    this.getDataList()
    this.getShowPos()
  },
  methods: {
    // 获取显示位置
    async getShowPos(){
      let res = await MenuAppSlidesApi.getShowPos()
      // console.log('getShowPos', res)
      this.showPos = res.data.body.map((item, index) => {
        var obj = {}
        obj.label = item.itemName
        obj.value = item.itemCode
        return obj
      })
      this.searchConfigData[0].options = this.showPos
    },
    
    //  图片放大
    showImg (row) {
      this.dialogImageUrl = row.imgUrl
      this.dialogVisible = true
    },
    formatData (data) {
      const obj = {}
      for (const i in data) {
        const item = data[i]
        item.fDicKey = item.fDicKey || ''
        if (!item.fDicKey) {
          continue
        }
        obj[item.fDicKey] = {
          value: item.fDicKey || '',
          label: item.fDicValue || ''
        }
      }
      return obj
    },
    // 初始化表格渲染
    async getDataList () {
      this.formSetData.pageIndexB1 = this.pagination.pageNum
      this.formSetData.pageSize = this.pagination.pageSize
      const res = await MenuAppSlidesApi.pageList(this.formSetData)
      this.data = res.data.body.content
      this.pagination.total = res.data.body.total * 1
    },
    // 提交编辑表格行信息
    onEditSubmit (e) {
      if ((this.titleSrc == 'banner修改')) {
        MenuAppSlidesApi.saveRow(e.form).then(res => {
          this.getDataList()
        })
      } else if (this.titleSrc == 'banner添加') {
        this.form = e.form
        this.form.status = 1
        this.form.android = 1
        this.form.ios = 1
        this.form.startTm = 0
        this.form.endTm = 0
        delete this.form.fPicUrl
        MenuAppSlidesApi.saveRow(this.form).then(res => {
          this.getDataList()
        })
      }
    },
    // 打开编辑弹窗
    openDialog ({ row }) {
      this.form = {
        ...row
      }

      this.$refs.addSubDialog.showDialog(row)
      this.titleSrc = 'banner修改'
      this.config[3].options = this.showPos

      let showPos_label = this.form.showPos
      let showPos_value = ''
      for(var i=0; i<this.config[3].options.length; i++){
        if(showPos_label == this.config[3].options[i].label){
          showPos_value = this.config[3].options[i].value
          break
        }
      }
      this.form.showPos = showPos_value
    },

    onAdd () {
      this.$refs.addSubDialog.showDialog(this.form)
      this.titleSrc = 'banner添加'
      this.form = {
        bgColor: "",
        carouselName: "",
        imgUrl: "",
        linkUrl: "",
        showPos: "",
        weight: '',
      }
      this.config[3].options = this.showPos
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '提示', {}).then(() => {
        MenuAppSlidesApi.deleteRow({ key: row.id })
          .then(response => {
            if (!response.data.code == 0) return
            this.$message({
              message: '删除成功',
              type: 'success',
              duration: 1500
            })
            this.getDataList()
          })
          .catch(response => {
            console.error(response)
            this.$message.error('删除失败')
          })
      })
    },
   
    // 表格查询按钮
    onQuery () {
      this.pagination.pageNum = 1
      this.getDataList()
    },
    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.getDataList()
    },

    handleQueryFocus(){},
    handleFocus(){}
  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
