<template>
  <d2-container>

    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="6.8"
      :btnColspan="4"
      :labelWidth="'100px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <!-- 添加导航按钮 -->
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        添加优惠券
      </el-button>
    </div>
    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:prescription="{ row }">
          {{row.prescriptionStr}}
        </template>

        <template v-slot:status="{ row }">
          {{row.status == 0 ? '禁用' : '启用'}}
        </template>
      </free-table>
    </div>
    <!--添加/修改弹窗-->
    <add-sub
      :applyCouseList="applyCouseList"
      :couponTypeList="couponTypeList"
      :numList='numList'
      :courseTypeList='courseTypeList'
      :sendRoleList='sendRoleList'
      :dateTimeList='dateTimeList'
      ref="addSubDialog"
      width="50%"
      align="left"
      :form = form
      :config="config"
      :rules="rules"
      :titleSrc="titleSrc"
      :courseList="courseList"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
      @on-get-course="getCourseList"
    />
    <el-dialog
      width="20%"
      class="el-dialog-col-2"
      :visible.sync="dialogVisible"
      :close-on-click-modal="true"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </d2-container>
</template>
<script>
let id = 1000;
//引入查询条件配置组件
import SearchConfig from "@/views/components/searchConfigForm";
import FreeTable from "@/views/components/FreeTable";
//引入页面层级组件
import AddSub from "./components/addSub";
//引入接口
import couponManageListApi from "@/model/modules/couponManage/couponManageList";

export default {
  name: "couponManage",
  components: {
    SearchConfig,
    FreeTable,
    AddSub,
  },
  data() {
    return {
      applyCouseList: [], //适用课程范围数组
      couponTypeList: [], //适用优惠券类型数组
      numList: [], //接受上限数组
      dateTimeList: [], //时效数组
      courseTypeList: [], //适用课程分类数组
      sendRoleList: [], //发送角色
      courseTypeList: [], //适用课程分类数组
      courseList:[], // 适用课程
      titleSrc: "",
      searchConfigData: [
        {
          label: "优惠券名称",
          formItemId: 0,
          placeholder: "请输入",
          type: "input",
          prop: "couponName",
        },
        // {
        //   label: "适用课程分类",
        //   formItemId: 1,
        //   placeholder: "请选择",
        //   type: "select",
        //   prop: "courseType",
        //   options: [],
        // },
        {
          label: "适用课程范围",
          formItemId: 2,
          placeholder: "请选择",
          type: "select",
          prop: "applyCourse",
          options: [],
        }
        // {
        //   label: "优惠券状态",
        //   formItemId: 1,
        //   placeholder: "请选择",
        //   type: "select",
        //   prop: "couponStatus",
        //   options: [],
        // },
        // {
        //   formItemId: 2,
        //   type: 'multipleBoxes',
        //   configDatas: [
        //     {
        //       label: '',
        //       formItemId: 0,
        //       placeholder: '请选择',
        //       type: 'select',
        //       prop: '',
        //       isApi: false,
        //       filterable: false,
        //       multiple: false,
        //       options: [
        //         {
        //           label: '创建时间',
        //           value: '0'
        //         },
        //         {
        //           label: '时效时间',
        //           value: '1'
        //         }
        //       ]
        //     },
        //     {
        //       label: '',
        //       formItemId: 1,
        //       placeholder: '请选择时间',
        //       type: 'timePicker',
        //       prop: '',
        //       optionType: 'date'
        //     },
        //     {
        //       label: '',
        //       formItemId: 2,
        //       placeholder: '请选择时间',
        //       type: 'timePicker',
        //       prop: '',
        //       optionType: 'date'
        //     }
        //   ]
        // },
        // {
        //   formItemId: 2,
        //   type: 'multipleBoxes',
        //   configDatas: [
        //     {
        //       label: '',
        //       formItemId: 0,
        //       placeholder: '请选择',
        //       type: 'select',
        //       prop: '',
        //       isApi: false,
        //       filterable: false,
        //       multiple: false,
        //       options: [
        //         {
        //           label: '发送数量',
        //           value: '0'
        //         },
        //         {
        //           label: '接受上限',
        //           value: '1'
        //         }
        //       ]
        //     },
        //     {
        //       label: '',
        //       formItemId: 1,
        //       placeholder: '请输入',
        //       type: 'input',
        //       prop: '',
        //     },
        //     {
        //       label: '',
        //       formItemId: 2,
        //       placeholder: '请输入',
        //       type: 'input',
        //       prop: '',
        //     }
        //   ]
        // },
      ],
      form: {
        commonType: '',
        couponName: '',
        applyCouse: '',
        sendCount: '',
        num: '',
        dateTime: '',
        couponType: '',
        endTm: '',
        startTm: '',
        sendRole: '',
        courseType: '',
        rulesContent: ''
      },
      config: [
        {
          label: "优惠券名称",
          formItemId: 0,
          placeholder: "请输入",
          type: "input",
          prop: "couponName",
        },
        {
          label: "适用课程分类",
          formItemId: 1,
          placeholder: "请选择",
          type: "select",
          prop: "courseType",
          options: [
              {
                  label: '全部分类',
                  value: 0
              },
              {
                  label: '类型一',
                  value: 1
              },
              {
                  label: '类型二',
                  value: 2
              },
              {
                  label: '类型三',
                  value: 3
              }
          ],
        },
        {
          label: "发放数量",
          formItemId: 2,
          placeholder: "请输入优惠券发放数量",
          type: "input",
          prop: "sendCount",
        },
        {
          label: "发放角色",
          formItemId: 3,
          placeholder: "请选择",
          type: "select",
          prop: "sendRole",
          options: [],
        },
        {
          label: "接收上限",
          type: "select",
          prop: "recCount",
          isApi: false,
          options: [],
        },
        {
          label: "适用课程范围",
          formItemId: 1,
          placeholder: "请选择",
          type: "select",
        //   isApi: true,
          prop: "applyCourse",
          options: [],
        },
        {
          label: "时效",
          formItemId: 4,
          placeholder: "请选择",
          type: "select",
          // isSlot:true,
          prop: "prescription",
          options: [],
        },
        {
          label: "优惠券类型",
          placeholder: "请选择",
          type: "select",
        //   isApi: true,
          prop: "couponType",
          options: [],
        },
        {
          label: "规则（时效）",
          type: "input",
          prop: "",
        },
        {
          label: "规则（优惠券类型）",
          type: "input",
          prop: "",
        },

      ],
      data: [], //表格数据渲染
      slotColumn: [
        //{ label: "选择", prop: "selection", type: "selection" },
        //  { label: '序号', prop: 'index', type: 'index' },
        { label: "优惠券名称", prop: "couponName" },
        // { label: "图片", prop: "imgUrl", slotScope: true },
        { label: "类型", prop: "couponTypeStr"},
        { label: "规则", prop: "rulesContent" },
        // { label: "适用课程类型", prop: "courseType"},
        { label: "适用课程范围", prop: "applyCourseStr"},
        { label: "时效", prop: "prescription", slotScope: true },
        { label: "发送数量", prop: "sendCount"},
        { label: "优惠券状态", prop: "status", slotScope: true },
        { label: "接受上限", prop: "recCount"},
        { label: "创建时间", prop: "ctmStr", sort: true},
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: '300px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row);
                  }}
                >
                  删除
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.upValidStatus(scope.row);
                  }}
                >
                  {scope.row.status == 0 ? "启用" : "禁用"}
                </el-button>
              </div>
            );
          },
        },
      ],
      formSetData: {
        // 查询搜索内容
        pageIndexB1: Number,
        pageSize: Number,
        couponName: '',
        applyCourse: '',
      },
      columnsProps: {
        width: "auto",
        showOverflowTooltip: false,
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 1,
      },
      dialogImageUrl: "",
      dialogVisible: false,
      // 表单验证
      rules: {
        couponName: [
          { required: true, message: "请输入优惠券名称", trigger: "blur" },
        ],
        courseType: [
          { required: true, message: "请选择适用课程分类", trigger: "blur" },
        ],
        sendCount: [
          { required: true, message: "请输入发放数量", trigger: "blur" },
        ],
        sendRole: [
          { required: true, message: "请输入发放角色", trigger: "blur" },
        ],
        recCount: [
          { required: true, message: "请选择接受上限", trigger: "blur" },
        ],
        applyCourse: [
          { required: true, message: "请选择适用课程范围", trigger: "blur" },
        ],
        prescription: [
          { required: true, message: "请选择时效", trigger: "blur" },
        ],
        couponType: [
          { required: true, message: "请选择优惠券类型", trigger: "blur" },
        ],
        commonType: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        endTm: [
          { required: true, message: "请选择活动结束时间", trigger: "blur" },
        ],
        startTm: [
          { required: true, message: "请选择活动开始时间", trigger: "blur" },
        ],
        rulesContent: [
          { required: true, message: "请输入价格", trigger: "blur" },
        ]
      },
    };
  },
  mounted() {
    this.getDataList()
    this.getApplyCourse()
    this.getCouponType()
    this.getNum()
    this.getDateTime()
    this.getCourseType()
    this.getSendRole()
    this.getCourseList('')
    // this.getDataList();
    // dicList({type: 'HOS_NAVI_POSITION'}).then((res) => {
    //   this.fShowPositions=this.formatData(res.data.data)
    // })
  },
  methods: {

    getCourseList(keyword){
      couponManageListApi.findCourseList({keyword:keyword}).then(res=>{
        this.courseList = res.data.body.content;
      })
    },
    // 获取优惠券类型
    getCouponType(){
      couponManageListApi.getCouponType().then((res) => {
        this.couponTypeList = res.data.body.map((item, index) => {
          let obj = {}
          obj.label = item.itemName
          obj.value = item.itemCode
          return obj
        })
      })
    },

    // 获取适用课程范围
    getApplyCourse(){
      couponManageListApi.getApplyCourse().then((res) => {
        this.applyCouseList = res.data.body.map((item, index) => {
          let obj = {}
          obj.label = item.itemName
          obj.value = item.itemCode
          return obj
        })
        this.searchConfigData[1].options = this.applyCouseList
      })
    },

    // 获取接受上限
    getNum(){
      couponManageListApi.getNum().then((res) => {
        this.numList = res.data.body.map((item, index) => {
          let obj = {}
          obj.label = item.itemName
          obj.value = item.itemCode
          return obj
        })
      })
    },

    // 获取时效
    getDateTime(){
      couponManageListApi.getDateTime().then((res) => {
        this.dateTimeList = res.data.body.map((item, index) => {
          let obj = {}
          obj.label = item.itemName
          obj.value = item.itemCode
          return obj
        })
      })
    },

    // 获取适用课程分类
    getCourseType(){
      couponManageListApi.getCourseType().then((res) => {
        this.courseTypeList = res.data.body[0].children[3].children
      })
    },

    // 获取发送角色
    getSendRole(){
      couponManageListApi.getSendRole().then((res) => {
        this.sendRoleList = res.data.body.map((item, index) => {
          let obj = {}
          obj.label = item.roleName
          obj.value = item.roleCode
          return obj
        })
      })
    },

    //初始化表格渲染
    async getDataList() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      let res = await couponManageListApi.pageList(this.formSetData);
      this.data = res.data.body.content;
      this.pagination.total = res.data.body.total * 1;
    },

    //提交编辑表格行信息
    async onEditSubmit(e) {
        this.form.status = 1

      if (this.titleSrc == "优惠券修改") {

        var req = {};
        Object.assign(req, this.form);
        if(typeof req.courseType!='string'){
          req.courseType = JSON.stringify(req.courseType);
        }

        couponManageListApi.saveRow(req)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 1500,
              });
              this.getDataList();
            } else {
              console.error(res);
              this.$message.error("修改失败");
            }
          })
          .catch((err) => {
            console.error(err);
            this.$message.error("修改失败");
          });
      } else if (this.titleSrc == "优惠券添加") {


        var req = {};
        Object.assign(req, this.form);
        if(typeof req.courseType!='string'){
          req.courseType = JSON.stringify(req.courseType);
        }

        couponManageListApi.saveRow(req)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "添加成功",
                type: "success",
                duration: 1500,
              });
              this.pagination.pageNum = 1;
              this.getDataList();
            } else {
              console.error(res);
              this.$message.error("添加失败");
            }
          })
          .catch((err) => {
            console.error(err);
            this.$message.error("添加失败");
          });
      }
    },

    //打开编辑弹窗
    openDialog({ row }) {

      var rowInfo = {};
      Object.assign(rowInfo, row)
      if( row.couponType!=4){
        try{
          rowInfo.courseType = rowInfo.courseType? JSON.parse(rowInfo.courseType):[];
        }catch(e){
          rowInfo.courseType = [];
        }
      }


      this.form = {
        ...rowInfo
      };
      this.titleSrc = "优惠券修改";
      let data = {
        form: this.form,
        titleSrc: this.titleSrc
      }
      this.$refs.addSubDialog.showDialog(data);

    },

    onAdd() {
      this.form = {}

      this.titleSrc = "优惠券添加";
      this.config[4].options = this.dateTime
      this.config[6].options = this.couponTypeList
      this.config[8].options = this.numList
      this.config[9].options = this.applyCouseList

      let data = {
        form: this.form,
        titleSrc: this.titleSrc
      }
      this.$refs.addSubDialog.showDialog(data);
    },


    handleDelete(row) {
      this.$confirm("确认删除吗？", "提示", {}).then(() => {
        couponManageListApi.deleteRow({ key: row.id })
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "删除成功",
                type: "success",
                duration: 1500,
              });
              this.getDataList();
            } else {
              console.error(res);
              this.$message.error("删除失败");
            }
          })
          .catch((err) => {
            console.error(err);
            this.$message.error("删除失败");
          });
      });
    },

    // 启动/禁用
    upValidStatus(row) {

      let mess = row.status==0 ? "确定启用吗？" : "确定禁用吗？";
      let params = {
        id: row.id,
        status: row.status==0 ? 1 : 0
      }
      this.$confirm(mess, "提示", {}).then(() => {
        couponManageListApi.getUpValidStatus(params)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
              });
              this.getDataList();
            } else {
              console.error(res);
              this.$message.error("操作失败");
            }
          })
          .catch((err) => {
            console.error(err);
            this.$message.error("操作失败");
          });
      });
    },
    //表格查询按钮
    onQuery() {
      this.pagination.pageNum = 1;
      this.getDataList();
    },
    //分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getDataList();
    },
    handleFocus(e) {
      this.config[e.index].options = this.fShowPositions;
    },
    handleQueryFocus(e) {
      this.searchConfigData[e.index].options = this.fShowPositions;
    },
  },
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
