<template>
    <div>
        <div class="slider_wrap">
            <el-progress :text-inside="true" :stroke-width="24" :percentage="percentage" status="success"></el-progress>
            <div class="display_flex_wrap">
                <div>已导入 <span class="green_color">1000</span></div>
                <div>速度 <span class="green_color">472</span>条/秒</div>
                <div>剩余 <span class="green_color">0</span>分</div>
            </div>
            <div class="display_flex_wrap">
                <div>总数 <span class="green_color">1000</span></div>
                <div>已导入 <span class="green_color">472</span>条/秒</div>
                <div>重复 <span class="green_color">0</span>分</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"sliderCmp",
        props:{
            dataRatio:Number
        }

    }
</script>

<style lang="scss" scoped>
.slider_wrap{
    width:80%;
    .display_flex_wrap{
        display: flex;
        justify-content: space-between;
        margin-left: 40px;
        margin-right: 40px;
        margin-top:10px;
        .green_color{
            color:green;
            font-size:13px;
        }
    }
}
</style>