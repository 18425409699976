/**
 * 问诊管理
 */
import { post, get } from '../../service'
import apiUrl from './api-url'

export const consultHistory = (params = undefined) => {
  return post(apiUrl.consultHistory, params)
}

export const history = (params = undefined) => {
  return post(apiUrl.history, params)
}

export const updateOnlineStatus = (params = undefined) => {
  return post(apiUrl.updateOnlineStatus, params)
}


