<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="4.8"
      :btnColspan="5"
      :labelWidth="'100px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        新增直播
      </el-button>
    </div>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
      </free-table>
    </div>
    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
      @on-handle-Change="handleChange"
    />

    <!-- 推荐课程 -->
    <recommended-courses
      ref="recommendedCourses"
      :positions="fShowPositions"
      @on-edit-submit="onEditSubmit"
    >
    </recommended-courses>
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'
// 引入页面层级组件
import AddSub from './components/addSub'
import RecommendedCourses from './components/recommendedCourses'

// 引入接口
import {
  liveMenuPosition,
  findAll,
  liveConsultList,
  adddeleteAlter,
  setRecommendLive
} from '@/model/modules/modLive'

export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
    FreeTable,
    AddSub,
    RecommendedCourses
  },
  data () {
    return {
      fShowPositions: {},
      titleSrc: '新增直播',

      // 搜索框渲染
      formSetData: {
        keywordsType: 1,
        keyword: null,
        timeType: 1,
        time: [],
        startTime: null,
        endTime: null,
        liveStatus: null,
        itemCode: null,
        pageIndexB1: Number,
        pageSize: Number
      },
      searchConfigData: [
        {
          label: '',
          formItemId: 0,
          type: 'multipleBoxes',
          configDatas: [
            {
              label: '',
              formItemId: 0,
              placeholder: '请输入直播名称',
              type: 'select',
              isApi: false,
              prop: 'keywordsType',
              width: 130,
              options: [
                {
                  label: '直播名称',
                  value: 1
                },
                {
                  label: '直播号',
                  value: 2
                },
                {
                  label: '主讲人',
                  value: 3
                }
              ]
            },
            {
              label: '',
              formItemId: 1,
              placeholder: '请输入直播名称',
              type: 'input',
              prop: 'keyword',
              width: 170
            }
          ]
        },
        {
          label: '',
          formItemId: 1,
          type: 'multipleBoxes',
          configDatas: [
            {
              label: '',
              formItemId: 0,
              placeholder: '请选择开播时间状态',
              type: 'select',
              isApi: false,
              prop: 'timeType',
              width: 130,
              options: [
                {
                  label: '开播时间',
                  value: 1
                },
                {
                  label: '最近开播时间',
                  value: 2
                },
                {
                  label: '最近结束时间',
                  value: 3
                },
                {
                  label: '创建时间',
                  value: 4
                }
              ]
            },
            {
              label: '',
              formItemId: 1,
              placeholder: '请选择开始日期',
              type: 'timePicker',
              prop: 'startTime',
              optionType: 'date',
              width: 170
            },
            {
              label: '',
              formItemId: 2,
              placeholder: '请选择结束日期',
              type: 'timePicker',
              prop: 'endTime',
              optionType: 'date',
              width: 170
            }
          ]
        },
        {
          label: '菜单位置',
          formItemId: 2,
          placeholder: '请选择菜单位置',
          type: 'select',
          isApi: true,
          prop: 'itemCode',
          options: []
        },
        {
          label: '直播状态',
          formItemId: 3,
          placeholder: '请选择直播状态',
          type: 'select',
          isApi: false,
          prop: ' liveStatus',
          options: [
            {
              label: '未开播',
              value: 1
            },
            {
              label: '正在直播',
              value: 2
            },
            {
              label: '过期未开播',
              value: 3
            },
            {
              label: '已结束',
              value: 4
            },
            {
              label: '已失效',
              value: 5
            }
          ]
        }
      ],

      // 表格数据渲染
      data: [],
      slotColumn: [
        { label: 'id', prop: 'id',width: '300px' },
        { label: '直播名称', prop: 'liveName' },
        { label: '直播房间号', prop: 'liveRoom' },
        { label: '主讲人', prop: 'liveAuthor' },
        { label: '计划开播时间', prop: 'liveBegin', sort: true },
        { label: '直播状态', prop: 'liveStatus' },
        {
          label: '最近直播开始时间',
          prop: 'realBeginTm',
          sort: true,
          width: '170px'
        },
        {
          label: '最近直播结束时间',
          prop: 'realEndTm',
          sort: true,
          width: '170px'
        },
        { label: '创建时间', prop: 'ctm', sort: true },
        { label: '菜单位置', prop: 'itemName' },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '270px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.btnClick(1, scope.row)
                  }}
                >
                  设置推荐
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.btnClick(2, scope.row)
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.btnClick(3, scope.row)
                  }}
                >
                  删除
                </el-button>
              </div>
            )
          }
        }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },

      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      },

      // 新增课程
      form: {
        liveName: '',
        cover: '',
        coverMini: '',
        liveAuthor: '',
        uid: '',
        liveBegin: '',
        liveEnd: '',
        content: ''
      },
      config: [
        [
          {
            colSpan: 12,
            label: '直播名',
            type: 'input',
            prop: 'liveName'
          },
          {
            colSpan: 12,
            label: '主讲人',
            type: 'select',
            prop: 'uid',
            isApi: true,
            options: []
          },
          {
            colSpan: 12,
            label: '直播时间',
            type: 'timePicker',
            prop: 'liveBegin',
            optionType: 'datetime',
            placeholder: '选择日期时间',
            isApi: true
          },
          {
            colSpan: 12,
            label: '直播间禁用时间',
            type: 'timePicker',
            prop: 'liveEnd',
            optionType: 'datetime',
            placeholder: '选择日期时间',
            isApi: false
          }
        ],
        [
          {
            colSpan: 12,
            label: '直播封面',
            type: 'uploadAvatar',
            prop: 'cover'
          },
          {
            colSpan: 12,
            label: '直播小封面',
            type: 'uploadAvatar',
            prop: 'coverMini'
          }
        ],
        [
          {
            label: '直播简介',
            type: 'quill',
            prop: 'content'
          }
        ]
      ],
      // 表单验证
      rules: {
        liveName: [
          { required: true, message: '请输入直播名', trigger: 'blur' }
        ],
        cover: [{ required: true, message: '请输入直播封面', trigger: 'blur' }],
        coverMini: [{ required: true, message: '请输入直播小封面', trigger: 'blur' }],
        liveAuthor: [
          { required: true, message: '请选择主讲人', trigger: 'blur' }
        ],
        liveBegin: [
          { required: true, message: '请输入直播时间', trigger: 'blur' }
        ],
        liveEnd: [
          { required: true, message: '请输入直播间禁用时间', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入课程介绍', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.liveMenuPosition()
    this.liveConsultList()
    this.findAll()
  },
  methods: {
    // 位置
    async liveMenuPosition () {
      const res = await liveMenuPosition()
      this.fShowPositions = this.formatData(
        res.data.body,
        'itemCode',
        'itemName'
      )
    },
    handleQueryFocus (e) {
      this.searchConfigData[e.index].options = this.fShowPositions
    },

    // 列表数据
    async findAll () {
      this.formSetData.pageIndexB1 = this.pagination.pageNum
      this.formSetData.pageSize = this.pagination.pageSize
      const res = await findAll(this.formSetData)
      this.data = res.data.body.content
      this.pagination.total = res.data.body.total * 1
    },

    // 表格查询按钮
    onQuery () {
      this.pagination.pageNum = 1
      this.findAll()
    },

    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.findAll()
    },

    // 主讲人
    async liveConsultList () {
      const res = await liveConsultList()
      this.config.map((item, index) => {
        item.map((n, j) => {
          if (n.label === '主讲人') {
            n.options = this.formatData(res.data.body, 'uid', 'fullname')
          }
        })
      })
    },

    // 新增课程
    onAdd () {
      this.form = {
        liveName: '',
        cover: '',
        coverMini: '',
        liveAuthor: '',
        uid: '',
        liveBegin: '',
        liveEnd: '',
        content: ''
      }
      this.$refs.addSubDialog.showDialog(this.form)
      this.titleSrc = '新增直播'
    },
    handleFocus (e) {},
    handleChange (e) {
      const t = new Date(this.form[e.item.prop]).getTime()
      this.form.liveEnd = this.setTime(t)
    },

    btnClick (index, e) {
      switch (index) {
        case 1: // 设置推荐
          this.$refs.recommendedCourses.showDialog(e)
          this.titleSrc = '设置直播推荐'
          break
        case 2: // 修改
          this.titleSrc = '修改直播'
          this.form = {
            ...e
          }
          this.$refs.addSubDialog.showDialog(this.form)
          break
        case 3: // 删除
          // eslint-disable-next-line no-case-declarations
          const mess =
            e.liveStatus === '正在直播'
              ? '该直播间正在直播中，删除会结束本场直播，确认删除吗？,'
              : '确认删除吗？'
          this.$confirm(mess, '提示', {})
            .then(() => {
              this.listLoading = true
              e.status = -1
              e.ctm = new Date(e.ctm).getTime()
              if (e.realBeginTm) {
                e.realBeginTm = new Date(e.realBeginTm).getTime()
              }
              if (e.realEndTm) {
                e.realEndTm = new Date(e.realEndTm).getTime()
              }
              adddeleteAlter(e).then((res) => {
                if (res.data.code === 0) {
                  this.findAll()
                  this.$message({
                    message: '操作成功',
                    type: 'success',
                    duration: 1500
                  })
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            })
            .catch(() => {})
          break
        default:
          this.$message.error('未定义')
          break
      }
    },

    // 提交编辑表格行信息
    onEditSubmit (e) {
      if (this.titleSrc === '新增直播' || this.titleSrc === '修改直播') {
        if (this.titleSrc === '新增直播') {
          // e.form.uid = e.form.liveAuthor
        }
        if (this.titleSrc === '修改直播') {

          // e.form.uid = e.form.liveAuthor
          if (e.form.ctm) {
            delete e.form.ctm
          }
          if (e.form.realBeginTm) {
            delete e.form.realBeginTm
          }
          if (e.form.realEndTm) {
            delete e.form.realEndTm
          }
        }
        // console.log(e.form)
        adddeleteAlter(e.form).then((res) => {
          if (res.data.code === 0) {
            this.findAll()
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
      } else if (this.titleSrc === '设置直播推荐') {
        setRecommendLive(e.form).then((res) => {
          if (res.data.code === 0) {
            this.findAll()
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }
    },

    formatData (data, key, val) {
      const obj = {}
      for (const i in data) {
        const item = data[i]
        obj[item[key]] = {
          value: item[key] || '',
          label: item[val] || ''
        }
      }
      return obj
    },

    setTime (data) {
      const t = data + 24 * 60 * 60 * 1000 // 24小时 * 60分钟 * 60秒 * 1000
      const d = new Date(t)

      let theMonth = d.getMonth() + 1
      let theDate = d.getDate()
      let theHours = d.getHours()
      let theMinutes = d.getMinutes()
      let theSeconds = d.getSeconds()

      if (theMonth < 10) {
        theMonth = '0' + theMonth
      }
      if (theDate < 10) {
        theDate = '0' + theDate
      }
      if (theHours < 10) {
        theHours = '0' + theHours
      }
      if (theMinutes < 10) {
        theMinutes = '0' + theMinutes
      }
      if (theSeconds < 10) {
        theSeconds = '0' + theSeconds
      }

      const date = d.getFullYear() + '-' + theMonth + '-' + theDate
      const time = theHours + ':' + theMinutes + ':' + theSeconds
      const spare = date + ' ' + time
      return spare
    }
  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
