<template>
    <div>
        <el-dialog
            :title="titleCode"
            :visible.sync="dialogFormVisible"
            v-bind="$attrs"
        >
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="数据导入" name="first">
                    <div class="flex_wrap">
                        <div class="flex_item">
                            <div class="dis_margin">
                                商品信息 id：<span class="color_red">17</span>
                                名称： <span class="color_red">老李卤豆干炸卤豆腐干*250g</span>
                            </div>
                            <div class="dis_margin">
                                出场批次信息 id：<span class="color_red">17</span>
                                名称： <span class="color_red">20210716000008</span>
                            </div>
                        </div>
                    </div>
                    <slider-cmp :dataRatio="dataRatio"></slider-cmp>
                    <div class="dialog-footer">
                        <el-button type="danger" @click="handleCreating">选择文件</el-button>
                        <div class="flex_btn">
                            <el-button type="danger" @click="onStopCreate">停止</el-button>
                            <el-button type="default" @click="onClose">关闭</el-button>
                        </div>
                    </div>

                </el-tab-pane>
                <el-tab-pane label="数据导出" name="second">
                    <div class="flex_wrap">
                        <div class="flex_item">
                            <div class="dis_margin">
                                商品信息 id：<span class="color_red">17</span>
                                名称： <span class="color_red">老李卤豆干炸卤豆腐干*250g</span>
                            </div>
                            <div class="dis_margin">
                                出场批次信息 id：<span class="color_red">17</span>
                                名称： <span class="color_red">20210716000008</span>
                            </div>
                            <div class="dis_margin">
                                <div class="flex_label">导出数据类型</div>
                                <el-radio-group v-model="typeRadio" style="margin-left:10px;">
                                    <el-radio :label="1">excel</el-radio>
                                </el-radio-group>
                            </div>
                            <div class="dis_margin">
                                <div class="flex_label">导出数据范围</div>
                                <el-radio-group v-model="typeRadio" style="margin-left:10px;">
                                    <el-radio :label="1">本批次： 1000条</el-radio>
                                    <el-radio :label="2">id范围</el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                    </div>
                    <slider-cmp :dataRatio="dataRatio"></slider-cmp>
                    <div class="dialog-footer-flex">
                        <el-button type="warning" @click="handleCreating">删除服务器文件</el-button>
                        <el-button type="success" @click="handleDownLoad">下载</el-button>
                        <el-button type="success" @click="onStartCreate">开始导出</el-button>
                        <el-button type="danger" @click="onStopCreate">停止</el-button>
                        <el-button type="success" @click="onClose">关闭</el-button>
                    </div>

                
                </el-tab-pane>
            </el-tabs>


        </el-dialog>
    </div>
</template>

<script>
    import sliderCmp from "@/views/components/sliderCmp/index.vue"
    export default {
        data(){
            return {
                count:1000,
                num:10,
                dialogFormVisible:false,
                radio: 3,
                typeRadio:1,
                isSliderVisible:false,
                dataRatio:{},
                activeName:"first"
            }
        },
        props:{
            titleCode:String
        },
        components:{
            sliderCmp
        },
        methods:{
            showDialog(e){
                this.dialogFormVisible = true
            },
            //开始生成
            handleCreating(){
                this.isSliderVisible = true
            },
            //停止
            onStopCreate(){
                this.isSliderVisible = false

            },
            onClose(){
                this.dialogFormVisible = false
            }
        }
    }
</script>

<style lang="scss" scoped>
.flex_wrap{
    display:flex;
    .flex_item{
        flex:1;
        .color_red{
          color:red;
          margin-right:10px;  
        }
    }
}
.dis_margin{
    margin-bottom:22px;
    font-size:14px;
    display: flex;
    .flex_label{
        flex:0 0 100px;
        width:100px;
    }
}
.dialog-footer{
    display:flex;
    justify-content: space-between;
    align-items:center;
    margin-top:20px;
    .flex_btn{
        display:flex;
        flex:1;
        justify-content: flex-end;
    }
}
.dialog-footer-flex{
    display:flex;
    justify-content:flex-end;
    margin-top:20px;
}
</style>