<template>
  <div>
    <el-dialog :title="titleSrc" :visible.sync="dialogFormVisible">
      <el-form :model="form" class="textDiv">
        <el-form-item label="菜单位置">
          <el-select v-model="form.position" placeholder="请选择" style="width:230px">
            <el-option v-for="item in positions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推荐排序">
          <el-input type="number" min="0" v-model="form.itemNo" autocomplete="off" placeholder="请输入排序序号" style="width:230px"></el-input>
        </el-form-item>
        <el-button class="btn"
          type="success"
          size="small"
          icon="el-icon-circle-plus-outline"
          @click="addClick"
        >
          添加
        </el-button>
      </el-form>

      <div class="border"></div>

      <ul class="infinite-list" style="overflow:auto; ">
        <li class="item" v-for="(item, index) in list" :key="index">
          <span class="item-list">{{ optionsComputed(item) }}</span>
          <span class="item-list">
            <span class="sort">排序</span>
            <el-input size="mini" style="width:70px" v-model="item.itemNo"></el-input>
          </span>
          <el-button
            type="success"
            size="small"
            icon="el-icon-remove-outline"
            circle
            @click="deleteClick(index)"
          >
          </el-button>
        </li>
      </ul>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onEditSubmit">提交</el-button>
        <el-button type="default" @click="onClose">取消</el-button>
      </div>

    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'recommendedCourses',
  components: {
  },
  props: {
    positions: Object,
    titleSrc: String
  },
  data () {
    return {
      dialogFormVisible: false,
      liveId: '',
      form: {
        position: '',
        itemNo: ''
      },
      list: [
      ]
    }
  },
  computed: {
    optionsComputed () {
      return (item) => {
        return this.positions[item.position].label
      }
    }
  },
  methods: {
    showDialog (e = {}) {
      this.dialogFormVisible = true
      this.liveId = e.id
      const arr = [...e.itemList]
      this.list = arr
      this.form = {
        position: '',
        itemNo: ''
      }
    },
    onClose () {
      this.dialogFormVisible = false
      this.liveId = ''
      this.list = []
    },
    addClick () {
      if (this.form.position === '' || this.form.itemNo === '') {
        this.$message({
          message: '不能为空',
          type: 'success',
          duration: 1500
        })
        return
      }
      for (const item of this.list) {
        if (item.position === this.form.position) {
          this.$message({
            message: '已经设置了这个推荐',
            type: 'success',
            duration: 1500
          })
          return
        }
      }
      this.list.unshift(this.form)
      this.form = {
        position: '',
        itemNo: ''
      }
    },
    deleteClick (param) {
      this.list.splice(param, 1)
    },
    onEditSubmit () {
      this.$confirm('确认提交吗？', '提示', {}).then(() => {
        const obj = {
          liveId: this.liveId,
          list: this.list
        }
        this.$emit('on-edit-submit', { form: obj })
        this.onClose()
      }).catch(() => {})
    }
  }
}
</script>
<style>
.textDiv {
  font-size: 14px;
  align-items: center;
  position: relative;
}
.btn {
  position: absolute;
  top: 65%;
  left: 330px;
}
.border {
  width: 100%;
  margin: 10px 0px;
  border: 1px solid #bdbdbd;
}
ul, li {
  margin: 0;
  padding: 0;
}
.infinite-list {
  width: 370px;
  height: 190px;
  font-size: 16px;
}
.item {
  display: flex;
  padding: 10px;
  margin-top: 3px;
  background-color: #F5F5F5;
  align-items: center;
}
.item-list {
  flex: 1;
}
.sort {
  margin-right: 3px;
}
</style>
