import { deletes, post, get } from '../../service'

// 问诊专家管理
export default class SpeakerManageApi {
  // 获取列表
  static pageList (params = undefined) {
    return post('/service/consult/list', params)
  }

  // 新增/修改
  static saveRow (params = undefined) {
    return post('/service/consult/save', params)
  }

  // 删除
  static deleteRow (params = undefined) {
    return get('/service/consult/deleteById/'+params.id)
  }

  // 获取问诊专家用户
  static getSpeakerList (params = undefined) {
    return post('/service/consult/lecturer/asOptions', params)
  }
 }