import Http from '@/api/Http';



export default class UserApi {

    static async providerAsOptions(keyword, onSuccess, onHouseKeeping){
        return await Http.post("/service/user/providers/asOptions", {keyword}
        , onSuccess, onHouseKeeping)
    }

    static async teacherAsOptions(keyword, onSuccess, onHouseKeeping){
        return await Http.post("/service/user/teachers/asOptions", {keyword}
        , onSuccess, onHouseKeeping)
    }
}