<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogFormVisible" width="70%" @close="onClose">
      <div style="padding-top: 25px"></div>
      <div class="textBorderBottom">用户基本信息</div>
      <el-form ref="addSub" :model="form" :rules="rules" label-width="130px" label-position="left">
        <el-row>
          <el-col :span="12">
            <el-form-item label="用户昵称:" prop="nickName">
              <el-input
                v-model="form.nickName"
                autocomplete="off"
                placeholder="请输入用户昵称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户电话:" prop="tel">
              <el-input
                v-model="form.tel"
                autocomplete="off"
                placeholder="请输入用户电话"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="真实姓名:" prop="fullname">
              <el-input
                :disabled="title=='用户修改'? true : false"
                v-model="form.fullname"
                autocomplete="off"
                placeholder="请输入用户真实姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号:" prop="idNum">
              <el-input
                v-model="form.idNum"
                autocomplete="off"
                placeholder="请输入身份证号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="头像" prop="avatar">
              <el-upload
                class="avatar-uploader"
                action="http://dev.kcguoyi.com/service/upload/huawei"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="handleImport"
              >
                <img v-if="form.avatar" :src="form.avatar" class="avatar" @click="onImgClick(0)" />
                <i v-else class="el-icon-plus avatar-uploader-icon" @click="onImgClick(0)"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="用户备注:" prop="remark">
              <el-input
                type="textarea"
                maxlength="300"
                :autosize="{ minRows:4 }"
                v-model="form.remark"
                autocomplete="off"
                placeholder="请输入用户备注"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="role !== 2">
            <el-form-item label="关联所属区域:" prop="adminDivisionStr" >
              <el-cascader 
                v-if="dialogFormVisible"
                clearable
                v-model="form.adminDivisionStr" 
                :props="associatedRegion" 
                class="cascader">
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="role === 2">
            <el-form-item label="绑定服务商:" prop="serviceVal1">
              <el-select
                clearable
                filterable
                placeholder="请选择"
                v-model="form.serviceVal1"
              >
                <el-option
                  :label="optItem.label"
                  :value="optItem.value"
                  v-for="(optItem, index) in serviceProviderOption"
                  :key="index"
                ></el-option>
              </el-select>            
              <el-input
                :disabled="serviceDisabled"
                v-model="form.serviceVal"
                autocomplete="off"
                placeholder='请输入'
                @change="handleServiceChange"
              ></el-input>               
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="role === 2">
            <el-form-item label="诊所名称:" prop="clinicName">
              <el-input
                v-model="form.clinicName"
                autocomplete="off"
                placeholder="请输入诊所名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="role === 2">
            <el-form-item label="诊所地址:" prop="clinicAddress">
              <el-input
                v-model="form.clinicAddress"
                autocomplete="off"
                placeholder="请输入诊所名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="role === 3 || role === 1">
            <el-form-item label="用户类别:" prop="userType">
              <el-select
                clearable
                filterable
                v-model="form.userType"
                placeholder="请选择用户类别"
              >
                <el-option
                  :label="optItem.label"
                  :value="optItem.value"
                  v-for="(optItem, index) in fShowExpertType"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="role === 3">
            <el-form-item label="用户角色信息" prop="roleCode1">
              <el-select
                clearable
                filterable
                v-model="form.roleCode1"
                :multiple="true"
                placeholder="请选择用户角色信息"
              >
                <el-option
                  :label="optItem.label"
                  :value="optItem.value"
                  v-for="(optItem, index) in roleCodeOption"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="role === 3 || role === 2">
            <el-form-item label="所在地区:" prop="provinceCity">
              <el-select
                clearable
                filterable
                v-model="form.province"
                placeholder="请输入省"
                @change="handleChange"
              >
                <el-option
                  :label="optItem.label"
                  :value="optItem.value"
                  v-for="(optItem, index) in fShowProvince"
                  :key="index+'option_selected'"
                ></el-option>
              </el-select>              
              <el-select
                clearable
                filterable
                v-model="form.city"
                placeholder="请输入市"
              >
                <el-option
                  :label="optItem.label"
                  :value="optItem.value"
                  v-for="(optItem, index) in fShowCity"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="role === 2">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="GSP证书/药品经营许可证:" prop="gspImg">
                  <el-upload
                    class="avatar-uploader"
                    :action="'http://dev.kcguoyi.com/service/upload/huawei'"
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="handleImport"
                  >
                    <img v-if="form.gspImg" :src="form.gspImg" class="avatar" @click="onImgClick(1)" />
                    <i v-else class="el-icon-plus avatar-uploader-icon" @click="onImgClick(1)"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="营业执照:" prop="bizLicImg">
                  <el-upload
                    class="avatar-uploader"
                    :action="'http://dev.kcguoyi.com/service/upload/huawei'"
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="handleImport"
                  >
                    <img v-if="form.bizLicImg" :src="form.bizLicImg" class="avatar" @click="onImgClick(4)"/>
                    <i v-else class="el-icon-plus avatar-uploader-icon" @click="onImgClick(4)"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证正面:" prop="idCordImgInfo">
                  <el-upload
                    class="avatar-uploader"
                    :action="'http://dev.kcguoyi.com/service/upload/huawei'"
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="handleImport"
                  >
                    <img v-if="form.idCordImgInfo" :src="form.idCordImgInfo" class="avatar" @click="onImgClick(2)" />
                    <i v-else class="el-icon-plus avatar-uploader-icon" @click="onImgClick(2)"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证反面:" prop="idCardImgNationalEmblen">
                  <el-upload
                    class="avatar-uploader"
                    :action="'http://dev.kcguoyi.com/service/upload/huawei'"
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="handleImport"
                  >
                    <img v-if="form.idCardImgNationalEmblen" :src="form.idCardImgNationalEmblen" class="avatar" @click="onImgClick(3)" />
                    <i v-else class="el-icon-plus avatar-uploader-icon" @click="onImgClick(3)"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="onEditSubmit">提交审核</el-button>
        <el-button type="default" @click="onClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uploadPic } from '@/model/modules/user'
// 引入接口
import { getAreaListInfo, bindCustomerInfo } from '@/model/modules/userinfo'

import SearchConfig from '@/views/components/searchConfigForm'
export default {
  props: {
    title: String,
    fShowProvince: [],
    fShowCity: [],
    fShowExpertType: []
  },
  components: {
    SearchConfig
  },
  data () {
    // 是否上传图片
    var checkImg = (rule, value, callback) => {
      // console.log(rule)
      // console.log(value)
      // console.log(callback)
    }

    // 身份证号验证
    var checkIdNum = (rule, value, callback) => {
      var reg15 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$/
      var reg18 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      if((value.length == 15 && reg15.test(Number(value))) || (value.length == 18 && reg18.test(Number(value)))){
        return callback()
      }else if(!value){
        callback(new Error('请输入身份证号'))
      }else{
        callback(new Error('请输入正确的身份证号码'))
      }
    }

    return {
      is_cascader: true,
      role: 0,
      dialogFormVisible: false,
      associatedRegion: {
        checkStrictly: true,
        lazy: true,
        lazyLoad: this.lazyLoad
      },
      roleCodeOption: [
        {
          label: '课程专家',
          value: 'COMP_SPEAKER'
        },
        {
          label: '直播专家',
          value: 'COMP_COUNSELOR'
        },
        {
          label: '问诊专家',
          value: 'COMP_BROADCASTER'
        }
      ],
      serviceDisabled: true,
      serviceProviderOption: [
        {
          label: '服务商ID',
          value: 'idVal'
        },
        {
          label: '服务商真实姓名',
          value: 'nameVal'
        },
        {
          label: '服务商电话',
          value: 'telVal'
        }
      ],
      imgVal: '',
      form: {
        nickName: '',
        tel: '',
        fullname: '',
        idNum: '',
        remark: '',
        adminDivision: '',
        adminDivisionStr: '',
        location: '',
        locationStr: '',
        serviceVal1: '',
        serviceVal: '',
        idVal: '',
        nameVal: '',
        telVal: '',
        clinicName: '',
        clinicAddress: '',
        userType: '',
        roleCode1: '',
        province: '',
        city: '',
        avatar: '',
        gspImg: '',
        idCordImgInfo: '',
        idCardImgNationalEmblen: '',
        bizLicImg: ''
      },
      rules: {
        nickName: [{ required: true, message: '请输入用户昵称', trigger: 'blur' }],
        tel: [
          { required: true, message: '请输入用户电话', trigger: 'blur' },
          { type : "string" , message: '请输入正确的手机号码', pattern : /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, trigger: 'blur' }
        ],
        // idNum: [{ required: true, validator: checkIdNum , trigger: 'blur' }],
        // userType: [{ required: true, message: '请选择用户类别', trigger: 'change' }],
        fullname: [
          { required: true, type: 'string', message: '请输入用户真实姓名', pattern: /^[\u4e00-\u9fa5]{2,4}$/, trigger: 'blur' }
        ],
        adminDivisionStr: [{ required: true, message: '请选择关联所属区域', trigger: 'change' }],
        clinicName: [{ required: true, message: '请输入诊所名称', trigger: 'blur' }],
        clinicAddress: [{ required: true, message: '请输入诊所地址', trigger: 'blur' }],
        gspImg: [{ required: true, message: '请选择GSP证书/药品经营许可证', trigger: 'change' }],
        idCordImgInfo: [{ required: true, message: '请选择身份证正面', trigger: 'change' }],
        idCardImgNationalEmblen: [{ required: true, message: '请选择身份证反面', trigger: 'change' }],
        bizLicImg: [{ required: true, message: '请选择营业执照', trigger: 'change' }],
        // remark: [{ required: true, message: '请输入用户备注', trigger: 'blur' }],
        avatar: [{ required: true, message: '请添加头像', trigger: 'blur' }],
        roleCode1: [{ required: true, message: '请选择用户角色信息', trigger: 'change' }]
      },
    }
  },
  watch: {
    'form.serviceVal1'() {
      if (this.form.serviceVal1 !== '') {
        this.serviceDisabled = false
      } else {
        this.serviceDisabled = true
      }
    }
  },
  computed: {
    len() {
      if (this.form.adminDivisionStr.length > 0) {
        return this.form.adminDivisionStr.length - 1
      } else {
        return 0
      }
    }
  },
  methods: {
    lazyLoad(node, resolve) {
      let id = ''
      if (node.data !== undefined) {
        id = node.data.id
      }
      getAreaListInfo({ id: id }).then(res => {
        if (res.data.code === 0) {
          const nodes = res.data.body.map(item => ({
            value: `${item.orgCode}`,
            label: `${item.orgName}`,
            id: item.id,
          }))
          resolve(nodes);
          // if (this.len > 0) {
          //   console.log(node.level)
          //   const nodes = res.data.body.map(item => ({
          //     value: `${item.orgCode}`,
          //     label: `${item.orgName}`,
          //     id: item.id,
          //     leaf: node.level >= this.len
          //   }))
          //   resolve(nodes);
          // }
          // else {
          //   const nodes = res.data.body.map(item => ({
          //     value: `${item.orgCode}`,
          //     label: `${item.orgName}`,
          //     id: item.id,
          //   }))
          //   resolve(nodes);
          // }
        }
      })
    },
    handleServiceChange(val) {
      if (this.form.serviceVal1 === 'idVal') {
        this.form.idVal = this.form.serviceVal
      } else if (this.form.serviceVal1 === 'nameVal') {
        this.form.nameVal = this.form.serviceVal
      } else if (this.form.serviceVal1 === 'telVal') {
        this.form.telVal = this.form.serviceVal
      }
    },
    handleChange(val) {
      this.form.city = ''
      this.$emit('on-handle-change', val)
    },
    onImgClick(index) {
      this.imgVal = index
    },
    handleImport (file, fileList) {
      const formdata = new FormData()
      formdata.append('file', file.raw)
      uploadPic(formdata).then(res => {
        if (res.data.code === 0) {
          if (this.imgVal === 0) {
            this.form.avatar = res.data.body[0].filepath
          } else if (this.imgVal === 1) {
            this.form.gspImg = res.data.body[0].filepath
          } else if (this.imgVal === 2) {
            this.form.idCordImgInfo = res.data.body[0].filepath
          } else if (this.imgVal === 3) {
            this.form.idCardImgNationalEmblen = res.data.body[0].filepath
          } else if (this.imgVal === 4) {
            this.form.bizLicImg = res.data.body[0].filepath
          }
          this.imgVal = ''
        }
      })
    },
    showDialog (roleIdx, e) {
      console.log(e)
      this.role = roleIdx;
      if (e !== undefined) {
        this.form = e
        if(e.province == undefined || e.province == "undefined"){
          this.form.province = "";
        }
        if(e.city == undefined || e.city == "undefined"){
          this.form.city = "";
        }
      
        // 去掉输入框前后空格
        let str = this.form.fullname.replace(/^\s+|\s+$/g,"");
        this.form.fullname = str
      }else{
        this.form = {
          nickName: '',
          tel: '',
          fullname: '',
          idNum: '',
          remark: '',
          adminDivision: '',
          adminDivisionStr: '',
          location: '',
          locationStr: '',
          serviceVal1: '',
          serviceVal: '',
          idVal: '',
          nameVal: '',
          telVal: '',
          clinicName: '',
          clinicAddress: '',
          userType: '',
          roleCode1: '',
          province : "",
          city: '',
          avatar: '',
          gspImg: '',
          idCordImgInfo: '',
          idCardImgNationalEmblen: '',
          bizLicImg: ''
        }
      }
      this.dialogFormVisible = true
    },
    onClose () {
      this.dialogFormVisible = false
      this.form = {
        nickName: '',
        tel: '',
        fullname: '',
        idNum: '',
        remark: '',
        adminDivision: '',
        adminDivisionStr: '',
        location: '',
        locationStr: '',
        serviceVal1: '',
        serviceVal: '',
        idVal: '',
        nameVal: '',
        telVal: '',
        clinicName: '',
        clinicAddress: '',
        userType: '',
        roleCode1: '',
        province: '',
        city: '',
        avatar: '',
        gspImg: '',
        idCordImgInfo: '',
        idCardImgNationalEmblen: '',   
        bizLicImg: ''
      }
    },
    onEditSubmit () {
      this.$refs.addSub.validate((valid) => {
        console.log(this.form)
        
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            if (this.form.province !== '' || this.form.city !== '') {
              this.form.location = this.form.province+','+this.form.city
            }
            if (this.form.roleCode1 !== '') {
              let str = ''
              this.form.roleCode1.map((item, index) => {
                str += item+','
              })
              this.form.roleCode = str.substring(0, str.length-1)
            }
            // if (this.form.locationStr !== '') {
            //   let str1 = ''
            //   this.form.locationStr.map((item, index) => {
            //     str1 += item+','
            //   })
            //   this.form.location = str1.substring(0, str1.length-1)
            // }
            if (this.form.adminDivisionStr !== '') {
              let str1 = ''
              this.form.adminDivisionStr.map((item, index) => {
                str1 += item+','
              })
              this.form.adminDivision = str1.substring(0, str1.length-1)
            }
            this.$emit('on-add-submit', this.form)
            this.onClose()
          }).catch(() => {})
        } else {
          return false;
        }
      });
    }
  }
}
</script>
<style scoped>
.textBorderBottom {
  width: 100%;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  padding-left: 13px;
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 10px;
}
.textBorderBottom:before {
  content: "";
  display: block;
  width: 3px;
  height: 20px;
  position: absolute;
  left: 0px;
  top: -2px;
  background-color: #409eff;
}
.flex_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.avatar-uploader,.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 150px;
  height: 150px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
.cascader >>> .el-input__inner {
  width: 460px;
  font-size: 13px;
}
/deep/ .el-input__inner {
  width: 240px;
  font-size: 13px;
}
</style>
