
import { post } from '../../service'
import apiUrl from './api-url'

// 直播管理
export const liveMenuPosition = (params = undefined) => {
  return post(apiUrl.liveMenuPosition, params)
}
export const findAll = (params = undefined) => {
  return post(apiUrl.findAll, params)
}
export const liveConsultList = (params = undefined) => {
  return post(apiUrl.liveConsultList, params)
}
export const adddeleteAlter = (params = undefined) => {
  return post(apiUrl.adddeleteAlter, params)
}
export const setRecommendLive = (params = undefined) => {
  return post(apiUrl.setRecommendLive, params)
}

// 我的直播
export const myLiveList = (params = undefined) => {
  return post(apiUrl.myLiveList, params)
}
export const startAndStopLive = (params = undefined) => {
  return post(apiUrl.startAndStopLive, params)
}
// 获取直播拉流地址
export const fetchPullUrl = (params = undefined) => {
  return postRequest(apiUrl.fetchPullUrl, params)
}
