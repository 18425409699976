import Http from '@/api/Http';



export default class CouponApi {

    /**
     * 请求优惠券持有列表
     * @param {*} param 分页查询表单.
     * @param {function} onUpdateData 更新数据回调.
     * @param {function} onUpdatePaging 更新分页信息回调.
     * @param {function} onUpdateExt 扩展信息回调.
     * @returns Promise.
     */
    static async postCouponHoldList(param, onUpdateData, onUpdatePaging, onUpdateExt){
        return await Http.postPageData("/service/coupon/hold/list", param
        , onUpdateData, onUpdatePaging, onUpdateExt)
    }

    static async asOptions(keyword, onSuccess, onHouseKeeping){
        return await Http.post("/service/coupon/asOptions", {keyword}
        , onSuccess, onHouseKeeping)
    }
    static async commonList(){
        return await Http.get("/service/coupon/hold/commonList")
    }
    /**
     * 发优惠券给服务商和讲师
     * @param {*} param 发送参数.
     * @param {*} onSuccess 成功回调.
     * @param {*} onHouseKeeping 清理.
     * @returns 
     */
    static async sendCoupon(param, onSuccess, onHouseKeeping){
        return await Http.post("/service/coupon/hold/send", param
        , onSuccess, onHouseKeeping)
    }

    
    /*
     * 发放优惠券
     * @param {String} couponId 
     * @param {String[]} targetUserIds 
     * @returns 
     static async postSendCoupon(param, onUpdateData, onUpdatePaging, onUpdateExt){
        return await Http.postPageData("/service/demo/list", param
        , onUpdateData, onUpdatePaging, onUpdateExt)
    }
    static async postSendCoupon(couponId, targetUserIds){
        return await post("/service/coupon/hold/send",{couponId, targetUserIds});
    }

     */
    
};


