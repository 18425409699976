<template>
  <div>
    <el-dialog
      :title="titleSrc"
      :visible.sync="dialogFormVisible"
      v-bind="$attrs"
      :before-close="saveDialogHandleClose"
    >
      <el-form
        ref="searchConfig"
        :inline="true"
        :model="form"
        :rules="rules"
        label-width="150px"
        label-position="left"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="优惠券名称" prop="couponName">
              <el-input v-model="form.couponName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.couponType == 4">
            <el-form-item label="适用课程" prop="courseType">
              <el-select
                v-model="form.courseType"
                clearable
                filterable
                multiple
                remote
                placeholder="请选择"
                :remote-method="remoteMethod"
                :loading="loading"
                :disabled="is_read"
                @change="handleSelectChange"
              >
                <el-option
                  v-for="item in courseList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                  clearable
                  placeholder="请选择"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-else>
            <el-form-item label="适用课程分类" prop="courseType">
              <!-- <el-select v-model="form.courseType" clearable placeholder="请选择">
              <el-option v-for="item in courseTypeList" :key="item.value" :label="item.label" :value="item.value" clearable placeholder="请选择"></el-option>
            </el-select> -->
              <el-cascader
                :disabled="is_read"
                :show-all-levels="false"
                :options="courseTypeList"
                :props="{
                  checkStrictly: false,
                  multiple: true,
                  value: 'id',
                  label: 'clgName',
                  children: 'children'
                }"
                v-model.lazy="form.courseType"
                clearable
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="时效" prop="prescription">
              <el-select
                v-model="form.prescription"
                clearable
                placeholder="请选择"
                :disabled="is_read"
                @change="changePrescription"
              >
                <el-option
                  v-for="(item, index) in dateTimeList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item v-if="form.prescription == 3" label="规则（时效）">
              <el-input
                value="永久有效"
                readonly
                :disabled="is_read"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-else-if="form.prescription == 1"
              label="规则（时效）"
            >
              <el-form-item prop="startTm">
                <el-date-picker
                  v-model="form.startTm"
                  type="datetime"
                  :disabled="is_read"
                >
                </el-date-picker>
              </el-form-item>
              <div>-</div>
              <el-form-item prop="endTm">
                <el-date-picker
                  v-model="form.endTm"
                  type="datetime"
                  :disabled="is_read"
                >
                </el-date-picker>
              </el-form-item>
            </el-form-item>

            <el-form-item v-else label="规则（时效）">
              <el-input placeholder="请选择时效类型" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="优惠券类型" prop="couponType">
              <el-select
                v-model="form.couponType"
                clearable
                placeholder="请选择"
                :disabled="is_read"
                @change="changeCouponType"
              >
                <el-option
                  v-for="(item, index) in couponTypeList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="规则（优惠券类型）"
              v-if="form.couponType == 1"
            >
              <span>满</span>
              <el-input
                v-model="input1"
                class="small_input"
                :disabled="is_read"
              ></el-input>
              <span>减</span>
              <el-input
                v-model="input2"
                class="small_input"
                :disabled="is_read"
              ></el-input>
              <span>单位（元）</span>
            </el-form-item>

            <el-form-item
              label="规则（优惠券类型）"
              v-else-if="form.couponType == 2"
            >
              <span>减</span>
              <el-form-item prop="rulesContent" style="margin: 0 20px;">
                <el-input
                  class="small_input"
                  v-model="form.rulesContent"
                  :disabled="is_read"
                ></el-input>
              </el-form-item>
              <span>单位（元）</span>
            </el-form-item>

            <el-form-item
              label="规则（优惠券类型）"
              v-else-if="form.couponType == 3"
            >
              <span>折扣</span>
              <el-input
                v-model="form.rulesContent"
                class="small_input"
                :disabled="is_read"
              ></el-input>
              <span>%</span>
            </el-form-item>

            <el-form-item
              label="规则（优惠券类型）"
              v-else-if="form.couponType == 4"
              prop="commonType"
            >
              <el-select
                v-model="form.commonType"
                placeholder="请选择"
                clearable
                :disabled="is_read"
              >
                <el-option
                  v-for="item in commonTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="规则（优惠券类型）" v-else>
              <el-input value="请选择优惠券类型" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12" v-if="form.couponType != 4">
            <el-form-item label="发放数量" prop="sendCount">
              <el-input v-model="form.sendCount" :disabled="is_read"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="form.couponType != 4">
            <el-form-item label="发放角色" prop="sendRole">
              <el-select
                v-model="form.sendRole"
                placeholder="请选择"
                :disabled="is_read"
              >
                <el-option
                  v-for="item in sendRoleList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  clearable
                  placeholder="请选择"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12" v-if="form.couponType != 4">
            <el-form-item label="接受上限" prop="recCount">
              <el-select
                v-model="form.recCount"
                clearable
                placeholder="请选择"
                :disabled="is_read"
              >
                <el-option
                  v-for="(item, index) in numList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="form.couponType != 4">
            <el-form-item label="适用课程范围" prop="applyCourse">
              <el-select
                v-model="form.applyCourse"
                placeholder="请选择"
                :disabled="is_read"

              >
                <el-option
                  v-for="item in applyCouseList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-col :span="24">
          <el-form-item label="规则" v-if="form.couponType == 4" label-width="40px">
            <span>：注册即送</span>
          </el-form-item>
        </el-col> -->

        <div class="dialog-footer" style="text-align: right">
          <el-button type="primary" @click="onEditSubmit">提交</el-button>
          <el-button type="default" @click="onClose">取消</el-button>
        </div>
      </el-form>

      <!-- <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onEditSubmit">提交</el-button>
        <el-button type="default" @click="onClose">取消</el-button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>import { consultHistory } from "@/model/modules/mod_health/index";

// import SearchConfig from "@/views/components/searchConfigForm";
export default {
  name: "addSub",
  // components: {
  //   SearchConfig
  // },
  props: {
    applyCouseList: Array,
    couponTypeList: Array,
    courseTypeList: Array,
    numList: Array,
    dateTimeList: Array,
    sendRoleList: Array,
    config: Array,
    // form: Object,
    rules: Object,
    courseList: Array
    // titleSrc: String
  },
  data() {
    return {
      courseTypeStr: '',
      commonTypeOptions: [
        {
          label: "注册用户",
          value: 0
        },
        {
          label: "指定发放",
          value: 1
        }
      ],
      input1: "",
      input2: "",
      is_read: false,
      dialogFormVisible: false,
      cur_dateTime: 0,
      cur_couponType: 0,
      allObjTags: [],
      obj: "",
      ratio: "",
      form: {

      },
      titleSrc: "",
      labelData: Number,
      loading: false
    };
  },
  methods: {
    changeCouponType(e){
      this.form.courseType = '';
    },
    handleSelectChange(e){
      console.log(e)
      var str = e.join(',')
      this.courseTypeStr = str
      this.$set(this.form, this.form.courseType, e)
    },

    saveDialogHandleClose(done) {
      this.$refs.searchConfig.clearValidate();
      this.dialogFormVisible = false;
    },

    remoteMethod(e) {
      this.$emit("on-get-course", e);
    },
    changePrescription() {
      delete this.form["startTm"];
      delete this.form["endTm"];
    },

    showDialog(e = {}) {
      this.form = e.form;

      this.titleSrc = e.titleSrc;
      if (this.titleSrc == "优惠券修改") {
        this.is_read = true;
      } else if (this.titleSrc == "优惠券添加") {
        this.is_read = false;
      }

      // 拼接满减优惠券的规则
      if (this.form.couponType == 1) {
        let rule = this.form.rulesContent;
        let ruleArr = rule.split("-");
        this.input1 = ruleArr[0];
        this.input2 = ruleArr[1];
      }

      if(this.form.couponType == 4){
        this.form.courseType = this.form.courseType.split(',')
      }

      this.dialogFormVisible = true;
      // this.form.courseType = [["100", "101", "10101", "1010101", "101010101"]];
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key.toString();
    },
    onEditSubmit() {
      this.$refs.searchConfig.validate(valid => {
        if (!valid) return;

        if (this.form.couponType == 1) {
          this.form.rulesContent = this.input1 + "," + this.input2;
        }

        if (this.form.couponType == 4 &&this.form.commonType == 0) {
          this.form.rulesContent = "注册即送";
        } else if (this.form.couponType == 4 && this.form.commonType == 1) {
          this.form.rulesContent = "指定发放";
        }



        if (this.form.prescription == 1) {
          this.form.startTm = this.$moment(this.form.startTm).valueOf();
          this.form.endTm = this.$moment(this.form.endTm).valueOf();
        } else if (this.form.prescription == 3) {
          this.form.startTm = 0;
          this.form.endTm = 0;
        }

        this.$confirm("确认提交吗？", "提示", {}).then(() => {
          if(this.form.couponType == 4){
            console.log(this.courseTypeStr)
            this.form.courseType = this.courseTypeStr
          }
          this.$emit("on-edit-submit", { form: this.form, visible: false });
          this.onClose();
        });
      });
    },
    onClose() {
      this.dialogFormVisible = false;
    },
    handleFocus(e) {
      this.labelData = e.index;
      this.$emit("on-focus-select", { index: e.index });
    },
    handleAvatarSuccess(e) {
      e.origin.imgUrl = e.res.filepath;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    }
  }
};
</script>
<style>
.small_input {
  width: 80px;
  /* margin: 0 20px; */
}

.mt20 {
  margin-top: 20px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}


.el-select__tags-text {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-tag__close.el-icon-close {
  top: -7px;
}

</style>
