<template>
  <d2-container>
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        添加公众号
      </el-button>
    </div>
    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >

      </free-table>
    </div>
    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
    />
  </d2-container>
</template>
<script>
import FreeTable from "@/views/components/FreeTable";
//引入页面层级组件
import AddSub from "./components/addSub";
//引入接口addWxMenuButton
import {
  pageList,
  addRow,
  editRow,
  changeData
} from "@/model/modules/pubnum";
export default {
  name: "roleinfo",
  components: {
    FreeTable,
    AddSub
  },
  data() {
    return {
      titleSrc:"添加公众号",
      data: [], //表格数据
      slotColumn: [
        {
          label: "",
          prop: "expand",
          type: "expand",
          render: (h, scope) => {
            return (
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="Token:">
                  <span>{scope.row.fToken}</span>
                </el-form-item>
                <el-form-item label="AesKey:">
                  <span>{scope.row.fAesKey}</span>
                </el-form-item>
                <el-form-item label="关注回复:">
                  <span>{scope.row.fReplyMsg}</span>
                </el-form-item>
              </el-form>
            );
          },
        },
        { label: 'ID', prop: 'index', type: 'index' },
        { label: "公众号名称", prop: "fPublicName" },
        { label: "AppId", prop: "fAppId" },
        { label: "Secret", prop: "fAppSecret" },
        { label: "启用状态", prop: "fValidaStatus" },
        { label: "备注", prop: "remark" },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: "300",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  icon="el-icon-document-copy"
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  编辑
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.goEditPubNumMenu(scope);
                  }}
                >
                  编辑菜单
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleSetKeyWords(scope);
                  }}
                >
                  设置关键词
                </el-button>
              </div>
            );
          },
        },
      ],
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      formSetData: {
        pageNum: Number,
        pageSize: Number,
      },
      rules: { // 表单验证
        fPublicName: [
          { required: true, message: '请输入公众号名称', trigger: 'blur' }
        ],
        fAppId: [
          { required: true, message: '请输入公众号AppId', trigger: 'blur' }
        ],
        fAppSecret: [
          { required: true, message: '请输入公众号Secret', trigger: 'blur' }
        ],
        fToken: [
          { required: true, message: '请输入Token', trigger: 'blur' }
        ],
        fAesKey: [
          { required: true, message: '请输入AesKey', trigger: 'blur' }
        ]
      },
      form: {},
      config: [
        {
          label: "公众号名称",
          type: "input",
          prop: "fPublicName"
        },
        {
          label: "AppId",
          type: "input",
          prop: "fAppId"
        },
        {
          label: "AppSecret",
          type: "input",
          prop: "fAppSecret"
        },
        {
          label: "Token",
          type: "input",
          prop: "fToken"
        },
        {
          label: "AesKey",
          type: "input",
          prop: "fAesKey"
        },
        {
          label: "关注回复",
          type: "input",
          prop: "fReplyMsg"
        }
      ],
      menuData:{}
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    //初始化表格渲染
    async getDataList() {
      this.formSetData.pageNum = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      let res = await pageList(this.formSetData);
      this.data = res.data.data.pag;
      console.log(res.data.data, "xxxccc===res.data.data");
      this.pagination.total = res.data.data.total * 1;
    },
    //分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getDataList();
    },
    onAdd() {
      this.form = {
        fPublicName: '',
        fAppId: '',
        fAppSecret: '',
        fToken: '',
        fAesKey: '',
        fReplyMsg: ''
      };
      this.$refs.addSubDialog.showDialog(this.form);
      this.titleSrc = "添加公众号";
    },
        //提交编辑表格行信息
    onEditSubmit(e) {
      if ((this.titleSrc == "编辑公众号")) {
        editRow(e.form).then(res => {
          this.getDataList();
        });
      } else if(this.titleSrc == "添加公众号") {
        addRow(e.form).then(res => {
          this.getDataList();
        });
      }
    },
    openDialog({ row }) {
      this.form = row;
      this.$refs.addSubDialog.showDialog(row);
      this.titleSrc = "编辑公众号";
    },
    goEditPubNumMenu({row}){
      this.$router.push("/modEchat/wxPublicManage?fId="+row.fId+"&fPublicName="+row.fPublicName)
    },
    handleSetKeyWords({row}){
      this.$router.push("/modEchat/setKeyWords?fId="+row.fId+"&fPublicName="+row.fPublicName)
    }
  },
};
</script>
<style>
.table_a {
  border: 1px solid #ebeef5;
  /* 消除单元格之间的空隙 */
  border-collapse: collapse;
}
.table_a th,
.table_a td {
  padding: 10px;
  border: 1px solid #ebeef5;
}
</style>
<style lang="scss" scoped>
.mt20 {
  margin-top: 20px;
}
.panel_wrap {
  border: 1px solid #ddd;
  height: 400px;
  .panel_title {
    font-size: 14px;
    margin-left: 10px;
  }
  .panel_column_flex {
    display: flex;
    .panel_column_item {
      height: 40px;
      background: #ddd;
      text-align: center;
      line-height: 40px;
    }
  }
}
</style>
