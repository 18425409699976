/**
 * 首页管理-导航设置
 */
 const apiUrl ={
    getPageList: `/erp/prodbatch/query`,
    insertInfo: `/erp/prodbatch/save`,
    updateInfo: `/erp/prodbatch/save`,
    getGoodsList: `/erp/production/query`,
    buildCode: `/erp/antifakecode/buildBatchValidateCodes`,
    exportCode: `/erp/antifakecode/exportBatchValidateExcel/`
}

export default apiUrl 