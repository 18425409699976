<template>
  <d2-container>
    <!--查询条件配置组件-->
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="分类编码">
        <el-input
          v-model="formInline.cateCode"
          clearable
          placeholder="请输入分类编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="分类名称">
        <el-input
          v-model="formInline.cateName"
          clearable
          placeholder="请输入分类名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">搜索</el-button>
        <el-button type="primary" @click="onAdd">新增</el-button>
      </el-form-item>
    </el-form>
    <!--表单内容-->
    <el-row :gutter="40">
      <el-col :span="10">
        <div class="panel_wrap">
          <free-table
            border
            :data="data"
            :column="slotColumn"
            :columnsProps="columnsProps"
            pagination
            :auto-scroll="false"
            :total.sync="pagination.total"
            :page.sync="pagination.pageNum"
            :limit.sync="pagination.pageSize"
            @pagination="getList"
          />
        </div>
      </el-col>
      <el-col :span="10">
        <div class="panel_wrap" v-show="isExpendSubPanel">
          <free-table
            v-if="isExpendSubPanel"
            border
            :data="dataSubNum"
            :column="slotSubNumColumn"
            :columnsProps="columnsProps"
            :pagination="true"
            :auto-scroll="false"
            :pageSizes="pagination.pageKeySizes"
            :total.sync="pagination.Keytotal"
            :page.sync="pagination.pageKeyNum"
            :limit.sync="pagination.pageKeySize"
            @pagination="getSubList"
          />
        </div>
      </el-col>
    </el-row>
    <!--新增分类-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :searchConfigData="searchConfigData3"
      :form="formAdd"
      :rules="rules1"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
    />
    <!--新增子码-->
    <add-sub
      ref="addParentDialog"
      width="50%"
      align="left"
      :searchConfigData="searchConfigData1"
      :form="form1"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
    />
    <!--编辑子码-->
    <add-sub
      ref="editSubDialog"
      width="50%"
      align="left"
      :searchConfigData="searchConfigData2"
      :form="form2"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
    />
  </d2-container>
</template>

<script>
// 接口对接
import {
  dictInfo,
  getKeyDicPage,
  addKeyDicPage,
  addRow,
  editKeyDicPage
} from "@/model/modules/dict";
// 引入查询条件配置组件
import SearchConfig from "@/views/components/searchConfigForm";
import FreeTable from "@/views/components/FreeTable";
// 引入页面层级组件
import AddSub from "./components/addSub";
export default {
  name: "dictinfo",
  components: {
    SearchConfig,
    FreeTable,
    AddSub // 新增组件
  },
  data() {
    return {
      isExpendSubPanel: false,
      formInline: {
        pageIndexB1: 1,
        pageSize: 10
      },
      queryKeyData: {
        // 查询
        cateCode: "",
        pageIndexB1: 1,
        pageSize: 10
      },
      pagination: {
        // 分页
        pageNum: 1,
        pageSizes: [10, 20, 30, 50],
        pageSize: 10,
        total: 0,
        pageKeyNum: 1,
        pageKeySizes: [10, 20, 50, 100],
        pageKeySize: 10,
        Keytotal: 0
      },
      data: [], // 表格数据渲染
      dataSubNum: [],
      form2: {},
      form1: {},
      formAdd: {},
      slotSubNumColumn: [
        // 子码表格列头
        { label: "子码值", prop: "itemCode" },
        { label: "子码名", prop: "itemName" },
        { label: "排序", prop: "itemOrder" },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: 200,
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="text"
                  size="small"
                  onClick={() => {
                    this.checkAndModified(scope);
                  }}
                >
                  查看/修改
                </el-button>
              </div>
            );
          }
        }
      ],
      slotColumn: [
        // 分类表格列头
        { label: "分类编码", prop: "cateCode" },
        { label: "分类名称", prop: "cateName" },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: 200,
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.addOperateBtn(scope);
                  }}
                >
                  新增
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openPanel(scope.$index, scope.row);
                  }}
                >
                  子码
                </el-button>
              </div>
            );
          }
        }
      ],
      columnsProps: {
        width: "auto"
      },
      editForm: {},
      titleDialog: "新增功能权限配置",
      dialogVisible: false,
      searchConfigData2: [
        {
          label: "子码值",
          type: "input",
          prop: "itemCode"
        },
        {
          label: "子码名",
          type: "input",
          prop: "itemName"
        },
        {
          label: "排序",
          type: "input",
          prop: "itemOrder"
        },
        // {
        //   label: '备注',
        //   type: 'input',
        //   prop: 'fDicRemark'
        // },
        {
          label: "父节点",
          type: "input",
          prop: "cateCode",
          disabled: true
        },
        {
          label: "子码内容",
          type: "input",
          prop: "itemText"
        }
      ],
      searchConfigData1: [
        {
          label: "分类编码",
          type: "input",
          prop: "cateCode",
          disabled: true
        },
        {
          label: "分类名称",
          type: "input",
          prop: "cateName",
          disabled: true
        },
        {
          label: "子码值",
          type: "input",
          prop: "itemCode"
        },
        {
          label: "子码名",
          type: "input",
          prop: "itemName"
        },
        {
          label: "排序",
          type: "input",
          prop: "itemOrder"
        },
        {
          label: "说明",
          type: "input",
          prop: "itemDesc"
        },
        {
          label: "子码内容",
          type: "input",
          prop: "itemText"
        }
      ],
      searchConfigData3: [
        {
          label: "分类编码",
          type: "input",
          prop: "cateCode"
        },
        {
          label: "分类名称",
          type: "input",
          prop: "cateName"
        },
        {
          label: "排序",
          type: "input",
          prop: "cateOrder"
        },
        {
          label: "说明",
          type: "input",
          prop: "cateDesc"
        }
      ],
      // 校验规则
      rules1: {
        cateCode: [
          { required: true, message: "请输入分类编码", trigger: "blur" }
        ],
        cateName: [
          { required: true, message: "请输入分类名称", trigger: "blur" }
        ],
        cateOrder: [{ required: true, message: "请输入排序", trigger: "blur" }]
      },
      rules: {
        itemCode: [
          { required: true, message: "请输入子码值", trigger: "blur" }
        ],
        itemName: [
          { required: true, message: "请输入子码名", trigger: "blur" }
        ],
        itemOrder: [
          {
            required: true,
            message: "请输入排序",
            trigger: "blur"
          }
        ]
      },
      titleSrc: "新增"
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getListData();
    },
    getListData() {
      // 字典表表格信息获取
      this.formInline.pageIndexB1 = this.pagination.pageNum;
      this.formInline.pageSize = this.pagination.pageSize;
      dictInfo(this.formInline).then(res => {
        this.data = res.data.body.content;
        this.pagination.total = res.data.body.total * 1;
      });
    },
    // 提交编辑表格行信息
    onEditSubmit(e) {
      if (this.titleSrc == "编辑子码") {
        const para = e.form;
        editKeyDicPage(para)
          .then(res => {
            if (res.data.code == 0) {
              this.$message({
                message: "新增成功",
                type: "success",
                duration: 1500
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
                duration: 1500
              });
            }
            this.subPageTabel();
          })
          .catch(err => {
            this.$message({
              message: err.data.msg,
              type: "error",
              duration: 1500
            });
            this.subPageTabel();
          });
      } else if (this.titleSrc == "新增子码") {
        const para = e.form;
        delete para.id;
        para.status = 1;
        addKeyDicPage(para)
          .then(res => {
            if (res.data.code == 0) {
              this.$message({
                message: "新增成功",
                type: "success",
                duration: 1500
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
                duration: 1500
              });
            }
            this.subPageTabel();
          })
          .catch(err => {
            this.$message({
              message: err.data.msg,
              type: "error",
              duration: 1500
            });
            this.subPageTabel();
          });
      } else if (this.titleSrc == "新增分类") {
        const para = e.form;
        para.status = 1;
        addRow(para)
          .then(res => {
            if (res.data.code == 0) {
              this.$message({
                message: "新增成功",
                type: "success",
                duration: 1500
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
                duration: 1500
              });
            }
            this.getListData();
          })
          .catch(err => {
            this.$message({
              message: err.data.msg,
              type: "error",
              duration: 1500
            });
            this.getListData();
          });
      }
    },
    // 搜索绑定事件
    onSearch() {
      this.pagination.pageNum = 1;
      this.isExpendSubPanel = false;
      this.getListData();
    },
    // 新增绑定事件
    onAdd() {
      this.titleSrc = "新增分类";
      this.formAdd = {}
      this.$refs.addSubDialog.showDialog();
    },
    // 查看子码
    openPanel(index, scope) {
      console.log(index);
      console.log(scope);
      this.isExpendSubPanel = true;
      this.queryKeyData.cateCode = scope.cateCode;
      this.pagination.pageKeyNum = 1;
      this.subPageTabel();
    },
    subPageTabel() {
      this.queryKeyData.pageIndexB1 = this.pagination.pageKeyNum;
      this.queryKeyData.pageSize = this.pagination.pageKeySize;
      getKeyDicPage(this.queryKeyData).then(res => {
        this.dataSubNum = res.data.body.content;
        this.pagination.Keytotal = res.data.body.total * 1;
      });
    },
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getListData();
    },
    getSubList(e) {
      this.pagination.pagekeyNum = e.page;
      this.pagination.pagekeySize = e.limit;
      this.subPageTabel();
    },
    // 查看/修改 子码
    checkAndModified(scope) {
      this.titleSrc = "编辑子码";
      this.form2 = { ...scope.row };
      this.$refs.editSubDialog.showDialog(this.form2);
    },
    addOperateBtn(scope) {
      this.titleSrc = "新增子码";
      this.form1 = { ...scope.row };
      this.$refs.addParentDialog.showDialog();
    },
    onClose() {
      this.$refs.addSubDialog.dialogFormVisible = false;
    },
    onSubmit() {
      this.$refs.addSubDialog.dialogFormVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
