<template>
  <div>
    <!-- 返回按钮 -->
    <div>
      <el-button style="float: right" type="primary" size="mini" @click="back">
        返回上一级
      </el-button>
    </div>
    <!-- 按钮 -->
    <div style="margin-bottom: 20px">
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        新增
      </el-button>
    </div>

    <!-- 表格 -->
    <free-table
      border
      :data="data"
      :column="slotColumn"
      :columnsProps="columnsProps"
      pagination
      :auto-scroll="false"
      :pageSizes="pagination.pageSizes"
      :total.sync="pagination.total"
      :page.sync="pagination.pageNum"
      :limit.sync="pagination.pageSize"
      @pagination="getList"
    >
      <template v-slot:address_ids="{ row }">
        <span>{{ row.provinceName + row.cityName + row.areaName }}</span>
      </template>
    </free-table>

    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
    />
  </div>
</template>

<script>
// 引入组件
import freeTable from "@/views/components/FreeTable";
import searchConfig from "@/views/components/searchConfigForm";
import addSub from "./addSub";

// 引入接口
import addressApi from "@/model/modules/userinfo_address/index";

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    searchConfig,
    freeTable,
    addSub,
  },
  data() {
    return {
      id: "",
      addressTree: [],
      prePageTabActiveName: "", //上一页当前tab值
      titleSrc: "",
      // 表格
      data: [],
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true,
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [1, 10, 20, 50, 100],
        total: 0,
      },
      formSetData: {
        pageIndexB1: 1,
        pageSize: 10,
      },
      slotColumn: [
        { label: "收货人", prop: "receiverName" },
        { label: "手机号码", prop: "tel" },
        { label: "所在地区", prop: "address_ids", slotScope: true },
        { label: "详细地址", prop: "addressName" },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: "200px",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row);
                  }}
                >
                  删除
                </el-button>
              </div>
            );
          },
        },
      ],

      // 新增修改弹窗
      form: {
        receiverName: "",
        tel: "",
        addressName: "",
        address_ids: [],
        provinceId: "",
        cityId: "",
        areaId: "",
      },
      config: [
        {
          label: "收货人",
          placeholder: "请输入",
          type: "input",
          prop: "receiverName",
        },
        {
          label: "手机号码",
          placeholder: "请输入",
          type: "input",
          prop: "tel",
        },
        {
          label: "所在地区",
          placeholder: "请选择",
          type: "cascader",
          prop: "address_ids",
          options: [],
          node_key: "nodeId",
          defaultProps: {
            label: "nodeName",
            value: "nodeId",
            children: "childList",
          },
        },
        {
          label: "详细地址",
          placeholder: "请输入",
          type: "input",
          prop: "addressName",
        },
      ],
      rules: {
        a: [{ required: true, message: "请输入收货人", trigger: "blur" }],
        b: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
        c: [{ required: true, message: "请选择所在地区", trigger: "blur" }],
        d: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
      },
    };
  },
  mounted() {
    if (this.$route.query.activeName) {
      this.prePageTabActiveName = this.$route.query.activeName;
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    this.getPageList();
    this.getInitData();
  },
  methods: {
    // 初始化数据
    async getInitData() {
      let res_addressTree = await addressApi.getAddressTrees();
      this.addressTree = res_addressTree.data.body;
    },
    // 获取列表
    getPageList() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      this.formSetData.userId = this.id;
      addressApi.pageList(this.formSetData).then((res) => {
        if (res.data.code == 0) {
          this.data = res.data.body.content;
          this.pagination.total = res.data.body.total;
        }
      });
    },

    // 点击查询按钮
    onQuery() {
      this.pagination.pageNum = 1;
      this.getPageList();
    },

    // 点击新增按钮
    onAdd() {
      this.titleSrc = "收货地址添加";
      this.config[2].options = this.addressTree;
      this.form = {
        receiverName: "",
        tel: "",
        address_ids: [],
        addressName: "",
      };
      this.$refs.addSubDialog.showDialog();
    },

    // 点击删除
    handleDelete(row) {
      this.$confirm("确认删除吗？", "提示", {}).then(() => {
        let params = {
          id: row.id,
        };
        addressApi.deleteRow(params).then((response) => {
          if (response.data.code == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
              duration: 1500,
            });
          }
          this.getPageList();
        });
      });
    },

    // 打开编辑弹窗
    openDialog({ row }) {
      this.form = {
        ...row,
      };
      this.form.address_ids = [];
      this.form.address_ids[0] = this.form.provinceId
        ? this.form.provinceId
        : "";
      this.form.address_ids[1] = this.form.cityId ? this.form.cityId : "";
      this.form.address_ids[2] = this.form.areaId ? this.form.areaId : "";

      console.log(this.form);

      this.$refs.addSubDialog.showDialog();
      this.titleSrc = "收货地址修改";
    },

    // 分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getPageList();
    },

    // 提交编辑表格行信息
    onEditSubmit(e) {
      this.form = {
        ...e,
      };
      console.log(this.form);
      this.form.provinceId = this.form.address_ids[0]
        ? this.form.address_ids[0]
        : "";
      this.form.cityId = this.form.address_ids[1]
        ? this.form.address_ids[1]
        : "";
      this.form.areaId = this.form.address_ids[2]
        ? this.form.address_ids[2]
        : "";
      this.form.userId = this.id;

      // delete this.form.address_ids
      console.log(this.form);
      // return false

      addressApi.saveRow(this.form).then((res) => {
        if (res.data.code == 0) {
          this.getPageList();
        }
      });
    },

    back() {
      this.$router.replace({
        // path: '/appconsole/userinfo',
        // query: {
        //   activeName: this.prePageTabActiveName
        // }

        name: "用户管理",
        params: {
          activeName: this.prePageTabActiveName,
        },
      });
    },

    handleFocus() {},
  },
};
</script>
