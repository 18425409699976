import http, { get } from "@/model/service";

var baseUrl = process.env.VUE_APP_API_URL_BASE;

export default class Http {
    static async get(url) {
        return await get(url);
    }

    static async post(url, data, onSuccess, onHouseKeeping){
        var headers = {};
        if (localStorage.getItem('token')) {
            headers.Authorization = 'Bearer ' + localStorage.getItem('token')
        }
        return await http({
            url: baseUrl + url,
            method: 'post',
            headers,
            data
          }).then(resp=>{

            switch(resp.status)
            {
                case 200:
                case 201:
                var jsonres = resp.data;
                if(jsonres.code===0){
                    (onSuccess?onSuccess:()=>{})(jsonres.body);
                }
                break;
                default:
                    console.log(resp&resp.body?resp.body.msg:"");
            }

         }).finally(()=>{
            (onHouseKeeping?onHouseKeeping:()=>{})();
        });
    }

    static async postPageData(url, data, onUpdateData, onUpdatePaging, onUpdateExt){
        var headers = {};
        if (localStorage.getItem('token')) {
            headers.Authorization = 'Bearer ' + localStorage.getItem('token')
        }
        return await http({
            url: baseUrl + url,
            method: 'post',
            headers,
            data
          }).then(resp=>{

            switch(resp.status)
            {
                case 200:
                case 201:
                var jsonres = resp.data;
                if(jsonres.code===0){
                    var page = jsonres.body;
                    (onUpdateData?onUpdateData:()=>{})(page.content, page.total);
                    (onUpdatePaging?onUpdatePaging:()=>{})(page.pageIndexB1-1, page.pageSize);
                }
                break;
                default:
                    console.log(resp&resp.body?resp.body.msg:"");
            }

         }).finally(()=>{
            (onUpdateExt?onUpdateExt:()=>{})(this.conditions?this.conditions:{});
            /*console.log("$force update")
            this.$nextTick(()=>{
                console.log("next")
                this.$forceUpdate();
            })
            */
        });
    }

}