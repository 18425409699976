import { deletes, post } from "@/model/service";

export default class QuickQuestionManage {
    // 问题列表
    static getQuestionList(params = undefined) {
        return post('/service/question/queryList', params)
    }
    // 二级问题列表
    static getQuestionNextList(params = undefined) {
        return post('/service/question/questionNextList', params)
    }
    // 更改问题状态
    static changeQuestionStatus(params = undefined) {
        return post('/service/question/openOrStop', params)
    }
    // 更改解答状态
    static changeAnswerStatus(params = undefined) {
        return post('/service/answer/openOrStop', params)
    }
    // 引导新增/修改
    static addOrUpdateQuestion(params = undefined) {
        return post('/service/question/save', params)
    }
    // 引导解答新增/修改
    static addOrUpdateNextQuestion(params = undefined) {
        return post('/service/answer/save', params)
    }
    // 删除问题
    static deteleQuestion(params = undefined) {
        return post('/service/question/deleteById', params)
    }
    // 删除解答
    static deteleAnswer(params = undefined) {
        return deletes('/service/answer/id/' + params.id)
    }

}