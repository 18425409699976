import { get, post } from  '../../service'

export default class AnswerUserManage {
    static pageList(params = undefined) {
        return post('/service/user/answerPerson', params) 
    }

    // 获取讲师用户类别
    static getLecturerType(params = undefined) {
        return get('/service/user/getLecturerType', params)
    }

    // 获取咨询问卷列表
    static getQuestionnaire(params = undefined) {
        return get('/service/user/questionnaire/'+params.id)
    }

    // 设置权重
    static setUpUserWeight(params = undefined) {
        return post('/service/user/setUpUserWeight', params) 
    }
}