<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogFormVisible" width="70%">
      <!--查询条件配置组件-->
      <search-config
        ref="searchConfig"
        :configData="searchConfigData"
        :formSet="formSetData"
        :colspan="5.8"
        :btnColspan="5"
        :labelWidth="'100px'"
        @on-focus-select="handleFocus"
        @on-change-select="handleChange"
      >
        <el-button type="primary" @click="onQuery">查询</el-button>
      </search-config>
      <!-- <div>
        <span style="margin-right: 10px">转移到</span>
        <el-select
          clearable
          v-model="qqq"
          placeholder="请选择"
          style="width: 130px" 
        >
          <el-option
            :label="optItem.label"
            :value="optItem.value"
            v-for="(optItem, index) in serviceProviderOption"
            :key="index"
          ></el-option>
        </el-select>
        <el-input
          v-model="www"
          autocomplete="off"
          placeholder="请输入"
          style="width: 170px" 
        ></el-input>
        <el-button type="primary" @click="onTransferToClick">转移</el-button>
      </div> -->

      <!--表格条件配置组件-->
      <div class="mt20">
        <free-table
          border
          :data="data"
          :column="slotColumn"
          :columnsProps="columnsProps"
          pagination
          :auto-scroll="false"
          :pageSizes="pagination.pageSizes"
          :total.sync="pagination.total"
          :page.sync="pagination.pageNum"
          :limit.sync="pagination.pageSize"
          @pagination="getList"
        >
          <template v-slot:status="{ row }">
            <el-switch
              v-model="row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="handleSwitchFocus(row)"
            >
            </el-switch>
          </template>
        </free-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 引入接口
import { getCustomerListInfo, UnbindInfo, transferInfo } from '@/model/modules/userinfo'

import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'

export default {
  name: 'visitor',
  components: {
    SearchConfig,
    FreeTable
  },
  props: {
    title: String
  },
  data() {
    return {
      dialogFormVisible: false,
      serviceProviderOption: [
        {
          label: '服务商ID',
          value: ''
        },
        {
          label: '服务商真实姓名',
          value: ''
        },
        {
          label: '服务商电话',
          value: ''
        }
      ],
      // 搜索框渲染
      formSetData: {
        val: '',
        nameVal: '',
        telVal: '',
        uid: '',
        pageIndexB1: Number,
        pageSize: Number
      },
      searchConfigData: [
        {
          label: '',
          formItemId: 0,
          type: 'multipleBoxes',
          configDatas: [
            {
              label: '',
              formItemId: 0,
              placeholder: '请选择',
              type: 'select',
              isApi: true,
              isApi1: true,
              prop: 'val',
              width: 130,
              options: [
                {
                  label: '诊所合伙人姓名',
                  value: 'nameVal'
                },
                {
                  label: '合伙人电话',
                  value: 'telVal'
                }
              ]
            },
            {
              label: '',
              formItemId: 1,
              placeholder: '请输入',
              type: 'input',
              prop: '',
              width: 170,
            }
          ]
        }
      ],

      // 表格数据渲染
      data: [
      ],
      slotColumn: [
        { label: '选择', prop: 'selection', type: 'selection' },
        { label: '诊所合伙人', prop: 'fullname' },
        { label: '电话', prop: 'tel' },
        { label: '注册时间', prop: 'regTmStr' },
        { label: '绑定时间', prop: 'bindTmStr' },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '370px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onUnbindClick(scope.row)
                  }}
                >
                  解除绑定
                </el-button>
              </div>
            )
          }
        }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },
      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      }
    }
  },
  methods: {
    showDialog (e) {
      this.formSetData = {
        val: '',
        nameVal: '',
        telVal: '',
        uid: '',
        pageIndexB1: Number,
        pageSize: Number
      },
      this.dialogFormVisible = true
      this.formSetData.uid = e.id
      this.getCustomerListInfo()
    },
    onClose () {
      this.dialogFormVisible = false
      this.formSetData = {
        val: '',
        nameVal: '',
        telVal: '',
        uid: '',
        pageIndexB1: Number,
        pageSize: Number
      }
    },
    async getCustomerListInfo() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum
      this.formSetData.pageSize = this.pagination.pageSize
      const res = await getCustomerListInfo(this.formSetData);
      if (res.data.code === 0) {
        this.data = res.data.body.content
        this.pagination.total = res.data.body.total * 1
      }
    },

    // 搜索框下拉
    handleFocus (e) {
    },
    handleChange(item) {
      let val = item.configDatas[1].prop
      if (val !== '') {
        this.formSetData[val] = ''
      }
      item.configDatas[1].prop = this.formSetData.val
    },
    // 查询
    onQuery() {
      this.pagination.pageNum = 1
      this.getCustomerListInfo()
    },
    // 转移到
    onTransferToClick() {
      transferInfo().then(res => {

      })
    },
    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.getCustomerListInfo();
    },

    // 解除绑定
    onUnbindClick(e) {
      this.$confirm('确定解除绑定么？', '提示', {}).then(() => {
        const data = {
          relieveId: e.id,
          uid: this.formSetData.uid
        }
        UnbindInfo(data).then(res => {
          if (res.data.code === 0) {
            this.getCustomerListInfo();
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>