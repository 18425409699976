<template>
  <d2-container>
    <h2>快捷答疑管理</h2>
    <div class="question-search">
      <div>
        <span>引导名头：</span>
        <el-input placeholder="请输入内容" v-model="questionInput" clearable class="question-input"></el-input>
        <el-button type="primary" @click="queryQuestionList">查询</el-button>
      </div>
      <el-button type="primary" @click="addQuestion">新增引导</el-button>
    </div>
    <!-- 表格 -->
    <div class="question-table">
      <el-table
        ref="table"
        :data="tableData"
        style="width: 100%"
        row-key="id"
        :expand-row-keys="expands"
        @expand-change="expandChange"
        border
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      >
        <el-table-column prop label width="100"></el-table-column>
        <el-table-column prop="title" label="引导名头"></el-table-column>
        <el-table-column prop="state" label="启用状态">
          <template slot-scope="scope">
            <el-switch
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="(changeStatus(scope.row))"
              :value="scope.row.state"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="triggerCount" label="触发次数"></el-table-column>
        <el-table-column prop="time" label="上传时间" width="170"></el-table-column>
        <el-table-column prop label="操作" width="240" align="center">
          <template slot-scope="scope">
            <el-button
              type="default"
              size="small"
              @click="addNextQuestion(scope)"
              v-if="scope.row.questionId == undefined"
            >新增</el-button>
            <el-button type="default" size="small" @click="deleteDialogQuestion(scope)">删除</el-button>
            <el-button type="default" size="small" @click="editDialogQuestion(scope)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <pagination
      v-if="pagination.total > 0"
      :pageSizes="pagination.pageSizes"
      :total.sync="pagination.total"
      :page.sync="pagination.pageNum"
      :limit.sync="pagination.pageSize"
      @pagination="getList"
    />
    <!-- 删除提示 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>{{dialogTip}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteQuestion">确 定</el-button>
      </span>
    </el-dialog>
    <!--添加/修改弹窗-->
    <form-model
      ref="formDialog"
      :title="formModelTitle"
      :rowInfomation="rowInfomation"
      @refresh="getQuestionList"
    />
  </d2-container>
</template>
<script>
// 引入新增/修改组件
import FormModel from "./components/formModel";
// 分页
import Pagination from "@/views/components/Pagination/index";
// 引入接口
import QuickQuestionManageApi from "@/model/modules/quickQuestionManage/index";

export default {
  name: "quickQuestionManage",
  components: {
    FormModel,
    Pagination
  },
  data() {
    return {
      expands: [], // table展开行
      children: [], // 当前子级
      currentQuestion: {}, // 当前要删除问题
      deleteQuestionTip: "删除后二级引导也将共同删除",
      deleteNextQuestionTip: "确定删除二级引导？删除后不可恢复。",
      dialogTip: "", // 删除提示内容
      formModelTitle: "", // 组件dialogtitle
      rowInfomation: {}, // 表格行信息
      dialogVisible: false,
      questionInput: "", // 引导名头
      tableData: [], // 表格数据
      pagination: {
        // 分页
        pageNum: 1,
        pageSize: 10,
        pageSizes: [3, 5, 10],
        total: 0
      }
    };
  },
  mounted() {
    this.getQuestionList();
  },
  methods: {
    // table行点击
    expandChange(row, expanded) {
      if (expanded) {
        this.expands = [expanded.id];
      } else {
        this.expands = [];
      }
    },
    // 新增引导
    addQuestion() {
      this.formModelTitle = "新增引导";
      this.rowInfomation = {};
      this.$refs.formDialog.showDialog(1);
    },
    // 新增引导答疑
    addNextQuestion(scope) {
      this.formModelTitle = "新增引导答疑";
      this.rowInfomation = {
        questionId: scope.row.id
      };
      this.$refs.formDialog.showDialog(2);
    },
    // 修改
    editDialogQuestion(scope) {
      this.rowInfomation = scope.row;
      if (this.rowInfomation.questionId != undefined) {
        this.formModelTitle = "修改引导答疑";
        this.$refs.formDialog.showDialog(2);
      } else {
        this.formModelTitle = "修改引导";
        this.$refs.formDialog.showDialog(1);
      }
    },
    // 删除dialog
    deleteDialogQuestion(scope) {
      this.currentQuestion = scope.row;
      if (this.currentQuestion.questionId == undefined) {
        this.dialogTip = this.deleteQuestionTip;
      } else {
        this.dialogTip = this.deleteNextQuestionTip;
      }
      this.dialogVisible = true;
    },
    // 删除引导/二级引导
    async deleteQuestion() {
      const { questionId, id } = this.currentQuestion;
      const params = {
        id: id
      };
      let res = {};
      if (questionId == undefined) {
        res = await QuickQuestionManageApi.deteleQuestion(params);
      } else {
        res = await QuickQuestionManageApi.deteleAnswer(params);
      }
      this.dialogVisible = false;
      this.message("删除成功", res.data);
    },
    // 更改启用状态
    async changeStatus(item) {
      const params = {
        id: item.id,
        status: Number(!item.state)
      };
      let res = {};
      if (item.questionId == undefined) {
        res = await QuickQuestionManageApi.changeQuestionStatus(params);
      } else {
        res = await QuickQuestionManageApi.changeAnswerStatus(params);
      }
      this.message("操作成功", res.data);
    },
    message(title, data) {
      if (data.code == 0) {
        this.$message({
          message: title,
          type: "success",
          duration: 1500
        });
        this.getQuestionList();
      } else {
        this.$message.error(data.msg);
      }
    },
    // 查询
    queryQuestionList() {
      this.pagination.pageNum = 1;
      this.getQuestionList();
    },
    // 表格数据
    async getQuestionList() {
      const params = {
        keyword: this.questionInput,
        pageIndexB1: this.pagination.pageNum,
        pageSize: this.pagination.pageSize
      };
      const { data } = await QuickQuestionManageApi.getQuestionList(params);
      this.tableData = data.body.content;
      // table数据格式化
      this.tableData.forEach(ele => {
        ele.children = ele.answerList;
        ele.time = this.$moment(ele.mtm).format("YYYY.MM.DD hh:mm:ss");
        ele.state = Boolean(ele.status);
        ele.children.forEach(eleAnswer => {
          eleAnswer.title = eleAnswer.answerTitle;
          eleAnswer.state = Boolean(eleAnswer.status);
          eleAnswer.time = this.$moment(eleAnswer.mtm).format(
            "YYYY.MM.DD hh:mm:ss"
          );
        });
      });
      this.pagination.total = data.body.total;
    },
    // 分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getQuestionList();
    }
  }
};
</script>

<style scoped lang="less">
.question-search {
  display: flex;
  justify-content: space-between;
}
.question-input {
  width: 280px;
  margin-right: 24px;
}
.question-table {
  margin-top: 20px;
}
/deep/.question-table .el-icon-arrow-right {
  font-size: 20px;
}
</style>
