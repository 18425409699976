import { get, post } from '../../service'

export default class Comment {
    // 评论列表
    static commentList(params = undefined) {
        return post('/service/comment/getManageCourseCommentList', params)
    }
    // 弹幕列表
    static screenList(params = undefined) {
        return post('/service/barrage/ManageBarrageList', params)
    }
    // 弹幕举报列表
    static screenWarnList(params = undefined) {
        return post('/service/barrage/ManageComplainList', params)
    }
    // 评论删除
    static deleteComment(params = undefined) {
        return get('/service/comment/deleteByIds/' + params)
    }
    // 弹幕删除
    static deleteScreen(params = undefined) {
        return get('/service/barrage/deleteBarrage/' + params)
    }
    // 心得列表
    static experienceList(params = undefined) {
        return post('/service/experience/getPageList', params)
    }
    // 心得删除
    static deleteExperience(ids = undefined) {
        return get('/service/experience/deleteById/' + ids)
    }
    // 留言列表
    static messageList(params = undefined) {
        return post('/service/case/manageCommentList', params)
    }
    // 留言删除
    static deleteMessage(ids = undefined) {
        return get('/service/case/deleteById/' + ids)
    }
}
