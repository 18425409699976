/**
 *  广告设置
 */
const apiUrl = {
  getNewsPage: '/NoticeController/getNoticeListByFType', // get分页列表
  getPageList: '/TMtStartPicController/getPageinfo',
  deleteInfo: '/TMtStartPicController/delInfoByKey',
  insertInfo: '/TMtStartPicController/insertInfo',
  updateInfo: '/TMtStartPicController/updateInfo',
  updateStatus: '/TMtStartPicController/updateStatus'
}

export default apiUrl
