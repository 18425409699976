<template>
  <d2-container>
    <!--工具条-->
    <el-row style="height: 100%">
      <el-col :offset="1" :span="9" style="height: 100%; overflow-y: auto">
        <!--树形菜单列表-->
        <el-tree
          class="filter-tree nodes-style"
          default-expand-all
          :data="treeData"
          :props="defaultProps"
          node-key="id"
          :expand-on-click-node="true"
          :default-expanded-keys="defaultExpandedNode"
          highlight-current
          @node-click="editMenuInfo"
          ref="tree"
        >
        </el-tree>
        <!--树形菜单列表 end-->
      </el-col>
      <el-col :offset="1" :span="12">
        <!--列表-->
        <el-card style="margin-left: 30px">
          <el-form
            :model="menuData"
            label-width="80px"
            :rules="rules"
            ref="formData"
            v-loading="listLoading"
            style="margin-top: 20px"
          >
            <el-form-item v-show="addMenuButtonVisible">
              <div class="grid-content bg-purple">
                <el-button
                  type="primary"
                  @click.native="addEditSubmit"
                  :loading="addEditSubmitLoading"
                  >保存菜单信息</el-button
                >
              </div>
            </el-form-item>
            <el-form-item v-show="!addMenuButtonVisible">
              <div class="grid-content bg-purple">
                <el-button type="primary" @click.native="addEditForm"
                  >新增子菜单</el-button
                >
                <el-button
                  type="primary"
                  @click.native="updateEditSubmit"
                  :loading="updateEditSubmitLoading"
                  >修改菜单信息</el-button
                >
                <el-button
                  v-if="menuData.status == 0"
                  @click="toggleSysMenuStatus(menuData)"
                  :class="{ 'el-button--success': menuData.status == 0 }"
                  >启用</el-button
                >
                <el-button
                  v-else-if="menuData.status == 1"
                  @click="toggleSysMenuStatus(menuData)"
                  :class="{ 'el-button--danger': menuData.status == 1 }"
                  >禁用</el-button
                >
                <el-button type="danger" @click.native="delMenu">删除菜单</el-button>
              </div>
            </el-form-item>
            <el-form-item label="上级菜单" prop="pidStr">
              <el-input v-model="menuData.pidStr" placeholder="" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="菜单名称" prop="clgName">
              <el-input v-model="menuData.clgName" placeholder=""></el-input>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </d2-container>
</template>

<script>
import { menuList, addSysMenu, delMenu } from '@/model/modules/menu_comm_cate'
export default {
  name: 'menuCommCate',
  data () {
    return {
      listLoading: false, // 载入tableData
      updateEditSubmitLoading: false, // 修改按钮点击后
      addEditSubmitLoading: false,  //保存菜单信息
      addMenuButtonVisible: false, // 新增界面是否显示
      defaultExpandedNode: ['-1'], // 默认展开节点的key数组

      queryData: {
        // 查询
        pageIndexB1: Number,
        pageSize: Number
      },

      rules: {
        // 表单验证
        clgName: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }]
      },

      treeData: [],
      defaultProps: {
        id: 'id',
        children: 'children',
        label: 'clgName'
      },
      menuData: {} ,// 表单数据
      alterMenuData: {} // 表单数据
    }
  },
  methods: {
    // 查询
    getListData () {
      this.listLoading = true
      this.queryData.pageIndexB1 = 1
      this.queryData.pageSize = 10
      menuList(this.queryData)
        .then((response) => {
          this.treeData = response.data.body
          this.listLoading = false
        })
        .catch((response) => {
          this.listLoading = false
        })
    },
    // 树形菜单节点点击F
    editMenuInfo (data, node, _node) {
      if (this.newNode !== undefined) {
        this.$refs.tree.remove(this.$refs.tree.getNode(''))
        this.newNode = undefined
      }
      this.addMenuButtonVisible = false
      this.menuData = {...data}
      if (data.pid !== 0) {
        this.menuData.pidStr = this.$refs.tree.getNode(data.pid).data.clgName
      }
      // 清空表单的验证
      this.$refs.formData.clearValidate()
    },
    //  菜单启用/禁用 处理请求结果
    updateSysMenuValidStatus (val) {
      const _val = Number(val.status) === 1 ? 0 : 1
      const _message = Number(val.status) === 1 ? '已禁用' : '已启用'
      // eslint-disable-next-line no-undef
      this.menuData.status = _val
      addSysMenu(val)
        .then((response) => {
          if (response.data.code === 0) {
            this.$message.info(_message)
            this.getListData()
          }
        })
        .catch((response) => {
          console.error(response)
          this.$message.error('操作失败')
        })
    },
    // 菜单启用/禁用 请求
    toggleSysMenuStatus (_val) {
      if (Number(_val.status) === 1) {
        this.$confirm('确认禁用吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.updateSysMenuValidStatus(_val)
          })
          .catch(() => {
            // return
          })
      } else {
        if (this.$refs.tree.currentNode.node.parent.data.status === 0) {
          this.$message({
            message: '父级菜单已禁用，操作失败',
            type: 'danger',
            duration: 1500
          })
          return
        }
        this.$confirm('确认启用吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.updateSysMenuValidStatus(_val)
          })
          .catch(() => {
            // return
          })
      }
    },
    // 修改菜单
    updateEditSubmit () {
      if (this.$refs.tree.getCurrentNode()) {
        this.$refs.formData.validate((valid) => {
          if (!valid) return
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            this.updateEditSubmitLoading = true
            const _data = this.menuData
            addSysMenu(_data)
              .then((response) => {
                if (response.data.code === 0) {
                  this.updateEditSubmitLoading = false
                  this.$message({
                    message: '操作成功',
                    type: 'success',
                    duration: 1500
                  })
                  this.getListData()
                } else {
                  this.updateEditSubmitLoading = false
                  this.$message.error('操作失败')
                }
              })
              .catch((response) => {
                this.updateEditSubmitLoading = false
                console.error(response)
                this.$message.error('操作失败')
              })
          })
        })
      } else {
        this.$message({
          message: '请先选择节点再进行操作',
          type: 'warning',
          duration: 1500
        })
      }
    },
    // 新增子菜单表单
    addEditForm () {
      const selNode = this.$refs.tree.getCurrentNode()
      if (selNode) {
        this.addMenuButtonVisible = true
        this.newNode = {
          pid: selNode.id,
          id: '',
          clgName: '',
        }
        this.menuData = this.newNode
        this.menuData.pidStr = selNode.clgName
        this.$refs.tree.append(this.newNode, selNode)
      } else {
        this.$message({
          message: '请先选择节点再进行操作',
          type: 'warning',
          duration: 1500
        })
      }
    },
    // 新增 添加请求
    addEditSubmit () {
      this.$refs.formData.validate((valid) => {
        if (!valid) return
        this.$confirm('确认提交吗？', '提示', {}).then(() => {
          this.addEditSubmitLoading = true
          const _data = this.menuData
          const para = {
            pid: _data.pid,
            clgName: _data.clgName,
          }
          addSysMenu(para)
            .then((response) => {
              if (response.data.code === 0) {
                this.addEditSubmitLoading = false
                this.addMenuButtonVisible = false
                this.$message({
                  message: '保存成功',
                  type: 'success',
                  duration: 1500
                })
                this.getListData()
              } else {
                this.addEditSubmitLoading = false
                this.addMenuButtonVisible = false
                this.$message.error('操作失败')
                if (this.newNode !== undefined) {
                  this.$refs.tree.remove(this.$refs.tree.getNode(''))
                  this.newNode = undefined
                }
              }
            })
            .catch((response) => {
              this.addEditSubmitLoading = false
              this.addMenuButtonVisible = false
              console.error(response)
              this.$message.error('操作失败')
            })
        })
      })
    },
    // 删除菜单
    delMenu () {
      this.$confirm('确认删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
          const para = {
            id: this.menuData.id
          }
          delMenu(para)
          .then((response) => {
            if (response.data.code === 0) {
              this.$message({
                message: '删除成功',
                type: 'success',
                duration: 1500
              })
              this.getListData()
            } else {
              this.$message.error('操作失败')
            }
          })
          .catch((response) => {
            console.error(response)
            this.$message.error('操作失败')
          })
      }).catch(() => {})
    }
  },
  mounted () {
    this.getListData()
  }
}
</script>

