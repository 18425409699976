<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5.8"
      :btnColspan="5"
      :labelWidth="'80px'"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <!-- <div>
      <el-button type="default" size="mini" @click="onExport"> 导出 </el-button>
    </div> -->



    <!--表格条件配置组件-->
    <div class="mt20">
      <div style="height: 39px">
        <el-col :span="6">
          <span class="payAmount"
            >订单总价：<span style="color: #d9001b">{{ price }}</span>
            元</span
          ></el-col
        >
        <el-col :span="6">
          <span class="payAmount"
            >应付金额：<span style="color: #d9001b">{{ cost }}</span>
            元</span
          ></el-col
        >
        <el-col :span="6">
          <span class="payAmount"
            >三方手续费：<span style="color: #d9001b">{{ serviceChange }}</span>
            元</span
          ></el-col
        >
        <el-col :span="6">
          <span class="payAmount"
            >公司拟得金额：<span style="color: #d9001b">{{ companyMoney }}</span>
            元</span
          ></el-col
        >
      </div>

      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:couponPrice="{ row }">
          <div v-if="row.couponPrice">{{ row.couponPrice }}元</div>
        </template>
        <template v-slot:priceLabel="{ row }">
          <div v-if="row.priceLabel">{{ row.priceLabel }}元</div>
        </template>
        <template v-slot:costLabel="{ row }">
          <div v-if="row.costLabel">{{ row.costLabel }}元</div>
        </template>
        <template v-slot:ctm="{ row }">
          {{ timeConvert(row.ctm, 1) }}
        </template>
        <template v-slot:createPaymentTm="{ row }">
          {{ timeConvert(row.createPaymentTm, 1) }}
        </template>
        <template v-slot:paymentTm="{ row }">
          {{ timeConvert(row.paymentTm, 1) }}
        </template>
      </free-table>
    </div>

    <!--详情-->
    <particulars ref="particularsSubDialog" :titleSrc="titleSrc" />
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from "@/views/components/searchConfigForm";
import FreeTable from "@/views/components/FreeTable";
// 引入页面层级组件
import particulars from "./components/particulars";

// 引入接口
import Order from "@/model/modules/mod_order";

export default {
  name: "roleinfo",
  components: {
    SearchConfig,
    FreeTable,
    particulars
  },
  data() {
    return {
      companyMoney: '',
      cost: '',
      price: '',
      serviceChange: '',
      fShowPositions: {},
      titleSrc: "订单详情",

      // 搜索框渲染
      formSetData: {
        pageIndexB1: 1,
        pageSize: 10,
        payWay:  '',
        ctmStartTime: '',
        ctmEndTime: '',
        orderNo: '',
        tel: '',
        orderStatus: '',

        ororderNoOrTelSelect: '',
        ororderNoOrTelSelectValue: ''
      },
      searchConfigData: [
        {
          label: "支付渠道",
          placeholder: "请选择",
          type: "select",
          prop: "payWay",
          options: [
            {
              label: "全部",
              value: 0
            },
            {
              label: "微信支付",
              value: 1
            },
            {
              label: "康驰币",
              value: 2
            },
            {
              label: "无需支付",
              value: 3
            }
          ]
        },
        {
          label: "下单时间",
          type: "multipleBoxes",
          configDatas: [
            {
              label: "",
              placeholder: "开始时间",
              type: "timePicker",
              prop: "ctmStartTime",
              optionType: "date"
            },
            {
              label: "",
              placeholder: "结束时间",
              type: "timePicker",
              prop: "ctmEndTime",
              optionType: "date"
            }
          ]
        },
        {
          label: '',
          type: 'multipleBoxes',
          configDatas: [
            {
              label: '',
              placeholder: '请选择',
              type: 'select',
              isApi1: true,
              prop: 'ororderNoOrTelSelect',
              width: '150',
              options: [
                {
                  label: '订单号',
                  value: '0'
                },
                {
                  label: '下单人手机号',
                  value: '1'
                }
              ]
            },
            {
              label: '',
              placeholder: '请输入',
              type: 'input',
              prop: 'ororderNoOrTelSelectValue',
              optionType: 'date'
            },
          ]
        },
        {
          label: "用券类型",
          placeholder: "请选择",
          type: "select",
          prop: "couponType",
          options: [
            {
              label: "全部",
              value: 0
            },
            {
              label: "立减券",
              value: 1
            },
            {
              label: "通用券",
              value: 2
            },
            {
              label: "未用券",
              value: 3
            }
          ]
        },
         {
          label: "订单状态",
          placeholder: "请选择",
          type: "select",
          prop: "orderStatus",
          options: [
            {
              label: "全部",
              value: 0
            },
            {
              label: "待支付",
              value: 1
            },
            {
              label: "支付完成",
              value: 2
            },
            {
              label: "交易关闭",
              value: 3
            }
          ]
        },
        // {
        //   label: "订单号",
        //   type: "input",
        //   prop: "orderNoVal"
        // }
      ],

      // 表格数据渲染createnPaymentTm
      data: [],
      slotColumn: [
        // { label: "选择", prop: "selection", type: "selection" },
        { label: "订单号", prop: "orderNo" },
        // { label: "购买用户id", prop: "uid" },
        { label: "真实姓名", prop: "fullName" },
        { label: "手机号", prop: "tel" },
        { label: "商品名称", prop: "goodsName" },
        { label: "原价", prop: "priceLabel", sort: true, slotScope: true },
        { label: "购买类型", prop: "priceDesc"},
        { label: "用劵类型", prop: "couponType" },
        { label: "用劵金额", prop: "couponPrice", slotScope: true },
        { label: "应付价格", prop: "costLabel", sort: true, slotScope: true },
        { label: "订单状态", prop: "orderStatusStr" },
        { label: "付款渠道", prop: "payWayStr" },
        { label: "三方手续费", prop: "serviceChange" },
        { label: "分润版本号", prop: "benefitVersion" },
        { label: "公司拟得金额", prop: "companyMoney" },
        { label: "下单时间", prop: "ctm", slotScope: true },
        { label: "付款时间", prop: "createPaymentTm", slotScope: true },
        { label: "完成时间", prop: "paymentTm", sort: true, slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: "270px",
          render: (h, scope) => {
            return (
              <div>
                {
                  //   <el-button
                  //   type="default"
                  //   size="small"
                  //   onClick={() => {
                  //     this.btnClick(1, scope.row);
                  //   }}
                  // >
                  //   退款
                  // </el-button>
                }
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.btnClick(2, scope.row);
                  }}
                >
                  详情
                </el-button>
              </div>
            );
          }
        }
      ],
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true
      },
      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        total: 1
      }
    };
  },
  mounted() {
    this.getSumCost();
    this.mngOrderList();
  },
  computed: {
    timeConvert() {
      //num:0 YYYY-MM-DD  num:1  YYYY-MM-DD hh:mm:ss // timestamp:时间戳
      return function(timestamp, num) {
        if (timestamp === 0) {
          return "-";
        } else {
          timestamp = timestamp.length === 10 ? timestamp * 1000 : timestamp;
          let date = new Date(timestamp);
          let y = date.getFullYear();
          let m = date.getMonth() + 1;
          m = m < 10 ? "0" + m : m;
          let d = date.getDate();
          d = d < 10 ? "0" + d : d;
          let h = date.getHours();
          h = h < 10 ? "0" + h : h;
          let minute = date.getMinutes();
          let second = date.getSeconds();
          minute = minute < 10 ? "0" + minute : minute;
          second = second < 10 ? "0" + second : second;
          if (num == 0) {
            return y + "-" + m + "-" + d;
          } else {
            return (
              y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second
            );
          }
        }
      };
    }
  },

  methods: {
    // 获取课程列表
    async mngOrderList() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;

      // 整理接口参数
      let params = { ...this.formSetData }
      delete params.ororderNoOrTelSelect
      delete params.ororderNoOrTelSelectValue

      let res = await Order.mngOrderList(params);
      this.data = res.data.body.content;
      this.data.forEach(element => {
        if(element.buyType == 0){
          element.priceDesc = "系列购买×"+ element.buyNum
        }else{
          element.priceDesc = "单节购买×"+ element.buyNum
        }
      });
      console.log(this.data)
      this.pagination.total = res.data.body.total * 1;
    },

    // 获取订单合计
    async getSumCost() {
      // 整理接口参数
      let params = { ...this.formSetData }
      delete params.ororderNoOrTelSelect
      delete params.ororderNoOrTelSelectValue

      let res = await Order.getSumCost(params);

      this.price = res.data.body.price;
      this.cost = res.data.body.cost;
      this.companyMoney = res.data.body.companyMoney;
      this.serviceChange = res.data.body.serviceChange;
    },

    // 查询
    onQuery() {
      // 判断搜索下拉框的值
      if(this.formSetData.ororderNoOrTelSelect==0){
        this.formSetData.orderNo = this.formSetData.ororderNoOrTelSelectValue
        this.formSetData.tel = ''
      }else if(this.formSetData.ororderNoOrTelSelect==1) {
        this.formSetData.tel = this.formSetData.ororderNoOrTelSelectValue
        this.formSetData.orderNo = ''
      }else {
        this.formSetData.orderNo = ''
        this.formSetData.tel = ''
      }

      this.pagination.pageNum = 1;
      this.mngOrderList();
      this.getSumCost()
    },
    // // 导出
    // onExport() {},

    //批量退款
    onBatchRefund() {},

    //表格提示
    onTips() {
      this.$confirm("确认退款么？", "提示", {}).then(() => {});
    },

    // 分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.mngOrderList();
    },

    btnClick(index, e) {
      console.log(e)
      switch (index) {
        case 1: // 退款
          this.$confirm("确认退款么？", "提示", {})
            .then(() => {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500
              });
            })
            .catch(err => {});
          break;
        case 2: // 详情
          this.titleSrc = "订单详情";
          this.$refs.particularsSubDialog.showDialog(e);
          break;
        default:
          this.$message.error("未定义");
          break;
      }
    },

    formatData(data, key, val) {
      let obj = {};
      for (let i in data) {
        let item = data[i];
        obj[item[key]] = {
          value: item[key] || "",
          label: item[val] || ""
        };
      }
      return obj;
    },

    setTime(data) {
      let t = data + 24 * 60 * 60 * 1000; //24小时 * 60分钟 * 60秒 * 1000
      let d = new Date(t);

      let theMonth = d.getMonth() + 1;
      let theDate = d.getDate();
      let theHours = d.getHours();
      let theMinutes = d.getMinutes();
      let theSeconds = d.getSeconds();

      if (theMonth < 10) {
        theMonth = "0" + theMonth;
      }
      if (theDate < 10) {
        theDate = "0" + theDate;
      }
      if (theHours < 10) {
        theHours = "0" + theHours;
      }
      if (theMinutes < 10) {
        theMinutes = "0" + theMinutes;
      }
      if (theSeconds < 10) {
        theSeconds = "0" + theSeconds;
      }

      let date = d.getFullYear() + "-" + theMonth + "-" + theDate;
      let time = theHours + ":" + theMinutes + ":" + theSeconds;
      let spare = date + " " + time;
      return spare;
    }
  }
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
.batchRefund {
  color: #02a7f0;
  display: inline-block;
  padding: 9px;
}
.payAmount {
  color: #02a7f0;
  padding-right: 19px;
  line-height: 39px;
}

/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
