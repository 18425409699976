<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :rules="rules"
      :colspan="5"
      :btnColspan="4"
      :labelWidth="'90px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
      <el-button type="primary" @click="goback">返回上一级</el-button>
    </search-config>

    <!-- 新增按钮 -->
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        添加批次
      </el-button>
    </div>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <!-- <template v-slot:w="{ row }">
          <el-image
            style="width: 100px; height: 100px"
            :src="row.w"
          >
          </el-image>
        </template> -->
      </free-table>
    </div>

    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :isTips="isTips"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
    />

    <!--导出弹窗-->
    <add-sub
      ref="addSubDialog_export"
      width="50%"
      align="left"
      :config="config_export"
      :form="form"
      :rules="rules"
      titleSrc="导出防伪码"
      @on-edit-submit="onEditSubmit_export"
      @on-focus-select="handleFocus"

    />

    <!--生成弹窗-->
    <add-sub
      ref="addSubDialog_build"
      width="50%"
      align="left"
      :config="config_build"
      :form="form_out"
      :rules="rules"
      titleSrc="生成防伪码"
      @on-edit-submit="onEditSubmit_build"
      @on-focus-select="handleFocus"
    />




  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'

//引入页面层级组件
import AddSub from "./components/addSub"

// 引入接口
import factoryBatchService from "@/model/modules/goodsManage_factoryBatch";

import { Loading } from 'element-ui';


const BASE_URL = process.env.VUE_APP_API_URL_BASE

export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
    FreeTable,
    AddSub
  },
  data () {
    return {
      v1:"",
      timer: '',
      chushiData: [], //当前企业和产品的名称和id
      titleSrc: '',
      // 搜索框渲染
      formSetData: {
        startTime: '',
        endTime: '',
        epId: '',
        epName:'',
        prodName:'',
        prodId: '',
        nameLike: '',
        pageIndexB1: Number,
        pageSize: Number
      },
      searchConfigData: [
        {
          label: '企业名称',
          formItemId: 0,
          type: 'input',
          disabled: true,
          prop: 'epName'
        },
        {
          label: '产品名称',
          formItemId: 1,
          type: 'input',
          disabled: true,
          prop: 'prodName'
        },
        {
          label: '批次名称',
          formItemId: 2,
          placeholder: '请输入批次名称',
          type: 'input',
          prop: 'nameLike'
        }
      ],

      // 表格数据渲染
      data: [],
      slotColumn: [
        { label: '批次名称', prop: 'batchName'},
        { label: '所属企业', prop: 'epName' },
        { label: '产品名称', prop: 'prodName' },
        { label: '防伪码数量', prop: 'batchProdAmountLabel' },
        // { label: '有效期截止日期', prop: 'indateTime'},
        { label: '生产日期', prop: 'releaseTime' },
        // { label: '已导出防伪码数量', prop: 'afcExportedCount' },
        { label: '已生成数量', prop: 'afcUnexportedCount' },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '370px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  编辑
                </el-button>

                 {
                 /** <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onBuild(scope.row)
                  }}
                >
                  生成
                </el-button>
                  */
               }
               {
                 scope.row.exportFinish==1?
                 <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onExport(scope.row)
                  }}
                >
                  导出
                </el-button>:''
               }

              </div>
            )
          }
        }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },

      // 添加和修改弹窗
      form: {

      },
      form_out:{},
      isTips:true,
      config: [
        {
          label: "企业名称",
          type: "input",
          prop: "epName",
          disabled:true
        },
        {
          label: "产品名称",
          type: "input",
          prop: "prodName",
          disabled:true
        },
        {
          label: "批次名称",
          type: "input",
          prop: "batchName",
        },
        {
          label: "防伪码数量",
          type: "numberInput",
          prop: "batchProdAmountLabel",
          disabled: false
        },
        // {
        //   label: "出厂时间",
        //   type: "timedateFormat",
        //   prop: "releaseTime",
        // },
        {
          label: "生产日期",
          type: "input",
          prop: "releaseTime",
        },
      ],
      config_export: [
        {
          label: "是否全部导出",
          type: "select",
          prop: "scope",
          options: [
            {
              label: '全部导出',
              value: 'ALL'
            },
            {
              label: '仅未导出部分',
              value: 'NOT_EXPORT'
            },
          ]
        },
      ],

      config_build: [
        {
          label: "防伪码数量",
          type: "input",
          prop: "amount",
        },
      ],


      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      },

      rules: {
        releaseTime: [
          { required: true, message: "请输入生产日期", trigger: "blur" },
        ],
        prodId: [
          { required: true, message: "请选择产品名称", trigger: "blur" },
        ],
        batchProdAmountLabel: [
          // { type: 'number', message: `请输入数字`, trigger: "blur" },
          // { type: 'regexp', pattern: /^(\\d|[1-9]\\d|100)$/, message: `范围`, trigger: "blur" },
        ]
      },
      timer:null
    }
  },
  mounted () {
    this.formSetData = {
      ...this.formSetData,
      epId:JSON.parse(localStorage.getItem("query")).epId,
      epName:JSON.parse(localStorage.getItem("query")).epName,
      prodName:JSON.parse(localStorage.getItem("query")).prodName,
      prodId:JSON.parse(localStorage.getItem("query")).id,
    }
    let that=this;
    that.getDataList();
    this.timer = window.setInterval(() => {
     
    // 　　setTimeout(()=>{
          that.getDataList();
      // },0)
    }, 20000)
  },

  activated() {
    this.timer = window.setInterval(() => {
      
    // 　　setTimeout(()=>{
          that.getDataList();
      // },0) 
    }, 20000)
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
    goback(){
      this.$router.go(-1)
    },
    // 初始列表数据渲染
    async getDataList() {
      this.formSetData = {
        ...this.formSetData,
        epId:JSON.parse(localStorage.getItem("query")).epId,
        epName:JSON.parse(localStorage.getItem("query")).epName,
        prodName:JSON.parse(localStorage.getItem("query")).prodName,
        prodId:JSON.parse(localStorage.getItem("query")).id,
        pageIndexB1: this.pagination.pageNum,
        pageSize:this.pagination.pageSize
      }
      let res = await factoryBatchService.pageList(this.formSetData);
      this.data = res.data.body.content;
      this.pagination.total = res.data.body.total * 1;
    },

    // 获取产品信息列表
    getGoodsList(){
      let para = {
        epId: this.cur_enterpriseId,
        pageIndexB1: 1,
        pageSize: 10,
        nameLike: ''
      }
      let res = factoryBatchService.getGoodsList(para)
      console.log(res.data)
    },

    // 查询组件 下拉框
    handleQueryFocus() {

    },

    // 表格查询按钮
    onQuery () {
      this.pagination.pageNum = 1
      this.$refs.searchConfig.$children[0].validate((valid) => {
          if (valid) {
            this.getDataList()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },

    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.getDataList()
    },

    //提交编辑表格行信息
    async onEditSubmit(e) {
      if (this.titleSrc == "批次修改") {
        let form = {
          ...e.form,
          releaseTm:this.$moment(e.form.indateTime).valueOf(),
          indateTm:this.$moment(e.form.indateTime).valueOf()
        };
        factoryBatchService.editRow(form)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 1500,
              });
              this.getDataList();
            } else {
              console.error(res);
              this.$message.error("修改失败");
            }
          })
          .catch((err) => {
            console.error(err);
            this.$message.error("修改失败");
          });
      } else if (this.titleSrc == "批次添加") {
        let form = {
          ...e.form,
          releaseTm:this.$moment(e.form.indateTime).valueOf(),
          indateTm:this.$moment(e.form.indateTime).valueOf(),
          status: 1,
          epId:JSON.parse(localStorage.getItem("query")).epId,
          epName:JSON.parse(localStorage.getItem("query")).epName,
          prodName:JSON.parse(localStorage.getItem("query")).prodName,
          prodId:JSON.parse(localStorage.getItem("query")).id,
          pageIndexB1: this.pagination.pageNum,
          pageSize:this.pagination.pageSize
        };
        // if(form.batchProdAmountLabel>50000) {
        //   this.$message.error("添加失败, 防伪码数量必须小于50000");
        //   return
        // }
        let loadingInstance = Loading.service({text: '正在操作，请等待'});
        factoryBatchService.addRow(form)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "添加成功",
                type: "success",
                duration: 1500,
              });
              this.pagination.pageNum = 1;
              this.form = {
                startTime: '',
                endTime: '',
                epId:JSON.parse(localStorage.getItem("query")).epId,
                epName:JSON.parse(localStorage.getItem("query")).epName,
                prodName:JSON.parse(localStorage.getItem("query")).prodName,
                prodId:JSON.parse(localStorage.getItem("query")).id,
                pageIndexB1: this.pagination.pageNum,
                pageSize:this.pagination.pageSize
              }
              this.getDataList();
            } else {
              console.error(res);
              // this.$message.error("添加失败");
            }
          })
          .catch((err) => {
            console.error(err);
            // this.$message.error("添加失败");
          }).finally(()=>{
            loadingInstance.close();
          });

          setTimeout(()=>{
            this.getDataList().finally(()=>{
              try{
                loadingInstance.close();
              }catch(e){
                //skip
              }
            })
          }, 2000)
      }
    },

    //生成防伪码 提交编辑表格行信息
    async onEditSubmit_build(e) {
      // console.log(e.form)
      let form = {
        amount: e.form.amount,
        batchId: e.form.id
      }
      factoryBatchService.buildCode(form)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
              });
              this.getDataList();
            } else {
              console.error(res);
              // this.$message.error("操作失败");
            }
          })
          .catch((err) => {
            console.error(err);
            // this.$message.error("操作失败");
          });
    },

    //导出防伪码 提交编辑表格行信息
    async onEditSubmit_export(e) {
      console.log(e.form,"kssivjijvvji====0000")
      // let form = {
      //   scope: e.form.scope,
      //   batchId: e.form.id
      // }
      // window.open(BASE_URL+'/erp/antifakecode/exportBatchValidateExcel/'+form.batchId+'/'+form.scope, "_blank")
    },

    // 新增
    onAdd() {
      this.form = {
        epId:JSON.parse(localStorage.getItem("query")).epId,
        epName:JSON.parse(localStorage.getItem("query")).epName,
        prodName:JSON.parse(localStorage.getItem("query")).prodName,
        prodId:JSON.parse(localStorage.getItem("query")).id,
        pageIndexB1: this.pagination.pageNum,
        pageSize:this.pagination.pageSize
      }
      this.$refs.addSubDialog.showDialog(this.form);
      this.titleSrc = "批次添加";
      if(this.titleSrc == '批次添加'){
        this.config[3].disabled = false
      }
    },

    // 选择
    onSelectClick(e) {
      this.$router.push({
        path: '/goodsManage/factoryBatchDetails',
        query: {
          form: e
        }
      })
    },

    // 生成
    onBuild(row){
      this.$refs.addSubDialog_build.showDialog(row);
    },

    // 导出
    onExport(row){
      window.open(row.fileUrl)
    },

    handleFocus(e) {
      this.config[e.index].options = this.fShowPositions;
    },

    //打开编辑弹窗
    openDialog({ row }) {
      this.form = {
        ...row,
        epId:JSON.parse(localStorage.getItem("query")).epId,
        epName:JSON.parse(localStorage.getItem("query")).epName,
        prodName:JSON.parse(localStorage.getItem("query")).prodName,
        prodId:JSON.parse(localStorage.getItem("query")).id,
        pageIndexB1: this.pagination.pageNum,
        pageSize:this.pagination.pageSize
        // fShowPosition:this.fShowPositions[row.fShowPosition].label
      };

      
      this.$refs.addSubDialog.showDialog(row);
      this.titleSrc = "批次修改";
      if(this.titleSrc == '批次修改'){
        this.config[3].disabled = true
      }
    },
  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
