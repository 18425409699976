<template>
  <div>
    <el-dialog
      :title="titleSrc"
      :visible.sync="dialogFormVisible"
      v-bind="$attrs"
    >

      <free-table
        border
        :data="dataDialog"
        :column="slotColumnDialog"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="paginationDialog.pageSizes"
        :total.sync="paginationDialog.total"
        :page.sync="paginationDialog.pageNum"
        :limit.sync="paginationDialog.pageSize"
        @pagination="getList"
      >
      </free-table>
    </el-dialog>
  </div>
</template>

<script>
import FreeTable from '@/views/components/FreeTable'
export default {
  name: 'addSub',
  components: {
    FreeTable
  },
  props: {
    dataDialog: Array,
    slotColumnDialog: Array,
    columnsProps: Object,
    paginationDialog:Object,
    titleSrc: String
  },
  data () {
    return {
      dialogFormVisible: false,
    }
  },
  methods: {
    showDialog (e = {}) {
      this.formData = e
      this.dialogFormVisible = true
    },
    onClose () {
      this.dialogFormVisible = false
    },
    onEditSubmit () {
      this.$refs.searchConfigFormOne.$children[0].validate((valid) => {
        if (!valid) return
        this.$confirm('确认提交吗？', '提示', {})
          .then(() => {
            this.$emit('on-edit-submit', {
              form: this.formData,
              visible: false
            })
            this.onClose()
          })
          .catch(() => {})
      })
    },
    
    handleFocus (e) {
      this.$emit('on-focus-select', { item: e.item })
    },
    handleAvatarSuccess (e) {
      e.origin.cover = e.res
    }
  }
}
</script>
<style>
.mt20 {
  margin-top: 20px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
