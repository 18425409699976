/**
 * 字典管理相关的接口Api
 */
import { post, postnoAuth } from '../../service'
import apiUrl from './api-url'
// 获取字典管理列表
export const dictInfo = (params = undefined) => {
  return post(apiUrl.getTVipDicPage, params)
}

// 获取子码表格
export const getKeyDicPage = (params = undefined) => {
  return post(apiUrl.getKeyDicPage, params)
}

// 提交
export const addKeyDicPage = (params = undefined) => {
  return post(apiUrl.addKeyDicPage, params)
}

// export const deleteRow = (params = undefined) => {
//   return postnoAuth(apiUrl.deleteTVipDicPage, params, '/bjl', {
//     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
//   })
// }

// 增加行
export const addRow = (params = undefined) => {
  return post(apiUrl.insertTVipDicPage, params)
}

// export const editRow = (params = undefined) => {
//   return postnoAuth(apiUrl.updateTVipDicPage, params, '/bjl', {
//     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
//   })
// }

// export const changeStatus = (params = undefined) => {
//   return postnoAuth(apiUrl.updateDicStatusPage, params, '/bjl', {
//     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
//   })
// }

// export const dicList = (params = undefined) => {
//   return postnoAuth(apiUrl.getDicList, params, '/bjl', {
//     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
//   })
// }

export const editKeyDicPage = (params = undefined) => {
  return post(apiUrl.updateKeyDicPage, params)
}
