<template>
  <d2-container>
    <!-- 查询 -->
    <el-form inline :model="searchForm">
      <el-form-item label="案例标题">
        <el-input
          clearable
          v-model="searchForm.title"
          placeholder="请输入案例标题"
        ></el-input>
      </el-form-item>
      <el-form-item label="案例作者">
        <el-input
          clearable
          v-model="searchForm.author"
          placeholder="请输入案例作者"
        ></el-input>
      </el-form-item>
      <el-form-item label="投稿时间">
        <el-date-picker
          v-model="searchForm.startTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="开始时间"
        >
        </el-date-picker>
        ——
        <el-date-picker
          v-model="searchForm.endTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="结束时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-button type="primary" @click="add()">新增</el-button>
      <el-button @click="getList">查询</el-button>
    </el-form>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      ref="table"
      row-key="id"
      border
      style="width: 100%"
      width="auto"
    >
      <el-table-column prop="caseTitle" label="案例标题" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="caseAuthor" label="案例作者"></el-table-column>
      <el-table-column prop="cover" label="案例封面">
        <template slot-scope="scope">
          <el-image
            class="avatar-size"
            :src="scope.row.cover"
            :preview-src-list="[scope.row.cover]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDateTime"
        label="创建时间"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" @click="deleteById(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      style="margin-top: 20px"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInit.cur_page"
      :page-sizes="pageInit.pageSizes"
      :page-size="pageInit.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInit.total"
    >
    </el-pagination>
    <el-dialog title="新增" :visible.sync="dialogVisible" width="80%">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="案例标题" prop="caseTitle">
              <el-input
                v-model="ruleForm.caseTitle"
                placeholder="最多20字"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="案例作者" prop="caseAuthor">
              <el-input
                v-model="ruleForm.caseAuthor"
                placeholder="最多10字"
                maxlength="10"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="案例封面" prop="cover" style="width: 200px">
              <el-upload
                ref="upload_avatar"
                class="avatar-uploader"
                :action="'http://dev.kcguoyi.com/service/upload/huawei'"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="handleChange"
              >
                <img
                  v-if="ruleForm.cover"
                  :src="ruleForm.cover"
                  class="avatar-up"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="案例详情" prop="info">
              <d2-quill
                v-model="ruleForm.info"
                ref="myQuillEditor"
                @text-change="onEditorBlur"
                @selection-change="onEditorFocus"
                @editor-change="onEditorChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="update">发布</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </d2-container>
</template>

<script>
import { uploadPic } from "@/model/modules/user";
import CaseManage from "@/model/modules/caseManage/index";
export default {
  data() {
    return {
      dialogVisible: false,
      // 搜索条件
      searchForm: {
        title: "",
        author: "",
        startTime: "",
        endTime: "",
        pageIndexB1: 1,
        pageSize: 10,
      },
      // dialog
      ruleForm: {
        caseTitle: "",
        caseAuthor: "",
        cover: "",
        info: "",
      },
      rules: {
        caseTitle: [
          { required: true, message: "请输入案例标题", trigger: "blur" },
        ],
        caseAuthor: [
          { required: true, message: "请输入案例作者", trigger: "blur" },
        ],
        cover: [{ required: true, message: "请输入案例封面", trigger: "blur" }],
        info: [{ required: true, message: "请输入案例详情", trigger: "blur" }],
      },
      // 分页
      pageInit: {
        total: 1,
        cur_page: 1,
        pageSize: 10,
        pageSizes: ["10", "20", "30"],
      },
      // 表格
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleChange(file, fileList) {
      const IMG = new FormData();
      IMG.append(file.name, file.raw);
      uploadPic(IMG).then((res) => {
        console.log(res.data.body[0].filepath);
        this.ruleForm.cover = res.data.body[0].filepath;
      });
    },
    onEditorBlur(delta, oldDelta, source) {
      // 失去焦点事件
    },
    onEditorFocus(range, oldRange, source) {
      // 获得焦点事件
    },
    onEditorChange(eventName, ...args) {
      // 内容改变事件
    },
    // 发布
    update() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.updateCase(this.ruleForm);
        } else {
          return false;
        }
      });
    },
    // 更新案例
    updateCase(params) {
      CaseManage.updateCase(params).then((res) => {
        if (res.data.code == 0) {
          this.dialogVisible = false;
          this.getList();
        }
      });
    },
    // 删除
    deleteById(row) {
      this.$confirm("确认删除吗？", "提示", {}).then(() => {
        CaseManage.delete(row.id).then((res) => {
          if (res.data.code == 0) {
            this.getList();
          }
        });
      });
    },
    // 新增
    add() {
      this.ruleForm = {
        caseTitle: "",
        caseAuthor: "",
        cover: "",
        info: "",
      };
      this.dialogVisible = true;
    },
    // 查看
    edit(row) {
      this.ruleForm = row;
      this.dialogVisible = true;
    },
    // 列表
    getList() {
      CaseManage.list(this.searchForm).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.body.content;
        }
      });
    },
    // 翻页
    handleSizeChange(e) {
      this.pageInit.pageSize = e;
      this.pageInit.cur_page = 1;
      // this.getData();
    },
    handleCurrentChange(e) {
      this.pageInit.cur_page = e;
      // this.getData();
    },
  },
};
</script>

<style scoped>
.margin {
  margin-bottom: 20px;
}
.line {
  display: flex;
}
.line-title {
  width: 80px;
}
.line-content {
  flex: 1;
}
.img-ul {
  display: flex;
  flex-wrap: wrap;
}
.img-box {
  width: 150px;
  margin-right: 30px;
  margin-bottom: 20px;
  text-align: center;
}
.img-box img {
  width: 150px;
  height: 150px;
}
.avatar-up {
  width: 148px;
  height: 148px;
}
.avatar-size {
  width: 100px;
  height: 100px;
}

</style>
