<template>
  <div>
    <el-dialog
      :title="titleSrc"
      :visible.sync="dialogFormVisible"
      v-bind="$attrs"
    >
      <el-divider content-position="left">企业基本信息</el-divider>
      <!--查询条件配置组件-->
      <searchConfigFormOne
        ref="searchConfigFormOne"
        :configData="config"
        :formSet="form"
        :rules="rules"
        :labelWidth="'130px'"
        @on-avatar-success="handleAvatarSuccess"
        @on-focus-select="handleFocus"
      />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onEditSubmit">提交</el-button>
        <el-button type="default" @click="onClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import searchConfigFormOne from '@/views/components/searchConfigForm/searchConfigFormOne'
export default {
  name: 'addSub',
  components: {
    searchConfigFormOne
  },
  props: {
    config: Array,
    form: Object,
    rules: Object,
    titleSrc: String
  },
  data () {
    return {
      dialogFormVisible: false,
      formData: {}
    }
  },
  methods: {
    showDialog (e = {}) {
      this.formData = e
      this.dialogFormVisible = true
    },
    onClose () {
      this.dialogFormVisible = false
    },
    onEditSubmit () {
      this.$refs.searchConfigFormOne.$children[0].validate((valid) => {
        if (!valid) return
        this.$confirm('确认提交吗？', '提示', {})
          .then(() => {
            this.$emit('on-edit-submit', {
              form: this.formData,
              visible: false
            })
            this.onClose()
          })
          .catch(() => {})
      })
    },
    
    handleFocus (e) {
      this.$emit('on-focus-select', { item: e.item })
    },
    handleAvatarSuccess (e) {
      e.origin.cover = e.res
    }
  }
}
</script>
<style>
.mt20 {
  margin-top: 20px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
