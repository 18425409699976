import { get, post } from '../../service'
import apiUrl from './api-url'
// 获取列表
export const pageList = (params = undefined) => {
  return post(apiUrl.getPageList, params)
}

// 新增商品
export const saveProduction = (params = undefined) => {
  return post(apiUrl.saveProduction, params)
}

// 企业信息-下拉列表
export const enterpriseSelect = (params = undefined) => {
  return get(apiUrl.enterpriseSelect, params, process.env.VUE_APP_API_URL_BASE,{
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  })
}
