<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="4"
      :labelWidth="'90px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <div>
      <el-button
        type="success"
        size="mini"
        @click="onAdd"
      >
        复位排序
      </el-button>
    </div>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
      </free-table>
    </div>

    <!--添加/修改弹窗-->
    <add-sub
      ref="selcetCodeBatchDialog"
      width="50%"
      align="left"
      :dataDialog="dataDialog"
      :slotColumnDialog="slotColumnDialog"
      :columnsProps="columnsProps"
      :paginationDialog="paginationDialog"
      :titleSrc="titleSrc"
    />
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'
// 引入页面层级组件
import AddSub from './components/addSub'

// 引入接口

export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
    FreeTable,
    AddSub
  },
  data () {
    return {
      titleSrc: '新增企业',

      // 搜索框渲染
      formSetData: {
        pageIndexB1: Number,
        pageSize: Number
      },
      searchConfigData: [
        {
          label: '企业id',
          formItemId: 0,
          placeholder: '请输入企业id',
          type: 'input',
          prop: 'courseName'
        },
        {
          label: '企业名称',
          formItemId: 0,
          placeholder: '请输入企业名称',
          type: 'input',
          prop: 'courseName'  
        },
        {
          label: '商品id',
          formItemId: 0,
          placeholder: '请输入商品id',
          type: 'input',
          prop: 'courseName'  
        },
        {
          label: '商品名称',
          formItemId: 0,
          placeholder: '请输入商品id',
          type: 'input',
          prop: 'courseName'  
        }
      ],

      // 表格数据渲染
      data: [
        {
          q: 'q',
          w: 'w',
          e: 'e',
          r: 'r',
          t: 't',
          y: 'y',
          u: 'u',
          i: 'i',
        }
      ],
      slotColumn: [
        { label: 'id', prop: 'q' , sort: true},
        { label: '缩略图', prop: 'img', slotScope: true },
        { label: '商品名称', prop: 'w' ,width:600},
        { label: '所属企业', prop: 'e'  , sort: true},
        { label: '价格', prop: 'r' , sort: true},
        { label: '防伪码数量', prop: 't', sort: true},
        { label: '批次数量', prop: 'y', sort: true },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '300px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onSelcetCodeBatch(scope.row)
                  }}
                >
                  选择批次
                </el-button>
              </div>
            )
          }
        }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },
      dataDialog: [
        {
          q: 'q',
          w: 'w',
          e: 'e',
          r: 'r',
          t: 't',
          y: 'y',
          u: 'u',
          i: 'i',
        }
      ], 
      slotColumnDialog: [
        { label:'序号', prop :'' ,  type:"index", width:50,},
        { label: 'id', prop: 'q' , sort: true},
        { label: '名称', prop: 'name'},
        { label: '说明', prop: 'w' },
        { label: '防伪码数量', prop: 'e'  , sort: true},
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '370px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onSelcet(scope.row)
                  }}
                >
                  选择
                </el-button>
              </div>
            )
          }
        }
      ], 
      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      },
      paginationDialog: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      }
    }
  },

  mounted () {
  },
  // 路由跳转之前保存当前滚动条的位置。
  async beforeRouteLeave(to, from, next) {
    console.log(to,from,"ccjejveijei====")
    await this.$store.dispatch('d2admin/page/open',to)
    next();
  },
  methods: {
    // 查询组件 下拉框
    handleQueryFocus() {

    },

    // 表格查询按钮
    onQuery () {
      this.pagination.pageNum = 1
    },

    // 分页选择器切换事件
    getList (e) {
      // this.pagination.pageNum = e.page
      // this.pagination.pageSize = e.limit
    },
    // 选择批次
    onSelcetCodeBatch () {
      this.$refs.selcetCodeBatchDialog.showDialog(this.form)
      this.titleSrc = '请选择批次'
    },
    onSelcet(){
      this.$router.push({
          path:"/codeManage/selectCodeBatch"
      })
    },
    // 提交编辑表格行信息
    onEditSubmit (e) {
      
    },

    // 商品
    onGoodsClick(e) {

    },
    // 编辑
    onEditClick(e) {
      const form = {
        ...e
      } 
      this.$refs.addSubDialog.showDialog(form)
      this.titleSrc = '编辑企业'
    },
    // 删除
    onDeleteClick(e) {

    },
  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
