<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5.8"
      :btnColspan="5"
      :labelWidth="'100px'"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>

    <div>
      <el-button type="default" size="mini" @click="onClickOperate">
        操作记录
      </el-button>
    </div>

    <!--表格条件配置组件-->
    <div class="mt20">
      <div style="height: 39px">
        <!-- <u class="batchRefund" @click="onBatchRefund">批量退款</u> -->
        <span class="payAmount"
          >交易成功订单实付金额：<span style="color: #D9001B">{{
            kcc_sum
          }}</span>
          元</span
        >
      </div>

      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:orderTm="{ row }">
          {{ formData(row.orderTm) }}
        </template>
        <template v-slot:paymentTm="{ row }">
          {{ formData(row.paymentTm) }}
        </template>
      </free-table>
    </div>

    <!--详情-->
    <particulars ref="particularsSubDialog" :titleSrc="titleSrc" />

    <!-- 操作记录弹窗 -->
    <el-dialog :visible.sync="operateDialogVisible" width="70%">
      <search-config
        ref="searchConfig"
        :configData="operateSearchConfigData"
        :formSet="operateFormSetData"
        :colspan="5.8"
        :btnColspan="5"
        :labelWidth="'110px'"
      >
        <el-button type="primary" @click="onQueryOperate">查询</el-button>
      </search-config>

      <free-table
        border
        :data="operateData"
        :column="operateSlotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="operatePagination.pageSizes"
        :total.sync="operatePagination.total"
        :page.sync="operatePagination.pageNum"
        :limit.sync="operatePagination.pageSize"
        @pagination="getOperateList"
      >
      </free-table>
    </el-dialog>
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from "@/views/components/searchConfigForm";
import FreeTable from "@/views/components/FreeTable";
// 引入页面层级组件
import particulars from "./components/particulars";

// 引入接口
import DespositApi from "@/model/modules/mod_deposit";

export default {
  name: "roleinfo",
  components: {
    SearchConfig,
    FreeTable,
    particulars
  },
  data() {
    return {
      operateDialogVisible: false, // 操作记录弹窗
      kcc_sum: 0, //交易成功订单实付金额总和
      fShowPositions: {},
      titleSrc: "订单详情",
      // 搜索框渲染
      formSetData: {
        userName: '',
        tel: '',
        pageIndexB1: 1,
        pageSize: 10
      },
      operateFormSetData: {
        operationAccount: '',
        targetName: '',
        tel: '',
        pageIndexB1: 1,
        pageSize: 10
      },
      searchConfigData: [
        {
          label: "用户昵称",
          placeholder: "请输入",
          type: "input",
          prop: "userName"
        },
        {
          label: "手机号",
          placeholder: "请输入",
          type: "input",
          prop: "tel"
        }
      ],

      operateSearchConfigData: [
        {
          label: "操作账号",
          placeholder: "请输入",
          type: "input",
          prop: "operationAccount"
        },
        {
          label: "充值对象",
          placeholder: "请输入",
          type: "input",
          prop: "targetName"
        },
        {
          label: "充值对象手机号",
          placeholder: "请输入",
          type: "input",
          prop: "tel"
        }
      ],
      // 表格数据渲染
      data: [],
      operateData: [],
      slotColumn: [
        // { label: '选择', prop: 'selection', type: 'selection' },
        { label: "订单号", prop: "orderNo" },
        { label: "购买用户id", prop: "owner" },
        { label: "用户昵称", prop: "nickName" },
        { label: "真实姓名", prop: "fullName" },
        { label: "手机号", prop: "tel" },
        { label: "购买康驰币个数", prop: "kccAmount" },
        { label: "订单状态", prop: "orderStatusStr" },
        { label: "付款渠道", prop: "payWayStr" },
        // { label: '购买平台', prop: '' },
        { label: "创建时间", prop: "orderTm", slotScope: true },
        { label: "完成时间", prop: "paymentTm", slotScope: true }
        // {
        //   label: '操作',
        //   prop: 'cation',
        //   align: 'center',
        //   width: '270px',
        //   render: (h, scope) => {
        //     return (
        //       <div>
        //         <el-button
        //           type="default"
        //           size="small"
        //           onClick={() => {
        //             this.btnClick(1, scope.row)
        //           }}
        //         >
        //           退款
        //         </el-button>
        //         <el-button
        //           type="default"
        //           size="small"
        //           onClick={() => {
        //             this.btnClick(2, scope.row)
        //           }}
        //         >
        //           详情
        //         </el-button>
        //       </div>
        //     )
        //   }
        // }
      ],
      operateSlotColumn: [
        { label: "操作账户", prop: "operationAccount" },
        { label: "充值对象", prop: "nickName" },
        { label: "充值对象手机号", prop: "tel" },
        { label: "充值数量", prop: "reChargeNum" },
        { label: "操作时间", prop: "operationLabel" },
      ],
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true
      },
      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        total: 1
      },
      operatePagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        total: 1
      }
    };
  },
  mounted() {
    this.getDataList();
  },
  computed: {
    // 将时间戳转化为时间格式
    formData() {
      return data => {
        let time = this.$moment(data).format("YYYY-MM-DD hh:mm:ss");
        return time;
      };
    }
  },
  methods: {
    // 获取充值总额
    getDepositSum() {
      DespositApi.getDepositSum().then(res => {
        if (res.data.code == 0) {
          this.kcc_sum = res.data.body;
        }
      });
    },

    // 初始化表格渲染
    async getDataList() {
      this.getDepositSum();

      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      const res = await DespositApi.pageList(this.formSetData);
      this.data = res.data.body.content;
      this.pagination.total = res.data.body.total * 1;
    },

    async getOperateDataList() {
      this.operateFormSetData.pageIndexB1 = this.operatePagination.pageNum;
      this.operateFormSetData.pageSize = this.operatePagination.pageSize;
      const res = await DespositApi.operateList(this.operateFormSetData);
      this.operateData = res.data.body.content;
      this.operatePagination.total = res.data.body.total * 1;
    },

    // 查询
    onQuery() {
      this.pagination.pageNum = 1;
      this.getDataList()
    },

    // 查询操作记录
    onQueryOperate() {
      this.operatePagination.pageNum = 1
      this.getOperateDataList()
    },

    // 操作记录
    onClickOperate() {
      this.operateDialogVisible = true;
      this.getOperateDataList()

    },

    // 分页选择器切换事件
    getList(e) {
      console.log(e);
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getDataList();
    },

    getOperateList(e) {
      console.log(e);
      this.operatePagination.pageNum = e.page;
      this.operatePagination.pageSize = e.limit;
      this.getOperateDataList();
    },

    //批量退款
    onBatchRefund() {},

    btnClick(index, e) {
      switch (index) {
        case 1: // 退款
          this.$confirm("确认退款么？", "提示", {})
            .then(() => {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500
              });
            })
            .catch(err => {});
          break;
        case 2: // 详情
          this.titleSrc = "订单详情";
          this.$refs.particularsSubDialog.showDialog(e);
          break;
        default:
          this.$message.error("未定义");
          break;
      }
    },

    formatData(data, key, val) {
      let obj = {};
      for (let i in data) {
        let item = data[i];
        obj[item[key]] = {
          value: item[key] || "",
          label: item[val] || ""
        };
      }
      return obj;
    },

    setTime(data) {
      let t = data + 24 * 60 * 60 * 1000; //24小时 * 60分钟 * 60秒 * 1000
      let d = new Date(t);

      let theMonth = d.getMonth() + 1;
      let theDate = d.getDate();
      let theHours = d.getHours();
      let theMinutes = d.getMinutes();
      let theSeconds = d.getSeconds();

      if (theMonth < 10) {
        theMonth = "0" + theMonth;
      }
      if (theDate < 10) {
        theDate = "0" + theDate;
      }
      if (theHours < 10) {
        theHours = "0" + theHours;
      }
      if (theMinutes < 10) {
        theMinutes = "0" + theMinutes;
      }
      if (theSeconds < 10) {
        theSeconds = "0" + theSeconds;
      }

      let date = d.getFullYear() + "-" + theMonth + "-" + theDate;
      let time = theHours + ":" + theMinutes + ":" + theSeconds;
      let spare = date + " " + time;
      return spare;
    }
  }
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
.batchRefund {
  color: #02a7f0;
  display: inline-block;
  padding: 9px;
}
.payAmount {
  color: #02a7f0;
  position: relative;
  float: right;
  padding-right: 19px;
  line-height: 39px;
}

/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
