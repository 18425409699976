<template>
  <div class="imItem_wrapper_flex">
    <img src="" width="54" height="54" />
    <div class="member_msg">
      <div>吴用 <span class="client_name">山东梁山诊所</span></div>
      <div class="speak_talker"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'imItem'
}
</script>

<style lang="scss" scoped>
.imItem_wrapper_flex {
  width: 576px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
}
.member_msg {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-size: 14px;
  color: #808080;
  margin-left: 30px;
  .client_name {
    margin-left: 20px;
    font-size: 12px;
  }
  .speak_talker {
    color: #333333;
    width: 100%;
    height: 45px;
    font-size: 13px;
    line-height: 25px;
    border: 1px solid #dfdfdf;
    min-height: 45px;
    background: #fff;
  }
}
</style>
