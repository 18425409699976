import { post } from '../../service'
import apiUrl from './api-url'

// 课程管理
export const getPosition = (params = undefined) => {
  return post(apiUrl.getPosition, params)
}
export const pageList = (params = undefined) => {
  return post(apiUrl.getQueryCriteria, params)
}
export const getAddModify = (params = undefined) => {
  return post(apiUrl.getAddModify, params)
}
export const getCourseCatalog = (params = undefined) => {
  return post(apiUrl.getCourseCatalog, params)
}
export const getSpeaker = (params = undefined) => {
  return post(apiUrl.getSpeaker, params)
}
export const getRangeList = (params = undefined) => {
  return post(apiUrl.getRangeList, params)
}
export const setRecommendCourse = (params = undefined) => {
  return post(apiUrl.setRecommendCourse, params)
}
export const batchShelves = (params = undefined) => {
  return post(apiUrl.batchShelves, params)
}
// 获取菜单列表
export const menuList = (params = {}) => {
  return post(apiUrl.getMenuList, params)
}

// 视频管理
export const getCourseVideoList = (params = undefined) => {
  return post(apiUrl.getCourseVideoList, params)
}
export const saveCourseVideo = (params = undefined) => {
  return post(apiUrl.saveCourseVideo, params)
}
export const updateVideoNoById = (params = undefined) => {
  return post(apiUrl.updateVideoNoById, params)
}
export const batchDelVideo = (params = undefined) => {
  return post(apiUrl.batchDelVideo, params)
}


