/**
 * 用户审核
 */


 import { post } from '../../service'
 import apiUrl from './api-url'

export const getUserAudit = (params = undefined) => {
   return post(apiUrl.userAudit, params)
}
export const certificationAudit = (params = undefined) => {
   return post(apiUrl.certificationAudit, params)
}
export const getUserInfo = (params = undefined) => {
   return post(apiUrl.getUserInfo, params)
}
