/**
 * 公众号
 */

 import { post ,postnoAuth, get , deletes , put } from '../../service'
 import apiUrl from './api-url'
 //获取列表
 export const pageList = (params=undefined)=>{
  return postnoAuth(apiUrl.getPagelist,params,"/bjl", {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  })
 } 
 
 // 增加行
 export const addRow= (params = undefined)=>{
  return postnoAuth(apiUrl.insert,params,"/bjl", {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  })
 }
 
 // 
 export const editRow= (params = undefined)=>{
     return postnoAuth(apiUrl.update,params,"/bjl", {
       "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
     })
 }
 
 export const changeData= (params = undefined)=>{
     return postnoAuth(apiUrl.rollbackData,params,"/bjl", {
       "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
     })
 }
 
 export const addWxMenuButton= (params = undefined)=>{
  return postnoAuth(apiUrl.insertWxMenuButton,params,"/bjl", {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  })
 }
 //

 export const getMenuButton= (params = undefined)=>{
  return postnoAuth(apiUrl.getMenuAPI,params,"/bjl", {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  })
 }