<template>
  <d2-container>
    <el-tabs @tab-click="onTabClick" v-model="commentType">
      <el-tab-pane label="心得管理" name="3">
        <!-- 查询 -->
        <el-form inline :model="experienceListForm">
          <el-form-item label="中药类型">
            <el-select v-model="experienceListForm.type" placeholder="请选择" clearable>
              <el-option
                v-for="item in experienceList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称">
            <el-input
              v-model="experienceListForm.name"
              placeholder="请输入课程名称"
              class="form-input"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="评价时间">
            <el-date-picker
              v-model="experienceListForm.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="开始时间"
            ></el-date-picker>——
            <el-date-picker
              v-model="experienceListForm.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="结束时间"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="commentQuery">查询</el-button>
            <el-button type="primary" @click="deleteAll">批量删除</el-button>
          </el-form-item>
        </el-form>
        <!-- 表格 -->
        <el-table
          :data="experienceTableData"
          ref="experienceTable"
          @selection-change="handleSelectionChange"
          row-key="id"
          border
          style="width: 100%"
          width="auto"
        >
          <el-table-column type="selection" width="55" show-overflow-tooltip reserve-selection></el-table-column>
          <el-table-column prop="prescriptionName" label="中药名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="sourceName" label="中药类型" show-overflow-tooltip></el-table-column>
          <el-table-column prop="fullName" label="真实姓名" show-overflow-tooltip></el-table-column>
          <el-table-column prop="tel" label="用户手机号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createDateTime" label="创建时间" show-overflow-tooltip></el-table-column>
          <el-table-column prop="content" label="心得内容" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button type="text" @click="deleteItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="留言管理" name="4">
        <!-- 查询 -->
        <el-form inline :model="messageListForm">
          <el-form-item label="案例标题">
            <el-input
              v-model="messageListForm.title"
              placeholder="请输入案例标题"
              class="form-input"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="messageListForm.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="开始时间"
            ></el-date-picker>——
            <el-date-picker
              v-model="messageListForm.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="结束时间"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="commentQuery">查询</el-button>
            <el-button type="primary" @click="deleteAll">批量删除</el-button>
          </el-form-item>
        </el-form>
        <!-- 表格 -->
        <el-table
          :data="messageTableData"
          ref="experienceTable"
          @selection-change="handleSelectionChange"
          row-key="id"
          border
          style="width: 100%"
          width="auto"
        >
          <el-table-column type="selection" width="55" show-overflow-tooltip reserve-selection></el-table-column>
          <el-table-column prop="cname" label="案例标题" show-overflow-tooltip></el-table-column>
          <el-table-column prop="fullName" label="真实姓名" show-overflow-tooltip></el-table-column>
          <el-table-column prop="ownerTel" label="用户手机号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createDateTime" label="创建时间" show-overflow-tooltip></el-table-column>
          <el-table-column prop="content" label="留言内容" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button type="text" @click="deleteItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="评价管理" name="1">
        <!-- 查询 -->
        <el-form inline :model="searchForm">
          <el-form-item label="课程名称">
            <el-input clearable v-model="searchForm.courseName" placeholder="请输入课程名称"></el-input>
          </el-form-item>
          <el-form-item label="评价时间">
            <el-date-picker
              v-model="searchForm.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="开始时间"
            ></el-date-picker>——
            <el-date-picker
              v-model="searchForm.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="结束时间"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="commentQuery">查询</el-button>
            <el-button type="primary" @click="deleteAll">批量删除</el-button>
          </el-form-item>
        </el-form>
        <!-- 表格 -->
        <el-table
          :data="commentTableData"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          row-key="id"
          border
          style="width: 100%"
          width="auto"
        >
          <el-table-column type="selection" width="55" show-overflow-tooltip reserve-selection></el-table-column>
          <el-table-column prop="courseName" label="评价课程" show-overflow-tooltip></el-table-column>
          <el-table-column prop="id" label="用户ID" show-overflow-tooltip></el-table-column>
          <el-table-column prop="ownerName" label="用户昵称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="tel" label="用户手机号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="tmLable" label="评价时间" show-overflow-tooltip></el-table-column>
          <el-table-column prop="score" label="评价星级" show-overflow-tooltip></el-table-column>
          <el-table-column prop="content" label="评价内容" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button type="text" @click="deleteItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="弹幕管理" name="2">
        <!-- 查询 -->
        <el-form inline :model="screenForm">
          <el-form-item>
            <el-select
              v-model="screenType"
              placeholder="请选择"
              clearable
              style="width: 120px"
              @change="changeScreenType"
            >
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-input
              v-model.number="screenForm.aliasCourse"
              placeholder="请输入课程名称"
              class="form-input"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="screenForm.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="开始时间"
            ></el-date-picker>——
            <el-date-picker
              v-model="screenForm.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="结束时间"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="commentQuery">查询</el-button>
            <el-button type="primary" @click="deleteAll">批量删除</el-button>
          </el-form-item>
        </el-form>
        <!-- 表格 -->
        <el-table
          :data="screenTableData"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          border
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="courseName" label="所属课程" width="100"></el-table-column>
          <el-table-column prop="videoName" label="所属视频"></el-table-column>
          <el-table-column prop="userId" label="用户ID"></el-table-column>
          <el-table-column prop="nickName" label="用户昵称"></el-table-column>
          <el-table-column prop="tel" label="用户手机号"></el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <el-table-column prop="content" label="弹幕内容"></el-table-column>
          <el-table-column prop="count" label="举报次数">
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row)">
                {{
                  scope.row.count
                }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button type="text" @click="deleteItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <!-- 分页 -->
    <el-pagination
      style="margin-top: 20px"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInit.cur_page"
      :page-sizes="pageInit.pageSizes"
      :page-size="pageInit.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInit.total"
    ></el-pagination>

    <el-dialog :visible.sync="deleteDialogVisible" width="50%">
      <div>是否确认删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmVersion">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="70%">
      <!-- 表格 -->
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="nickName" label="举报人昵称"></el-table-column>
        <el-table-column prop="tel" label="举报人手机号"></el-table-column>
        <el-table-column prop="createTime" label="举报时间"></el-table-column>
        <el-table-column prop="reason" label="举报原因"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="margin-top: 20px"
        @size-change="handleDialogSizeChange"
        @current-change="handleDialogCurrentChange"
        :current-page="dialogPageInit.cur_page"
        :page-sizes="dialogPageInit.pageSizes"
        :page-size="dialogPageInit.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dialogPageInit.total"
      ></el-pagination>
    </el-dialog>
  </d2-container>
</template>
<script>
import Comment from "@/model/modules/comment/index";

export default {
  data() {
    return {
      cur_del_id: "", // 当前删除id
      dialogId: "", // 举报id
      dialogTitle: "新增版本",
      screenType: "0", // 所属课程/视频
      commentType: "3", // 1评价管理 2弹幕管理 3心得管理 4留言管理
      // 弹幕弹窗列表
      tableData: [], // 举报列表
      // 弹幕
      typeList: [
        { value: "0", label: "所属课程" },
        { value: "1", label: "所属视频" },
      ],
      screenForm: {
        aliasCourse: "", // 课程名称别名
        courseName: "", // 课程名称
        videoName: "",
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        pageIndexB1: 1,
        pageSize: 10,
      },
      screenTableData: [],
      // 评价
      searchForm: {
        courseName: "", // 课程名称
        startTime: "", // 开始时间
        endTime: "", // 结束时间
        pageIndexB1: 1,
        pageSize: 10,
      },
      commentTableData: [],
      // 心得
      experienceList: [
        { value: "0", label: "全部" },
        { value: "1", label: "中药单方" },
        { value: "2", label: "中药组方" },
      ],
      experienceListForm: {
        type: "0",
        name: "",
        startTime: "",
        endTime: "",
        pageIndexB1: 1,
        pageSize: 10,
      },
      experienceTableData: [],
      // 留言
      messageListForm: {
        title: "",
        startTime: "",
        endTime: "",
        pageIndexB1: 1,
        pageSize: 10,
      },
      messageTableData: [],
      // 分页
      pageInit: {
        total: 1,
        cur_page: 1,
        pageSize: 10,
        pageSizes: ["10", "20", "30"],
      },
      dialogPageInit: {
        total: 1,
        cur_page: 1,
        pageSize: 10,
        pageSizes: ["10", "20", "30"],
      },
      dialogVisible: false,
      deleteDialogVisible: false,
    };
  },

  mounted() {
    this.getExperienceList();
  },
  methods: {
    changeScreenType() {
      this.screenForm.aliasCourse ="";
    },
    // 批量选择
    handleSelectionChange(val) {
      let arr = val.map((res) => {
        return res.id;
      });
      this.cur_del_id = arr.join(",");
    },
    // 心得列表
    getExperienceList() {
      this.experienceListForm.pageIndexB1 = this.pageInit.cur_page;
      this.experienceListForm.pageSize = this.pageInit.pageSize;
      Comment.experienceList(this.experienceListForm).then((res) => {
        if (res.data.code == 0) {
          this.experienceTableData = res.data.body.content;
          this.experienceTableData.forEach((ele) => {
            if (ele.source == 1) {
              ele.sourceName = "中药单方";
            } else {
              ele.sourceName = "中药组方";
            }
          });
          this.pageInit.total = res.data.body.total;
        }
      });
    },
    // 留言列表
    getMessageList() {
      this.messageListForm.pageIndexB1 = this.pageInit.cur_page;
      this.messageListForm.pageSize = this.pageInit.pageSize;
      Comment.messageList(this.messageListForm).then((res) => {
        if (res.data.code == 0) {
          this.messageTableData = res.data.body.content;
          this.pageInit.total = res.data.body.total;
        }
      });
    },
    // 评价列表
    getDataList() {
      this.searchForm.pageIndexB1 = this.pageInit.cur_page;
      this.searchForm.pageSize = this.pageInit.pageSize;
      Comment.commentList(this.searchForm).then((res) => {
        if (res.data.code == 0) {
          this.commentTableData = res.data.body.content;
          this.pageInit.total = res.data.body.total;
        }
      });
    },
    // 弹幕列表
    getScreenDataList() {
      if (this.screenType == 1) {
        this.screenForm.videoName = this.screenForm.aliasCourse;
        this.screenForm.courseName = "";
      } else {
        this.screenForm.courseName = this.screenForm.aliasCourse;
        this.screenForm.videoName = "";
      }
      this.screenForm.pageIndexB1 = this.pageInit.cur_page;
      this.screenForm.pageSize = this.pageInit.pageSize;
      Comment.screenList(this.screenForm).then((res) => {
        if (res.data.code == 0) {
          this.screenTableData = res.data.body.content;
          this.pageInit.total = res.data.body.total;
        }
      });
    },
    // 查询
    commentQuery() {
      this.pageInit.cur_page = 1;
      this.getData();
    },
    // 确定
    confirmVersion() {
      this.deleteCommon();
    },
    // 切换标签
    onTabClick(tab, e) {
      this.pageInit.cur_page = 1;
      this.pageInit.pageSize = 10;
      this.commentType = tab.name;
      // 清空
      this.cur_del_id = "";
      this.getData();
    },
    getData() {
      if (this.commentType == 1) {
        // 1 评价
        this.getDataList();
      } else if (this.commentType == 2) {
        // 2 弹幕
        this.getScreenDataList();
      } else if (this.commentType == 3) {
        // 3 心得
        this.getExperienceList();
      } else {
        // 4 留言
        this.getMessageList();
      }
    },
    // 举报次数列表
    openDetail(row) {
      if (row.count == 0) {
        return;
      }
      this.dialogId = row.id;
      this.getDetail();
    },
    getDetail() {
      let params = {
        pageSize: this.dialogPageInit.pageSize,
        pageIndexB1: this.dialogPageInit.cur_page,
        id: this.dialogId,
      };
      var loading = this.$loading({
        lock: true,
      });
      Comment.screenWarnList(params).then((res) => {
        loading.close();
        if (res.data.code == 0) {
          this.tableData = res.data.body.content;
          this.dialogPageInit.total = res.data.body.total;
          this.dialogTitle = "编辑版本";
          this.dialogVisible = true;
        }
      });
    },
    // 评价批量删除
    deleteAll() {
      this.deleteCommon();
    },
    // 评价根据id删除
    deleteItem(row) {
      this.deleteDialogVisible = true;
      this.cur_del_id = row.id;
    },
    deleteCommon() {
      if (this.commentType == 1) {
        Comment.deleteComment(this.cur_del_id).then((res) => {
          this.deleteDialogVisible = false;
          if (res.data.code == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
              duration: 1500,
            });
            this.getDataList();
          }
        });
      } else if (this.commentType == 2) {
        Comment.deleteScreen(this.cur_del_id).then((res) => {
          this.deleteDialogVisible = false;
          if (res.data.code == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
              duration: 1500,
            });
            this.getScreenDataList();
          }
        });
      } else if (this.commentType == 3) {
        Comment.deleteExperience(this.cur_del_id).then((res) => {
          this.deleteDialogVisible = false;
          if (res.data.code == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
              duration: 1500,
            });
            this.getExperienceList();
          }
        });
      } else {
        Comment.deleteMessage(this.cur_del_id).then((res) => {
          this.deleteDialogVisible = false;
          if (res.data.code == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
              duration: 1500,
            });
            this.getMessageList();
          }
        });
      }
    },
    // 翻页
    handleSizeChange(e) {
      this.pageInit.pageSize = e;
      this.pageInit.cur_page = 1;
      this.getData();
    },
    handleCurrentChange(e) {
      this.pageInit.cur_page = e;
      this.getData();
    },
    // 翻页
    handleSizeChange(e) {
      this.pageInit.pageSize = e;
      this.pageInit.cur_page = 1;
      this.getData();
    },
    handleCurrentChange(e) {
      this.pageInit.cur_page = e;
      this.getData();
    },
    handleDialogSizeChange(e) {
      this.dialogPageInit.pageSize = e;
      this.dialogPageInit.cur_page = 1;
      this.getDetail();
    },
    handleDialogCurrentChange(e) {
      this.dialogPageInit.cur_page = e;
      this.getDetail();
    },
  },
};
</script>
