<template>
  <d2-container>
    <!--查询条件配置组件-->
    <!-- <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="5"
      :labelWidth="'70px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config> -->
    <!-- 添加导航按钮 -->
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        添加活动
      </el-button>
    </div>
    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:status="{ row }">
          {{ row.status == 1 ? "启用" : "禁用" }}
        </template>
        <template v-slot:cover="{ row }">
          <div @click="showImg(row)" style="margin-top: 7px; cursor: pointer">
            <img :src="row.cover" width="30" height="20" class="image" />
          </div>
        </template>
        <template v-slot:ctm="{ row }">
          {{ formData(row.ctm) }}
        </template>
      </free-table>
    </div>
    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
    />
    <el-dialog
      width="20%"
      class="el-dialog-col-2"
      :visible.sync="dialogVisible"
      :close-on-click-modal="true"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </d2-container>
</template>
<script>
let id = 1000;
//引入查询条件配置组件
import SearchConfig from "@/views/components/searchConfigForm";
import FreeTable from "@/views/components/FreeTable";
//引入页面层级组件
import AddSub from "./components/addSub";
//引入接口
import ActivityList from "@/model/modules/activityManage";

export default {
  name: "roleinfo",
  components: {
    SearchConfig,
    FreeTable,
    AddSub
  },
  data() {
    return {
      fShowPositions: {},
      titleSrc: "",
      searchConfigData: [
        {
          label: "导航名称",
          formItemId: 0,
          placeholder: "请输入导航名称",
          type: "input",
          prop: "fNaviName"
        },
        {
          label: "显示位置",
          formItemId: 1,
          placeholder: "请选择",
          type: "select",
          isApi: true,
          prop: "fShowPosition",
          options: []
        }
      ],
      form: {
        name: "",
        regBeginTmLabel: "",
        regFinishTmLabel: "",
        startTmLabel: "",
        endTmLabel: "",
        quota: ""
      },
      config: [
        [
          {
            colSpan: 12,
            label: "活动名称",
            type: "input",
            prop: "name",
            placeholder: "请输入活动名称(15字)"
          },
          {
            colSpan: 12,
            label: "报名开始时间",
            type: "timePicker",
            prop: "regBeginTmLabel",
            placeholder: "请选择报名开始时间"
          },
          {
            colSpan: 12,
            label: "报名结束时间",
            type: "timePicker",
            prop: "regFinishTmLabel",
            placeholder: "请选择报名结束时间"
          },
          {
            colSpan: 12,
            label: "活动开始时间",
            type: "timePicker",
            prop: "startTmLabel",
            placeholder: "请选择活动开始时间"
          },
          {
            colSpan: 12,
            label: "活动结束时间",
            type: "timePicker",
            prop: "endTmLabel",
            placeholder: "请选择活动结束时间"
          },
          {
            colSpan: 12,
            label: "活动地点",
            type: "input",
            prop: "place",
            placeholder: "请输入活动详细地址(30字)"
          },
          {
            colSpan: 12,
            label: "活动名额",
            type: "numberInput",
            prop: "quota",
            placeholder: "请输入活动最多人数",
            disabled: false
          },
          {
            colSpan: 12,
            label: "联系电话",
            type: "input",
            prop: "phone",
            placeholder: "请输入活动电话"
          },
          {
            colSpan: 12,
            label: "活动价格(元)",
            type: "numberInput",
            prop: "priceLabel",
            placeholder: "0元即免费，创建后不可修改",
            disabled: false
          },
          {
            colSpan: 12,
            label: "活动封面",
            type: "uploadAvatar",
            prop: "cover"
          }
        ],
        [
          {
            colSpan: 24,
            label: "活动详情",
            type: "quill",
            prop: "content"
          }
        ]
      ],
      data: [], //表格数据渲染
      slotColumn: [
        //{ label: "选择", prop: "selection", type: "selection" },
        //  { label: '序号', prop: 'index', type: 'index' },
        { label: "id", prop: "id" },
        { label: "名称", prop: "name" },
        { label: "报名开始时间", prop: "regBeginTmLabel" },
        { label: "报名结束时间", prop: "regFinishTmLabel" },
        { label: "活动开始时间", prop: "startTmLabel" },
        { label: "活动结束时间", prop: "endTmLabel" },
        { label: "活动地点", prop: "place" },
        { label: "活动名额", prop: "quota" },
        { label: "已报名人数", prop: "actCount" },
        { label: "活动状态", prop: "activityStatus" },
        { label: "联系电话", prop: "phone" },
        { label: "创建时间", prop: "ctm", slotScope: true },
        // { label: "图片", prop: "imgUrl", slotScope: true },
        // { label: "链接", prop: "linkUrl" },
        // { label: "显示位置", prop: "showPosition", slotScope: true },
        // { label: "状态", prop: "status", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  编辑
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row);
                  }}
                >
                  结束
                </el-button>
              </div>
            );
          }
        }
      ],
      formSetData: {
        // 查询搜索内容
        pageIndexB1: Number,
        pageSize: Number
      },
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 1,
        pageSizes: [2, 10, 20]
      },
      dialogImageUrl: "",
      dialogVisible: false,
      // 表单验证
      rules: {
        name: [
          { required: true, message: "请输入活动名称(15字)", trigger: "blur" }
        ],
        regBeginTmLabel: [
          { required: true, message: "请选择报名开始时间", trigger: "blur" }
        ],
        regFinishTmLabel: [
          { required: true, message: "请选择报名结束时间", trigger: "blur" }
        ],
        startTmLabel: [
          { required: true, message: "请选择活动开始时间", trigger: "blur" }
        ],
        endTmLabel: [
          { required: true, message: "请选择活动结束时间", trigger: "blur" }
        ],
        place: [
          {
            required: true,
            message: "请输入活动详细地址(30字)",
            trigger: "blur"
          }
        ],
        quota: [
          { required: true, message: "请输入活动最多人数", trigger: "blur" }
        ],
        priceLabel: [
          { required: true, message: "请输入活动价格", trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    this.getDataList();
  },
  computed: {
    // 将时间戳转化为时间格式
    formData() {
      return data => {
        let time = this.$moment(data).format("YYYY-MM-DD hh:mm:ss");
        return time;
      };
    }
  },
  methods: {
    //  图片放大
    showImg(row) {
      this.dialogImageUrl = row.cover;
      this.dialogVisible = true;
    },
    //初始化表格渲染
    async getDataList() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      let res = await ActivityList.pageList(this.formSetData);
      this.data = res.data.body.content;
      this.pagination.total = res.data.body.total * 1;
    },
    //提交编辑表格行信息
    async onEditSubmit(e) {
      this.form = e.form;
      this.form.regBeginTm = this.$moment(this.form.regBeginTmLabel).valueOf();
      this.form.regFinishTm = this.$moment(
        this.form.regFinishTmLabel
      ).valueOf();
      this.form.startTm = this.$moment(this.form.startTmLabel).valueOf();
      this.form.endTm = this.$moment(this.form.endTmLabel).valueOf();
      this.form.price = this.form.priceLabel * 100 * 1000;
      console.log("onEditSubmit");
      console.log(this.form);

      if (this.titleSrc == "活动修改") {
        ActivityList.save(this.form)
          .then(res => {
            if (res.data.code == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 1500
              });
              this.getDataList();
            } else {
              console.error(res);
              this.$message.error("修改失败");
            }
          })
          .catch(err => {
            console.error(err);
            this.$message.error("修改失败");
          });
      } else if (this.titleSrc == "活动添加") {
        this.form.status = 1;
        ActivityList.save(this.form)
          .then(res => {
            if (res.data.code == 0) {
              this.$message({
                message: "添加成功",
                type: "success",
                duration: 1500
              });
              this.pagination.pageNum = 1;
              this.form = {};
              this.getDataList();
            } else {
              console.error(res);
              this.$message.error("添加失败");
            }
          })
          .catch(err => {
            console.error(err);
            this.$message.error("添加失败");
          });
      }
    },
    //打开编辑弹窗
    openDialog({ row }) {
      // this.config[0][5].disabled = true
      // this.config[0][7].disabled = true
      this.form = {
        ...row
      };
      this.$refs.addSubDialog.showDialog(row);
      this.titleSrc = "活动修改";
    },

    onAdd() {
      this.form = {};
      // this.config[0][5].disabled = false
      // this.config[0][7].disabled = false
      this.$refs.addSubDialog.showDialog(this.form);
      this.titleSrc = "活动添加";
    },

    handleDelete(row) {
      let str = "确认结束活动么？ 已报名人数：" + row.actCount;
      this.$confirm(str, "提示", {}).then(() => {
        let params = {
          id: row.id
        };
        ActivityList.delete(params)
          .then(res => {
            if (res.data.code == 0) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500
              });
              this.getDataList();
            } else {
              console.error(res);
              this.$message.error("操作失败");
            }
          })
          .catch(err => {
            console.error(err);
            this.$message.error("操作失败");
          });
      });
    },

    //表格查询按钮
    onQuery() {
      this.pagination.pageNum = 1;
      this.getDataList();
    },
    //分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getDataList();
    },
    handleFocus(e) {
      this.config[e.index].options = this.fShowPositions;
    },
    handleQueryFocus(e) {
      this.searchConfigData[e.index].options = this.fShowPositions;
    }
  }
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
