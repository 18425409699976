import { deletes, post, get, postnoAuth } from '../../service'

export default class MedicineJinFang {
  // 单方列表
  static pageListFolk(params = undefined) {
    return post('/service/onePres/findList', params)
  }

  // 单方分类
  static findDfCate(params = undefined) {
    return get('/service/onePres/findDfCate')
  }

  // 排序
  static modifyWeight(params = undefined) {
    return post('/service/article/modifyWeight', params)
  }

  // 组方列表
  static pageListGroup(params = undefined) {
    return post('/service/prescription/getPrescriptionList', params)
  }

  // 组方分类
  static findZfCate(params = undefined) {
    return get('/service/prescription/getCatalogueName')
  }

  // 单方标签
  static findDfLabel(params = undefined) {
    return get('/service/onePres/presTags')
  }

  // 单方组方删除
  static deleteRow(params = undefined) {
    if(params.medicineType == 1){
      return deletes('/service/onePres/id/'+params.id)
    }else {
      return get('/service/prescription/deleteById/'+params.id)
    }
  }

  // 单方组方保存修改
  static saveRow(params = undefined)  {
    if(params.medicineType == 1){
      return post('/service/onePres/save', params.form)
    }else {
      return post('/service/prescription/save', params.form)
    }
  }
}
