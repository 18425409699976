import { postnoAuth, post, get, deletes } from '../../service'

export default class MedalApi {
  // 获取列表
  static pageList (params = undefined) {
    return post('/service/medal/findList', params)
  }

  // 新增/修改
  static saveRow (params = undefined) {
    return post('/service/medal/save', params)
  }
}
