<template>
  <d2-container>
    <div class="top-item">
      <span>阅读物料</span>
    </div>
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        新增
      </el-button>
    </div>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
      >
        <template v-slot:weight="{ row }">
          <el-input
            v-model="row.weight"
            type="number"
            @change="onChangeClick(row, row.weight)"
            @keyup.enter.native="$event.target.blur()"
          ></el-input>
        </template>
      </free-table>
    </div>

    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
    />
  </d2-container>
</template>
<script>
// 引入组件
import FreeTable from "@/views/components/FreeTable";

// 引入页面层级组件
import AddSub from "./components/addSub";

// 引入接口
import { ReadMaterialManageApi } from "@/model/modules/mijiManage/index";

export default {
  name: "readMaterialManage",
  components: {
    FreeTable,
    AddSub
  },
  data() {
    return {
      id: "",
      titleSrc: "",
      form: {
        cid: "",
        title: "",
        weight: "",
        content: ""
      },
      config: [
        [
          {
            label: "标题",
            type: "input",
            prop: "title"
          },
          {
            label: "排序",
            type: "numberInput",
            prop: "weight"
          }
        ],
        [
          {
            colSpan: 24,
            label: "内容",
            type: "quill",
            prop: "content"
          }
        ]
      ],
      data: [], // 表格数据渲染
      slotColumn: [
        { label: "标题", prop: "title" },
        { label: "排序", prop: "weight", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  编辑
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row);
                  }}
                >
                  删除
                </el-button>
              </div>
            );
          }
        }
      ],
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true
      },
      // 表单验证
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        weight: [{ required: true, message: "请输入排序号", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }]
      }
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.getDataList();
  },
  methods: {
    // 初始化表格渲染
    async getDataList() {
      const res = await ReadMaterialManageApi.pageList({ id: this.id });
      this.data = res.data.body;
    },

    // 提交编辑表格行信息
    onEditSubmit(e) {
      if (this.titleSrc == "阅读物料修改") {
        ReadMaterialManageApi.saveRow(e.form)
          .then(res => {
            if (res.data.code == 0) {
              this.$message.success("修改成功");
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(err => {
            this.$message.error(err);
          })
          .finally(() => {
            this.getDataList();
          });
      } else if (this.titleSrc == "阅读物料添加") {
        this.form = e.form;
        this.form.cid = this.id;
        ReadMaterialManageApi.saveRow(this.form)
          .then(res => {
            if (res.data.code == 0) {
              this.$message.success("添加成功");
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(err => {
            this.$message.error(err);
          })
          .finally(() => {
            this.getDataList();
          });
      }
    },

    // 打开编辑弹窗
    openDialog({ row }) {
      this.form = {
        ...row
      };

      this.$refs.addSubDialog.showDialog();
      this.titleSrc = "阅读物料修改";
    },

    onAdd() {
      this.$refs.addSubDialog.showDialog(this.form);
      this.titleSrc = "阅读物料添加";
      this.form = {
        cid: "",
        title: "",
        weight: "",
        content: ""
      };
    },

    handleDelete(row) {
      this.$confirm("确认删除吗？", "提示", {}).then(() => {
        ReadMaterialManageApi.deleteRow({ key: row.id })
          .then(response => {
            if (!response.data.code == 0) return;
            this.$message({
              message: "删除成功",
              type: "success",
              duration: 1500
            });
            this.getDataList();
          })
          .catch(err => {
            this.$message.error(err);
          });
      });
    },

    // 监听排序输入框改变
    onChangeClick(row, value) {
      if (!/^\d+$/.test(Number(value)) || value == "") {
        this.$message({
          type: "error",
          message: "请输入0或正整数"
        });
        return;
      } else {
        this.$confirm("确定修改吗 ?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          let params = {
            id: row.id,
            weight: value,
          };

          ReadMaterialManageApi.changeWeight(params)
            .then(res => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功",
                  duration: 1500
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                  duration: 1500
                });
              }
              this.getDataList();
            })
            .catch(err => {
              console.log(err);
            });
        });
      }
    }
  }
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}

.top-item {
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  margin-right: 70px;
}

/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
