/**
 * 用户信息相关的接口Api
 */
import { get, post , postnoAuth } from '../../service'
import apiUrl from './api-url'

const { getPageList, updateRoleInfo, getMenuTree, closeOrOpen, getRoleMenuListBykey, uriFetchMenuTree } = apiUrl

//获取权限菜单树
export const getMenuTreeApi = (params=undefined)=>{
  return post(getMenuTree , params)
}
//获取角色管理列表
export const pageList = (params=undefined)=>{
  return post(getPageList , params)
}
//新增 编辑
export const queryUpdateRoleInfo = (params=undefined)=>{
  return post(updateRoleInfo , params)
}
//启用禁用
export const closeOrOpenInfo = (params=undefined) => {
  return post(closeOrOpen , params)
}
// 根据ID，查询权限
export const roleMenuListBykey = (params=undefined)=>{
  return get(getRoleMenuListBykey+params.id, params)
}


// 根据ID，查询权限
export const postFetchMenuTree = ()=>{
  return post(uriFetchMenuTree, {})
}


// 授权
export const postGrant = (roleId, menuId)=>{
  return post(apiUrl.uriGrantMenu2Role, {roleId, menuId})
}

// 撤销授权
export const postUngrant = (roleId, menuId)=>{
  return post(apiUrl.uriUngrantMenuOfRole, {roleId, menuId})
}
