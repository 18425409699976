<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="4"
      :labelWidth="'90px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <div>
      <el-button
        type="default"
        size="mini"
        @click="onAdd"
      >
        新增批次
      </el-button>
      <el-button
        type="success"
        size="mini"
        @click="onAdd"
      >
        生成防伪码
      </el-button>
      <el-button
        type="success"
        size="mini"
        @click="onAdd"
      >
        导出二维码
      </el-button>
      <el-button
        type="danger"
        size="mini"
        @click="onAdd"
      >
        数据导入导出
      </el-button>
      <el-button
        type="danger"
        size="mini"
        @click="onAdd"
      >
        批量删除
      </el-button>
      <el-button
        type="warning"
        size="mini"
        @click="onAdd"
      >
        重置
      </el-button>
    </div>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
      </free-table>
    </div>
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
    />
  </d2-container>
</template>
<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'
import AddSub from './components/addSub.vue'
// 引入接口
export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
    FreeTable,
    AddSub
  },
  data () {
    return {
      titleSrc: '新增企业',
      // 搜索框渲染
      formSetData: {
        pageIndexB1: Number,
        pageSize: Number
      },
      searchConfigData: [
        {
          label: '起止时间',
          formItemId: 0,
          placeholder: '请输入起止时间',
          type: 'timePicker',
          prop: 'courseName'
        },
        {
          label: '批次名称',
          formItemId: 1,
          placeholder: '请输入企业名称',
          type: 'input',
          prop: 'batchName'  
        }
      ],
      // 表格数据渲染
      data: [
        {
          q: 'q',
          w: 'w',
          e: 'e',
          r: 'r',
          t: 't',
          y: 'y',
          u: 'u',
          i: 'i',
        }
      ],
      slotColumn: [
        { label: '序号', prop: 'index' , type:'index', width:50},
        { label: 'id', prop: 'id' },
        { label: '批次名', prop: 'w' },
        { label: '批次说明', prop: 'r' , sort: true},
        { label: '防伪码数量', prop: 't', sort: true},
        { label: '增加时间', prop: 'y', sort: true },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '300px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onSelcetCodeBatch(scope.row)
                  }}
                >
                  选择
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onSelcetCodeBatch(scope.row)
                  }}
                >
                  编辑
                </el-button>
              </div>
            )
          }
        }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },
      config: [
        {
          label: "批次号",
          type: "input",
          prop: "fId",
          placeholder:"请输入批次号"
        },
        {
          label: "批次说明",
          type: "textarea",
          rowSpan: 5,
          prop: "fPicName",
          placeholder:"请输入批次说明"
        }
      ],
      form:{
        fId:"",
        fPicName:""
      },
      dataDialog: [
        {
          q: 'q',
          w: 'w',
          e: 'e',
          r: 'r',
          t: 't',
          y: 'y',
          u: 'u',
          i: 'i',
        }
      ], 
      slotColumnDialog: [
        { label:'序号', prop :'' ,  type:"index", width:50,},
        { label: 'id', prop: 'q' , sort: true},
        { label: '名称', prop: 'name'},
        { label: '说明', prop: 'w' },
        { label: '防伪码数量', prop: 'e'  , sort: true},
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '370px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onSelcet(scope.row)
                  }}
                >
                  选择
                </el-button>
              </div>
            )
          }
        }
      ], 
      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      },
      paginationDialog: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      }
    }
  },

  mounted () {
  },
  // 路由跳转之前保存当前滚动条的位置。
  async beforeRouteLeave(to, from, next) {
    console.log(to,from,"ccjejveijei====")
    await this.$store.dispatch('d2admin/page/open',to)
    next();
  },
  methods: {
    onAdd(){
        this.$refs.addSubDialog.showDialog()
    },
    // 分页选择器切换事件
    getList (e) {
      // this.pagination.pageNum = e.page
      // this.pagination.pageSize = e.limit
    }
  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
