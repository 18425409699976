/**
 * 首页管理-导航设置
 */
 const apiUrl ={
    getNewsPage: `/NoticeController/getNoticeListByFType`, // get分页列表
    getPageList: `/service/navi/findList`,
    deleteInfo: `/service/navi/openOrClose`,
    insertInfo: `/service/navi/save`,
    updateInfo: '/service/navi/save',
    updateStatus: '/TMtNavigationController/updateStatus',
    getDicList:`/UIDicDataUtils/getDicList`,
    getPos:`/service/navi/naviPos`
}

export default apiUrl 