const apiUrl = {
 
  getRoleList: '/service/user/getRoleList', //获取用户角色信息
  uriListUserRoles: '/service/userRole/listRoleIds/',  //列出用户角id色列表
  uriGrantRole2User: '/service/userRole/grant',  //用户授予角色
  uriUngrantRoleOfUser: '/service/userRole/ungrant',  //撤销用户被授予角色

  getUserEntityNew: '/service/user/getUserEntityNew', //获取用户信息
  getUserVisitorList: '/service/user/getUserVisitorList', // 游客列表
  getVisitorDetail: '/service/user/getVisitorDetail', // 游客详情
  enableDisable: '/service/user/openOrClose',  //启用-禁用
  qualification: '/service/user/qualification', //资质图片
  addSave: '/service/user/saveUser',  //新增
  getPC: '/service/user/getPC',  //获取省市区域联动
  getExpertType: '/service/user/getExpertType',   //获取专家用户类型
  getLecturerType: '/service/user/getLecturerType',   //获取讲师用户类型
  getAreaList: '/service/user/getAreaList', //关联所属区域
  bindCustomer: '/service/user/bindCustomer', //新增用户-绑定服务商
  checkUserInfoUrl: '/service/user/getUserInfo',  //获取用户详情信息

  getCustomerList: '/service/user/getCustomerList', //客户列表 
  relieve: '/service/user/relieve',   //解除绑定
  transfer: '/service/user/transfer', //转移服务商
  getCancelUser: '/service/user/cancelUser', //注销用户

}

export default apiUrl
