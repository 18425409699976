<template>
  <div class="client_wrapper">
    <im-item></im-item>
  </div>
</template>

<script>
import imItem from './imItem'
export default {
  name: 'liveIm',
  components: {
    imItem
  }
}
</script>

<style lang="scss" scoped>
.client_wrapper {
}
</style>
