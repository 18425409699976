<template>
  <div>
    <el-dialog
      title="游客用户详情"
      :visible.sync="dialogFormVisible"
      v-bind="$attrs"
    >
      <el-form 
        :model="form" 
        label-width="90px" 
        label-position="left"
        ref="formData"
      >
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="用户昵称：" prop="nickName">
              {{ form.nickName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户电话：" prop="tel">
              {{ form.tel }}
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="头像：" prop="avatar">
              <el-image style="width: 100px; height: 100px" :src="form.avatar"></el-image>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注：" prop="remark">
              {{ form.remark }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item align="center">
          <el-button type="primary" @click="onEditSubmit">确定</el-button>
          <el-button type="default" @click="onClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'particulars',
  components: {
  },
  props: {
    form: Object
  },
  data () {
    return {
      dialogFormVisible: false,
      formData: {}
    }
  },
  methods: {
    showDialog (e = {}) {
      this.formData = e
      this.dialogFormVisible = true
    },
    onEditSubmit () {
      // this.$refs.formData.validate((valid) => {
      //   if (!valid) return
      //   this.$confirm('确认提交吗？', '提示', {}).then(() => {
      //     this.$emit('on-add-submit', this.form)
      //   })
      // })
      this.dialogFormVisible = false
    },
    onClose () {
      this.dialogFormVisible = false
    },
  }
}
</script>
<style lang="scss" scoped>
</style>
