var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d2-container',[_c('div',[_c('el-button',{attrs:{"type":"default","size":"mini","icon":"el-icon-circle-plus-outline"},on:{"click":_vm.onAdd}},[_vm._v(" 新增消息 ")])],1),_c('div',{staticClass:"mt20"},[_c('free-table',{attrs:{"border":"","data":_vm.data,"column":_vm.slotColumn,"columnsProps":_vm.columnsProps,"pagination":"","auto-scroll":false,"pageSizes":_vm.pagination.pageSizes,"total":_vm.pagination.total,"page":_vm.pagination.pageNum,"limit":_vm.pagination.pageSize},on:{"update:total":function($event){return _vm.$set(_vm.pagination, "total", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "pageNum", $event)},"update:limit":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"pagination":_vm.getList},scopedSlots:_vm._u([{key:"position",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getPositionZh(row.position))+" ")]}},{key:"duration",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.duration)+"秒 ")]}},{key:"cover",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"margin-top":"7px","cursor":"pointer"},on:{"click":function($event){return _vm.showImg(row)}}},[_c('img',{staticClass:"image",attrs:{"src":row.cover,"width":"30","height":"20"}})])]}},{key:"beginTmLabel",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.timeConvert(row.beginTm, 1))+" ")]}},{key:"endTmLabel",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.timeConvert(row.endTm, 1))+" ")]}},{key:"ctm",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.timeConvert(row.ctm, 1))+" ")]}}])})],1),_c('add-sub',{ref:"addSubDialog",attrs:{"width":"50%","align":"left","config":_vm.config,"form":_vm.form,"rules":_vm.rules,"titleSrc":_vm.titleSrc},on:{"on-edit-submit":_vm.onEditSubmit,"on-focus-select":_vm.handleFocus}}),_c('el-dialog',{staticClass:"el-dialog-col-2",attrs:{"visible":_vm.dialogVisible,"close-on-click-modal":true},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":""}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }