<template>
  <div>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig_folk"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5.8"
      :btnColspan="4"
      :labelWidth="'90px'"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>

    <!-- 按钮 -->
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        新增
      </el-button>
    </div>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:weight="{ row }">
          <el-input
            v-model="row.weight"
            type="number"
            @change="onChangeClick(row, row.weight, 1)"
            @keyup.enter.native="$event.target.blur()"
          ></el-input>
        </template>

        <template v-slot:recommend="{ row }">
          <el-input
            v-model="row.recommend"
            type="number"
            @change="onChangeClick(row, row.recommend, 2)"
            @keyup.enter.native="$event.target.blur()"
          ></el-input>
        </template>

        <template v-slot:ctm="{ row }">
          {{ formData(row.ctm) }}
        </template>
      </free-table>

      <!--添加/修改弹窗-->
      <add-sub
        ref="addSubDialog"
        width="50%"
        align="left"
        :config="config"
        :form="form"
        :rules="rules"
        :titleSrc="titleSrc"
        @on-edit-submit="onEditSubmit"
      />
    </div>
  </div>
</template>

<script>
// 引入组件
import FreeTable from "@/views/components/FreeTable";
import AddSub from "./addSub";
import SearchConfig from "@/views/components/searchConfigForm";

// 引入接口
import ProjectProductApi from "@/model/modules/modCms_projectProduct/index";

// 引入方法
import { formData } from "@/utils/tool"

export default {
  props: {
    type: {
      type: String,
      default: "1"
    }
  },

  components: {
    FreeTable,
    AddSub,
    SearchConfig
  },

  data() {
    return {
      titleSrc: "",

      // 搜索框渲染
      searchConfigData: [
        {
          label: "产品名称",
          placeholder: "请输入",
          type: "input",
          prop: "productName"
        }
      ],

      formSetData: {
        productName: "",
        type: "1",
        pageIndexB1: 1,
        pageSize: 10
      },

      // 表格渲染
      data: [],
      slotColumn: [
        { label: "产品ID", prop: "productNo" },
        { label: "产品名称", prop: "productName" },
        { label: "创建时间", prop: "ctm", slotScope: true },
        { label: "首页推荐", prop: "recommend", slotScope: true },
        { label: "列表排序", prop: "weight", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: "200px",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row);
                  }}
                >
                  删除
                </el-button>
              </div>
            );
          }
        }
      ],
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true
      },
      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [1, 10, 20, 50, 100],
        total: 0
      },

      // 新增弹窗
      form: {
        source: '',
        productName: "",
        introduce: "",
        attending: "",
        cover: "",
        productInfo: ""
      },
      config: [
        [
          {
            colSpan: 12,
            label: "产品名称",
            type: "input",
            prop: "productName"
          },
          {
            colSpan: 12,
            label: "简介",
            type: "input",
            prop: "introduce"
          },
          {
            colSpan: 12,
            label: "主治",
            type: "input",
            prop: "attending"
          },
        ],
        [
          {
            colSpan: 24,
            label: "封面",
            type: "uploadAvatar",
            prop: "cover"
          }
        ],
        [
          {
            colSpan: 24,
            label: "产品详情",
            type: "quill",
            prop: "productInfo"
          }
        ]
      ],
      rules: {
        productName: [
          { required: true, message: "请输入产品名称", trigger: "blur" }
        ],
        introduce: [{ required: true, message: "请输入简介", trigger: "blur" }],
        attending: [{ required: true, message: "请输入主治", trigger: "blur" }],
        cover: [{ required: true, message: "请选择封面", trigger: "change" }],
        productInfo: [
          { required: true, message: "请输入详情", trigger: "blur" }
        ]
      }
    };
  },

  mounted() {
    this.getPageList();
  },

  methods: {
    formData,

    //初始化表格渲染
    async getPageList() {
      this.formSetData.type = this.type;
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      let res = await ProjectProductApi.pageList(this.formSetData);
      this.data = res.data.body.content;
      this.pagination.total = res.data.body.total * 1;
    },

    // 分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getPageList();
    },

    // 新增
    onAdd() {
      // 根据tab判断 是否添加分类字段
      if(this.type == 6){
        this.config[0][3] = {
            colSpan: 12,
            label: "分类",
            type: "select",
            prop: "source",
            options: [
              {
                label: '主题产品',
                value: 0
              },
              {
                label: '组合产品',
                value: 1
              }
            ]
        }
      }

      this.titleSrc = "新增";
      this.form = {
        source: '',
        productName: "",
        introduce: "",
        attending: "",
        cover: "",
        productInfo: ""
      },
      this.$refs.addSubDialog.showDialog(this.form);
    },

    // 提交编辑表格行信息
    onEditSubmit(e) {
      this.form = e;
      console.log(this.form);
      let params = {
        type: this.type,
        ...this.form
      };
      delete params.imgUrl;
      if (this.titleSrc == "新增") {
        ProjectProductApi.saveRow(params)
          .then(response => {
            if (!response.data.code == 0) return;
            this.$message({
              message: "新增成功",
              type: "success",
              duration: 1500
            });
            this.getPageList();
          })
          .catch(response => {
            console.error(response);
            this.$message.error("新增失败");
          });
      } else if (this.titleSrc == "修改") {
        ProjectProductApi.saveRow(params)
          .then(response => {
            if (!response.data.code == 0) return;
            this.$message({
              message: "修改成功",
              type: "success",
              duration: 1500
            });
            this.getPageList();
          })
          .catch(response => {
            console.error(response);
            this.$message.error("修改失败");
          });
      }
    },

    // 编辑
    openDialog({ row }) {
      this.form = { ...row };
      this.titleSrc = "修改";
      this.$refs.addSubDialog.showDialog(row);
    },

    // 删除
    handleDelete(row) {
      console.log("delete");
      console.log(row.id);

      this.$confirm("确认删除吗？", "提示", {}).then(() => {
        let params = {
          id: row.id
        };
        ProjectProductApi.deleteRow(params).then(response => {
          if (response.data.code == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
              duration: 1500
            });
          } else {
            this.$message({
              message: "删除失败",
              type: "error",
              duration: 1500
            });
          }
          this.getPageList();
        });
      });
    },

    // 表格查询按钮
    onQuery() {
      this.pagination.pageNum = 1;
      this.getPageList();
    },

    // 监听排序输入框改变
    onChangeClick(row, value, index) {
      if (!/^\d+$/.test(Number(value)) || value == '') {
        this.$message({
          type: "error",
          message: "请输入0或正整数"
        });
        return;
      } else {
        this.$confirm
          ("确定修改吗 ?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            if(index == 1){ //列表排序
              let params = {
              id: row.id,
              weight: value
            };

            ProjectProductApi.changeWeight(params)
              .then(res => {
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功",
                    duration: 1500
                  });
                } else {
                  this.$message({
                    type: "error",
                    message: res.data.msg,
                    duration: 1500
                  });
                }
                this.getPageList();
              })
              .catch(err => {
                console.log(err);
              });
            }
            if(index == 2){ //首页推荐
              let params = {
              id: row.id,
              recommend: value
            };

            ProjectProductApi.changeRecommend(params)
              .then(res => {
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功",
                    duration: 1500
                  });
                } else {
                  this.$message({
                    type: "error",
                    message: res.data.msg,
                    duration: 1500
                  });
                }
                this.getPageList();
              })
              .catch(err => {
                console.log(err);
              });
            }

          });
      }
    },
  }
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
</style>
