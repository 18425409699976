<template>
  <d2-container>
    <el-tabs @tab-click="onTabClick" v-model="platform">
      <el-tab-pane label="安卓" name="Android"></el-tab-pane>
      <el-tab-pane label="IOS" name="iOS"></el-tab-pane>
    </el-tabs>

    <!-- 查询 -->
    <el-form inline :model="searchForm">
      <el-form-item label="版本编号">
        <el-input
          clearable
          v-model="searchForm.version"
          placeholder="请输入版本编号"
        ></el-input>
      </el-form-item>
      <el-form-item label="升级类型">
        <el-select
          v-model="searchForm.upgradeType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearchSubmit">查询</el-button>
        <el-button type="primary" @click="addVersion">新增版本</el-button>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column
        prop="version"
        label="版本编号"
        width="100"
      ></el-table-column>
      <el-table-column prop="description" label="更新简述"></el-table-column>
      <el-table-column prop="upgradeTypeName" label="升级类型"></el-table-column>
      <el-table-column prop="remark" label="更新日志"></el-table-column>
      <el-table-column
        prop="planActiveTm"
        label="计划发布时间"
      ></el-table-column>

      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-button type="text" @click="openDialog(scope.row)">编辑</el-button>
          <el-button type="text" @click="deleteItem(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      style="margin-top: 20px"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInit.cur_page"
      :page-sizes="pageInit.pageSizes"
      :page-size="pageInit.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInit.total"
    >
    </el-pagination>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="50%">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
      >
        <el-form-item label="版本编号" prop="version">
          <el-input
            v-model="ruleForm.version"
            placeholder="请输入版本编号"
            class="form-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户端" prop="platform">
          <el-radio-group v-model="ruleForm.platform">
            <el-radio label="iOS">IOS</el-radio>
            <el-radio label="Android">安卓</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="下载地址" prop="marketUrl">
          <el-input
            v-model="ruleForm.marketUrl"
            placeholder="请输入下载地址"
            class="form-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="更新简述" prop="description">
          <el-input
            v-model.number="ruleForm.description"
            placeholder="请输入更新简述"
            class="form-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="更新日志" prop="remark">
          <el-input
            type="textarea"
            :rows="6"
            v-model="ruleForm.remark"
            placeholder="请输入更新日志"
            class="form-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="升级类型" prop="upgradeType">
          <el-select
            v-model="ruleForm.upgradeType"
            placeholder="请选择"
          >
            <el-option
              v-for="item in addList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="planActiveTm">
          <el-date-picker
            v-model="ruleForm.planActiveTm"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmVersion('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </d2-container>
</template>
<script>
import Version from "@/model/modules/version/index";

export default {
  data() {
    return {
      dialogTitle: "新增版本",
      platform: "Android", // Android iOS
      typeList: [
        { value: "ALL", label: "全部" },
        { value: "FORCE", label: "强制更新" },
        { value: "NORMAL", label: "强提示更新" },
        { value: "NONE", label: "不提示" },
      ],
      addList: [
        { value: "FORCE", label: "强制更新" },
        { value: "NORMAL", label: "强提示更新" },
        { value: "NONE", label: "不提示" },
      ],
      searchForm: {
        version: "",
        upgradeType: "ALL", // FORCE,NONE,NORMAL
        platform: "", // 软件类型
        pageIndexB1: 1,
        pageSize: 10,
      },
      tableData: [],
      pageInit: {
        total: 1,
        cur_page: 1,
        pageSize: 10,
        pageSizes: ["10", "20", "30"],
      },
      dialogVisible: false,
      ruleForm: {
        appName: "",
        version: "",
        marketUrl: "",
        description: "",
        remark: "",
        upgradeType: "",
        platform: "",
        planActiveTm: "",
      },
      rules: {
        version: [
          { required: true, message: "请输入版本编号", trigger: "blur" },
        ],
        platform: [
          { required: true, message: "请选择客户端", trigger: "blur" },
        ],
        marketUrl: [
          { required: true, message: "请输入下载地址", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入更新描述", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入更新日志", trigger: "blur" },
        ],
        upgradeType: [
          { required: true, message: "请选择升级类型", trigger: "blur" },
        ],
        planActiveTm: [
          { required: true, message: "请选择计划发布时间", trigger: "blur" },
        ],
      },
    };
  },

  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.searchForm.platform = this.platform;
      this.searchForm.pageIndexB1 = this.pageInit.cur_page;
      this.searchForm.pageSize = this.pageInit.pageSize;

      var loading = this.$loading({
        lock: true,
      });
      Version.versionList(this.searchForm).then((res) => {
        loading.close();
        if (res.data.code == 0) {
          this.tableData = res.data.body.content;
          this.tableData.forEach((ele) => {
            ele.planActiveTm = this.$moment(ele.planActiveTm).format(
              "YYYY-MM-DD hh:mm:ss"
            );
            ele.upgradeTypeName = this.addList.filter(e=>e.value == ele.upgradeType)[0].label;
          });
          this.pageInit.total = res.data.body.totalPage;
        }
      });
    },
    // 查询
    onSearchSubmit() {
      this.pageInit.cur_page = 1;
      this.getDataList();
    },
    // 确定
    confirmVersion(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.ruleForm.planActiveTm = new Date(
            this.ruleForm.planActiveTm
          ).getTime();

          Version.saveVersion(this.ruleForm).then((res) => {
            if (res.data.code == 0) {
              this.getDataList();
              if (this.dialogTitle == "新增版本") {
                this.$message({
                  message: "新增版本成功",
                  type: "success",
                  duration: 1500,
                })
              } else {
                this.$message({
                  message: "编辑版本成功",
                  type: "success",
                  duration: 1500,
                });
              }
            }
          });

          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    // 切换标签
    onTabClick(tab, e) {
      this.pageInit.cur_page = 1;
      this.pageInit.pageSize = 10;
      this.platform = tab.name;
      this.getDataList();
    },
    // 添加版本
    addVersion() {
      this.ruleForm = { platform: this.platform };
      this.dialogTitle = "新增版本";
      this.dialogVisible = true;
    },
    // 编辑
    openDialog(row) {
      this.ruleForm = {...row};
      this.dialogTitle = "编辑版本";
      this.dialogVisible = true;
    },
    // 删除
    deleteItem(row) {
      Version.deleteVersion(row).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 1500,
          });
          this.getDataList();
        }
      });
    },
    // 翻页
    handleSizeChange(e) {
      this.pageInit.pageSize = e;
      this.pageInit.cur_page = 1;
      this.getDataList();
    },
    handleCurrentChange(e) {
      this.pageInit.cur_page = e;
      this.getDataList();
    },
  },
};
</script>

