import { postnoAuth, post, get, deletes } from '../../service'

export default class MenuAppSlidesApi {
  // 获取列表
  static pageList (params = undefined) {
    return post('/service/news/list', params)
  }

  // 新增/修改
  static saveRow (params = undefined) {
    return post('/service/news/save', params)
  }

  // 删除
  static deleteRow (params = undefined) {
    return get('/service/news/deleteById/'+params.key)
  }
}
