<template>
  <d2-container>
    <div class="kcgy-page-title">{{ pageTitle }}</div>
    <div class="kcgy-query-panel">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="优惠券名称">
          <el-input v-model="couponName" placeholder="优惠券名称"></el-input>
        </el-form-item>
        <el-form-item label="持有人手机号">
          <el-input v-model="tel" placeholder="持有人手机号"></el-input>
        </el-form-item>
      </el-form>

      <div class="kcgy-opera-panel">
        <div class="kcgy-space-holder" />
        <el-button
          type="default"
          size="mini"
          icon="el-icon-circle-plus-outline"
          @click="onClickSendCoupons"
        >
          {{ lblBtnSend }}
        </el-button>
        <el-button
          type="default"
          size="mini"
          icon="el-icon-search"
          @click="onClickQuery"
        >
          {{ lblBtnQuery }}
        </el-button>
      </div>
    </div>

    <el-table
      style="width: 100%"
      :data.sync="tableData"
      :loading.sync="loading"
    >
      <el-table-column prop="tel" label="手机号" width="180"> </el-table-column>
      <el-table-column prop="couponName" label="优惠券名称"> </el-table-column>
      <el-table-column prop="amount" label="持有数量"> </el-table-column>
      <el-table-column prop="used" label="已用数量"> </el-table-column>
    </el-table>

    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      :loading.sync="loading"
      :current-page.sync="pageIndex"
      :page-size.sync="pageSize"
      :page-sizes.sync="pageSizeList"
      :total.sync="pageTotal"
      @size-change="onLoadData"
      @current-change="onLoadData"
    >
    </el-pagination>

    <el-dialog
      title="发放优惠券"
      :visible.sync="flagDiaOpen"
      width="70%"
      center
      :before-close="dialogBeforeClose"
    >
      <div>
        <el-form ref="form" label-width="80px">
          <el-form-item label="发放类型">
            <el-select v-model="couponType" clearable placeholder="请选择">
              <el-option
                v-for="(item, index) in typeList"
                :key="index"
                :label="item.itemName"
                :value="item.itemCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="优惠券"
            v-if="couponType != 4"
            prop="sendCouponId"
          >
            <el-select
              v-model="sendCouponId"
              placeholder="请选择"
              filterable
              remote
              :remote-method="onSearchCouponOpts"
            >
              <el-option
                v-for="item in optCoupons"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="优惠券" v-else>
            <el-select
              v-model="sendCommonCouponId"
              placeholder="请选择"
              filterable
              value-key="couponId"
              @change="onSearchCouponChange"
            >
              <el-option
                v-for="item in couponList"
                :key="item.couponId"
                :label="item.couponName"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发放数量" v-if="couponType != 4">
            <el-input-number
              size="medium"
              v-model="sendAmount"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="服务商" v-if="couponType != 4">
            <el-select
              v-model="providerIds"
              placeholder="请选择"
              multiple
              filterable
              remote
              :remote-method="onSearchProviderOpts"
            >
              <el-option
                v-for="item in optProviders"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="讲师" v-if="couponType != 4">
            <el-select
              v-model="teacherIds"
              placeholder="请选择"
              multiple
              filterable
              remote
              :remote-method="onSearchTeacherOpts"
            >
              <el-option
                v-for="item in optTeachers"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="发放人"
            v-if="couponType == 4 && cur_coupon.commonType == 0"
          >
            <el-input
              value="全部注册用户"
              readonly
              style="width: 18%"
            ></el-input>
          </el-form-item>
          <el-form-item label="发放人" v-else-if="couponType == 4 && cur_coupon.commonType == 1">
            <el-select
              v-model="searchCommonCouponUsers"
              multiple
              filterable
              remote
              reserve-keyword
              placeholder="请输入真实姓名或手机号"
              :remote-method="onSearchCommonCouponUsers"
              :loading="commonCouponLoading"
              @visible-change="onVisibleChangeCommonCouponUsers"
            >
              <el-option
                v-for="item in commonCouponUsers"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="flagDiaOpen = false">取 消</el-button>
        <el-button type="primary" @click="onClickConfirmSend">确 定</el-button>
      </span>
    </el-dialog>
  </d2-container>
</template>
<script>
import ListPage from "@/components/kcgy/ListPage";
import ListPageVo from "@/components/kcgy/ListPageVo";
import CouponApi from "@/api/CouponApi";
import UserApi from "@/api/UserApi";
import { definePage } from "@/common/Commons";
import couponManageListApi from "@/model/modules/couponManage/couponManageList";
import log from "@/plugin/log";

export default definePage(
  class CouponManage extends ListPage {
    name = "couponManage";
    components = {};

    data = () => {
      this.vo = Object.assign(new ListPageVo(), {
        commonCouponUsers: [],
        commonCouponLoading: false,
        searchCommonCouponUsers: "", //通用优惠券搜索条件
        cur_coupon: {}, //当前选中的发放的优惠券
        couponName: "",
        tel: "",
        pageTitle: "优惠券持有情况",
        lblBtnSend: "发券",
        lblBtnQuery: "查询",
        flagDiaOpen: false,
        sendCouponId: "",
        sendAmount: 1,
        providerIds: [],
        teacherIds: [],
        optCoupons: [],
        optProviders: [],
        optTeachers: [],
        typeList: [],
        couponType: "2",
        couponList: [],
        sendCommonCouponId: ""
      });
      return this.vo;
    };

    created = () => {
      this.methods.onSearchCouponOpts("");
      this.methods.onSearchTeacherOpts("");
      this.methods.onSearchProviderOpts("");
      this.methods.onSearchCouponList();
      this.methods.getCouponType();
      this.onLoadData();
    };

    /**
     * 构建查询条件.
     */
    buildQueryContidions() {
      return {
        couponName: this.vo.couponName,
        tel: this.vo.tel
      };
    }
    loadData(onUpdateData, onUpdatePaging, onUpdateExt) {
      return CouponApi.postCouponHoldList(
        this.buildPagingQueryForm(),
        onUpdateData,
        onUpdatePaging,
        onUpdateExt
      );
    }

    methods = {
      onVisibleChangeCommonCouponUsers: () => {
        this.vo.commonCouponUsers = []
      },

      /// 事件处理
      onClickSendCoupons: () => {
        this.vo.flagDiaOpen = true;
      },
      onClickQuery: () => {
        this.onLoadData();
      },

      onLoadData: () => {
        this.vo.loading = true;
        this.loadData(
          (data, total) => {
            this.vo.tableData = data;
            this.vo.pageTotal = total;
          },
          (pageIndex, pageSize) => {
            this.vo.pageIndex = pageIndex + 1;
            this.vo.pageSize = pageSize;
          },
          extInfo => {
            this.vo.loading = false;
          }
        );
      },

      onSearchCouponChange(e) {
        this.cur_coupon = e;
      },

      // 获取优惠券类型
      getCouponType: () => {
        let that = this;
        couponManageListApi.getCouponType().then(res => {
          that.vo.typeList = res.data.body;
        });
      },
      onSearchCouponOpts: keyword => {
        CouponApi.asOptions(keyword, body => {
          this.vo.optCoupons = body.filter(ele => ele.type != 4);
        });
      },
      onSearchCouponList: () => {
        CouponApi.commonList().then(res => {
          this.vo.couponList = res.data.body;
        });
      },
      onSearchProviderOpts: keyword => {
        UserApi.providerAsOptions(keyword, body => {
          this.vo.optProviders = body;
        });
      },
      onSearchTeacherOpts: keyword => {
        UserApi.teacherAsOptions(keyword, body => {
          this.vo.optTeachers = body;
        });
      },
      onSearchCommonCouponUsers: query => {
        if (query !== "") {
          this.vo.commonCouponLoading = true;
          let params = {
            keyword: query
          };
          couponManageListApi.findCommonCouponUsers(params).then(res => {
            if (res.data.code === 0) {
              this.vo.commonCouponUsers = res.data.body.map(item => {
                let obj = {
                  label: item.fullname,
                  value: item.userId
                };
                return obj;
              });
              this.vo.commonCouponLoading = false;
            } else {
              this.vo.commonCouponUsers = [];
            }
          });
        } else {
          this.vo.commonCouponUsers = [];
        }
      },

      dialogBeforeClose: () => {
        this.vo.flagDiaOpen = false;
        setTimeout(() => {
          this.$nextTick(() => {
            this.vo.sendCouponId = "";
            this.vo.sendAmount = 1;
            this.vo.providerIds = [];
            this.vo.teacherIds = [];
            this.vo.commonCouponUsers = [];
            this.vo.searchCommonCouponUsers = "";
            this.vo.cur_coupon = {};
            this.vo.sendCommonCouponId = "";
            this.vo.couponType = '2'
            this.onLoadData();
          });
        }, 200);
      },

      onClickConfirmSend: () => {
        const params = {
          sendType: this.vo.couponType
        };
        if (this.vo.couponType != 4) {
          params.couponId = this.vo.sendCouponId;
          params.sendAmount = this.vo.sendAmount;
          params.providerIds = this.vo.providerIds;
          params.teacherIds = this.vo.teacherIds;
        } else {
          params.couponId = this.vo.cur_coupon.couponId;
          params.commonType = this.vo.cur_coupon.commonType;
          params.commonUserIds = this.vo.searchCommonCouponUsers;
        }

        CouponApi.sendCoupon(params, () => {
          this.methods.dialogBeforeClose()
        });
      }
      /// 事件处理结束
    };
  }
);
</script>
<style scoped></style>
