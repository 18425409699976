// 组织管理
const apiUrl = {
  getNewsPage: '/service/Org/tree', // get分页列表
  getPageList: '/service/Org/tree',
  deleteInfo: '/TMtNavigationController/delInfoByKey',
  insertInfo: '/service/Org/save',
  updateInfo: '/service/Org/list',
  updateStatus: '/TMtNavigationController/updateStatus'
}

export default apiUrl
