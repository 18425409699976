<template>
  <d2-container>
    <el-tabs @tab-click="onTabClick" v-model="medicineType">
      <el-tab-pane label="中药单方" name="1">
        <!--查询条件配置组件-->
        <search-config
          ref="searchConfig_folk"
          :configData="searchConfigData_folk"
          :formSet="formSetData"
          :colspan="5.8"
          :btnColspan="4"
          :labelWidth="'90px'"
        >
          <el-button type="primary" @click="onQuery">查询</el-button>
        </search-config>

        <!-- 按钮 -->
        <div>
          <el-button
            type="default"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="onAdd"
          >
            新增
          </el-button>
        </div>

        <!--表格条件配置组件-->
        <div class="mt20">
          <free-table
            border
            :data="data"
            :column="slotColumn_folk"
            :columnsProps="columnsProps"
            pagination
            :auto-scroll="false"
            :pageSizes="pagination.pageSizes"
            :total.sync="pagination.total"
            :page.sync="pagination.pageNum"
            :limit.sync="pagination.pageSize"
            @pagination="getList"
          >
            <template v-slot:recWeight="{ row }">
              <el-input
                v-model="row.recWeight"
                type="number"
                @blur="onChangeClick(row, row.recWeight)"
                @keyup.enter.native="$event.target.blur()"
              ></el-input>
            </template>
            <template v-slot:weight="{ row }">
              <el-input
                v-model="row.weight"
                type="number"
                @blur="onChangeClick(row, row.weight)"
                @keyup.enter.native="$event.target.blur()"
              ></el-input>
            </template>
            <template v-slot:ctm="{ row }">
              {{ formData(row.ctm) }}
            </template>
            <template v-slot:ctlId="{ row }">
              {{ getLabelBySelectValue(dfCate, row.ctlId) }}
            </template>
          </free-table>
        </div>
      </el-tab-pane>

      <el-tab-pane label="中药组方" name="2">
        <!--查询条件配置组件-->
        <search-config
          ref="searchConfig_folk"
          :configData="searchConfigData_group"
          :formSet="formSetData"
          :colspan="5.8"
          :btnColspan="4"
          :labelWidth="'90px'"
        >
          <el-button type="primary" @click="onQuery">查询</el-button>
        </search-config>

        <!-- 按钮 -->
        <div>
          <el-button
            type="default"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="onAdd"
          >
            新增
          </el-button>
        </div>

        <!--表格条件配置组件-->
        <div class="mt20">
          <free-table
            border
            :data="data"
            :column="slotColumn_group"
            :columnsProps="columnsProps"
            pagination
            :auto-scroll="false"
            :pageSizes="pagination.pageSizes"
            :total.sync="pagination.total"
            :page.sync="pagination.pageNum"
            :limit.sync="pagination.pageSize"
            @pagination="getList"
          >
            <template v-slot:recommend="{ row }">
              <el-input
                v-model="row.recommend"
                type="number"
                @blur="onChangeClick(row, row.recommend)"
                @keyup.enter.native="$event.target.blur()"
              ></el-input>
            </template>
            <template v-slot:weight="{ row }">
              <el-input
                v-model="row.weight"
                type="number"
                @blur="onChangeClick(row, row.weight)"
                @keyup.enter.native="$event.target.blur()"
              ></el-input>
            </template>
            <template v-slot:ctm="{ row }">
              {{ formData(row.ctm) }}
            </template>
            <template v-slot:ctlId="{ row }">
              {{ getLabelBySelectValue(zfCate, row.ctlId) }}
            </template>
          </free-table>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
    />
  </d2-container>
</template>

<script>
//引入页面层级组件
import AddSub from "./components/addSub";
import SearchConfig from "@/views/components/searchConfigForm";
import FreeTable from "@/views/components/FreeTable";

// 引入接口
import MedicineJinFang from "@/model/modules/modCms_medicineJinFang/index";

import {
  selectFormatData,
  formData,
  getLabelBySelectValue
} from "@/utils/tool";

export default {
  components: {
    AddSub,
    SearchConfig,
    FreeTable
  },

  data() {
    return {
      dfCate: [], //单方分类
      zfCate: [], //组方分类
      titleSrc: "新增",
      medicineType: "1",

      // 搜索框渲染
      formSetData: {
        title: "",
        ctlId: "",
        pageIndexB1: 1,
        pageSize: 10
      },
      searchConfigData_folk: [
        {
          label: "单方名称",
          formItemId: 0,
          placeholder: "请输入",
          type: "input",
          prop: "title"
        },
        {
          label: "单方分类",
          formItemId: 2,
          placeholder: "请选择",
          type: "select",
          isApi: false,
          prop: "ctlId",
          options: []
        }
      ],
      searchConfigData_group: [
        {
          label: "组方名称",
          formItemId: 0,
          placeholder: "请输入",
          type: "input",
          prop: "name"
        },
        {
          label: "组方分类",
          formItemId: 2,
          placeholder: "请选择",
          type: "select",
          isApi: false,
          prop: "ctlId",
          options: []
        }
      ],

      // 表格数据渲染
      data: [],
      slotColumn_folk: [
        { label: "单方ID", prop: "dpNo" },
        { label: "单方名称", prop: "title" },
        { label: "单方分类", prop: "ctlId", slotScope: true },
        { label: "创建时间", prop: "ctm", slotScope: true },
        { label: "推荐排序", prop: "recWeight", slotScope: true },
        { label: "列表排序", prop: "weight", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: "300px",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row);
                  }}
                >
                  删除
                </el-button>
              </div>
            );
          }
        }
      ],
      slotColumn_group: [
        { label: "组方ID", prop: "prescriptionNo" },
        { label: "组方名称", prop: "title" },
        { label: "组方分类", prop: "ctlId", slotScope: true },
        { label: "创建时间", prop: "ctm", slotScope: true },
        { label: "推荐排序", prop: "recommend", slotScope: true },
        { label: "列表排序", prop: "weight", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: "300px",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row);
                  }}
                >
                  删除
                </el-button>
              </div>
            );
          }
        }
      ],
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true
      },

      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [2, 10, 20, 50, 100],
        total: 1
      },

      // 新增
      form: {},
      config: [],
      config_folk: [ //单方
        [
          {
            colSpan: 12,
            label: "单方名称",
            type: "input",
            prop: "title"
          },
          {
            colSpan: 12,
            label: "副标题",
            type: "input",
            prop: "subTitle"
          },
          {
            colSpan: 12,
            label: "单方分类",
            type: "select",
            prop: "ctlId",
            options: []
          },
          {
            colSpan: 12,
            label: "单方标签",
            type: "select",
            prop: "tag",
            multiple: true,
            options: []
          },
          {
            colSpan: 12,
            label: "封面",
            type: "uploadAvatar",
            prop: "cover"
          }
        ],
        [
          {
            colSpan: 24,
            label: "单方详情",
            type: "quill",
            prop: "detail"
          }
        ]
      ],
      config_group: [ //组方
        [
          {
            colSpan: 12,
            label: "组方名称",
            type: "input",
            prop: "title"
          },
          {
            colSpan: 12,
            label: "主治",
            type: "input",
            prop: "attend"
          },
          {
            colSpan: 12,
            label: "出处",
            type: "input",
            prop: "source"
          },
          {
            colSpan: 12,
            label: "组方分类",
            type: "select",
            prop: "ctlId",
            options: []
          }
        ],
        [
          {
            colSpan: 24,
            label: "组方详情",
            type: "quill",
            prop: "description"
          }
        ]
      ],

      // 表单验证
      rules: {
        title: [{ required: true, message: "请输入单方名称", trigger: "blur" }],
        subTitle: [
          { required: true, message: "请输入副标题", trigger: "blur" }
        ],
        ctlId: [{ required: true, message: "请选择分类", trigger: "blur" }],
        cover: [{ required: true, message: "请选择封面", trigger: "blur" }],
        detail: [
          { required: true, message: "请输入单方详情", trigger: "blur" }
        ],
        attend: [{ required: true, message: "请输入主治", trigger: "blur" }],
        source: [{ required: true, message: "请输入出处", trigger: "blur" }],
        description: [
          { required: true, message: "请输入组方详情", trigger: "blur" }
        ]
      },

      // 单方标签
      dfLabel: []
    };
  },

  mounted() {
    this.config = this.config_folk;
    this.getPageList();
    this.findDfCate();
    this.findZfCate();
    this.findDfLabel()
  },

  methods: {
    selectFormatData,
    formData,
    getLabelBySelectValue,

    //初始化表格渲染
    async getPageList() {
      let res = "";
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      if (this.medicineType == 1) {
        res = await MedicineJinFang.pageListFolk(this.formSetData);
      } else {
        res = await MedicineJinFang.pageListGroup(this.formSetData);
      }
      this.data = res.data.body.content;
      this.pagination.total = res.data.body.total * 1;
    },

    // 获取单方分类
    findDfCate() {
      MedicineJinFang.findDfCate().then(res => {
        if (res.data.code == 0) {
          this.dfCate = this.selectFormatData(res.data.body, "id", "clgName");
          this.searchConfigData_folk[1].options = this.dfCate;
          this.config[0][2].options = this.dfCate;
        } else {
          console.log(res.data.msg);
        }
      });
    },

    // 获取组方分类
    findZfCate() {
      MedicineJinFang.findZfCate().then(res => {
        if (res.data.code == 0) {
          this.zfCate = this.selectFormatData(res.data.body, "id", "clgName");
          this.searchConfigData_group[1].options = this.zfCate;
          this.config[0][3].options = this.zfCate;
        } else {
          console.log(res.data.msg);
        }
      });
    },

    // 获取单方标签
    findDfLabel() {
      MedicineJinFang.findDfLabel().then(res => {
        if (res.data.code == 0) {
          this.dfLabel = this.selectFormatData(res.data.body, "itemCode", "label");
          this.config_folk[0][3].options = this.dfLabel;
        } else {
          console.log(res.data.msg);
        }
      });
    },

    // 顶部tab切换
    onTabClick(tab) {
      this.medicineType = tab.name;

      this.pagination = {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [2, 10, 20, 50, 100],
        total: 0
      };
      if (this.medicineType == 1) {
        this.formSetData = {
          title: "",
          ctlId: "",
          pageIndexB1: 1,
          pageSize: 10
        };
      } else {
        this.formSetData = {
          name: "",
          ctlId: "",
          pageIndexB1: 1,
          pageSize: 10
        };
      }

      this.getPageList();
    },

    //编辑
    openDialog({ row }) {
      if (this.medicineType == 1) {
        this.form = {
          title: "",
          subTitle: "",
          ctlId: "",
          cover: "",
          tag: '',
          detail: ""
        };
        this.formSetData = {
          title: "",
          ctlId: "",
          pageIndexB1: 1,
          pageSize: 10
        };
        this.config = this.config_folk;
        this.config[0][2].options = this.dfCate;
      } else {
        this.form = {
          title: "",
          attend: "",
          source: "",
          ctlId: "",
          description: ""
        };
        this.formSetData = {
          name: "",
          ctlId: "",
          pageIndexB1: 1,
          pageSize: 10
        };
        this.config = this.config_group;
        this.config[0][3].options = this.zfCate;
      }
      this.form = {...row};
      if(this.form.tag){
        this.form.tag = this.form.tag.split(',')
      }
      this.titleSrc = "修改";
      this.config[0][2].options = this.dfCate;
      this.$refs.addSubDialog.showDialog(this.form);
    },

    // 删除
    handleDelete(row) {
      this.$confirm("确认删除吗？", "提示", {}).then(() => {
        let params = {
          medicineType: this.medicineType,
          id: row.id
        };
        MedicineJinFang.deleteRow(params)
          .then(response => {
            if (response.data.code == 0) {
              this.$message({
                message: "删除成功",
                type: "success",
                duration: 1500
              });
            } else {
              this.$message({
                message: "删除失败",
                type: "error",
                duration: 1500
              });
            }
            this.getPageList();
          })
          .catch(response => {
            console.error(response);
            this.$message.error("删除失败");
          });
      });
    },

    // 新增课程
    onAdd() {
      if (this.medicineType == 1) {
        this.form = {
          title: "",
          subTitle: "",
          ctlId: "",
          cover: "",
          tag: '',
          detail: ""
        };
        this.formSetData = {
          title: "",
          ctlId: "",
          pageIndexB1: 1,
          pageSize: 10
        };
        this.config = this.config_folk;
        this.config[0][2].options = this.dfCate;
        this.config[0][3].options = this.dfLabel;
      } else {
        this.form = {
          title: "",
          attend: "",
          source: "",
          ctlId: "",
          description: ""
        };
        this.formSetData = {
          name: "",
          ctlId: "",
          pageIndexB1: 1,
          pageSize: 10
        };
        this.config = this.config_group;
        this.config[0][3].options = this.zfCate;
      }
      this.titleSrc = "新增";
      this.$refs.addSubDialog.showDialog(this.form);
    },

    // 监听排序输入框改变
    onChangeClick(row, value) {
      if (!/^\d+$/.test(Number(value))) {
        this.$message({
          type: "error",
          message: "请输入0或正整数"
        });
        return;
      } else {
        this.$confirm
          ("确定修改排序吗 ?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            let params = {
              medicineType: this.medicineType,
              form: row
            };

            MedicineJinFang.saveRow(params)
              .then(res => {
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功",
                    duration: 1500
                  });
                } else {
                  this.$message({
                    type: "error",
                    message: res.data.msg,
                    duration: 1500
                  });
                }
                this.getPageList();
              })
              .catch(err => {
                console.log(err);
              });
          });
      }
    },

    // 提交编辑表格行信息
    onEditSubmit(e) {
      this.form = e;
      if(this.form.tag){
        this.form.tag = this.form.tag.join()
      }
      

      let params = {
        medicineType: this.medicineType,
        form: this.form
      };
      if (this.titleSrc == "新增") {
        MedicineJinFang.saveRow(params)
          .then(response => {
            if (!response.data.code == 0) return;
            this.$message({
              message: "新增成功",
              type: "success",
              duration: 1500
            });
            this.getPageList();
          })
          .catch(response => {
            console.error(response);
            this.$message.error("新增失败");
          });
      } else if (this.titleSrc == "修改") {
        MedicineJinFang.saveRow(params)
          .then(response => {
            if (!response.data.code == 0) return;
            this.$message({
              message: "修改成功",
              type: "success",
              duration: 1500
            });
            this.getPageList();
          })
          .catch(response => {
            console.error(response);
            this.$message.error("修改失败");
          });
      }
    },

    // 分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getPageList();
    },

    // 表格查询按钮
    onQuery() {
      this.pagination.pageNum = 1;
      this.getPageList();
    }
  }
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
</style>
