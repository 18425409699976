<template>
  <d2-container>
    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        :auto-scroll="false"
      >
        <template v-slot:open="{ row }">
          {{row.open==0?'禁用':'启用'}}
        </template>
      </free-table>
    </div>

    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
    />
  </d2-container>
</template>
<script>
import FreeTable from "@/views/components/FreeTable";
// 引入页面层级组件
import AddSub from "./components/addSub";
// 引入接口
import MenuShowApi from "@/model/modules/menuShow/index";

export default {
  components: {
    FreeTable,
    AddSub
  },
  data() {
    return {
      titleSrc: "",
      data: [], // 表格数据渲染
      slotColumn: [
        { label: "平台", prop: "platform" },
        { label: "版本", prop: "clientVersion" },
        { label: "显示状态", prop: "open", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  编辑
                </el-button>
              </div>
            );
          }
        }
      ],
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true
      },
      form: {},
      config: [
         {
          label: '版本',
          type: 'input',
          prop: 'clientVersion'
        },
        {
          label: '显示状态',
          type: 'select',
          prop: 'open',
          options: [
              {
                label: '启用',
                value: 1
              },
              {
                label: '禁用',
                value: 0
              }
          ]
        },

      ]

    };
  },

  mounted() {
    this.getDataList();
  },
  methods: {
    // 初始化表格渲染
    async getDataList() {
      const res = await MenuShowApi.pageList();
      this.data = res.data.body.content;
    },

    // 提交编辑表格行信息
    onEditSubmit(e) {
      MenuShowApi.saveRow(e.form)
        .then(response => {
          if (!response.data.code == 0) return;
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1500
          });
          this.getDataList();
        })
        .catch(response => {
          console.error(response);
          this.$message.error("修改失败");
        });
    },
    // 打开编辑弹窗
    openDialog({ row }) {
      this.form = {
        ...row
      };

      this.$refs.addSubDialog.showDialog(row);
      this.titleSrc = "修改";
    },

    // 分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getDataList();
    },

    handleQueryFocus() {},
    handleFocus() {}
  }
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
