<template>
  <div>
    <el-dialog
      :title="titleSrc"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        ref="formData"
        label-width="170px"
        label-position="left"
        :model="form"
        :rules="rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="分润版本ID:" prop="id">
              <el-input 
                v-model="form.id"
                :disabled="true"
                placeholder="提交后自动生成"
              ></el-input>
            </el-form-item>
            <el-form-item label="推广人分润百分比:" prop="ratePop">
              <el-input 
                v-model="form.ratePop"
                placeholder="保留小数点后两位"
              ></el-input>
            </el-form-item>
            <el-form-item label="服务商分润百分比:" prop="rateServ">
              <el-input 
                v-model="form.rateServ"
                placeholder="保留小数点后两位"
              ></el-input>
            </el-form-item>
            <el-form-item label="执行时间:" prop="enable">
              <el-select v-model="form.enable" placeholder="请选择">
                <el-option
                  v-for="item in executionTimeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:" prop="remark">
              <el-input
                v-model="form.remark"
                type="textarea"
                autosize
                placeholder="请输备注内容"
                >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="dialog-footer" align="center">
          <el-button type="primary" @click="onEditSubmit">提交</el-button>
          <el-button type="default" @click="onClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "addSub",
  props: {
    titleSrc: String,
    form: Object
  },
  data() {
     var cheakMessage = (rule, value, callback) => {
      const reg = /[^\d.\d$]/;
      if (value && reg.test(value)) {
        callback(new Error('请输入数字'))
      } else if (!value) {
        callback(new Error('请输入'))
      } else {
        callback()
      }
    }
    return {
      dialogFormVisible: false,
      // 表单验证
      rules: {
        ratePop: [{ required: true, validator: cheakMessage, trigger: 'blur' }],
        rateServ: [{ required: true, validator: cheakMessage, trigger: 'blur' }]
      },
      executionTimeOptions: [
        {
          label: '以后执行',
          value: 0
        },
        {
          label: '立即执行',
          value: 1
        }
      ],
      formData: {}
    };
  },
  methods: {
    showDialog(e = {}) {
      this.formData = e
      this.dialogFormVisible = true;
    },
    onClose() {
      this.dialogFormVisible = false;
    },
    onEditSubmit () {
      this.$confirm('确认提交吗？', '提示', {})
        .then(() => {
          if((Number(this.formData.ratePop) + Number(this.formData.rateServ)) > 100) {
            this.$message.error("分润比例不应该超过100%");
            return
          }
          this.$emit('on-edit-submit', this.formData)
          this.onClose()
        })
        .catch(() => {})
    }
  }
};
</script>
<style scoped>

/deep/ .el-input__inner {
  width: 340px;
}
</style>
