import { get, post, deletes } from '../../service'

export default class CaseManage {
    // 列表
    static list(params = undefined) {
        return post('/service/case/ManageCaseList', params)
    }
    // 新增/修改
    static updateCase(params = undefined) {
        return post('/service/case/ManageSaveCase', params)
    }
    // 删除
    static delete( id= undefined) {
        return get('/service/case/deleteCaseById/'+id)
    }
}
