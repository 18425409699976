/**
 * 订单管理
 */
import { post, get } from '../../service'

export default class Order {
  // 获取订单列表
  static mngOrderList (params = undefined) {
    return post('/service/order/mngOrderList', params)
  }

  // 获取交易成功订单实付总额
  static getSumCost (params = undefined) {
    return post('/service/order/getSumCost', params)
  }


  // 采购订单 获取订单列表
  static pOrderList (params = undefined) {
    return post('/service/pOrder/getManagePOrderList', params)
  }

  // 采购订单 获取订单详情
  static pOrderDetail (params = undefined) {
    return post('/service/pOrder/getManageOrder', params)
  }

   // 采购订单 修改数量
   static modifyGoodsNum (params = undefined) {
    return post('/service/pOrder/modifyGoodsNum', params)
  }


  // 对账账单 获取列表
  static billOrderList (params = undefined) {
    return post('/service/bill/getManageBill', params)
  }

  // 对账账单 获取对账总和
  static getBillSum (params = undefined) {
    return post('/service/bill/getBillSum', params)
  }

  // 对账账单 修改对账状态
  static checkBill (params = undefined) {
    return post('/service/bill/modifyBillStatus', params)
  }


}



