<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="5"
      :labelWidth="'100px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        新增课程包
      </el-button>
    </div>
    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:packageName="{ row }">
          {{row.courseName}}
        </template>
        <template v-slot:packageType="{ row }">
          {{getDataZh(packageTypeList, row.courseLevel)}}
        </template>
        <template v-slot:status="{ row }">
          {{row.status==1?'上架':'下架'}}
        </template>
        <template v-slot:ctm="{ row }">
          {{timeConvert(row.ctm, 1)}}
        </template>
      </free-table>
    </div>
    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
    />

    <!-- 添加课程弹窗 -->
    <el-dialog
      title="添加课程"
      :visible.sync="addCourseDialogVisible"
      width="30%"
      @opened="openidTreeDialog"
      @closed="closedTreeDialog">
      <el-tree
        :data="courseTree"
        show-checkbox
        node-key="id"
        ref="tree"
        :load="loadCourseTreeChild"
        lazy
        highlight-current
        :props="treeProps"
        :default-expanded-keys="cur_course_s"
        :default-checked-keys="cur_course_s"
        @check-change="onCheckChanged"
        >
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addCourseDialogVisible = false">关 闭</el-button>
        <!-- <el-button type="primary" @click="addCourseDialogVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>
  </d2-container>
</template>
<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'
// 引入页面层级组件
import AddSub from './components/addSub'
// 引入接口
import CoursePackageApi from '@/model/modules/coursePackage/index'
import { parse } from 'qs'

export default {
  name: 'roleinfo',
  components: {
    SearchConfig, 
    FreeTable,
    AddSub
  },
  data () {
    return {
      is_first: true, //标记添加课程弹窗是否第一次打开，用来判断tree空间的@check-change事件在刚打开弹窗时是否触发
      cur_course_s: [], //课程树默认选中的节点
      cur_coursePackageId: '', //当前添加课程绑定的课程包id
      treeProps: { //课程树的props属性配置
        label: 'courseName'
      },
      courseTagList: [], //目录标签列表
      courseClassify: [], //课程分类列表
      rangeList: [], //课程范围列表
      courseTree: [], //课程树
      packageTypeList: [], //课程包类型列表
      consultList: [], //主讲人列表
      addCourseDialogVisible: false, //控制添加课程弹窗的显示隐藏
      titleSrc: '',
      searchConfigData: [
        {
          label: '课程包名称',
          type: 'input',
          prop: 'coursePackageName'
        },
        {
          label: '课程包类型',
          placeholder: '请选择',
          type: 'select',
          prop: 'coursePackageType',
          options: []
        }

      ],
      form: {
        packageName: '',
        packageType: '',
        uid: '',
        courseCount: '',
        cost: '',
        tag: [],
        ctlId: [],
        cateCode: '',
        status: '', 
        cover: '',
        courseText: ''
      },
      config: [
        {
          label: '课程包名称',
          type: 'input',
          prop: 'packageName'
        },
        {
          label: '课程包类型',
          type: 'select',
          prop: 'packageType',
          options: []
        },
        {
          label: '主讲人',
          type: 'select',
          prop: 'uid',
          options: []
        },
        {
          label: '课时',
          type: 'input',
          prop: 'courseCount'
        },
        {
          label: '价格(单位：元)',
          type: 'numberInput',
          prop: 'cost',
        },
        {
          label: '目录标签',
          type: 'select',
          prop: 'tag',
          isApi: true,
          multiple: true,
          multipleLimit: 2,
          options: []
        },
        {
          label: '选择课程分类',
          type: 'cascader',
          prop: 'ctlId',
          placeholder: '请选择',
          options: []
        },
        {
          label: '选择课程范围',
          type: 'select',
          prop: 'cateCode',
          isApi: true,
          options: []
        },
        {
          label: '状态',
          type: 'select',
          prop: 'status',
          options: [
            {
              label: '上架',
              value: 1
            },
            {
              label: '下架',
              value: 0
            }
          ]
        },
        {
          label: '课程封面',
          type: 'uploadAvatar',
          prop: 'cover'
        },
        {
          label: '课程介绍',
          type: 'quill',
          prop: 'courseText'
        }
      ],
      data: [], // 表格数据渲染
      slotColumn: [
        { label: '序号', type: 'index' },
        { label: '课程包名称', prop: 'packageName', slotScope: true},
        { label: '课程包类型', prop: 'packageType', slotScope: true },
        { label: '主讲人', prop: 'uid' },
        { label: '价格(单位：元)', prop: 'cost' },
        { label: '状态', prop: 'status', slotScope: true },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '370px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.shelves(scope.row)
                  }}
                >
                  { scope.row.status==0?'上架':'下架' }
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openCourseTree(scope)
                  }}
                >
                  添加课程
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope)
                  }}
                >
                  编辑
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row)
                  }}
                >
                  删除
                </el-button>
              </div>
            )
          }
        }
      ],
      formSetData: {
        // 查询搜索内容
        pageIndexB1: 1,
        pageSize: 10,
        coursePackageName: '',
        coursePackageType: ''
      },
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [3, 5, 10],
        total: 1
      },
      dialogImageUrl: '',
      dialogVisible: false,
      // 表单验证
      rules: {
        packageName: [
          { required: true, message: '请输入课程包名称', trigger: 'blur' }
        ],
        packageType: [
          { required: true, message: '请选择课程包类型', trigger: 'blur' }
        ],
        uid: [
          {required: true, message: '请选择主讲人', trigger: 'blur'}
        ],
        cost: [
          {required: true, message: '请输入价格', trigger: 'blur'}
        ],
        status: [
          {required: true, message: '请选择状态', trigger: 'blur'}
        ],
        cover: [{ required: true, message: '请选择封面', trigger: 'blur' }],
        courseCount: [{ required: true, message: '请输入课时', trigger: 'blur' }],
        tag: [{ required: true, message: '请选择目录标签', trigger: 'blur' }],
        ctlId: [{ required: true, message: '选择课程分类', trigger: 'blur' }],
        cateCode: [{ required: true, message: '请选择课程范围', trigger: 'blur' }],
        courseText: [{ required: true, message: '请输入课程介绍', trigger: 'blur' }]
      }
    }
  },

  computed: {
    timeConvert() {//num:0 YYYY-MM-DD  num:1  YYYY-MM-DD hh:mm:ss // timestamp:时间戳 
      return function(timestamp, num) {
        if (timestamp === 0) {
          return '-'
        } else {
          timestamp = timestamp.length === 10  ? timestamp*1000 : timestamp;
          let date = new Date(timestamp);
          let y = date.getFullYear();  
          let m = date.getMonth() + 1;  
          m = m < 10 ? ('0' + m) : m;  
          let d = date.getDate();  
          d = d < 10 ? ('0' + d) : d;  
          let h = date.getHours();
          h = h < 10 ? ('0' + h) : h;
          let minute = date.getMinutes();
          let second = date.getSeconds();
          minute = minute < 10 ? ('0' + minute) : minute;  
          second = second < 10 ? ('0' + second) : second; 
          if(num==0){
            return y + '-' + m + '-' + d;  
          }else{
            return y + '-' + m + '-' + d +' '+ h +':'+ minute +':' + second;  
          }
        }
      }
    },

    // 下拉列表value转换成label 用于表格显示 list表示下拉列表
    getDataZh(){
      return function (list, value) {
        for(var i=0; i<list.length; i++){
          if(list[i].value == value){
            return list[i].label
            break
          }
        }
      }
    },
  },

  mounted () {
    this.getDataList()
    this.getConsultList()
    this.getPackageType()
    this.getCourseTagList()
    this.getCourseClassify()
    this.getRangeList()
  },
  methods: {
    // 打开添加课程弹窗回调
    openidTreeDialog(){
      this.is_first = false
      console.log(this.cur_course_s)
    },

    // 关闭添加课程弹窗回调
    closedTreeDialog(){
      this.cur_course_s = []
      this.is_first = true
    },
    // 获取课程树
    async getCourseTree(){
      let res = await CoursePackageApi.getCourseTree()
      this.courseTree = res.data.body
    },

    // 课程树 懒加载
    loadCourseTreeChild(node, resolve){
      if (node.level === 0) {
        return resolve(this.courseTree);
      }
      if (node.level > 1) return resolve([]);
      if(node.level === 1){
        this.getCourseTreeChild(node, resolve)
      }
    },

    // 获取课程树二级节点
    getCourseTreeChild(node, resolve){
      var params = {
        id: node.data.id
      }
      CoursePackageApi.getCourseTreeChild(params)
        .then(res =>{
          if(!res.data.code == 0) return
          var child = res.data.body
          resolve(child)
        })
    },

    // 获取已添加课程(课程树回显)
    getCourseChildrenList(){
      var params = {
        id: this.cur_coursePackageId
      }
      CoursePackageApi.getCourseChildrenList(params)
        .then(res => {
          this.cur_course_s = res.data.body
        })
    },

    // 获取主讲人列表
    async getConsultList(){
      let res = await CoursePackageApi.getConsultList()
      this.consultList = res.data.body.map((item, index) => {
        var obj = {}
        obj.label = item.fullname
        obj.value = item.uid
        return obj
      })
    },

    // 获取课程包类型列表
    async getPackageType(){
      let res = await CoursePackageApi.getPackageType()
      this.packageTypeList = res.data.body.map((item, index) => {
        var obj = {}
        obj.label = item.typeName
        obj.value = item.typeValue
        return obj
      })
      this.searchConfigData[1].options = this.packageTypeList
    },

    // 获取目录标签列表
    async getCourseTagList(){
      let res = await CoursePackageApi.getCourseTagList()
      this.courseTagList = res.data.body.map((item, index) => {
        var obj = {}
        obj.label = item.clgName
        obj.value = item.id
        return obj
      })
    },

    // 获取课程分类列表
    async getCourseClassify(){
      let res = await CoursePackageApi.getCourseClassify()
      let data = res.data.body[0].children[3].children
      data.map((item, index) => {
        let info = {}
        info['value'] = item.id
        info['label'] = item.clgName

        if (item.children != undefined) {
          let data1 = item.children
          info['children'] = []
          data1.map((item1, index1) => {
            let info1 = {}
            info1['value'] = item1.id
            info1['label'] = item1.clgName
            info['children'].push(info1)

            if (item1.children != undefined) {
              let data2 = item1.children
              info.children[index1]['children'] = []
              data2.map((item2, index2) => {
                let info2 = {}
                info2['value'] = item2.id
                info2['label'] = item2.clgName
                info.children[index1]['children'].push(info2)
              })
            }

          })
        }

        this.courseClassify.push(info)
      })
    },

    // 获取课程范围列表
    async getRangeList(){
      let res = await CoursePackageApi.getRangeList()
      this.rangeList = res.data.body.map((item, index) => {
        var obj = {}
        obj.label = item.itemName
        obj.value = item.itemCode
        return obj
      })
    },


    // 上下架
    shelves(data){
      CoursePackageApi.shelves(data.id)
       .then(response => {
         if (!response.data.code == 0) return
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500
          })
          this.getDataList()
       })
       .catch(response => {
          console.error(response)
          this.$message.error('操作失败')
        })
    },

    // 打开添加课程弹窗
    async openCourseTree({ row }){
      this.cur_coursePackageId = row.id
      var loading = this.$loading({
        lock: true,
          text: '数据加载中',
      })
      await this.getCourseTree()
      await this.getCourseChildrenList()
      this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
        loading.close();
      });
      this.addCourseDialogVisible = true
    },

    // 添加删除课程
    onCheckChanged(menu, checked){
      // console.log(checked)
      var params = {
        parentId: this.cur_coursePackageId,
        childrenId: menu.id
      }

      // 判断弹窗是否第一次打开，如果时第一次打开，不触发@check-change事件
      if(!this.is_first){
        if(checked){
          CoursePackageApi.addCourse(params)
        }else{
          CoursePackageApi.deleteCourse(params)
        }
      }
      
    },

    // 初始化表格渲染
    async getDataList () {
      this.formSetData.pageIndexB1 = this.pagination.pageNum
      this.formSetData.pageSize = this.pagination.pageSize
      const res = await CoursePackageApi.pageList(this.formSetData)
      this.data = res.data.body.content
      this.pagination.total = res.data.body.total * 1
    },

    // 提交编辑表格行信息
    onEditSubmit (e) {
      // 将数组格式转换为string，逗号拼接
      let tag = ''
      e.form.tag.map((item, index) => {
        tag += item + ','
      })
      tag = tag.substring(0, tag.length - 1)
      e.form.tag = tag

      // 截取最后一级id
      e.form.ctlId = e.form.ctlId[e.form.ctlId.length-1]

      if ((this.titleSrc == '课程包修改')) {
        CoursePackageApi.saveRow(e.form)
          .then(response => {
            if (!response.data.code == 0) return
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 1500
            })
            this.getDataList()
          })
          .catch(response => {
            console.error(response)
            this.$message.error('修改失败')
          })
      } else if (this.titleSrc == '课程包添加') {
        CoursePackageApi.saveRow(this.form)
          .then(response => {
            if (!response.data.code == 0) return
            this.$message({
              message: '添加成功',
              type: 'success',
              duration: 1500
            })
            this.getDataList()
          })
          .catch(response => {
            console.error(response)
            this.$message.error('添加失败')
          })
      }
    },
    
    // 打开编辑弹窗
    openDialog ({ row }) {
      this.form = {
        packageName: row.courseName,
        packageType: row.courseLevel,
        uid: row.uid,
        cost: row.cost,
        status: row.status,
        id: row.id,
        courseCount: row.courseCount,
        tag: row.tag,
        ctlId: row.ctlId,
        cateCode: row.cateCode, 
        cover: row.cover,
        courseText: row.courseText
      }

      // 将string转换为array
      if (this.form.tag !== undefined) {
        if (typeof this.form.tag === 'string') {
          var _tag = []
          this.form.tag.split(',').map((val) => {
            _tag.push(val)
          })
          this.form.tag = _tag
        }
      }

 
      this.$refs.addSubDialog.showDialog(this.form)
      this.titleSrc = '课程包修改'
      this.config[2].options = this.consultList
      this.config[1].options = this.packageTypeList
      this.config[5].options = this.courseTagList
      this.config[6].options = this.courseClassify
      this.config[7].options = this.rangeList
    },

    onAdd () {
      this.form = {
        packageName: '',
        packageType: '',
        uid: '',
        courseCount: '',
        cost: '',
        tag: [],
        ctlId: [],
        cateCode: '',
        status: '', 
        cover: '',
        courseText: ''
      }
      this.$refs.addSubDialog.showDialog(this.form)
      this.titleSrc = '课程包添加'
      this.config[2].options = this.consultList
      this.config[1].options = this.packageTypeList
      this.config[5].options = this.courseTagList
      this.config[6].options = this.courseClassify
      this.config[7].options = this.rangeList
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '提示', {}).then(() => {
        CoursePackageApi.deleteRow({ id: row.id })
          .then(response => {
            if (!response.data.code == 0) return
            this.$message({
              message: '删除成功',
              type: 'success',
              duration: 1500
            }) 
            this.getDataList()
          })
          .catch(response => {
            console.error(response)
            this.$message.error('删除失败')
          })
      })
    },
   
    // 表格查询按钮
    onQuery () {
      this.pagination.pageNum = 1
      this.getDataList()
    },
    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.getDataList()
    },

    handleQueryFocus(){},
    handleFocus(){}
  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
