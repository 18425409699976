<template>
  <div>
    <el-dialog
      :title="titleSrc"
      :visible.sync="dialogFormVisible"
      v-bind="$attrs"
    >
      <!--查询条件配置组件-->
      <search-config
        ref="searchConfig"
        :configData="config"
        :formSet="form"
        :rules="rules"
        :colspan="12"
        :btnColspan="5"
        :labelWidth="'100px'"
      />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onEditSubmit">提交</el-button>
        <el-button type="default" @click="onClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SearchConfig from "@/views/components/searchConfigForm/searchConfigFormOne.vue";
export default {
  name: "addSub",
  components: {
    SearchConfig
  },
  props: {
    config: Array,
    form: Object,
    rules: Object,
    titleSrc: String
  },
  data() {
    return {
      dialogFormVisible: false,
    };
  },
  methods: {
    showDialog() {
      this.dialogFormVisible = true;
    },
    onEditSubmit() {
      this.$refs.searchConfig.$children[0].validate(valid => {
        if (!valid) return false;
        this.$confirm("确认提交吗？", "提示", {}).then(() => {
          this.$emit("on-edit-submit", { form: this.form, visible: false });
          this.onClose();
        });
      });
    },
    onClose() {
      this.dialogFormVisible = false;
    }
  }
};
</script>
<style>
.mt20 {
  margin-top: 20px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
