/**
 * 分润管理
 */

import { post } from '../../service'
import apiUrl from './api-url'

export const benefitQuery = (params = undefined) => {
  return post(apiUrl.benefitQuery, params)
}

export const benefitAdd = (params = undefined) => {
  return post(apiUrl.benefitAdd, params)
}

export const enableBenefit = (params = undefined) => {
  return post(apiUrl.enableBenefit, params)
}

export const benefitClone = (params = undefined) => {
  return post(apiUrl.benefitClone, params)
}
