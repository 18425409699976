<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="titleSrc"
      :visible.sync="dialogFormVisible"
      v-bind="$attrs"
    >
      <!--查询条件配置组件-->
      <searchConfigFormOne
        ref="searchConfigFormOne"
        :configData="config"
        :formSet="form"
        :rules="rules"
        :labelWidth="'120px'"
        @on-avatar-success="handleAvatarSuccess"
        @on-focus-select="handleFocus"
        @on-handle-change="handleChange"
      />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onEditSubmit">提交</el-button>
        <el-button type="default" @click="onClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import searchConfigFormOne from '@/views/components/searchConfigForm/searchConfigFormOne'
export default {
  name: 'addSub',
  components: {
    searchConfigFormOne
  },
  props: {
    config: Array,
    form: Object,
    rules: Object,
    titleSrc: String
  },
  data () {
    return {
      dialogFormVisible: false,
      formData: {},
      allObjTags: [],
      obj: '',
      ratio: ''
    }
  },
  methods: {
    showDialog (e = {}) {
      this.formData = e
      this.dialogFormVisible = true
    },
    handleSelect (key, keyPath) {
      this.activeIndex = key.toString()
    },
    onEditSubmit () {
      this.$refs.searchConfigFormOne.$children[0].validate(valid => {
        if (!valid) return
        this.$confirm('确认提交吗？', '提示', {}).then(() => {
          this.$emit('on-edit-submit', { form: this.formData, visible: false })
          this.onClose()
        }).catch(() => {})
      })
    },
    onClose () {
      this.dialogFormVisible = false
    },
    handleChange (e) {
      this.$emit('on-handle-Change', { item: e })
    },
    handleFocus (e) {
      this.$emit('on-focus-select', { item: e.item })
    },
    handleAvatarSuccess (e) {
      if(e.propName == 'cover'){
        e.origin.cover = e.res.filepath
      }else if(e.propName == 'coverMini'){
         e.origin.coverMini = e.res.filepath
      }else{
        console.log('error')
      }
      
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>
<style>
.mt20 {
  margin-top: 20px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
