<template>
  <d2-container>
    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
      >
      </free-table>
    </div>

    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
    />
  </d2-container>
</template>
<script>
// 引入组件
import FreeTable from "@/views/components/FreeTable";

// 引入页面层级组件
import AddSub from "./components/addSub";

// 引入接口
import MijiManageApi from "@/model/modules/mijiManage/index";

export default {
  name: "mijiManage",
  components: {
    FreeTable,
    AddSub
  },
  data() {
    return {
      titleSrc: "",
      form: {
        panUrl: "",
        panCert: "",
        detail: ""
      },
      config: [
        [
          {
            label: "链接",
            type: "input",
            prop: "panUrl"
          },
          {
            label: "秘钥",
            type: "input",
            prop: "panCert"
          }
        ],
        [
          {
            colSpan: 24,
            label: "详情",
            type: "quill",
            prop: "detail"
          }
        ]
      ],
      data: [], // 表格数据渲染
      slotColumn: [
        { label: "标题", prop: "cheatsName" },
        { label: "链接", prop: "panUrl" },
        { label: "秘钥", prop: "panCert" },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  编辑
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.toReadMaterialManage(scope);
                  }}
                >
                  阅读物料管理
                </el-button>
              </div>
            );
          }
        }
      ],
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true
      },
      // 表单验证
      rules: {
        panUrl: [{ required: true, message: "请输入链接", trigger: "blur" }],
        panCert: [{ required: true, message: "请输入秘钥", trigger: "blur" }],
        detail: [{ required: true, message: "请输入详情", trigger: "blur" }]
      }
    };
  },

  mounted() {
    this.getDataList();
  },
  methods: {
    // 初始化表格渲染
    async getDataList() {
      const res = await MijiManageApi.pageList();
      this.data = res.data.body;
    },

    // 提交编辑表格行信息
    onEditSubmit(e) {
      MijiManageApi.saveRow(e.form)
        .then(res => {
          if (res.data.code == 0) {
            this.$message.success("修改成功");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(err => {
          this.$message.error(err);
        })
        .finally(() => {
          this.getDataList();
        });
    },

    // 打开编辑弹窗
    openDialog({ row }) {
      this.form = {
        ...row
      };

      this.$refs.addSubDialog.showDialog();
      this.titleSrc = "秘籍修改";
    },

    toReadMaterialManage(scope) {
      this.$router.push({
        path: "/modList/readMaterialManage",
        query: {
          id: scope.row.id
        }
      });
    }
  }
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
