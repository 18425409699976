<template>
  <d2-container>
    <el-row>
      <el-col :span="5">
        <div class="left">
          <div class="left-head">
            <el-avatar
              style="margin-left: 9px"
              :size="50"
              :src="circleUrl"
            ></el-avatar>
            <span style="margin-left: 9px">{{ username }}</span>
            <el-dropdown class="upDownLine" trigger="click">
              <el-button type="primary">
                {{ upDownLineText }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  style="padding-top: 5px"
                  v-for="item in arrLineStatus"
                  :key="item.value"
                >
                  <div @click="onUpDownLineClick(item.value)">
                    {{ item.label }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div style="border-bottom: 2px solid #a8a8a8"></div>
          <el-input
            class="left-conter-search"
            v-model="queryState"
            placeholder="请输入内容"
            @change="querySearchAsync"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <ul ref="consultHistoryScroll" style="overflow-x: hidden">
            <li
              class="left-centre-item"
              :class="{ active: index === currentIndex }"
              v-for="(item, index) in arrConsultHistory"
              :key="index"
              @click="listItemClick(index)"
            >
              <div>
                <el-badge
                  v-if="item.unreadedCount > 0"
                  :value="item.unreadedCount"
                  class="badge"
                ></el-badge>
                <el-avatar :size="50" :src="item.avatar"></el-avatar>
              </div>
              <div class="item-text">
                <span>{{ item.fullname }}</span>
                <span>{{ item.clinicName }}</span>
                <span
                  style="
                    width: 230px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  "
                  >{{ item.latestMsgContent }}</span
                >
              </div>
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :span="19">
        <div class="right">
          <div class="right-head">
            <span>{{ fullName }}</span>
            <span>{{ clinicName }}</span>
          </div>
          <div ref="chatRecordScroll" class="right-center">
            <div class="center" v-for="(item, index) in arrmsg" :key="index">
              <div v-if="item.uid !== uuid" class="center-left-item">
                <div class="item">
                  <span>{{ item.name }}</span>
                  <el-avatar
                    shape="circle"
                    :size="50"
                    :src="item.avatar"
                  ></el-avatar>
                </div>
                <img
                  v-if="item.contentType == 'IMAGE'"
                  :src="item.msg"
                  style="margin-left: 10px"
                  width="150"
                  height="200"
                />
                <span v-else class="item-msg">{{ item.msg }}</span>
              </div>
              <div v-else class="center-right-item">
                <img
                  v-if="item.contentType == 'IMAGE'"
                  :src="item.msg"
                  style="margin-left: 10px"
                  width="150"
                  height="200"
                />
                <span v-else class="item-msg">{{ item.msg }}</span>
                <div class="item">
                  <span>{{ item.name }}</span>
                  <el-avatar
                    shape="circle"
                    :size="50"
                    :src="item.avatar"
                  ></el-avatar>
                </div>
              </div>
            </div>
          </div>

          <div class="right-footer">
            <div class="icon">
              <i class="el-icon-s-order"></i>
            </div>
            <el-input
              class="inp"
              type="textarea"
              rows="4"
              auto-complete="off"
              placeholder="请在此输入内容"
              v-model="sendMsgInp"
            ></el-input>
            <el-button
              class="position_btn"
              @click="handelSendBtn"
              type="default"
              >发送</el-button
            >
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="call-bg popup" v-if="callBl">
      <div class="call">
        <div class="call_head">
          <span>{{ arrCall.name }}</span>
        </div>
        <h1 class="call_tips">{{ arrCall.msg }}</h1>
        <div class="btn_answer" @click="_expertsPass">接听</div>
        <div class="btn_refuse" @click="_expertsRefuse">拒绝</div>
      </div>
    </div>

    <div class="voice-answering" v-if="voiceAnsweringBl">
      <div class="voice-head"></div>
      <div class="voice-item">
        <i class="el-icon-phone-outline"></i>
        <div class="answering-time">
          <span
            style="
              width: 130px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
          >
            正在通话: {{ arrCall.name }}</span
          >
          <span>{{ time.hours }}:{{ time.minutes }}:{{ time.seconds }}</span>
        </div>
      </div>
      <div class="ringOff" @click="refuseCaller">挂断</div>
    </div>

    <div class="video-answering popup" v-if="videoAnsweringBl">
      <div class="video">
        <div class="video-head">
          <span>{{ arrCall.name }}</span>
          <span
            >{{ time.hours }} : {{ time.minutes }} : {{ time.seconds }}</span
          >
        </div>
        <div id="video_call" class="video_call">
          <!-- <div id="video_mine" class="video_mine"></div> -->
        </div>
        <div class="video-ringOff" @click="leaveCall">挂断</div>
      </div>
    </div>
  </d2-container>
</template>

<script>
import AgoraRTC from 'agora-rtc-sdk-ng'
// 引入接口
import {
  consultHistory,
  history,
  updateOnlineStatus
} from '@/model/modules/mod_health'

export default {
  name: 'menuHealthPage',
  data () {
    return {
      uuid: '',
      username: '',
      circleUrl: require('@/assets/wximage/avatar.png'),
      client: '',
      localAudioTrack: '',
      localVideoTrack: '',
      bl: true,
      upDownLineText: '',
      arrLineStatus: [
        {
          label: '空闲',
          value: 0
        },
        {
          label: '繁忙',
          value: 1
        },
        {
          label: '离开',
          value: 2
        },
        {
          label: '离线',
          value: 3
        }
      ],
      consultHistoryPageIndexB1: 1,
      arrConsultHistoryBl: true,
      arrConsultHistory: [],
      historyList: [],
      queryState: '',
      currentIndex: null,

      fullName: '',
      clinicName: '',
      recieverId: '',
      sendMsgInp: '',
      arrmsg: [],
      arrmsgPageIndexB1: 1,
      arrmsgBl: true,

      callBl: false,
      arrCall: {},

      voiceAnsweringBl: false,

      videoAnsweringBl: false,

      time: {
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      timeFun: '',

      bl: true
    }
  },
  watch: {
    arrmsg () {
      this.$nextTick(() => {
        // 滚动条在低  就滚动
        const posH =
          this.$refs.chatRecordScroll.scrollHeight -
          this.$refs.chatRecordScroll.scrollTop
        const chatAreaH = this.$refs.chatRecordScroll.offsetHeight
        const itemH =
          this.$refs.chatRecordScroll.children[
            this.$refs.chatRecordScroll.children.length - 1
          ].offsetHeight
        if (posH === chatAreaH + itemH) {
          this.$refs.chatRecordScroll.scrollTop =
            this.$refs.chatRecordScroll.scrollHeight
        }
      })
    }
  },
  methods: {
    // 患者列表
    async consultHistory () {
      const res = await consultHistory({
        pageIndexB1: this.consultHistoryPageIndexB1,
        pageSize: 20
      })
      if (res.data.body.content.length > 0) {
        this.arrConsultHistoryBl = true
        res.data.body.content.map((item, index) => {
          if (item.id !== this.uuid) {
            if (item.latestMsgContentType === 'VIDEO') {
              item.latestMsgContent = '[ 视频通话 ]'
            } else if (item.latestMsgContentType === 'AUDIO') {
              item.latestMsgContent = '[ 语音通话 ]'
            } else if (item.latestMsgContentType === 'IMAGE') {
              item.latestMsgContent = '[ 图片 ]'
            }
            this.arrConsultHistory.push(item)
          }
        })
      } else {
        if (this.consultHistoryPageIndexB1 >= 2) {
          this.consultHistoryPageIndexB1 -= 1
        }
        this.arrConsultHistoryBl = false
      }
    },

    // 点击患者获取历史记录
    listItemClick (index) {
      setTimeout(() => {
        this.$refs.chatRecordScroll.scrollTop =
          this.$refs.chatRecordScroll.scrollHeight
      }, 300)
      this.currentIndex = index
      this.recieverId = this.arrConsultHistory[index].id
      this.fullName = this.arrConsultHistory[index].fullname
      this.clinicName = this.arrConsultHistory[index].clinicName
      this.arrConsultHistory[index].unreadedCount = 0
      this.arrmsgPageIndexB1 = 1
      this.history(this.arrConsultHistory[index].id)
    },
    history (id) {
      const obj = {
        pageIndexB1: this.arrmsgPageIndexB1,
        pageSize: 50,
        receiverId: id,
        scene: 'CONSULTING',
        senderId: this.uuid
      }
      history(obj).then((res) => {
        if (res.data.body.content.length > 0) {
          this.arrmsgBl = true
          res.data.body.content.map((item, inde) => {
            const obj = {}
            obj.contentType = item.contentType
            obj.msg = item.content
            obj.name = item.senderName
            obj.avatar = item.senderAvatarUrl
            obj.uid = item.senderUid
            this.arrmsg.push(obj)
          })
        } else {
          if (this.arrmsgPageIndexB1 >= 2) {
            this.arrmsgPageIndexB1 -= 1
          }
          this.arrmsgBl = false
        }
      })
    },

    // 专家状态切换
    onUpDownLineClick (param) {
      updateOnlineStatus({ onlineStatus: param }).then((res) => {
        if (res.data.code === 0) {
          this.upDownLineText = this.arrLineStatus[param].label
        }
      })
    },

    // 搜索
    querySearchAsync (queryString) {
      if (queryString !== '') {
        const arrQueryConsult = []
        this.arrConsultHistory.map((item, index) => {
          if (item.fullname.indexOf(queryString) === 0) {
            arrQueryConsult.push(item)
          }
        })
        this.arrConsultHistory = arrQueryConsult
      } else {
        this.arrConsultHistory = []
        this.consultHistory()
      }
    },

    // 发送按钮点击事件
    handelSendBtn () {
      const data = this._createMessage()
      this.arrmsg.push({
        contentType: 'TEXT',
        msg: this.sendMsgInp,
        name: localStorage.getItem('username'),
        avatar: this.circleUrl,
        uid: this.uuid + ''
      })
      this.websocket.send(JSON.stringify(data)) // 发送数据，传什么数据由实际需求决定
      this.sendMsgInp = ''
    },

    generateUUID () {
      var d = new Date().getTime()
      if (window.performance && typeof window.performance.now === 'function') {
        d += performance.now() // use high-precision timer if available
      }
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          var r = (d + Math.random() * 16) % 16 | 0
          d = Math.floor(d / 16)
          return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
        }
      )
      return uuid
    },

    timerClick () {
      this.timeFun = setInterval(timer, 1000)
      const self = this
      function timer () {
        self.time.seconds = self.time.seconds + 1
        if (self.time.seconds > 60) {
          self.time.seconds = 0
          self.time.minutes = self.time.minutes + 1
          if (self.time.minutes > 59) {
            self.time.minutes = 0
            self.time.hours = self.time.hours + 1
          }
        }
      }
    },

    // 人历史记录滚动
    consultHistoryScroll (e) {
      const top = this.$refs.consultHistoryScroll.scrollTop
      const height = this.$refs.consultHistoryScroll.clientHeight
      const scrollHeight = this.$refs.consultHistoryScroll.scrollHeight
      if (top + height >= scrollHeight && this.arrConsultHistoryBl) {
        this.consultHistoryPageIndexB1 += 1
        this.consultHistory()
      }
    },
    // 聊天历史记录滚动
    chatRecordScroll (e) {
      const top = this.$refs.chatRecordScroll.scrollTop
      if (top <= 0 && this.arrmsgBl) {
        this.arrmsgPageIndexB1 += 1
        this.history(this.recieverId)
      }
    },

    // 接受语音聊天申请
    async acceptCaller (channelName, roomToken) {
      const roomAppId = process.env.VUE_APP_WEBSOKECT_ROOM
      // eslint-disable-next-line no-unused-vars
      const uid = await this.client.join(
        roomAppId,
        channelName,
        roomToken,
        null
      )

      this.client.on('user-published', async (user, mediaType) => {
        // 开始订阅远端用户。
        await this.client.subscribe(user, mediaType)

        // 表示本次订阅的是音频。
        if (mediaType === 'audio') {
          // 订阅完成后，从 `user` 中获取远端音频轨道对象。
          const remoteAudioTrack = user.audioTrack
          // 播放音频因为不会有画面，不需要提供 DOM 元素的信息。
          remoteAudioTrack.play()
        }
      })

      // 通过麦克风采集的音频创建本地音频轨道对象。
      this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
      // 将这些音频轨道对象发布到频道中。
      await this.client.publish([this.localAudioTrack])

      // 远端用户用户挂断
      const slef = this
      this.client.on('user-left', function (evt) {
        slef.refuseCaller()
      })
    },
    // 拒绝语音聊天申请
    async refuseCaller () {
      // 销毁本地音频轨道。
      this.localAudioTrack.close()
      // 离开频道。
      await this.client.leave()
      this.voiceAnsweringBl = false
      this.time = {
        hours: 0,
        minutes: 0,
        seconds: 0
      }
      clearInterval(this.timeFun)
    },

    // 接受视频通话申请
    async acceptVideoCaller (channelName, roomToken) {
      const roomAppId = process.env.VUE_APP_WEBSOKECT_ROOM
      // eslint-disable-next-line no-unused-vars
      const uid = await this.client.join(
        roomAppId,
        channelName,
        roomToken,
        null
      )

      this.client.on('user-published', async (user, mediaType) => {
        // 开始订阅远端用户。
        await this.client.subscribe(user, mediaType)
        // 表示本次订阅的是视频。
        if (mediaType === 'video') {
          const player = document.createElement('div')
          player.style.width = '100%'
          player.style.height = '100%'
          player.id = user.uid.toString()
          document.getElementById('video_call').appendChild(player)
          user.videoTrack.play(player.id)
        }

        // 表示本次订阅的是音频。
        if (mediaType === 'audio') {
          // 订阅完成后，从 `user` 中获取远端音频轨道对象。
          const remoteAudioTrack = user.audioTrack
          // 播放音频因为不会有画面，不需要提供 DOM 元素的信息。
          remoteAudioTrack.play()
        }
      })
      this.client.on('user-unpublished', (user, mediaType) => {
        if (mediaType === 'video' && user._audioTrack === undefined) {
          this.leaveCall()
        }
      })
      // 通过麦克风采集的音频创建本地音频轨道对象。
      this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
      // 通过摄像头采集的视频创建本地视频轨道对象。
      this.localVideoTrack = await AgoraRTC.createCameraVideoTrack()
      // 将这些音视频轨道对象发布到频道中。
      await this.client.publish([this.localAudioTrack, this.localVideoTrack])
    },
    async leaveCall () {
      // 销毁本地音视频轨道。
      this.localAudioTrack.close()
      this.localVideoTrack.close()

      // 遍历远端用户。
      this.client.remoteUsers.forEach((user) => {
        // 销毁动态创建的 DIV 节点。
        const playerContainer = document.getElementById(user.uid.toString())
        playerContainer && playerContainer.remove()
      })

      // 离开频道。
      await this.client.leave()
      this.videoAnsweringBl = false

      this.time = {
        hours: 0,
        minutes: 0,
        seconds: 0
      }
      clearInterval(this.timeFun)
    },

    // 发送一条消息
    _createMessage () {
      return {
        id: this.generateUUID(),
        tm: Date.parse(new Date()) * 1000, // 当前时间戳,
        msgType: 'MSG_SEND',
        deviceId: '',
        clientId: this.clientId,
        token: localStorage.getItem('token'),
        senderUid: this.uuid,
        recieverUid: this.recieverId,
        contentType: 'TEXT',
        format: '',
        content: this.sendMsgInp
      }
    },
    // 专家拒绝
    _expertsRefuse () {
      const data = {
        id: this.generateUUID(),
        tm: Date.parse(new Date()) * 1000, // 当前时间戳,
        msgType: 'CALL_RESP',
        deviceId: '',
        clientId: this.clientId,
        token: localStorage.getItem('token'),
        dialUid: this.arrCall.dailUid,
        targetUid: this.arrCall.targetUid,
        callRes: 'REJECT'
      }
      this.websocket.send(JSON.stringify(data))
      this.callBl = false
    },
    // 专家接听
    _expertsPass () {
      const data = {
        id: this.generateUUID(),
        tm: Date.parse(new Date()) * 1000, // 当前时间戳,
        msgType: 'CALL_RESP',
        deviceId: '',
        clientId: this.clientId,
        token: localStorage.getItem('token'),
        dialUid: this.arrCall.dailUid,
        targetUid: this.arrCall.targetUid,
        callRes: 'PASS'
      }
      this.websocket.send(JSON.stringify(data))
    },
    // 专家下线
    _expertsOffLine () {
      const data = {
        id: this.generateUUID(),
        tm: Date.parse(new Date()) * 1000, // 当前时间戳,
        msgType: 'CONSULTING_OFFLINE',
        deviceId: '',
        clientId: this.clientId,
        token: localStorage.getItem('token'),
        uid: this.uuid
      }
      this.websocket.send(JSON.stringify(data))
    },
    // 专家上线
    _expertsOnline () {
      this.upDownLineText = '空闲'
      const data = {
        id: this.generateUUID(),
        tm: Date.parse(new Date()) * 1000, // 当前时间戳,
        msgType: 'CONSULTING_ONLINE',
        deviceId: '',
        clientId: this.clientId,
        token: localStorage.getItem('token'),
        uid: this.uuid
      }
      this.websocket.send(JSON.stringify(data))
    },

    // websocket心跳  防连接超时  WebSocket规定在一定时间内没有数据交互，就会自动断开
    doSend () {
      this._expertsOnline()
    },
    // 绑定websokect连接建立时的事件回调
    websocketonopen () {
      setTimeout(() => {
        this.doSend()
      }, 300)
    },
    // 发送数据
    websocketsend () {
      var time = Date.parse(new Date()) * 1000 // 当前时间戳
      let data = {
        id: this.generateUUID(),
        tm: time,
        msgType: 'IM',
        deviceId: '',
        clientId: this.clientId,
        token: localStorage.getItem('token'),
        sign: '',
        role: 'COMP_COUNSELOR'
      }
      data = JSON.stringify(data) // websocket只能传字符串数据
      this.websocket.send(data) // 发送数据，传什么数据由实际需求决定
    },
    // websokect数据接收
    websocketonmessage (e) {
      var res = JSON.parse(e.data)
      this.reciveMsg = res
      const obj = {}
      if (res.msgType === 'WHO_ARE_YOU') {
        // 第一次问候
        this.clientId = res.clientId
        this.websocketsend()
      } else if (res.msgType === 'MSG_RECEIVE' && res.senderUid !== this.uuid) {
        // 收消息
        if (this.recieverId === res.senderUid) {
          obj.contentType = res.contentType
          obj.name = res.senderName
          obj.avatar = res.senderAvatar
          obj.msg = res.content
          obj.uid = res.senderUid + ''
          this.arrmsg.push(obj)
        } else {
          for (let i = 0; i < this.arrConsultHistory.length; i++) {
            if (this.arrConsultHistory[i].id === res.senderUid) {
              this.arrConsultHistory[i].unreadedCount += 1
              if (res.contentType === 'VIDEO') {
                this.arrConsultHistory[i].latestMsgContent = '[ 视频通话 ]'
              } else if (res.contentType === 'AUDIO') {
                this.arrConsultHistory[i].latestMsgContent = '[ 语音通话 ]'
              } else if (res.contentType === 'IMAGE') {
                this.arrConsultHistory[i].latestMsgContent = '[ 图片 ]'
              } else {
                this.arrConsultHistory[i].latestMsgContent = res.content
              }
              return
            }
          }
          this.arrConsultHistory = []
          this.consultHistory()
        }
      } else if (res.msgType === 'CALL') {
        // 语音/视频
        obj.dailUid = res.dialUid
        obj.targetUid = res.targetUid
        obj.name = res.dialName
        obj.avatar = res.dailAvatar
        obj.callType = res.callType
        if (res.callType === 'VOICE') {
          obj.msg = '视频等待接听...'
        } else if (res.callType === 'VIDEO') {
          obj.msg = '语音电话等待接听...'
        }
        this.arrCall = obj
        this.callBl = true
      } else if (res.msgType === 'ENTER_ROOM') {
        // 接听
        this.callBl = false
        if (this.arrCall.callType === 'VOICE') {
          this.voiceAnsweringBl = true
          this.acceptCaller(res.channelName, res.roomToken)
          this.timerClick()
        } else if (this.arrCall.callType === 'VIDEO') {
          this.videoAnsweringBl = true
          this.acceptVideoCaller(res.channelName, res.roomToken)
          this.timerClick()
        }
      } else if (res.msgType === 'CANCEL_CALL') {
        // 对方挂断
        this.callBl = false
        this.$message({
          message: '对方已关断',
          type: 'warning'
        })
      }
    },
    reconnect (url) {
      setTimeout(() => {
        // 没连接上会一直重连，设置延迟避免请求过多
        this.createWebSocket(url)
      }, 2000)
    },
    createWebSocket (url) {
      try {
        if ('WebSocket' in window) {
          this.websocket = new WebSocket(url)
        }
        this.initEventHandle()
      } catch (e) {
        this.reconnect(process.env.VUE_APP_WEBSOKECT_URL)
      }
    },
    // 关闭socket
    webSocketClose () {
      this.websocket.close() // 这句话是关键，之前我忘了写，一直没有真正的关闭socket
    },
    // 初始化事件函数
    initEventHandle () {
      this.websocket.onmessage = this.websocketonmessage // 客户端接收服务端数据时触发
      this.websocket.onopen = this.websocketonopen // 连接建立时触发
      this.websocket.onclose = () => {
        if (this.bl) {
          this.reconnect(process.env.VUE_APP_WEBSOKECT_URL)
        }
      }
      this.websocket.onerror = () => {
        this.reconnect(process.env.VUE_APP_WEBSOKECT_URL)
      }
    }
  },
  mounted () {
    // 绑定页面的滚动事件
    setTimeout(() => {
      this.$refs.consultHistoryScroll.addEventListener(
        'scroll',
        this.consultHistoryScroll
      )
      this.$refs.chatRecordScroll.addEventListener(
        'scroll',
        this.chatRecordScroll
      )
    }, 300)
    this.consultHistory()
    this.createWebSocket(process.env.VUE_APP_WEBSOKECT_URL)
    // 调用声网API
    this.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })
    this.uuid = localStorage.getItem('uuid')
    this.username = localStorage.getItem('username')
  },
  beforeRouteLeave (to, from, next) {
    // 在离开此页面的时候主动关闭socket
    this.bl = false
    this.webSocketClose()
    next()
  }
}
</script>

<style lang="scss" scoped>
.el-row {
  width: 100%;
  height: 100%;
}
.el-col {
  height: 100%;
}

.left {
  height: 100%;
  width: 100%;
  border: 2px solid #a8a8a8;
  background-color: #ffffff;
  position: relative;
  .left-head {
    position: relative;
    display: flex;
    align-items: center;
    height: 62px;
    .upDownLine {
      position: absolute;
      right: 9px;
    }
  }
  .left-conter-search {
    padding: 16px;
    width: 290px;
    i {
      padding-left: 13px;
    }
  }
  ul {
    position: absolute;
    top: 134px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow-y: hidden;
    overflow-y: scroll;
    .active {
      background-color: #f6bdbd;
    }
  }
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 0 20px;
  }
  .left-centre-item {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px;
    width: 290px;
    border-top: 2px solid #a8a8a8;

    .badge {
      position: absolute;
      top: 7px;
      left: 3px;
    }
    .item-text {
      display: flex;
      flex-flow: column wrap;
      margin-left: 13px;
    }
  }
}

.right {
  height: 100%;
  width: 100%;
  border: 2px solid #a8a8a8;
  background-color: #ffffff;
  position: relative;
  .right-head {
    height: 62px;
    line-height: 62px;
    span {
      margin-left: 20px;
    }
    border-bottom: 2px solid #a8a8a8;
  }
  // .right-center::-webkit-scrollbar{
  //   display: none
  // }
  .right-center {
    position: absolute;
    top: 64px;
    bottom: 192px;
    left: 0px;
    right: 0px;
    overflow-y: hidden;
    overflow-y: scroll;
    .center {
      padding: 20px;

      .center-left-item {
        display: flex;
        justify-content: flex-start;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .item-msg {
          line-height: 60px;
          padding-left: 20px;
        }
      }

      .center-right-item {
        display: flex;
        justify-content: flex-end;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .item-msg {
          line-height: 60px;
          padding-right: 20px;
        }
      }
    }
  }
  .right-footer {
    width: 100%;
    height: 190px;
    border-top: 2px solid #a8a8a8;
    position: absolute;
    bottom: 0px;
    .icon {
      height: 30px;
      border-bottom: 2px solid #a8a8a8;
      padding: 0 9px;
      i {
        padding: 5px;
      }
    }
    .inp {
      width: 80%;
      margin-top: 10px;
      margin-left: 10px;
    }
    .position_btn {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
}

.popup {
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.call-bg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  .call {
    background-color: #ffffff;
    width: 420px;
    height: 330px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .call_head {
      width: 100%;
      height: 50px;
      position: absolute;
      top: -50px;
      background-color: rgba(2, 2, 2, 0.5);
      span {
        color: #ffffff;
        line-height: 50px;
        padding-left: 20px;
      }
    }
    .call_tips {
      font-size: 36px;
      text-align: center;
      margin-top: 50px;
    }
    .btn_answer,
    .btn_refuse {
      transform: translateX(-50%);
      border-radius: 5px;
      color: #ffffff;
      line-height: 53px;
      text-align: center;
    }
    .btn_answer {
      width: 181px;
      height: 53px;
      position: absolute;
      bottom: 25%;
      left: 50%;
      background-color: #70b603;
    }
    .btn_refuse {
      width: 181px;
      height: 53px;
      position: absolute;
      bottom: 5%;
      left: 50%;
      background-color: #d9001b;
    }
    // p {
    //   margin-left: 10px;
    // }
    // .btn_flex {
    //   background: red;
    //   position: absolute;
    //   bottom: 30px;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    // display: flex;
    // justify-content: center;
    // align-items: flex-end;
    // div {
    //   width: 80px;
    //   height: 30px;
    //   color: #fff;
    //   border-radius: 10px;
    //   line-height: 30px;
    //   text-align: center;
    //   margin-top: 30px;
    //   &:nth-child(1) {
    //     background: green;
    //   }
    //   &:nth-child(2) {
    //     background: red;
    //     margin-left: 10px;
    //   }
    // }
    // }
  }
}

.voice-answering {
  width: 170px;
  height: 100px;
  border: 1px solid #000000;
  background-color: #ffffff;
  z-index: 3;
  position: fixed;
  top: 35%;
  right: 3%;
  .voice-head {
    width: 100%;
    height: 20px;
    background-color: #7f7f7f;
  }
  .voice-item {
    display: flex;
    i {
      margin: 10px;
    }
    .answering-time {
      display: flex;
      flex-direction: column;
      span {
        margin: 3px 0;
      }
    }
  }
  .ringOff {
    width: 90px;
    height: 20px;
    background-color: red;
    border-radius: 5px;
    line-height: 20px;
    text-align: center;
    position: relative;
    left: 50%;
    bottom: -5%;
    transform: translate(-50%, 0);
  }
}

.video-answering {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  .video {
    width: 290px;
    height: 480px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .video-head {
      width: 100%;
      height: 40px;
      background-color: #7f7f7f;
      span {
        line-height: 40px;
        padding-left: 9px;
      }
    }
    .video_call {
      width: 100%;
      height: calc(100% - 40px);
      background-color: #ffffff;
      position: relative;

      .video_mine {
        width: 100px;
        height: 100px;
        position: absolute;
        z-index: 1000;
        right: 0;
        bottom: 50px;
        background: rgba(0, 0, 0, 0.3);
      }
    }
    .video-ringOff {
      width: 120px;
      height: 40px;
      background-color: red;
      border-radius: 5px;
      line-height: 40px;
      text-align: center;
      position: absolute;
      left: 50%;
      bottom: 5px;
      transform: translate(-50%, 0);
    }
  }
}
</style>
