<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="5"
      :labelWidth="'100px'"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        新增广告
      </el-button>
    </div>
    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:fValidaStatus="{ row }">
          {{ row.fValidaStatus == 1 ? "启用" : "禁用" }}
        </template>
        <template v-slot:fPicUrl="{ row }">
          <div @click="showImg(row)" style="margin-top: 7px; cursor:pointer">
            <img :src="row.fPicUrl" width="30px" height="30px" class="image" />
          </div>
        </template>
      </free-table>
    </div>
    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
    />
  </d2-container>
</template>
<script>
let id = 1000;
//引入查询条件配置组件
import SearchConfig from "@/views/components/searchConfigForm";
import FreeTable from "@/views/components/FreeTable";
//引入页面层级组件
import AddSub from "./components/addSub";
//引入接口
import {
  pageList,
  deleteRow,
  addRow,
  editRow,
  changeStatus
} from "@/model/modules/ad";
export default {
  name: "roleinfo",
  components: {
    SearchConfig,
    FreeTable,
    AddSub
  },
  // mixins: [TableData],
  data() {
    return {
      titleSrc: "广告修改",
      rules: {
        fPicName: [
          { required: true, message: "请输入广告名称", trigger: "blur" }
        ],
        fSort: [{ required: true, message: "请输入排序号", trigger: "blur" }],
        f: [{ required: true, message: "请输入排序号", trigger: "blur" }],
        fValidaStatus: [
          { required: true, message: "请输入是否启用", trigger: "blur" }
        ],
        fStartTime: [
          { required: true, message: "请输入上架日期", trigger: "blur" }
        ],
        fEndTime: [
          { required: true, message: "请输入下架日期", trigger: "blur" }
        ],
        fLinkUrl: [{ required: true, message: "请输链接地址", trigger: "blur" }]
      },
      searchConfigData: [
        {
          label: "App广告设置",
          formItemId: 0,
          placeholder: "请输入广告名称",
          type: "input",
          prop: "fPicName"
        }
      ],
      form: {
        fId:"",
        fPicName: "",
        fSort: "",
        fStartTime: "",
        fEndTime: "",
        fValidaStatus: "",
        fLinkUrl: "",
        fRemark: "",
        fPicUrl: ""
      },
      config: [
        {
          label: "id",
          type: "input",
          prop: "fId",
          ishidden:true
        },
        {
          label: "广告名称",
          type: "input",
          prop: "fPicName"
        },
        {
          label: "排序",
          type: "input",
          prop: "fSort"
        },
        {
          label: "上架时间",
          type: "timePicker",
          prop: "fStartTime"
        },
        {
          label: "下架时间",
          type: "timePicker",
          prop: "fEndTime"
        },
        {
          label: "是否启用",
          type: "select",
          prop: "fValidaStatus",
          isApi: false,
          options: [
            {
              value: 1,
              label: "启用"
            },
            {
              value: 0,
              label:"禁用"
            }
          ]
        },
        {
          label: "链接地址",
          type: "input",
          prop: "fLinkUrl"
        },
        {
          label: "备注",
          type: "input",
          prop: "fRemark"
        },
        {
          label: "广告图片",
          type: "uploadAvatar",
          prop: "fPicUrl"
        }
      ],
      data: [], //表格数据渲染
      slotColumn: [
        //{ label: "选择", prop: "selection", type: "selection" },
        //  { label: '序号', prop: 'index', type: 'index' },
        { label: "名称", prop: "fPicName" },
        { label: "排序", prop: "fSort" },
        { label: "图片", prop: "fPicUrl", slotScope: true },
        { label: "链接", prop: "fLinkUrl" },
        { label: "状态", prop: "fValidaStatus", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  v-if=""
                  onClick={() => {
                    this.upValidStatus(scope.row);
                  }}
                >
                  {scope.row.fValidaStatus == 0 ? "启用" : "禁用"}
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  编辑
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row);
                  }}
                >
                  删除
                </el-button>
              </div>
            );
          }
        }
      ],
      formSetData: {
        // 查询搜索内容
        pageNum: Number,
        pageSize: Number,
        fPicName: null
      },
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true
      },
      pagination: {
        pageNum: 1,
        pageSize: 2,
        pageSizes: [2, 3, 4],
        total: 1
      },
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    //初始化表格渲染
    async getDataList() {
      this.formSetData.pageNum = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      let res = await pageList(this.formSetData);
      this.data = res.data.data.pag;
      console.log(res.data.data, "xxxccc===res.data.data");
      this.pagination.total = res.data.data.total * 1;
    },
    //提交编辑表格行信息
    onEditSubmit(e) {
      console.log(e,"eeeeechuanzhi=====")
      if ((this.titleSrc == "广告修改")) {
        editRow(e.form).then(res => {
          this.getDataList();
        });
      } else if(this.titleSrc == "添加广告") {
        addRow(e.form).then(res => {
          this.getDataList();
        });
      }
    },
    //打开编辑弹窗
    openDialog({ row }) {
      this.form = row;
      this.$refs.addSubDialog.showDialog(row);
      this.titleSrc = "广告修改";
    },
    onAdd() {
      this.form = {
        fId:"",
        fPicName: "",
        fSort: "",
        fStartTime: "",
        fEndTime: "",
        fValidaStatus: "",
        fLinkUrl: "",
        fRemark: "",
        fPicUrl: ""
      };
      this.$refs.addSubDialog.showDialog(this.form);
      this.titleSrc = "添加广告";
    },
    handleDelete(row) {
      this.$confirm("确认删除吗？", "提示", {}).then(() => {
        deleteRow({ key: row.fId })
          .then(response => {
            if (!response.data.success) return;
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500
            });
            this.getDataList();
          })
          .catch(response => {
            console.error(response);
            this.$message.error("操作失败");
          });
      });
    },
    // 启动/禁用
    upValidStatus(_dat) {
      let sts = _dat.fValidaStatus ? 0 : 1;
      let mess = _dat.fValidaStatus ? "确定禁用吗？" : "确定启用吗？";
      this.$confirm(mess, "提示", {}).then(() => {
        this.listLoading = true;
        let para = {
          fId: _dat.fId,
          fValidaStatus: sts
        };
        changeStatus(para)
          .then(response => {
            if (response.data.data > 0) {
              this.listLoading = false;
              _dat.fValidaStatus = _dat.fValidaStatus ? 0 : 1;
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500
              });
            }
          })
          .catch(response => {
            this.listLoading = false;
            console.error(response);
            this.$message.error("操作失败");
          });
      });
    },
    //表格查询按钮
    onQuery() {
      this.pagination.pageNum = 1;
      this.getDataList();
    },
    //分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getDataList();
    }
  }
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
