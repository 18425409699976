<template>
    <d2-container>
        <!-- 查询 -->
        <el-form :model="searchForm" :inline="true">
            <el-form-item label="标题">
                <el-input clearable v-model="searchForm.keyword"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSearchSubmit"
                    >查询</el-button
                >
            </el-form-item>
        </el-form>

        <!-- 新增 -->
        <div style="margin-bottom: 20px">
            <el-button
                type="default"
                size="mini"
                icon="el-icon-circle-plus-outline"
                @click="onAdd"
            >
                新增
            </el-button>
        </div>

        <!-- 表格 -->
        <el-table :data="articleTableData" border style="width: 100%">
            <el-table-column label="类型">
                <template slot-scope="scope">
                    {{ scope.row.type == "0" ? "文章" : "视频" }}
                </template>
            </el-table-column>
            <el-table-column
                prop="title"
                label="标题"
                width="400px"
            ></el-table-column>
            <el-table-column label="封面">
                <template slot-scope="scope">
                    <img
                        :src="scope.row.coverimg"
                        style="width: 30px; height: 20px"
                        @click="showImg(scope.row)"
                    />
                </template>
            </el-table-column>
            <el-table-column prop="channel" label="频道">
                <template slot-scope="scope">
                    {{ getPositionZh(scope.row.channel, channelList) }}
                </template>
            </el-table-column>
            <el-table-column prop="author" label="作者"></el-table-column>
            <el-table-column prop="orderWeight" label="排序" width="150px">
                <template slot-scope="scope">
                    <el-input
                        v-model="scope.row.orderWeight"
                        type="number"
                        @change="onChangeClick(scope.row)"
                    ></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="ctm" label="创建时间">
                <template slot-scope="scope">
                    {{ formData(scope.row.ctm) }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="300px">
                <template slot-scope="scope">
                    <el-button size="mini" @click="onEidt(scope.row)"
                        >编辑</el-button
                    >
                    <el-button size="mini" @click="onDelete(scope.row)"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>

        <div style="margin: 20px auto">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageInit.currentPage"
                :page-sizes="pageInit.pageSizes"
                :page-size="pageInit.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pageInit.total"
            >
            </el-pagination>
        </div>

        <!-- 新增/编辑弹窗 -->
        <el-dialog
            :close-on-click-modal="false"
            :title="saveDialogTitle"
            :visible.sync="saveDialogVisble"
            width="40%"
            :before-close="saveDialogHandleClose"
        >
            <el-form
                ref="saveForm"
                :model="saveForm"
                :inline="true"
                label-width="80px"
                :rules="saveFormRules"
                label-position="left"
            >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="类型" prop="type">
                            <el-select
                                v-model="saveForm.type"
                                @change="changeFileType"
                            >
                                <!-- <el-option label="文章" value="0"></el-option> -->
                                <el-option label="视频" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="标题" prop="title">
                            <el-input
                                v-model="saveForm.title"
                                show-word-limit
                                maxlength="50"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="频道" prop="channel">
                            <el-select v-model="saveForm.channel">
                                <el-option
                                    v-for="item in channelList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="作者" prop="author">
                            <el-input v-model="saveForm.author"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row v-if="saveDialogTitle == '修改'">
                    <el-col :span="12">
                        <el-form-item label="点赞数">
                            <el-input v-model="saveForm.agreeCount"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="转发数">
                            <el-input
                                v-model="saveForm.retransmissionCount"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col
                        id="desctiption"
                        :span="24"
                        v-if="saveForm.type === '1'"
                    >
                        <el-form-item label="视频链接" prop="content">
                            <el-input v-model="saveForm.content"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="封面" prop="coverimg">
                            <el-upload
                                class="avatar-uploader"
                                action="http://dev.kcguoyi.com/service/upload/huawei"
                                :show-file-list="false"
                                :auto-upload="false"
                                :on-change="handleAvatarChange"
                            >
                                <img
                                    v-if="saveForm.coverimg"
                                    :src="saveForm.coverimg"
                                    class="avatar"
                                />
                                <i
                                    v-else
                                    class="el-icon-plus avatar-uploader-icon"
                                ></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row v-if="saveForm.type == '0'">
                    <el-col :span="24">
                        <el-form-item label="文章内容" prop="content">
                            <d2-quill
                                v-model="saveForm.content"
                                ref="myQuillEditor"
                                @text-change="onEditorBlur"
                                @selection-change="onEditorFocus"
                                @editor-change="onEditorChange"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row v-if="saveForm.type == '1'">
                    <div id="desctiption">
                        <el-form-item label="简介" prop="desctiption">
                            <el-input
                                type="textarea"
                                v-model="saveForm.desctiption"
                                show-word-limit
                                maxlength="200"
                                :autosize="{ minRows: 7 }"
                            />
                        </el-form-item>
                    </div>
                </el-row>

                <el-row style="text-align: right">
                    <el-form-item>
                        <el-button @click="saveDialogHandleClose"
                            >取 消</el-button
                        >
                        <el-button type="primary" @click="onSaveSubmit"
                            >确 定</el-button
                        >
                    </el-form-item>
                </el-row>
            </el-form>
        </el-dialog>

        <!-- 图片放大弹窗 -->
        <el-dialog
            width="30%"
            :visible.sync="imgDialogVisible"
            :close-on-click-modal="true"
        >
            <img width="100%" :src="imgDialogUrl" alt="" />
        </el-dialog>
    </d2-container>
</template>
<script>
//引入页面层级组件
import AddSub from "./components/addSub";

//引入接口
import KcNewsApi from "@/model/modules/modCms_menuKcNews";
import { uploadPic } from "@/model/modules/user";
import { formData } from "@/utils/tool";

export default {
    name: "",

    components: {
        AddSub,
    },

    data() {
        var checkFileType = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("请输入内容"));
            } else {
                let type = this.checkTileType(value);
                if (this.saveForm.type == "1" && type == "0") {
                    return callback(new Error("请输入正确的视频链接"));
                } else {
                    return callback();
                }
            }
        };

        return {
            imgDialogUrl: "", //当前放大图片的url
            imgDialogVisible: false, //是否显示图片弹窗
            pageInit: {
                total: 1,
                pageSize: 10,
                pageSizes: [10, 20, 30, 40],
                currentPage: 1,
            },

            searchForm: {
                keyword: "",
                pageIndexB1: 1,
                pageSize: 10,
            },

            articleTableData: [],

            saveDialogTitle: "标题",
            saveDialogVisble: false,
            saveForm: {
                type: "1",
                title: "",
                channel: "",
                author: "",
                content: "",
                coverimg: "",
                desctiption: "",
            },
            saveFormRules: {
                type: {
                    required: true,
                    message: "请选择类型",
                    trigger: "blur",
                },
                title: {
                    required: true,
                    message: "请输入标题",
                    trigger: "blur",
                },
                channel: {
                    required: true,
                    message: "请选择频道",
                    trigger: "blur",
                },
                author: {
                    required: true,
                    message: "请输入作者",
                    trigger: "blur",
                },
                content: {
                    required: true,
                    validator: checkFileType,
                    trigger: "blur",
                },
                coverimg: {
                    required: true,
                    message: "请选择图片",
                    trigger: "blur",
                },
                auditType: {
                    required: true,
                    message: "请选择状态",
                    trigger: "blur",
                },
            },

            channelList: [
                {
                    label: "经验分享",
                    value: "08",
                },
                {
                    label: "国医学院",
                    value: "09",
                },
                {
                    label: "康驰动态",
                    value: "10",
                },
                {
                    label: "皇家经典",
                    value: "11",
                },
                {
                    label: "医学思维",
                    value: "12",
                },
                {
                    label: "名家学说",
                    value: "13",
                },
            ],
        };
    },

    mounted() {
        this.getDataList();
    },

    computed: {
        getPositionZh() {
            return function (str, data) {
                for (let i in data) {
                    if (str == data[i].value) {
                        return data[i].label;
                    }
                }
            };
        },

        timeConvert() {
            //num:0 YYYY-MM-DD  num:1  YYYY-MM-DD hh:mm:ss // timestamp:时间戳
            return function (timestamp, num) {
                if (timestamp === 0) {
                    return "-";
                } else {
                    timestamp =
                        timestamp.length === 10 ? timestamp * 1000 : timestamp;
                    let date = new Date(timestamp);
                    let y = date.getFullYear();
                    let m = date.getMonth() + 1;
                    m = m < 10 ? "0" + m : m;
                    let d = date.getDate();
                    d = d < 10 ? "0" + d : d;
                    let h = date.getHours();
                    h = h < 10 ? "0" + h : h;
                    let minute = date.getMinutes();
                    let second = date.getSeconds();
                    minute = minute < 10 ? "0" + minute : minute;
                    second = second < 10 ? "0" + second : second;
                    if (num == 0) {
                        return y + "-" + m + "-" + d;
                    } else {
                        return (
                            y +
                            "-" +
                            m +
                            "-" +
                            d +
                            " " +
                            h +
                            ":" +
                            minute +
                            ":" +
                            second
                        );
                    }
                }
            };
        },

        // 根据str判断是音频还是h5 0 文章 1 视频
        checkTileType() {
            return function (str) {
                if (str) {
                    let start_str = str.substring(0, 4);
                    let end_str = str.substring(str.length - 3, str.length);
                    if (start_str === "http" && end_str === "mp4") {
                        return "1";
                    } else {
                        return "0";
                    }
                }
            };
        },
    },

    methods: {
        formData,
        saveDialogHandleClose() {
            this.$refs.saveForm.resetFields();
            this.saveDialogVisble = false;
        },
        getDataList() {
            this.searchForm.pageIndexB1 = this.pageInit.currentPage;
            this.searchForm.pageSize = this.pageInit.pageSize;
            KcNewsApi.pageList(this.searchForm).then((res) => {
                if (!res.data.code === 0) return;
                this.articleTableData = res.data.body.content;
                this.pageInit.total = res.data.body.total;

                for (var i = 0; i < this.articleTableData.length; i++) {
                    this.articleTableData[i].type = this.checkTileType(
                        this.articleTableData[i].content
                    );
                }
            });
        },

        onSearchSubmit() {
            this.pageInit.currentPage = 1;
            this.getDataList();
        },

        // 监听排序输入框改变
        onChangeClick(row) {
            if (!/^\d+$/.test(Number(row.orderWeight))) {
                this.$message({
                    type: "error",
                    message: "请输入整数",
                });
                return;
            } else {
                let params = {
                    id: row.id,
                    weight: Number(row.orderWeight),
                };

                KcNewsApi.modifyWeight(params)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$message({
                                type: "success",
                                message: "操作成功",
                                duration: 1500,
                            });
                        } else {
                            this.$message({
                                type: "error",
                                message: res.data.msg,
                                duration: 1500,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },

        onAdd() {
            this.saveDialogVisble = true;
            this.saveDialogTitle = "新增";
            this.saveForm = {
                type: "1",
                title: "",
                channel: "",
                author: "",
                content: "",
                coverimg: "",
            };
        },

        onEidt(row) {
            this.saveDialogTitle = "修改";

            // 深拷贝
            let rowStr = JSON.stringify(row);
            this.saveForm = JSON.parse(rowStr);

            this.saveDialogVisble = true;
        },

        onSaveSubmit() {
            let s_message = this.saveDialogTitle + "成功";
            let e_message = this.saveDialogTitle + "失败";

            this.$refs.saveForm.validate((valid) => {
                if (!valid) return;
                this.$confirm("确认提交吗？", "提示", {}).then(() => {
                    KcNewsApi.saveRow(this.saveForm)
                        .then((res) => {
                            this.saveDialogVisble = false;
                            if (!res.data.code == 0) return;
                            this.$message({
                                message: s_message,
                                type: "success",
                                duration: 1500,
                            });
                            this.getDataList();
                        })
                        .catch((err) => {
                            console.log(err);
                            this.$message({
                                message: e_message,
                                type: "error",
                                duration: 1500,
                            });
                        });
                });
            });
        },

        onDelete(row) {
            var params = {
                id: row.id,
            };

            this.$confirm("确认删除吗？", "提示", {}).then(() => {
                KcNewsApi.deleteRow(params)
                    .then((res) => {
                        if (!res.data.code == 0) return;
                        this.$message({
                            message: "删除成功",
                            type: "success",
                            duration: 1500,
                        });
                        this.getDataList();
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$message({
                            message: "删除失败",
                            type: "error",
                            duration: 1500,
                        });
                    });
            });
        },

        showImg(row) {
            console.log(row);
            this.imgDialogUrl = row.coverimg;
            this.imgDialogVisible = true;
        },

        handleAvatarChange(file, fileList) {
            const formdata = new FormData();
            formdata.append("file", file.raw);

            var loading = this.$loading({
                lock: true,
                text: "图片上传中",
            });

            uploadPic(formdata)
                .then((res) => {
                    if (res.data.code === 0) {
                        this.saveForm.coverimg = res.data.body[0].filepath;
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.$nextTick(() => {
                        this.$forceUpdate();
                    });
                    loading.close();
                });
        },

        handleSizeChange(val) {
            this.pageInit.pageSize = val;
            this.getDataList();
        },
        handleCurrentChange(val) {
            this.pageInit.currentPage = val;
            this.getDataList();
        },

        // 改变文件类型选择器触发的change事件
        changeFileType(e) {
            this.saveForm = {
                type: e,
                title: "",
                channel: "",
                author: "",
                content: "",
                coverimg: "",
                desctiption: "",
            };
        },

        onEditorBlur(delta, oldDelta, source) {
            // 失去焦点事件
        },
        onEditorFocus(range, oldRange, source) {
            // 获得焦点事件
        },
        onEditorChange(eventName, ...args) {
            // 内容改变事件
        },
    },
};
</script>

<style scoped>
#desctiption .el-form-item {
    width: 90%;
}

/deep/ #desctiption .el-form-item__content {
    width: 100%;
}

/deep/ #desctiption .el-form-item__content {
    width: 100%;
}
</style>
