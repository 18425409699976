import { deletes, post, get } from "@/model/service";

export default class CoursePackageApi {
    static pageList(params = undefined) {
        return post('/service/course/findCoursePackage', params) 
    }

    static saveRow(params = undefined) {
        return post('/service/course/savePackage', params)
    }

    static deleteRow(params = undefined) {
        return get('/service/course/deleteById/'+params.id)
    }

    // 获取主讲人列表
    static getConsultList(params = undefined) {
        return post('/service/course/getConsultList', params)
    }

    // 获取目录标签列表
    static getCourseTagList(params = undefined) {
        return post('/service/course/getCourseTagList', params)
    }

    // 获取课程分类列表
    static getCourseClassify(params = undefined) {
        return post('/service/clg/tree', params)
    }

    // 获取课程范围列表
    static getRangeList(params = undefined) {
        return post('/service/course/couponRangeList', params)
    }

    // 获取课程树
    static getCourseTree(params = undefined) {
        return get('/service/course/getCourseTree')
    }

    // 获取已添加课程(课程树回显)
    static getCourseChildrenList(params = undefined) {
        return get('/service/course/getCourseChildrenList/'+params.id)
    }

    // 获取课程树子节点
    static getCourseTreeChild(params = undefined) {
        return get('/service/course/tree/'+params.id+'/children')
    }

    // 绑定课程包和课程的关系
    static addCourse(params = undefined) {
        return post('/service/course/addCourse', params)
    }

    // 删除课程包和课程的关系
    static deleteCourse(params = undefined) {
        return post('/service/course/deleteCourseBind', params)
    }

    // 获取课程包类型列表
    static getPackageType(params = undefined) {
        return get('/service/course/getPackageType', params)
    }

    // 上架下架
    static shelves(params = undefined) {
        return get('/service/course/shelves/'+params)
    }
}