import { deletes, post, get, postnoAuth } from '../../service'

export default class ProjectProductApi {
  // 列表
  static pageList(params = undefined) {
    return post('/service/project/findList', params)
  }

  // 删除
  static deleteRow(params = undefined) {
    return deletes('/service/project/deleteById/'+params.id)
  }

  // 保存修改
  static saveRow(params = undefined)  {
    return post('/service/project/save', params)
  }

  // 修改权重
  static changeWeight(params = undefined) {
    return get('/service/project/updateWeight/'+params.id+'/'+params.weight)
  }

  // 修改首页推荐
  static changeRecommend(params = undefined) {
    return get('/service/project/setRecommend/'+params.id+'/'+params.recommend)
  }

}
