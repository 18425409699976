const apiUrl ={
    getPagelist: `/TMtWxPublicController/getPagelist`, // get分页列表
    insert: `/TMtWxPublicController/insert`, // 新增
    update: `/TMtWxPublicController/update`, // 编辑
    rollbackData: `/TMtWxPublicController/rollbackByKey`, // 回滚数据显示
    insertWxMenuButton: `/TMtWxPublicMenuController/insert`,
    getMenuAPI: `/TMtWxPublicMenuController/getWxMenulist`,
    delete: `/TMtWxPublicMenuController/delete`
}

export default apiUrl