<template>
  <d2-container>
    <!--查询条件配置组件-->
    <!-- <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="5"
      :labelWidth="'70px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config> -->
    <!-- 添加导航按钮 -->
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        添加主讲人
      </el-button>
    </div>
    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:avatar="{ row }">
          <div @click="showImg(row)" style="margin-top: 7px; cursor: pointer">
            <img :src="row.avatar" width="30" height="20" class="image" />
          </div>
        </template>
        <template v-slot:checkIn="{ row }">
          {{row.checkIn == 0 ? '否': '是'}}
        </template>
      </free-table>
    </div>
    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
    />
    <el-dialog
      width="20%"
      class="el-dialog-col-2"
      :visible.sync="dialogVisible"
      :close-on-click-modal="true"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </d2-container>
</template>
<script>
let id = 1000;
//引入查询条件配置组件
import SearchConfig from "@/views/components/searchConfigForm";
import FreeTable from "@/views/components/FreeTable";
//引入页面层级组件
import AddSub from "./components/addSub";
//引入接口
import SpeakerManageApi from "@/model/modules/speakerManage";

export default {
  name: "roleinfo",
  components: {
    SearchConfig,
    FreeTable,
    AddSub,
  },
  data() {
    return {
      speakerList: [], //专家用户列表
      titleSrc: "",
      searchConfigData: [
        {
          label: "导航名称",
          formItemId: 0,
          placeholder: "请输入导航名称",
          type: "input",
          prop: "fNaviName",
        },
        {
          label: "显示位置",
          formItemId: 1,
          placeholder: "请选择",
          type: "select",
          isApi: true,
          prop: "fShowPosition",
          options: [],
        },
      ],
      form: {},
      config: [
        {
          label: "专家用户",
          type: "select",
          prop: "uid",
          filterable: 'filterable',
          options: []
        },
        {
          label: "头衔",
          type: "input",
          prop: "title",
        },
        {
          label: "个人说明",
          type: "input",
          prop: "description",
        },
        {
          label: "是否入住",
          type: "select",
          prop: "checkIn",
          options: [
            {
              label: '否',
              value: 0
            },
            {
              label: '是',
              value: 1
            },
          ]
        },
        {
          label: "头像",
          type: "uploadAvatar",
          prop: "avatar",
        },
      ],
      data: [], //表格数据渲染
      slotColumn: [
        //{ label: "选择", prop: "selection", type: "selection" },
        { label: '序号', prop: 'index', type: 'index' },
        { label: "专家用户", prop: "speakerName" },
        { label: "头像", prop: "avatar", slotScope: true },
        { label: "头衔", prop: "title" },
        { label: "个人说明", prop: "description" },
        { label: "是否入住", prop: "checkIn", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  编辑
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row);
                  }}
                >
                  删除
                </el-button>
              </div>
            );
          },
        },
      ],
      formSetData: {
        // 查询搜索内容
        pageIndexB1: Number,
        pageSize: Number
      },
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true,
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 1,
      },
      dialogImageUrl: "",
      dialogVisible: false,
      // 表单验证
      rules: {
        title: [
          { required: true, message: "请输入头衔", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入个人说明", trigger: "blur" },
        ],
        avatar: [
          { required: true, message: "请选择图片", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getDataList();
    this.getSpeakerList()
    this.config[0].options = this.a
  },
  methods: {
    // 获取专家用户列表
    getSpeakerList(){
      SpeakerManageApi.getSpeakerList().then(res => {
        if(res.data.code == 0){
          this.speakerList = res.data.body.map(item => {
            let obj = {}
            obj.label = item.label
            obj.value = item.code
            return obj
          })
          // console.log(this.speakerList)
          this.config[0].options = this.speakerList
        }
      })
    },
    //  图片放大
    showImg(row) {
      this.dialogImageUrl = row.avatar;
      this.dialogVisible = true;
    },
    //初始化表格渲染
    async getDataList() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      let res = await SpeakerManageApi.pageList(this.formSetData);
      this.data = res.data.body.content;
      this.pagination.total = res.data.body.total * 1;
    },
    //提交编辑表格行信息
    async onEditSubmit(e) {
      if (this.titleSrc == "主讲人修改") {
        let form = {
          ...e.form,
        };
        SpeakerManageApi.saveRow(form)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 1500,
              });
              this.getDataList();
            } else {
              console.error(res);
              this.$message.error("修改失败");
            }
          })
          .catch((err) => {
            console.error(err);
            this.$message.error("修改失败");
          });
      } else if (this.titleSrc == "主讲人添加") {
        this.form = e.form;
        SpeakerManageApi.saveRow(this.form)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "添加成功",
                type: "success",
                duration: 1500,
              });
              this.pagination.pageNum = 1;
              (this.form = {
                naviName: "",
                imgUrl: "",
                linkUrl: "",
                showPosition: "",
                status: "",
              }),
                this.getDataList();
            } else {
              console.error(res);
              this.$message.error("添加失败");
            }
          })
          .catch((err) => {
            console.error(err);
            this.$message.error("添加失败");
          });
      }
    },
    //打开编辑弹窗
    openDialog({ row }) {
      this.form = {
        ...row,
      };

      // 判断下拉选项中是否有对应的值，如果没有的话，显示空
      var flag = 0
      for(var i=0; i<this.speakerList.length; i++){
        if(this.form.uid == this.speakerList[i].value){
          flag = 1
          break
        }
      }
      if(flag == 0){
        this.form.uid = ''
        this.form.speakerName = ''
      }

      this.$refs.addSubDialog.showDialog(this.form);
      this.titleSrc = "主讲人修改";
    },
    onAdd() {
      this.form = {}
      this.$refs.addSubDialog.showDialog(this.form);
      this.titleSrc = "主讲人添加";
    },
    handleDelete(row) {
      this.$confirm("确认删除吗？", "提示", {}).then(() => {
        let params = {
          id: row.id,
          status: 0
        }
        SpeakerManageApi.deleteRow(params)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "删除成功",
                type: "success",
                duration: 1500,
              });
              this.getDataList();
            } else {
              console.error(res);
              this.$message.error("删除失败");
            }
          })
          .catch((err) => {
            console.error(err);
            this.$message.error("删除失败");
          });
      });
    },
    //表格查询按钮
    onQuery() {
      this.pagination.pageNum = 1;
      this.getDataList();
    },
    //分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getDataList();
    },
    handleFocus(e) {
      this.config[e.index].options = this.fShowPositions;
    },
    handleQueryFocus(e) {
      this.searchConfigData[e.index].options = this.fShowPositions;
    },
  },
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
