<template>
  <d2-container>
    <el-card style="width: 500px; margin: 0 auto">
      <el-form label-width="80px" :model="data" :rules="rules" ref="data">
        <el-form-item label="活动sign" prop="sign">
          <el-input v-model="data.sign"></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="data.title"></el-input>
        </el-form-item>
        <el-form-item label="跳转目标" prop="linkUrl">
          <el-input v-model="data.linkUrl"></el-input>
        </el-form-item>
        <el-form-item label="弹窗次数" prop="popCount">
          <el-input prop="popCount" type="number" min="1" v-model="data.popCount"></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="图片" prop="imgUrl">
              <el-row>
                <el-upload
                  class="avatar-uploader"
                  action="http://dev.kcguoyi.com/service/upload/huawei"
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-change="
                    (file, fileList) => {
                      handleAvatarChange(file, fileList, 'imgUrl');
                    }
                  "
                >
                  <el-image
                    fit="cover"
                    v-if="data.imgUrl"
                    :src="data.imgUrl"
                    class="avatar"
                    style="width: 120px; height: 120px"
                  ></el-image>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-row>

              <el-row style="text-align: center" v-if="data.imgUrl">
                <el-button type="text" @click="previewImg(data.imgUrl)">预览</el-button>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item style="text-align: center">
            <el-button type="primary" @click="onSubmit">提 交</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </el-card>

    <el-image-viewer v-if="showViewer" :on-close="() => {showViewer = false} " :url-list="[imgViewerSrc]">

    </el-image-viewer>
  </d2-container>
</template>

<script>
//引入接口
import CommonPopupSet from "@/model/modules/commonPopupSet";
import { uploadPic } from "@/model/modules/user";

// 引入组件 图片预览
import ElImageViewer from "element-ui/packages/image/src/image-viewer.vue"

export default {
  components: { ElImageViewer },

  data() {
    return {
      showViewer: false, //控制ElImageViewer的显示隐藏
      imgViewerSrc: '', //图片预览路径
      data: {
        title: '',
        linkUrl: '',
        popCount: 1,
        imgUrl: '',
        sign:''
      },
      rules: {
        imgUrl: [{ required: true, message: "请选择图片", trigger: "blur" }],
        linkUrl: [{ required: true, message: "请输入跳转目标", trigger: "blur" }],
        popCount: [{ required: true, message: "请输入整数", pattern: /^-?\d+$/, trigger: "blur" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        sign: [{ required: true, message: "请输入活动sign", trigger: "blur" }],
      },
    };
  },

  mounted() {
    this.getInfo();
  },

  methods: {
    // 预览图片
    previewImg(src){
      this.imgViewerSrc = src
      this.showViewer = true
    },

    // 获取通用券弹窗信息
    getInfo() {
      CommonPopupSet.getInfo().then((res) => {
        // console.log(res)
        if (!res.data.code === 0) return;
          if(res.data.body){
            this.data = res.data.body;
          }
      });
    },

    // 上传图片
    handleAvatarChange(file, fileList, name) {
      const formdata = new FormData();
      formdata.append("file", file.raw);

      var loading = this.$loading({
        lock: true,
        text: "图片上传中",
      });

      uploadPic(formdata)
        .then((res) => {
          if (res.data.code == 0) {
            this.data[name] = res.data.body[0].filepath;

          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$nextTick(() => {
            this.$forceUpdate();
          });
          loading.close();
        });
    },

    // 提交表单
    onSubmit() {
      this.$refs.data.validate((valid) => {
        if (!valid) return;
        this.$confirm("确认提交吗？", "提示", {}).then(() => {
          CommonPopupSet.save(this.data)
            .then((res) => {
              // console.log(res)
              if (res.data.code == 0) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 1500,
                });
              }
              this.getInfo();
            })
            .catch((res) => {
              console.error(response);
              this.$message.error("修改失败");
            });
        });
      });
    },
  },
};
</script>

<style scoped>
.avatar-uploader {
  width: 120px;
  height: 120px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  line-height: 120px;
  width: 120px;
  height: 120px;
}
</style>
