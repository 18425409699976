import { deletes, post, get, postnoAuth } from '../../service'

export default class KcNewsApi {
  static pageList(params = undefined) {
    return post('/service/article/articleList', params)
  }

  static saveRow(params = undefined)  {
    return post('/service/article/save', params)
  }

  static deleteRow(params = undefined) {
    return get('/service/article/deleteById/'+params.id)
  }

  static changeStatus(params = undefined)  {
    return post('/service/api/article/audit', params)
  }

  // 排序
  static modifyWeight(params = undefined) {
    return post('/service/article/modifyWeight', params)
  }
}
