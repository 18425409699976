<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="4"
      :labelWidth="'90px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="handelCreateCode"
      >
        生成防伪码
      </el-button>
      <el-button
        type="success"
        size="mini"
        @click="handleCreateBatchCode"
      >
        批量生成防伪码
      </el-button>
      <el-button
        type="success"
        size="mini"
        @click="onExportCode"
      >
        导出二维码
      </el-button>
      <el-button
        type="danger"
        size="mini"
        @click="onSelecTabClick"
      >
        数据导入导出
      </el-button>
      <el-button
        type="warning"
        size="mini"
        @click="onSettingClick"
      >
        防伪码设置
      </el-button>
    </div>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        @selection-change="handleSelectionChange"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:checkStatus="{ row }">
          {{ row.checkStatus == 1 ? "已核销" : "未核销" }}
        </template>
      </free-table>
    </div>
    <!--生成防伪码弹窗-->
    <create-code
      ref="createcodebatch"
      :titleCode="'生成防伪码'"
      width="50%"
      align="left"
    ></create-code>
    <!--批量生成防伪码弹窗-->
    <create-batch-code
      ref="createcodebatchCount"
      :titleCode="'批量生成防伪码'"
      width="50%"
      align="left"
    ></create-batch-code>
    <!--导出二维码-->
    <export-qr-code
      ref="exportcodebatch"
      :titleCode="'导出二维码'"
      width="50%"
      align="left"
    ></export-qr-code>
    <!--防伪码数据导入/导出-->
    <tab-select-data
      ref="selectTabData"
      :titleCode="'防伪码数据导入导出'"
      width="50%"
      align="left"
    >
    </tab-select-data>
    <!--防伪码设置弹窗-->
    <code-setting
      ref="codeSetting"
      :titleCode="'防伪码设置'"
      width="50%"
      align="left"
    ></code-setting>
    <!--查看详情-->
    <row-detail 
      ref="checkDetail"
      :titleCode="'查看详情'"
      width="65%"
      align="left"
    ></row-detail>
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'
// 引入页面层级组件
import CreateCode from './components/createCode.vue'
import CreateBatchCode from './components/createBatchCode.vue'
import ExportQrCode from './components/exportQrCode.vue'
import TabSelectData from './components/tabSelectData.vue'
//防伪码设置弹窗
import CodeSetting from './components/codeSetting'
//查看详情
import RowDetail from './components/rowDetail'
export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
    FreeTable,
    CreateCode,
    CreateBatchCode,
    ExportQrCode,
    TabSelectData,
    CodeSetting,
    RowDetail
  },
  data () {
    return {
      titleSrc: '新增课程',
      fShowPositions: [],
      fShowCourseCatalog: [],
      fShowSpeaker: [],
      fShowRangeList: [],

      // 搜索框渲染
      formSetData: {
        courseName: null,
        fullname: null,
        pageIndexB1: Number,
        pageSize: Number,
        status: '',
        position: ''
      },
      searchConfigData: [
        {
          label: '防伪码',
          formItemId: 0,
          placeholder: '请输入课程名称',
          type: 'input',
          prop: 'courseName'
        }
      ],
      // 表格数据渲染
      data: [
        {
          id:'111',
          productid:'2222',
          qrcode:'ivdic',
          batchid:'6788887',
          bactchnum:'ijji444',
          code:"ovkovdk",
          count:521656,
          addTime:4444545,
          checkStatus:1
        }
      ],
      slotColumn: [
        { label: '选择', prop: 'selection', type: 'selection' },
        { label: '序号', prop: 'index',type:'index' },
        { label: 'id', prop: 'id' },
        { label: '商品id', prop: 'productid', sort: true },
        { label: '二维码', prop: 'qrcode', slotScope: true },
        { label: '批次id', prop: 'batchid' },
        { label: '批次号', prop: 'bactchnum'},
        { label: '防伪码', prop: 'code'},
        { label: '查询次数', prop: 'count', sort: true},
        { label: '增加时间', prop: 'addTime', sort: true},
        { label: '核销状态', prop: 'checkStatus', slotScope: true},
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '170px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onbtnDetailClick(scope.row)
                  }}
                >
                  查询详情
                </el-button>
              </div>
            )
          }
        }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },

      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      },

      // 新增课程
      form: {
        courseName: '',
        cover: '',
        uid: '',
        courseCount: '',
        amount: '',
        tag: [],
        courseText: '',
        courseCateId: ''
      },
      config: [
        [
          {
            colSpan: 12,
            label: '课程名',
            type: 'input',
            prop: 'courseName'
          },
          {
            colSpan: 12,
            label: '主讲人',
            type: 'select',
            prop: 'uid',
            isApi: true,
            options: []
          },
          {
            colSpan: 12,
            label: '课时',
            type: 'input',
            prop: 'courseCount'
          },
          {
            colSpan: 12,
            label: '价格',
            type: 'input',
            prop: 'amount'
          },
          {
            colSpan: 12,
            label: '目录标签',
            type: 'select',
            prop: 'tag',
            isApi: true,
            multiple: true,
            options: []
          },
          {
            colSpan: 12,
            label: '选择课程分类',
            type: 'select',
            prop: '',
            isApi: true,
            options: []
          },
          {
            colSpan: 12,
            label: '选择课程范围',
            type: 'select',
            prop: 'courseCateId',
            isApi: true,
            options: []
          }
        ],
        [
          {
            colSpan: 20,
            label: '课程封面',
            type: 'uploadAvatar',
            prop: 'cover'
          }
        ],
        [
          {
            colSpan: 24,
            label: '课程介绍',
            type: 'quill',
            prop: 'courseText'
          }
        ]
      ],
      // 表单验证
      rules: {
        courseName: [
          { required: true, message: '请输入课程名', trigger: 'blur' }
        ],
        cover: [{ required: true, message: '请输入课程封面', trigger: 'blur' }],
        uid: [{ required: true, message: '请选择主讲人', trigger: 'blur' }],
        courseCount: [
          { required: true, message: '请输入总章节数', trigger: 'blur' }
        ],
        amount: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        tag: [{ required: true, message: '请选择目录', trigger: 'blur' }],
        courseCateId: [
          { required: true, message: '优惠券使用范围', trigger: 'blur' }
        ],
        courseText: [
          { required: true, message: '请输入课程介绍', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
  },
  methods: {
    //导出二维码
    onExportCode(){
      this.$refs.exportcodebatch.showDialog()
    },
    //生成防伪码
    handelCreateCode(){
      this.$refs.createcodebatch.showDialog()
    },
    handleCreateBatchCode(){
      this.$refs.createcodebatchCount.showDialog()
    },
    //导入导出数据
    onSelecTabClick(){
      this.$refs.selectTabData.showDialog()
    },
    //防伪码设置
    onSettingClick(){
      this.$refs.codeSetting.showDialog()
    },
    handleFocus (e) {},
    // 选中
    handleSelectionChange(val) {
      console.log('选中:', val)
    },
    //查看详情
    onbtnDetailClick(row){
       this.$refs.checkDetail.showDialog()
    }

  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
