<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5.8"
      :btnColspan="4"
      :labelWidth="'90px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        新增课程
      </el-button>
      <el-button
        type="success"
        size="mini"
        @click="onUpClick"
      >
        批量上架
      </el-button>
      <el-button
        type="success"
        size="mini"
        @click="onDownClick"
      >
        批量下架
      </el-button>
    </div>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        @selection-change="handleSelectionChange"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:status="{ row }">
          {{ row.status == 1 ? "已上架" : "未上架" }}
        </template>
      </free-table>
    </div>
    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
      @on-handle-select="handleSelect"
    />

    <!-- 课程预览弹窗 -->
    <el-dialog
      title="预览"
      :visible.sync="diaVisiblePreCourse"
      v-bind="$attrs"
    >
      <div class="preCourse_item">
        <div class="preCourse_item_title">课程名称：</div>
        <div>{{form.courseName}}</div>
      </div>

      <div class="preCourse_item">
        <div class="preCourse_item_title">课程封面：</div>
        <img :src="form.cover" alt="">
      </div>

      <div class="preCourse_item">
        <div class="preCourse_item_title">课程介绍：</div>
        <div v-html="form.courseText"></div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="diaVisiblePreCourse=false">关闭</el-button>
      </div>
    </el-dialog>

    <!-- 推荐课程 -->
    <recommended-courses
      ref="recommendedCourses"
      :positions="fShowPositions"
      @on-edit-submit="onEditSubmit"
    >
    </recommended-courses>
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'
// 引入页面层级组件
import AddSub from './components/addSub'
import RecommendedCourses from './components/recommendedCourses'

// 引入接口
import {
  getPosition,
  pageList,
  batchShelves,
  getAddModify,
  getCourseCatalog,
  menuList,
  getSpeaker,
  getRangeList,
  setRecommendCourse
} from '@/model/modules/course'

export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
    FreeTable,
    AddSub,
    RecommendedCourses
  },
  data () {
    // var checkImg = (ruel, value, callback) => {
    //   console.log(value);
    //   if(value){
    //     return callback()
    //   }else {
    //     return callback(new Error('请选择图片'))
    //   }
    // }

    return {
      diaVisiblePreCourse: false,
      titleSrc: '新增课程',
      fShowPositions: [],
      // fShowCourseCatalog: [],
      // fShowSpeaker: [],
      // fShowRangeList: [],
      fShowCurricularTaxonomy: [],
      selectId: [],

      // 搜索框渲染
      formSetData: {
        courseName: null,
        fullname: null,
        pageIndexB1: Number,
        pageSize: Number,
        status: '',
        position: ''
      },
      searchConfigData: [
        {
          label: '课程名',
          formItemId: 0,
          placeholder: '请输入课程名称',
          type: 'input',
          prop: 'courseName'
        },
        {
          label: '主讲人姓名',
          formItemId: 1,
          placeholder: '请输入登录名',
          type: 'input',
          prop: 'fullname'
        },
        {
          label: '课程状态',
          formItemId: 2,
          placeholder: '选择课程状态',
          type: 'select',
          isApi: false,
          prop: 'status',
          options: [
            {
              label: '已上架',
              value: 1
            },
            {
              label: '未下架',
              value: 0
            }
          ]
        },
        // {
        //   label: '菜单位置',
        //   formItemId: 3,
        //   placeholder: '请选择菜单位置',
        //   type: 'select',
        //   isApi: true,
        //   prop: 'position',
        //   options: []
        // },
        {
          label: '时间',
          formItemId: 3,
          type: 'multipleBoxes',
          configDatas: [
            {
              label: '',
              formItemId: 0,
              placeholder: '请选择',
              type: 'select',
              prop: '',
              isApi: false,
              filterable: false,
              multiple: false,
              options: [
                {
                  label: '创建时间',
                  value: '0'
                },
                {
                  label: '上架时间',
                  value: '1'
                }
              ]
            },
            {
              label: '',
              formItemId: 1,
              placeholder: '开始时间',
              type: 'timePicker',
              prop: '',
              optionType: 'date'
            },
            {
              label: '',
              formItemId: 2,
              placeholder: '结束时间',
              type: 'timePicker',
              prop: '',
              optionType: 'date'
            }
          ]
        },
      ],

      // 表格数据渲染
      data: [],
      slotColumn: [
        { label: '选择', prop: 'selection', type: 'selection' },
        { label: 'id', prop: 'id', width: '300px' },
        { label: '课程名称', prop: 'courseName' },
        { label: '主讲人', prop: 'fullname' },
        { label: '价格', prop: 'amount', sort: true },
        { label: '支持单节购买', prop: 'singleStatusName', sort: true },
        { label: '单节价格', prop: 'singlePrice', sort: true },
        { label: '每讲价格', prop: 'price', sort: true },
        { label: '课时', prop: 'courseCount', sort: true },
        { label: '视频数', prop: 'upload', sort: true },
        { label: '创建时间', prop: 'ctm', sort: true },
        { label: '上架时间', prop: 'shelvesTm', sort: true },
        { label: '状态', prop: 'status', slotScope: true },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '420px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.btnClick(1, scope.row)
                  }}
                >
                  视频管理
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.btnClick(2, scope.row)
                  }}
                >
                  设置推荐
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.btnClick(3, scope.row)
                  }}
                >
                  {scope.row.status === 0 ? '上架' : '下架'}
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.btnClick(4, scope.row)
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.btnClick(5, scope.row)
                  }}
                >
                  预览
                </el-button>

              </div>
            )
          }
        }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },

      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        total: 1
      },

      // 新增课程
      form: {
        courseName: '',
        detailCover: '',
        mindMap: '',
        cover: '',
        uid: '',
        courseCount: '',
        amount: '',
        tag: [],
        ctlId: [],
        courseText: '',
        cateCode: '',
        singlePrice:'',
        DJPrice:'单节同价',
        singleStatus:''
      },
      config: [
        [
          {
            colSpan: 12,
            label: '课程名',
            type: 'input',
            prop: 'courseName'
          },
          {
            colSpan: 12,
            label: '主讲人',
            type: 'select',
            filterable: true,
            prop: 'uid',
            isApi: true,
            options: []
          },
          {
            colSpan: 12,
            label: '课时',
            type: 'input',
            prop: 'courseCount'
          },
          {
            colSpan: 12,
            label: '价格',
            type: 'input',
            prop: 'amount'
          },
          {
            colSpan: 12,
            label: '目录标签',
            type: 'select',
            prop: 'tag',
            isApi: true,
            multiple: true,
            multipleLimit: 2,
            options: []
          },
          {
            colSpan: 12,
            label: '选择课程分类',
            type: 'cascader',
            prop: 'ctlId',
            placeholder: '请选择',
            options: []
          },
          {
            colSpan: 12,
            label: '选择课程范围',
            type: 'select',
            prop: 'cateCode',
            isApi: true,
            options: []
          },
          {
            colSpan: 12,
            label: '每讲价格',
            type: 'input',
            prop: 'price'
          },
          {
            colSpan: 24,
            label: '单节购买',
            type: 'select',
            prop: 'singleStatus',
            isApi: true,
            placeholder: '请选择是否支持单节购买',
            options: [
              {value:0,label:"是"},
              {value:1,label:"否"}
            ]
          },
        ],
        [
          {
            colSpan: 8,
            label: '课程封面',
            type: 'uploadAvatar',
            prop: 'cover'
          },
          {
            colSpan: 8,
            label: '课程详情封面',
            type: 'uploadAvatar',
            prop: 'detailCover'
          },
          {
            colSpan: 8,
            label: '思维导图',
            type: 'uploadAvatar',
            prop: 'mindMap'
          }
        ],
        [
          {
            colSpan: 24,
            label: '课程介绍',
            type: 'quill',
            prop: 'courseText'
          }
        ]
      ],
      // 表单验证
      rules: {
        courseName: [{ required: true, message: '请输入课程名', trigger: 'blur' }],
        cover: [{ required: true, message: '请输入课程封面', trigger: 'blur' }],
        // detailCover: [{ required: true, validator: checkImg, trigger: 'blur' }],
        // detailCover: [{ required: true, message: '请输入课程详情封面', trigger: 'change' }],
        uid: [{ required: true, message: '请选择主讲人', trigger: 'blur' }],
        courseCount: [{ required: true, message: '请输入总章节数', trigger: 'blur' }],
        amount: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        tag: [{ required: true, message: '请选择目录', trigger: 'blur' }],
        ctlId: [{ required: true, message: '选择课程分类', trigger: 'blur' }],
        cateCode: [{ required: true, message: '优惠券使用范围', trigger: 'blur' }],
        courseText: [{ required: true, message: '请输入课程介绍', trigger: 'blur' }],
        singleStatus: [{ required: true, message: '请选择是否支持单节购买', trigger: 'blur' }],
      }
    }
  },
  mounted () {
    const menu_course_cache = localStorage.getItem('menu_course');
    if(menu_course_cache){
      this.formSetData = JSON.parse(menu_course_cache);
    }
    this.getPosition()
    this.getPullDown()
    this.getCourseList()
  },
  methods: {
    // 位置
    async getPosition () {
      const res = await getPosition()
      this.fShowPositions = this.formatData(
        res.data.body,
        'itemCode',
        'itemName'
      )
    },
    handleQueryFocus (e) {
      this.searchConfigData[e.index].options = this.fShowPositions
    },

    async getPullDown () {
      const speaker = await getSpeaker()
      const courseCatalog = await getCourseCatalog()
      const rangeList = await getRangeList()

      const curricularTaxonomy = await menuList()
      let data = curricularTaxonomy.data.body[0].children[3].children
      data.map((item, index) => {
        let info = {}
        info['value'] = item.id
        info['label'] = item.clgName

        if (item.children != undefined) {
          let data1 = item.children
          info['children'] = []
          data1.map((item1, index1) => {
            let info1 = {}
            info1['value'] = item1.id
            info1['label'] = item1.clgName
            info['children'].push(info1)

            if (item1.children != undefined) {
              let data2 = item1.children
              info.children[index1]['children'] = []
              data2.map((item2, index2) => {
                let info2 = {}
                info2['value'] = item2.id
                info2['label'] = item2.clgName
                info.children[index1]['children'].push(info2)
              })
            }

          })
        }

        this.fShowCurricularTaxonomy.push(info)
      })

      this.config.map((item, index) => {
        item.map((n, j) => {
          if (n.label === '主讲人') {
            n.options = this.formatData(speaker.data.body, 'uid', 'fullname')
          }
          if (n.label === '目录标签') {
            n.options = this.formatData(
              courseCatalog.data.body,
              'id',
              'clgName'
            )
          }
          if (n.label === '选择课程范围') {
            n.options = this.formatData(
              rangeList.data.body,
              'itemCode',
              'itemName'
            )
          }
          if (n.label === '选择课程分类') {
            n.options = this.fShowCurricularTaxonomy
            // console.log('选择课程分类', n)
          }
        })
      })
    },

    handleFocus (e) {
      // console.log(e)
    },
    handleSelect (e) {
      const currentArr = this.config[0].filter(ele=>ele.prop == 'DJPrice');
      if(e==0){
        if(currentArr.length == 0){
          this.config[0].push({colSpan:12,label:'单节价格',type:'input',prop:'DJPrice',disabled:true})
          this.config[0].push({colSpan:12,label:'每节价格',type:'input',prop:'singlePrice'})
        }
      }else{
        if(currentArr.length != 0){
          this.config[0].pop()
          this.config[0].pop()
        }
      }

    },
    // 列表数据
    async getCourseList () {
      this.formSetData.pageIndexB1 = this.pagination.pageNum
      this.formSetData.pageSize = this.pagination.pageSize
      const res = await pageList(this.formSetData)
      this.data = res.data.body.content
      this.data.forEach(ele=>{
        ele.singleStatusName = ele.singleStatus==0?"是":"否";
      })
      this.pagination.total = res.data.body.total * 1
    },
    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.getCourseList()
    },
    // 选中
    handleSelectionChange(val) {
      this.selectId = []
      val.map(item => {
        this.selectId.push(item.id)
      })
    },

    // 新增课程
    onAdd () {
      this.form = {
        courseName: '',
        detailCover: '',
        mindMap: '',
        cover: '',
        uid: '',
        courseCount: '',
        amount: '',
        tag: [],
        ctlId: [],
        courseText: '',
        singleStatus:1,
        DJPrice:'单节同价',
        singlePrice:''
      }
        const currentArr = this.config[0].filter(ele=>ele.prop == 'DJPrice');
        if(currentArr.length != 0){
          this.config[0].pop()
          this.config[0].pop()
        }
  
      this.$refs.addSubDialog.showDialog(this.form)
      this.titleSrc = '新增课程'
    },
    // 提交编辑表格行信息
    onEditSubmit (e) {
      let is_titleSrc = '修改课程' + '（' + e.form.id + '）'

      if (this.titleSrc === is_titleSrc || this.titleSrc === '新增课程') {
        let tag = ''
        e.form.tag.map((item, index) => {
          tag += item + ','
        })
        tag = tag.substring(0, tag.length - 1)
        e.form.tag = tag

        e.form.ctlId = e.form.ctlId[e.form.ctlId.length-1]

        // 判断思维导图字段是否为空
        if(!e.form.mindMap){
          delete e.form.mindMap
        }
        delete e.form.DJPrice;
        getAddModify(e.form).then((res) => {
          if (res.data.code === 0) {
            this.getCourseList()
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
      } else if (this.titleSrc === '设置推荐课程') {
        setRecommendCourse(e.form).then((res) => {
          if (res.data.code === 0) {
            this.getCourseList()
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    // 批量上架
    onUpClick() {
      let info = {
        id: this.selectId,
        status: 1
      }
      let msg = '确认批量上架课程么？'
      this.batchShelves(msg, info)
    },
    // 批量下架
    onDownClick() {
      let info = {
        id: this.selectId,
        status: 0
      }
      let msg = '确认批量下架课程么？'
      this.batchShelves(msg, info)
    },
    batchShelves(msg, param) {
      this.$confirm(msg, '提示', {}).then(() => {
        batchShelves(param).then(res => {
          if (res.data.code === 0) {
            this.getCourseList()
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }).catch(() => {})
    },

    formatData (data, key, val) {
      const obj = []
      for (const i in data) {
        const item = data[i]
        obj[i] = {
          value: item[key] || '',
          label: item[val] || ''
        }
      }
      return obj
    },

    // 表格查询按钮
    onQuery () {
      localStorage.setItem('menu_course',JSON.stringify(this.formSetData));
      this.pagination.pageNum = 1
      this.getCourseList()
    },
    btnClick (index, e) {
      switch (index) {
        case 1: // 视频管理
          this.$router.push({
            path: '/modCourse/courseVideo',
            query: {
              form: e
            }
          })
          break
        case 2: // 设置推荐
          this.$refs.recommendedCourses.showDialog(e)
          this.titleSrc = '设置推荐课程'
          break
        case 3: // 上下架
          const mess = e.status ? '确认下架课程：'+e.courseName+'么？' : '确认上架课程：'+e.courseName+'么？'
          this.$confirm(mess, '提示', {})
            .then(() => {
              if (e.tag !== undefined) {
                if (typeof e.tag !== 'string') {
                  let tag = ''
                  e.tag.map((item, index) => {
                    tag += item + ','
                  })
                  tag = tag.substring(0, tag.length - 1)
                  e.tag = tag
                }
              }
              e.status = e.status ? 0 : 1
              getAddModify(e).then((res) => {
                if (res.data.code === 0) {
                  this.getCourseList()
                  this.$message({
                    message: '操作成功',
                    type: 'success',
                    duration: 1500
                  })
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            })
            .catch(() => {})
          break
        case 4: // 修改
          if (e.tag !== undefined) {
            if (typeof e.tag === 'string') {
              const tag = []
              e.tagList.map((item, index) => {
                e.tag.split(',').map((n, i) => {
                  if (item.id === n) {
                    tag[i] = item.id
                  }
                })
              })
              e.tag = tag
            }
          }

          if (e.ctlModel != undefined) {
            e.ctlId = []
            e.ctlId[0] = e.ctlModel.oneId
            e.ctlId[1] = e.ctlModel.twoId
            e.ctlId[2] = e.ctlModel.threeId
          }

          this.form = {
            ...e
          }
          this.form.DJPrice = '单节同价';
          const currentArr = this.config[0].filter(ele=>ele.prop == 'singlePrice');
          if(this.form.singleStatus == 0&&currentArr.length==0){
            this.config[0].push({colSpan:12,label:'单节价格',type:'input',prop:'DJPrice',disabled:true})
            this.config[0].push({colSpan:12,label:'每节价格',type:'input',prop:'singlePrice'})
          }else if(this.form.singleStatus == 1&&currentArr.length!=0){
            this.config[0].pop()
            this.config[0].pop()
          }
          this.$refs.addSubDialog.showDialog(this.form)
          this.titleSrc = '修改课程' + '（' + this.form.id + '）'
          break
        case 5: //预览
          this.form = {
            ...e
          }
          this.diaVisiblePreCourse = true
          break
        default:
          this.$message.error('未定义')
          break
      }
    }
  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
