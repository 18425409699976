var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d2-container',[_c('search-config',{ref:"searchConfig",attrs:{"configData":_vm.searchConfigData,"formSet":_vm.formSetData,"colspan":5.8,"btnColspan":5,"labelWidth":'80px'}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onQuery}},[_vm._v("查询")])],1),_c('div',{staticClass:"mt20"},[_c('div',{staticStyle:{"height":"39px"}},[_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"payAmount"},[_vm._v("订单总价："),_c('span',{staticStyle:{"color":"#d9001b"}},[_vm._v(_vm._s(_vm.price))]),_vm._v(" 元")])]),_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"payAmount"},[_vm._v("应付金额："),_c('span',{staticStyle:{"color":"#d9001b"}},[_vm._v(_vm._s(_vm.cost))]),_vm._v(" 元")])]),_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"payAmount"},[_vm._v("三方手续费："),_c('span',{staticStyle:{"color":"#d9001b"}},[_vm._v(_vm._s(_vm.serviceChange))]),_vm._v(" 元")])]),_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"payAmount"},[_vm._v("公司拟得金额："),_c('span',{staticStyle:{"color":"#d9001b"}},[_vm._v(_vm._s(_vm.companyMoney))]),_vm._v(" 元")])])],1),_c('free-table',{attrs:{"border":"","data":_vm.data,"column":_vm.slotColumn,"columnsProps":_vm.columnsProps,"pagination":"","auto-scroll":false,"pageSizes":_vm.pagination.pageSizes,"total":_vm.pagination.total,"page":_vm.pagination.pageNum,"limit":_vm.pagination.pageSize},on:{"update:total":function($event){return _vm.$set(_vm.pagination, "total", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "pageNum", $event)},"update:limit":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"pagination":_vm.getList},scopedSlots:_vm._u([{key:"couponPrice",fn:function(ref){
var row = ref.row;
return [(row.couponPrice)?_c('div',[_vm._v(_vm._s(row.couponPrice)+"元")]):_vm._e()]}},{key:"priceLabel",fn:function(ref){
var row = ref.row;
return [(row.priceLabel)?_c('div',[_vm._v(_vm._s(row.priceLabel)+"元")]):_vm._e()]}},{key:"costLabel",fn:function(ref){
var row = ref.row;
return [(row.costLabel)?_c('div',[_vm._v(_vm._s(row.costLabel)+"元")]):_vm._e()]}},{key:"ctm",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.timeConvert(row.ctm, 1))+" ")]}},{key:"createPaymentTm",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.timeConvert(row.createPaymentTm, 1))+" ")]}},{key:"paymentTm",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.timeConvert(row.paymentTm, 1))+" ")]}}])})],1),_c('particulars',{ref:"particularsSubDialog",attrs:{"titleSrc":_vm.titleSrc}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }