
/**
 * 课程
 */
const apiUrl = {

  // 课程管理
  getPosition: '/service/course/getCoursePosition',
  getQueryCriteria: '/service/course/getCourseList',
  getAddModify: '/service/course/saveCourse',
  getCourseCatalog: '/service/course/getCourseTagList',
  getSpeaker: '/service/course/getConsultList',
  getRangeList: '/service/course/couponRangeList',
  setRecommendCourse: '/service/course/setRecommendCourse',
  batchShelves: '/service/course/batchShelves', //批量上架、下架
  getMenuList: '/service/clg/tree', // 选择课程分类
  // 视频管理
  getCourseVideoList: '/service/course/getCourseVideoList',
  saveCourseVideo: '/service/course/saveCourseVideo',
  updateVideoNoById: '/service/course/updateVideoNoById',  //视频排序
  batchDelVideo: '/service/course/batchDelVideo', //批量删除
  

}

export default apiUrl
