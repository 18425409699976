
/**
 * 校验防伪码
 */
const apiUrl = {

  // 校验防伪码
  getValidate: '/jindan/antifakecode/validate'

}

export default apiUrl
