<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogFormVisible" width="60%">
      <div style="padding-top: 25px"></div>
      <div class="textBorderBottom">用户基本信息</div>

      <!-- 讲师审核 -->
      <el-form :model="form" label-width="100px" label-position="left">
        <el-row :gutter="4">
          <el-col :span="20">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="用户昵称:" prop="nickName">
                  {{form.nickName}}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="用户电话:" prop="tel">
                  {{form.tel}}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="身份证号:" prop="idNum">
                  {{form.idNum}}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="真实姓名:" prop="fullname">
                  {{form.fullname}}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" style="display:flex; justify-content:flex-start;align-items:flex-end">
              <el-col :span="20">
                <el-form-item label="用户备注:" prop="remark" style="margin-bottom:0;">
                  {{form.remark}}
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="4">
            <div>
              <img :src="form.avatarUrl" style="width: 95px;height: 95px;object-fit:cover;margin-left:10px;" />
            </div>
            <div>头像</div>
          </el-col>
        </el-row>
        <el-row>
          <div style="padding-top: 25px"></div>
          <div class="textBorderBottom">所属区域信息</div>
          <el-col :span="20">
            <el-form-item label="关联所属区域:" prop="adminDivisionStr">
              {{ form.adminDivisionStr }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="default" @click="dialogFormVisible=false">关闭</el-button>
        <el-button type="primary" @click="onEditSubmit(1)">审核不通过</el-button>
        <el-button type="primary" @click="onEditSubmit(0)">审核通过</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

// 引入接口
import { certificationAudit } from '@/model/modules/audit_user'

export default {
  props: {
    title: String
  },
  data () {
    return {
      dialogFormVisible: false,
      form: {}
    }
  },
  methods: {
    showDialog (param) {
      this.dialogFormVisible = true
      this.form = param
    },
    onEditSubmit (index) {
      const obj = {
        id: this.form.id,
        reason: '',
        type: ''
      }
      let mess = ''
      let showInput = ''

      if (index === 0) { // 通过
        obj.type = 0
        mess = '是否确认审核通过？通过审核后即视为认证成功。'
        showInput = false
      } else if (index === 1) { // 驳回
        obj.type = 1
        mess = '请输入批量驳回原因：'
        showInput = true
      }

      this.$prompt(mess, '提示', {
        showInput: showInput
      }).then(res => {
        obj.reason = res.value
        certificationAudit(obj).then(res => {
          if (res.data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500
            })
            this.dialogFormVisible = false
            this.$emit('refresh-details')
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }).catch(err => {})
    }
  }
}
</script>

<style scoped>
.textBorderBottom {
  width: 100%;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  padding-left: 13px;
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 10px;
}

.textBorderBottom:before {
  content: "";
  display: block;
  width: 3px;
  height: 20px;
  position: absolute;
  left: 0px;
  top: -2px;
  background-color: #409eff;
}

.flex_container{
  display: flex;
  justify-content:space-between;
  align-items: flex-start;
  margin-bottom:30px;
}

/deep/ .el-input__inner {
  width: 160px;
  font-size: 13px;
}
</style>
