const apiUrl = {
  // prefix: '/dicController',
  getTVipDicPage: '/service/DictCate/listByCateCodeAndName', // get分页列表
  getKeyDicPage: '/service/Dict/listByCateCode', // get分页列表
  insertTVipDicPage: '/service/DictCate/save', // 新增
  // updateTVipDicPage: '/dicController/updateDicPage', // 编辑
  updateKeyDicPage: '/service/Dict/save', // 编辑
  addKeyDicPage: '/service/Dict/save', // 新增子码
  // deleteTVipDicPage: '/dicController/deleteDicPage', // 删除
  // updateDicStatusPage: '/dicController/updateDicStatusPage' // 更新状态
}

export default apiUrl
