<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5.8"
      :btnColspan="5"
      :labelWidth="'80px'"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <div>
      <el-button type="default" size="mini" @click="outExe"> 导出 </el-button>
    </div>

    <!--表格条件配置组件-->
    <div class="mt20">
      <div style="height: 39px">
        <el-col :span="6">
          <span class="payAmount"
            >收入总额：<span style="color: #d9001b">{{ priceSum }}</span>
            元</span
          ></el-col
        >
        <el-col :span="6">
          <span class="payAmount"
            >实付金额：<span style="color: #d9001b">{{ costSum }}</span>
            元</span
          ></el-col
        >
        <el-col :span="6">
          <span class="payAmount"
            >三方手续费：<span style="color: #d9001b">{{ serviceSum }}</span>
            元</span
          ></el-col
        >
        <el-col :span="6">
          <span class="payAmount"
            >公司拟得金额：<span style="color: #d9001b">{{ moneySum }}</span>
            元</span
          ></el-col
        >
      </div>

      <el-table
        ref="tableRef"
        :data="data"
        row-key="id"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          prop="selection"
          label="选择"
          width="50px"
          type="selection"
          :checkec="true"
          :reserve-selection="true"
        >
        </el-table-column>
        <el-table-column
          prop="orderNo"
          label="订单号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="nickName" label="购买人"> </el-table-column>、
        <el-table-column prop="tel" label="手机号"></el-table-column>
        <el-table-column prop="consumeType" label="消费类型"> </el-table-column>
        <el-table-column prop="goodsName" label="销售商品"> </el-table-column>
        <el-table-column prop="priceLabel" label="付款原价"> </el-table-column>
        <el-table-column prop="userCoupon" label="是否用券"> </el-table-column>
        <el-table-column prop="discountLabel" label="减免金额">
        </el-table-column>
        <el-table-column prop="costLabel" label="实付金额"> </el-table-column>
        <el-table-column prop="payway" label="付款渠道">
          <template slot-scope="scope">
            {{ scope.row.payway=='WX'?'微信支付':'Apple Pay' }}
          </template>
        </el-table-column>
        <el-table-column prop="serviceChargeLabel" label="三方手续费">
        </el-table-column>
        <el-table-column prop="moneyLabel" label="公司拟得金额">
        </el-table-column>
        <el-table-column prop="paymentTm" label="付款时间">
          <template slot-scope="scope">
            {{ formData(scope.row.paymentTm) }}
          </template>
        </el-table-column>
        <el-table-column prop="billDate" label="对账时间">
          <template slot-scope="scope">
            {{ scope.row.billDate?formData(scope.row.billDate):'--' }}
          </template>
        </el-table-column>
        <el-table-column prop="" label="状态">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="color: #333333"
              v-if="scope.row.billStatus == 1"
            >
              已对账
            </el-button>
            <el-button
              type="text"
              size="small"
              v-else-if="scope.row.billStatus == 0"
              @click="checkBill(scope.row)"
            >
              未对账
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        style="margin-top: 20px;"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.pageNum"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>

    <!-- 弹窗 -->
    <el-dialog
      title="该订单是否已经完成对账?(对账完成后不可更改)"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        ref="billForm"
        :model="billForm"
        label-width="80px"
        :rules="billRules"
      >
        <el-form-item label="对账时间" prop="date">
          <el-date-picker
            type="datetime"
            v-model="billForm.date"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </el-form-item>
        <el-form-item style="text-align: right;">
          <el-button @click="dialogVisible = false">未完成</el-button>
          <el-button type="primary" @click="onSubmitCheckBill('billForm')"
            >对账完成</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from "@/views/components/searchConfigForm";
import FreeTable from "@/views/components/FreeTable";
// 引入js文件
import { formData } from "@/utils/tool";

// 引入接口
import Order from "@/model/modules/mod_order";

export default {
  name: "roleinfo",
  components: {
    SearchConfig,
    FreeTable
  },
  data() {
    return {
      selectList: [],
      billForm: {
        orderNo: "",
        date: ""
      },
      dialogVisible: false,
      excelData: [],
      sumCount: "", //成功订单实付总额
      fShowPositions: {},
      titleSrc: "订单详情",
      costSum: "0",
      moneySum: "0",
      priceSum: "0",
      serviceSum: "0",

      // 搜索框渲染
      formSetData: {
        pageIndexB1: 1,
        pageSize: 10,
        payWay: "",
        billStatus: "",
        orderNo: "",
        consumeType: "",
        time: "",
        startTime: "",
        endTime: ""
      },
      searchConfigData: [
        {
          label: "付款渠道",
          placeholder: "请选择",
          type: "select",
          isApi: false,
          prop: "payWay",
          options: [
            {
              label: "全部",
              value: 0
            },
            {
              label: "微信支付",
              value: 1
            },
            {
              label: "Apple pay",
              value: 2
            }
          ]
        },
        {
          label: "对账状态",
          placeholder: "请选择",
          type: "select",
          isApi: false,
          prop: "billStatus",
          options: [
            {
              label: "全部",
              value: 0
            },
            {
              label: "已对账",
              value: 1
            },
            {
              label: "未对账",
              value: 2
            }
          ]
        },
        {
          label: "订单编号",
          placeholder: "请输入登录名",
          type: "input",
          prop: "orderNo"
        },
        {
          label: "消费类型",
          placeholder: "请选择",
          type: "select",
          isApi: false,
          prop: "consumeType",
          options: [
            {
              label: "全部",
              value: 0
            },
            {
              label: "课程购买",
              value: 1
            },
            {
              label: "康驰币充值",
              value: 2
            },
            {
              label: "活动报名费",
              value: 3
            }
          ]
        },
        {
          label: "时间",
          type: "multipleBoxes",
          configDatas: [
            {
              label: "",
              placeholder: "请选择",
              type: "select",
              prop: "time",
              isApi1: true,
              filterable: false,
              multiple: false,
              options: [
                {
                  label: "付款时间",
                  value: "0"
                },
                {
                  label: "对账时间",
                  value: "1"
                }
              ]
            },
            {
              label: "",
              placeholder: "开始时间",
              type: "timePicker",
              prop: "startTime",
              optionType: "date"
            },
            {
              label: "",
              formItemId: 2,
              placeholder: "结束时间",
              type: "timePicker",
              prop: "endTime",
              optionType: "date"
            }
          ]
        }
      ],

      // 表格数据渲染createnPaymentTm
      data: [],
      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        total: 1
      },
      billRules: {
        date: [{ required: true, message: "请选择对账时间", trigger: "blur" }]
      }
    };
  },
  mounted() {
    this.getBillSum();
    this.billOrderList();
  },

  methods: {
    formData,
    // 获取列表
    async billOrderList() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      let res = await Order.billOrderList(this.formSetData);
      this.data = res.data.body.content;
      this.pagination.total = res.data.body.total * 1;
    },

    // 获取对账总和
    async getBillSum() {
      let res = await Order.getBillSum(this.formSetData);
      this.costSum = res.data.body.costSum;
      this.moneySum = res.data.body.moneySum;
      this.priceSum = res.data.body.priceSum;
      this.serviceSum = res.data.body.serviceSum;
    },

    // 点击查询
    onQuery() {
      this.pagination.pageNum = 1;
      this.selectList = []
      this.$refs.tableRef.clearSelection()
      this.getBillSum();
      this.billOrderList();
    },

    // 点击对账
    checkBill(row) {
      this.billForm.orderNo = row.orderNo;
      this.dialogVisible = true;
    },

    // 关闭弹窗前触发事件
    handleClose(done) {
      this.$refs.billForm.resetFields();
      done();
    },

    // 弹窗 确定按钮时间
    onSubmitCheckBill(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          Order.checkBill(this.billForm)
            .then(res => {
              if (res.data.code == 0) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1500
                });

                // 关闭弹窗
                this.$refs.billForm.resetFields();
                this.dialogVisible = false;

                this.billOrderList();
              } else {
                this.$message({
                  message: "操作失败",
                  type: "error",
                  duration: 1500
                });
              }
            })
            .catch(err => {
              console.error(err);
            });
        } else {
          return false;
        }
      });
    },

    // 点击选中
    handleSelectionChange(selectList) {
      this.selectList = selectList;
    },

    // 点击导出
    outExe() {
      var list = [];
      var str = "";
      if (this.selectList.length == 0) {
        list = this.data;
        str = "当前页面数据";
      } else {
        list = this.selectList;
        str = "选中数据";
      }
      this.$confirm("此操作将根据" + str + "导出excel文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.excelData = list.map(item => {
            if (item.paymentTm) {
              let ptimeStr = formData(item.paymentTm);
              item.paymentTm = ptimeStr;
            }

            if (item.billDate) {
              let btimeStr = formData(item.billDate);
              item.billDate = btimeStr;
            }

            return item;
          }); //你要导出的数据list。
          this.export2Excel();
        })
        .catch(() => {});
    },

    // 导出excel
    export2Excel() {
      var that = this;
      var slotColumn = this.$refs.tableRef.$children
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/Export2Excel"); //这里必须使用绝对路径，根据自己的命名和路径

        // 导出的表头名
        let tHeader = [];
        for (var i = 0; i < slotColumn.length; i++) {
          if (
            (slotColumn[i].label == "选择" && slotColumn[i].label) ||
            (slotColumn[i].label == "操作" && slotColumn[i].label)
          ) {
          } else {
            tHeader.push(slotColumn[i].label);
          }
        }

        // 导出的表头字段名
        let filterVal = [];
        for (var i = 0; i < slotColumn.length; i++) {
          if (
            (slotColumn[i].prop == "selection" && slotColumn[i].prop) ||
            (slotColumn[i].prop == "cation" && slotColumn[i].prop)
          ) {
          } else {
            filterVal.push(slotColumn[i].prop);
          }
        }

        const list = that.excelData;
        // that.excelData为传入的数据
        const data = that.formatJson(filterVal, list);
        // 获取当前时间 20220101
        var nowdate = formData(Date.parse(new Date()))
        var nowdate_arr = nowdate.split(' ')[0].split('-')
        var title_str = '销售订单-' + nowdate_arr[0] + nowdate_arr[1] + nowdate_arr[2]
        export_json_to_excel(tHeader, data, title_str); // 导出的表格名称，根据需要自己命名
        // tHeader为导出Excel表头名称，`xxxxxx`即为导出Excel名称
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },

    // 分页 切换每页数据总数
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.billOrderList();
    },

    // 分页 切换当前页
    handleCurrentChange(val) {
      this.pagination.pageNum = val;
      this.billOrderList();
    }
  }
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
.batchRefund {
  color: #02a7f0;
  display: inline-block;
  padding: 9px;
}
.payAmount {
  color: #02a7f0;
  margin: 0 auto;
  line-height: 39px;
}

.flex_c {
  display: flex;
  align-items: center;
}

/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
