<template>
  <div>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData_client"
      :formSet="formSetData"
      :colspan="4.8"
      :btnColspan="5"
      :labelWidth="'100px'"
      @on-focus-select="handleFocus"
      @on-change-select="handleChange"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
      <el-button type="primary" @click="resetForm">重置</el-button>
    </search-config>
    <div>
      <el-button type="default" size="mini" @click="takeClickMore(0)">批量通过</el-button>
      <el-button type="default" size="mini" @click="takeClickMore(1)">批量驳回</el-button>
    </div>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn_client"
        :columnsProps="columnsProps"
        @selection-change="selectionChange"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:sourceType="{ row }">
          {{row.sourceType==1?'App':'管理后台'}}
        </template>
      </free-table>
    </div>

    <!-- 详情 -->
    <show-detail ref="showDetailUserInfo" :title="'查看详情'" @refresh-details="refreshDetails" ></show-detail>

  </div>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'
import ShowDetail from './compoments/showDetails.vue'

// 引入接口
import { certificationAudit, getUserInfo } from '@/model/modules/audit_user'

export default {
  props: {
    formSetData: Object,
    columnsProps: Object,
    pagination: Object,
    data: Array
  },
  components: {
    SearchConfig,
    FreeTable,
    ShowDetail
  },
  data() {
    return {
      batchAudit: [],

      searchConfigData_client: [
        {
          label: '',
          formItemId: 1,
          type: 'multipleBoxes',
          configDatas: [
            {
              label: '',
              formItemId: 0,
              placeholder: '请选择',
              type: 'select',
              prop: 'param',
              isApi: false,
              isApi1: true,
              filterable: false,
              multiple: false,
              width: 130,
              options: [
                {
                  label: '用户昵称',
                  value: 'nickNameVal'
                },
                {
                  label: 'ID',
                  value: 'idVal'
                },
                {
                  label: '电话',
                  value: 'telVal'
                },
                {
                  label: '真实姓名',
                  value: 'fullNameVal'
                }
              ]
            },
            {
              label: '',
              formItemId: 1,
              placeholder: '请输入',
              type: 'input',
              prop: '',
              width: 170
            }
          ]
        },
        {
          label: '时间',
          formItemId: 2,
          type: 'multipleBoxes',
          configDatas: [
            {
              label: '',
              formItemId: 0,
              placeholder: '请选择',
              type: 'select',
              prop: 'time',
              isApi: false,
              isApi1: true,
              filterable: false,
              multiple: false,
              width: 130,
              options: [
                {
                  label: '最后申请时间',
                  value: '0'
                },
                {
                  label: '最后审核时间',
                  value: '1'
                }
              ]
            },
            {
              label: '',
              formItemId: 1,
              placeholder: '请选择开始时间',
              type: 'timePicker',
              prop: '',
              optionType: 'datetime',
              width: 170,
            },
            {
              label: '',
              formItemId: 2,
              placeholder: '请选择结束时间',
              type: 'timePicker',
              prop: '',
              optionType: 'datetime',
              width: 170,
            }
          ]
        },
        {
          label: '来源',
          formItemId: 3,
          placeholder: '请选择',
          type: 'select',
          prop: 'sourceType',
          isApi: false,
          filterable: false,
          multiple: false,
          options: [
            {
              label: '全部',
              value: '0'
            },
            {
              label: 'App',
              value: '1'
            },
            {
              label: '管理后台',
              value: '2'
            }
          ]
        },
      ],

      slotColumn_client: [
        { label: '选择', prop: 'selection', type: 'selection' },
        { label: 'ID', prop: 'id' },
        { label: '用户名称', prop: 'nickName' },
        { label: '真实姓名', prop: 'fullname' },
        { label: '电话', prop: 'tel' },
        { label: '来源', prop: 'sourceType', slotScope: true},
        { label: '最后申请时间', prop: 'mtmStr', sort: true },
        { label: '最后审核时间', prop: 'auditTmStr', sort: true },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: 300,
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onShowDetail(scope.row)
                  }}
                >
                  详情
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onTakeClick(0, scope.row)
                  }}
                >
                  通过
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onTakeClick(1, scope.row)
                  }}
                >
                   驳回
                </el-button>
              </div>
            )
          }
        }
      ],
    }
  },
  methods: {
    //查询 下拉框
    handleFocus() {

    },
    handleChange(item) {
      if (item.formItemId === 1) {
        let val = item.configDatas[1].prop
        if (val !== '') {
          this.formSetData[val] = ''
        }
        item.configDatas[1].prop = this.formSetData.param
      } else if (item.formItemId === 2) {
        let arrStr = []
        if (Number(this.formSetData.time) === 0) {
          arrStr[0] = 'mtmStartTime'
          arrStr[1] = 'mtmEndTime'
          this.formSetData.auditTmStartTime = ''
          this.formSetData.auditTmEndTime = ''
        } else if (Number(this.formSetData.time) === 1) {
          arrStr[0] = 'auditTmStartTime'
          arrStr[1] = 'auditTmEndTime'
          this.formSetData.mtmStartTime = ''
          this.formSetData.mtmEndTime = ''
        }
        arrStr.map((i, j) => {
          item.configDatas[(j+1)].prop = i
        })
      }
    },
    //查询
    onQuery() {
      this.pagination.pageNum = 1
      this.$emit('on-get-data')
    },
    //重置
    resetForm() {
      this.$emit('on-reset-Form')
    },
    //批量通过/批量驳回
    takeClickMore(index) {
      if (this.batchAudit.length <= 0) {
        this.$message({
          message: '请勾选需要的审核',
          type: 'warning'
        });
        return;
      }
      let strId = ''
      this.batchAudit.map((item,index) => {
        strId += item.id+','
      })
      strId = strId.substring(0, strId.length-1)
      const obj = {
        id: strId,
        reason: '',
        type: ''
      }
      this.batchAuditClick(index, obj)
    },
    // 表格多选
    selectionChange (param) {
      this.batchAudit = param
    },
    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.$emit('on-get-data')
    },
    // 详情
    onShowDetail (row) {
      const userDetails = {
        id: row.id
      }
      getUserInfo(userDetails).then(res => {
        if (res.data.code === 0) {
          const userDetailsData = res.data.body
          const id = row.id
          const formData = {
            ...userDetailsData,
            id
          }
          this.$refs.showDetailUserInfo.showDialog(formData)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 刷详情
    refreshDetails () {
      this.$emit('on-get-data')
    },
    //通过/驳回
    onTakeClick(index, row) {
      const obj = {
        id: row.id,
        reason: '',
        type: ''
      }
      this.batchAuditClick(index, obj)
    },

    //审核
    batchAuditClick(index, obj) {
      let mess = ''
      let showInput = ''
      if (index === 0) { // 通过
        obj.type = 0
        mess = '是否确认审核通过？通过审核后即视为认证成功。'
        showInput = false
      } else if (index === 1) { // 驳回
        obj.type = 1
        mess = '请输入驳回原因：'
        showInput = true
      }

      this.$prompt(mess, '提示', {
        showInput: showInput
      }).then(res => {
        if(!res.value && index == 1){
          this.$message({
            message: '驳回失败，请输入驳回原因',
            type: 'error',
            duration: 1500
          })
          return
        }
        
        obj.reason = res.value
        certificationAudit(obj).then(res => {
          if (res.data.code === 0) {
            this.$emit('on-get-data')
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }).catch(err => {})
    },
  },
  
}
</script>

<style lang="scss" scoped>
.mt20 {
  margin-top: 20px;
}
</style>