import { post, get } from '../../service'

export default class StartAdvert {
    // 获取开屏广告信息
    static getStartAdvertInfo(params = undefined) {
        return get('/service/startAdvert/findOne', params)
    }

    // 保存开屏广告信息
    static saveStartAdvert(params = undefined) {
        return post('/service/startAdvert/save', params)
    }
}