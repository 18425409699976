<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogVisible" :before-close="handleClose">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
        <el-form-item label="引导名头" prop="title">
          <el-input v-model="ruleForm.title" placeholder="最多20字" class="form-input"></el-input>
        </el-form-item>
        <el-form-item label="引导名头颜色" prop="color">
          <el-color-picker v-model="ruleForm.color"></el-color-picker>
        </el-form-item>
        <el-form-item label="权重" prop="weight">
          <el-input v-model.number="ruleForm.weight" class="form-input"></el-input>
        </el-form-item>
        <el-form-item label="引导解答" prop="answer" v-if="level == 2">
          <el-input
            type="textarea"
            :rows="6"
            v-model="ruleForm.answer"
            placeholder="最多200字"
            class="form-input"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="form-upload" v-if="level == 2">
        <el-upload
          v-if="uploadShow"
          ref="upload"
          class="upload-demo"
          action="http://dev.kcguoyi.com/service/upload/huawei"
          :auto-upload="false"
          :on-remove="handleRemove"
          :on-change="handleChange"
          :file-list="fileList"
          :limit="1"
          list-type="picture"
        >
          <el-button size="small" type="primary" style="width:200px;">上传图片</el-button>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitQusetion('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// 引入接口
import QuickQuestionManageApi from "@/model/modules/quickQuestionManage/index";
import { uploadPic } from "@/model/modules/user";

export default {
  name: "formModel",
  props: {
    title: String,
    rowInfomation: Object
  },
  data() {
    return {
      dialogVisible: false,
      uploadShow: false,
      level: 1, // 1问题2解答
      fileList: [], // 图片集合
      formDataImg: new FormData(),
      infomation: {},
      ruleForm: {
        title: "",
        answer: "",
        color:"#6EBD8A",
        weight:0
      },
      rules: {
        title: [
          { required: true, message: "请输入引导名头", trigger: "blur" },
          { max: 20, message: "最多20字", trigger: "blur" }
        ],
        answer: [
          { message: "请输入引导解答", trigger: "blur" },
          { max: 200, message: "最多200字", trigger: "blur" }
        ],
        color: [
          { required: true,trigger: "blur" },
        ],
        weight: [
          { required: true, message: "请输入权重", trigger: "blur" },
        ]
      }
    };
  },
  watch: {
    rowInfomation: {
      handler(newVal) {
        this.infomation = newVal;
        if (Object.keys(newVal).length > 0) {
          this.ruleForm.title = newVal.title;
          this.ruleForm.color=newVal.titleColor?newVal.titleColor:"#6EBD8A";
          this.ruleForm.weight=newVal.weight?newVal.weight:0;
          if (this.level == 2) {   
            this.ruleForm.answer = newVal.content ? newVal.content : "";
            this.fileList = newVal.imgUrl ? [{ url: newVal.imgUrl }] : [];
          }
        } else {
          this.ruleForm = {
            title: "",
            answer: "",
            color:"#6EBD8A",
            weight:0
          }
          this.fileList = [];
        }
      },
      deep: true
    }
  },
  methods: {
    // 显示dialog
    showDialog(level) {
      this.level = level;
      this.uploadShow = true;
      this.dialogVisible = true;
    },
    // 关闭dialog
    handleClose() {
      this.dialogVisible = false;
      this.uploadShow = false;
    },
    // 提交
    submitQusetion(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let params = this.infomation;  
          params.weight = this.ruleForm.weight;
          params.titleColor = this.ruleForm.color;
          if (this.level == 1) {
            params.title = this.ruleForm.title;
            this.addOrUpdateQuestion(params);
          } else {
            params.answerTitle = this.ruleForm.title;
            params.content = this.ruleForm.answer;
            if (this.formDataImg.get("file")) {
              params.imgUrl = await this.uploadPicture(this.formDataImg);
            }else{
              params.imgUrl = "";
            }
            params.mtm = new Date().getTime();
            this.addOrUpdateNextQuestion(params);
          }
        } else {
          return false;
        }
      });
    },
    // 保存引导
    async addOrUpdateQuestion(params) {
      const { data } = await QuickQuestionManageApi.addOrUpdateQuestion(params);
      this.message(data);
    },
    // 保存解答
    async addOrUpdateNextQuestion(params) {
      const { data } = await QuickQuestionManageApi.addOrUpdateNextQuestion(
        params
      );
      this.message(data);
    },
    // 保存提示
    message(data) {
      this.dialogVisible = false;
      this.uploadShow = false;
      if (data.code == 0) {
        this.$message({
          message: "提交成功",
          type: "success",
          duration: 1500
        });
        this.$emit('refresh');
      } else {
        this.$message.error(data.msg);
      }
    },
    // 上传图片
    async uploadPicture(formdata) {
      const _this = this;
      return new Promise(function(resolve, reject) {
        uploadPic(formdata).then(res => {
          let { data } = res;
          let { body } = data;
          if (data.code === 0) {
            resolve(body[0].filepath);
          }else{
            _this.$message({
              message: "图片上传失败",
              type: "error",
              duration: 1500
            });
          }
        });
      });
    },
    // 选择图片
    handleChange(file, fileList) {
      this.formDataImg.append("file", file.raw);
    },
    // 移除图片
    handleRemove() {
      this.formDataImg.delete("file");
    }
  }
};
</script>
<style scoped>
.form-upload {
  padding-left: 100px;
}
</style>