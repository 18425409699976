
/**
 * 用户审核
 */

const apiUrl = {
    userAudit: '/service/user/auth', //获取列表
    certificationAudit: '/service/user/pass',  //认证审核
    getUserInfo: '/service/user/getUserInfo',    //用户详情
}

export default apiUrl

