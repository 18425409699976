import { deletes, post, get, put } from "@/model/service";

export default class ListManage {
    // 列表
    static getList(params = undefined) {
        return post('/service/market/act211108/query', params)
    }
    // 更新数量
    static updateTarget(params = undefined) {
        return put('/service/market/act211108/target/' + params)
    }
    // 省-姓名
    static provinceName() {
        return get('/service/market/act211108/optProvince')
    }
    // 删除列表
    static deleteList(params = undefined) {
        return deletes('/service/market/act211108/' + params)
    }
    // 添加新纪录 
    static addRecord(params = undefined) {
        return put('/service/market/act211108/', params)
    }
}