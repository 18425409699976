
/**
 * 审核设置
 */

const apiUrl = {
    urlGetState: '/service/switch/switchInitialize', // 获取状态
    urlChangeState: '/service/switch/onOff/',  // 更改审核状态
}

export default apiUrl

