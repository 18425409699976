/* eslint-disable no-case-declarations */
<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="9"
      :btnColspan="5"
      :labelWidth="'90px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
      </free-table>
    </div>
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'

// 引入接口
import { myLiveList, startAndStopLive } from '@/model/modules/modLive'

export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
    FreeTable
  },
  data () {
    return {
      titleSrc: '新增直播',

      // 搜索框渲染
      formSetData: {
        keywordsType: 1,
        keyword: null,
        liveStatus: null,
        pageIndexB1: Number,
        pageSize: Number
      },
      searchConfigData: [
        {
          label: '',
          formItemId: 0,
          type: 'multipleBoxes',
          configDatas: [
            {
              label: '',
              formItemId: 0,
              placeholder: '请输入直播名称',
              type: 'select',
              isApi: false,
              prop: 'keywordsType',
              width: 170,
              options: [
                {
                  label: '直播名称',
                  value: 1
                },
                {
                  label: '直播号',
                  value: 2
                }
              ]
            },
            {
              label: '',
              formItemId: 0,
              placeholder: '请输入直播名称',
              type: 'input',
              prop: 'keyword',
              width: 230
            }
          ]
        },
        {
          label: '直播状态',
          formItemId: 3,
          placeholder: '请选择直播状态',
          type: 'select',
          isApi: false,
          prop: ' liveStatus',
          options: [
            {
              label: '未开播',
              value: 1
            },
            {
              label: '正在直播',
              value: 2
            },
            {
              label: '过期未开播',
              value: 3
            },
            {
              label: '已结束',
              value: 4
            }
          ]
        }
      ],

      // 表格数据渲染
      data: [],
      slotColumn: [
        { label: '直播名称', prop: 'liveName' },
        { label: '直播房间号', prop: 'liveRoom' },
        { label: '计划开播时间', prop: 'liveBegin', sort: true },
        {
          label: '最近直播开始时间',
          prop: 'realBeginTm',
          sort: true,
          width: '170px'
        },
        {
          label: '最近直播结束时间',
          prop: 'realEndTm',
          sort: true,
          width: '170px'
        },
        { label: '创建时间', prop: 'ctm', sort: true },
        { label: '直播状态', prop: 'liveStatus' },
        { label: '预约人数', prop: 'orderNum' },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          // width: "270px",
          render: (h, scope) => {
            const node = []
            if (scope.row.liveStatus === '正在直播') {
              node.push(
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.btnClick2(scope.row)
                  }}
                >
                  进入直播间
                </el-button>
              )
            }
            node.push(
              <el-button
                type="default"
                size="small"
                onClick={() => {
                  this.btnClick(1, scope.row)
                }}
              >
                {scope.row.liveStatus === '正在直播' ? '关播' : '开播'}
              </el-button>
            )
            return node
          }
        }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },

      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      }
    }
  },
  mounted () {
    this.myLiveList()
  },
  methods: {
    // 列表数据
    async myLiveList () {
      this.formSetData.pageIndexB1 = this.pagination.pageNum
      this.formSetData.pageSize = this.pagination.pageSize
      const res = await myLiveList(this.formSetData)
      this.data = res.data.body.content
      this.pagination.total = res.data.body.total * 1
    },
    // 表格查询按钮
    onQuery () {
      this.pagination.pageNum = 1
      this.myLiveList()
    },
    handleQueryFocus (e) {},

    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.myLiveList()
    },
    btnClick2 (e) {
      if (e.liveStatus === '正在直播') {
        this.$router.push({
          path: '/modLive/myLivesRoom?id=' + e.uid + '&liveId=' + e.id
        })
      }
    },
    btnClick (index, e) {
      switch (index) {
        case 1:
          // eslint-disable-next-line no-case-declarations
          const obj = {
            id: '',
            liveStatus: 0
          }
          // eslint-disable-next-line no-case-declarations
          const mess =
            e.liveStatus === '正在直播'
              ? '确认直播已结束要关闭直播间吗？'
              : '确认开启直播吗？'
          this.$confirm(mess, '提示', {})
            .then(() => {
              this.listLoading = true
              obj.id = e.id
              obj.liveStatus = e.liveStatus === '正在直播' ? 0 : 1
              if (obj.liveStatus === 1) {
                this.$router.push({
                  path: '/modLive/myLivesRoom?id=' + e.uid + '&liveId=' + e.id
                })
              }
              startAndStopLive(obj).then((res) => {
                if (res.data.code === 0) {
                  this.myLiveList()
                  this.$message({
                    message: '操作成功',
                    type: 'success',
                    duration: 1500
                  })
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            })
            .catch(() => {})
          break
        default:
          this.$message.error('未定义')
          break
      }
    },

    // 提交编辑表格行信息
    onEditSubmit (e) {
      if (this.titleSrc === '新增直播' || this.titleSrc === '修改直播') {
        if (this.titleSrc === '修改直播') {
          e.form.ctm = new Date(e.form.ctm).getTime()
        }
        // eslint-disable-next-line no-undef
        adddeleteAlter(e.form).then((res) => {
          this.findAll()
        })
      } else if (this.titleSrc === '设置推荐课程') {
        if (e.form.list.length <= 0) {
          this.$message({
            message: '操作失败',
            type: 'success',
            duration: 1500
          })
          return
        }
        // eslint-disable-next-line no-undef
        setRecommendLive(e.form).then((res) => {
          this.findAll()
        })
      }
    },

    formatData (data, key, val) {
      const obj = {}
      for (const i in data) {
        const item = data[i]
        obj[item[key]] = {
          value: item[key] || '',
          label: item[val] || ''
        }
      }
      return obj
    }
  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
