
/**
 * 直播
 */
const apiUrl = {
  // 直播管理
  liveMenuPosition: '/service/live/liveMenuPosition',
  findAll: '/service/live/findAll',
  liveConsultList: '/service/live/liveConsultList',
  adddeleteAlter: '/service/live/save',
  setRecommendLive: '/service/live/setRecommendLive',
  // 我的直播
  myLiveList: '/service/live/myLiveList',
  startAndStopLive: '/service/live/startAndStopLive',
  // 获取指定直播的拉流地址
  fetchPullUrl: '/service/AppLive/fetchPullUrl'

}

export default apiUrl
