<template>
  <div>
    <el-dialog
      :title="titleSrc"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      v-bind="$attrs"
      :before-close="onClose"
    >
      <!--查询条件配置组件-->
      <searchConfigFormOne
        ref="searchConfigFormOne"
        :configData="config"
        :formSet="childForm"
        :rules="rules"
        :labelWidth="'120px'"
        @on-avatar-success="handleAvatarSuccess"
        @on-focus-select="handleFocus"
      />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onEditSubmit">提交</el-button>
        <el-button type="default" @click="onClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import searchConfigFormOne from '@/views/components/searchConfigForm/searchConfigFormOne'
export default {
  name: 'addSub',
  components: {
    searchConfigFormOne
  },
  props: {
    config: Array,
    form: Object,
    rules: Object,
    titleSrc: String
  },
  data () {
    return {
      childForm:{},
      dialogFormVisible: false,
      allObjTags: [],
      obj: '',
      ratio: ''
    }
  },
  watch:{
    form:{
      handler(newValue){
        this.childForm = newValue;
      }
    }
  },
  methods: {
    showDialog () {
      this.dialogFormVisible = true
    },
    handleSelect (key, keyPath) {
      this.activeIndex = key.toString()
    },
    onEditSubmit () {
      this.$refs.searchConfigFormOne.$children[0].validate((valid) => {
        if (!valid) return
        this.$confirm('确认提交吗？', '提示', {})
          .then(() => {
            this.$emit('on-edit-submit', this.childForm)
            this.onClose()
          })
          .catch(() => {})
      })
    },
    onClose () {
      this.$refs.searchConfigFormOne.$refs.configData.resetFields()
      this.dialogFormVisible = false
    },

    handleFocus (e) {
      this.$emit('on-focus-select', { item: e.item })
    },
    handleAvatarSuccess (e) {
      e.origin[e.propName] = e.res.filepath
      // this.$refs.searchConfigFormOne.validateField('imageUrl')
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>
<style>
.mt20 {
  margin-top: 20px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.preCourse_item img{
  width: 100%;
}

.preCourse_item{
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 20px;
  font-size: 20px;
}

.preCourse_item_title {
  padding-bottom: 10px;
}
</style>
