<template>
  <d2-container>
    <el-tabs v-model="activeName">
      <el-tab-pane label="皇家金丹" name="1">
        <tab-pane-item :type="activeName" v-if="activeName=='1'"></tab-pane-item>
      </el-tab-pane>
      <el-tab-pane label="灵源项目" name="2">
        <tab-pane-item :type="activeName" v-if="activeName=='2'"></tab-pane-item>
      </el-tab-pane>
      <el-tab-pane label="散寒项目" name="3">
        <tab-pane-item :type="activeName" v-if="activeName=='3'"></tab-pane-item>
      </el-tab-pane>
      <el-tab-pane label="龙骨项目" name="4">
        <tab-pane-item :type="activeName" v-if="activeName=='4'"></tab-pane-item>
      </el-tab-pane>
      <el-tab-pane label="慢病项目" name="5">
        <tab-pane-item :type="activeName" v-if="activeName=='5'"></tab-pane-item>
      </el-tab-pane>
       <el-tab-pane label="炖服膏方" name="6">
        <tab-pane-item :type="activeName" v-if="activeName=='6'"></tab-pane-item>
      </el-tab-pane>
    </el-tabs>
  </d2-container>
</template>

<script>
// 引入组件
import tabPaneItem from './components/tabPaneItem'

export default {
  components: {
    tabPaneItem
  },

  data() {
    return {
      activeName: '1',
    };
  },

  mounted() {},

  methods: {}
};
</script>

<style scoped></style>
