<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5.8"
      :btnColspan="2"
      :labelWidth="'90px'"
      :rules="rules"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:status="{ row }">
          {{ row.status == 1 ? "已上架" : "未上架" }}
        </template>
      </free-table>
    </div>
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'

// 引入接口
import Order from "@/model/modules/mod_order";

import { options } from 'marked'

export default {
  name: 'purchaseOrder',
  components: {
    SearchConfig,
    FreeTable,
  },
  data () {
    return {
      // 搜索框渲染
      formSetData: {
        pageIndexB1: 1,
        pageSize: 10,
        company: '',
        province: '',
        tel: '',
        company: '',
        month: '',
        beginTime: '',
        endTime: '',
      },

      // 搜索框配置
      searchConfigData: [
        {
          label: '商业公司',
          placeholder: '请输入商业公司名称',
          type: 'input',
          prop: 'company'
        },
        {
          label: '省总',
          placeholder: '请输入省总',
          type: 'input',
          prop: 'province'
        },
        {
          label: '手机号码',
          placeholder: '请输入手机号码',
          type: 'input',
          prop: 'tel'
        },
        {
          label: '按月筛选',
          placeholder: '请输入',
          type: 'select',
          prop: 'month',
          options: [
            {
              label: 1,
              value: 1
            },
            {
              label: 2,
              value: 2
            },
            {
              label: 3,
              value: 3
            },
            {
              label: 4,
              value: 4
            },
            {
              label: 5,
              value: 5
            },
            {
              label: 6,
              value: 6
            },
            {
              label: 7,
              value: 7
            },
            {
              label: 8,
              value: 8
            },
            {
              label: 9,
              value: 9
            },
            {
              label: 10,
              value: 10
            },
            {
              label: 11,
              value: 11
            },
            {
              label: 12,
              value: 12
            }

          ]
        },
        {
          label: '时间',
          type: 'multipleBoxes',
          configDatas: [
            {
              label: '',
              formItemId: 1,
              placeholder: '开始时间',
              type: 'timedateFormat',
              prop: 'beginTime',
              // optionType: 'date'
            },
            {
              label: '',
              formItemId: 2,
              placeholder: '结束时间',
              type: 'timedateFormat',
              prop: 'endTime',
              // optionType: 'date'
            }
          ]
        },
      ],

      rules: {
        phone: { type : "string" , message: '请输入正确的手机号码', pattern : /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, trigger: 'blur' }
      },

      // 表格数据渲染
      data: [],
      slotColumn: [
        { label: '采购编号', prop: 'orderNo', width: '200px' },
        { label: '省总', prop: 'fullName', width: '100px' },
        { label: '商业公司', prop: 'companyName', width: '200px' },
        { label: '所属大区', prop: 'areaName', width: '300px' },
        { label: '手机号', prop: 'tel' },
        { label: '汇款日期', prop: 'payDate' },
        { label: '汇款对象', prop: 'targetName' },
        { label: '采购总价', prop: 'totalPrice' },
        { label: '采购品类', prop: 'item' },
        { label: '共计数量', prop: 'sum' },
        {
          label: '操作',
          prop: 'cation',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.btnClick(scope.row)
                  }}
                >
                  查看
                </el-button>
              </div>
            )
          }
        }
      ],

      columnsProps: {
        // width: 'auto',
        showOverflowTooltip: true
      },

      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        total: 1
      },
    }
  },
  mounted () {
    this.getCourseList()
  },
  methods: {
    handleQueryFocus (e) {
      this.searchConfigData[e.index].options = this.fShowPositions
    },

    handleFocus (e) {},

    // 列表数据
    async getCourseList () {
      this.formSetData.pageIndexB1 = this.pagination.pageNum
      this.formSetData.pageSize = this.pagination.pageSize
      const res = await Order.pOrderList(this.formSetData)
      this.data = res.data.body.content
      this.pagination.total = res.data.body.total * 1
    },
    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.getCourseList()
    },

    formatData (data, key, val) {
      const obj = []
      for (const i in data) {
        const item = data[i]
        obj[i] = {
          value: item[key] || '',
          label: item[val] || ''
        }
      }
      return obj
    },

    // 表格查询按钮
    onQuery () {
      this.pagination.pageNum = 1
      this.getCourseList()
    },

    btnClick (e) {
      this.$router.push({
        path: '/purchaseOrder/orderDetail/' + e.id,
      })
    }
  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
