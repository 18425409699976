<template>
  <d2-container>
    <!-- 查询 -->
    <el-form inline :model="searchForm">
      <el-form-item label="投稿标题">
        <el-input
          clearable
          v-model="searchForm.title"
          placeholder="请输入投稿标题"
        ></el-input>
      </el-form-item>
      <el-form-item label="审核状态">
        <el-select v-model="searchForm.auditRes" placeholder="请选择" clearable>
          <el-option
            v-for="item in optionList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投稿时间">
        <el-date-picker
          v-model="searchForm.startTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="开始时间"
        >
        </el-date-picker>
        ——
        <el-date-picker
          v-model="searchForm.endTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="结束时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-button type="primary" @click="getList">查询</el-button>
    </el-form>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      ref="table"
      row-key="id"
      border
      style="width: 100%"
      width="auto"
    >
      <el-table-column prop="title" label="投稿标题" show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="fullname"
        label="投稿人"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="tel"
        label="手机号"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="createDateTime"
        label="投稿时间"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="auditResStatus"
        label="审核状态"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-button type="text" @click="openDetail(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      style="margin-top: 20px"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInit.cur_page"
      :page-sizes="pageInit.pageSizes"
      :page-size="pageInit.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInit.total"
    >
    </el-pagination>

    <el-dialog title="查看" :visible.sync="dialogVisible" width="80%">
      <div class="line margin">
        <span class="line-title">投稿标题：</span>
        <div class="line-content">{{ detail.title }}</div>
      </div>
      <div class="line margin">
        <span class="line-title">投稿正文：</span>
        <div class="line-content">{{ detail.content }}</div>
      </div>
      <div>
        <span class="line-title">投稿素材：</span>
        <div class="img-ul">
          <div
            v-for="(img, index) in detail.coverList"
            :key="index"
            class="img-box"
            :class="'img-box' + index"
            :data-clipboard-text="img"
          >
            <!-- <img :src="img" alt="" /> -->
            <el-image
              class="avatar-size"
              :src="img"
              :preview-src-list="[img]"
            >
            </el-image>
            <el-button type="primary" plain @click="copy(index)"
              >复制</el-button
            >
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="agree">通过</el-button>
        <el-button @click="refuse">不通过</el-button>
        <el-button @click="dialogVisible = false">返回</el-button>
      </span>
    </el-dialog>
  </d2-container>
</template>

<script>
import Clipboard from "clipboard";
import Examine from "@/model/modules/examine/index";
export default {
  data() {
    return {
      dialogVisible: false,
      // 搜索条件
      searchForm: {
        title: "",
        auditRes: "0",
        startTime: "",
        endTime: "",
        pageIndexB1: 1,
        pageSize: 10,
      },
      // select
      optionList: [
        { value: "0", label: "全部" },
        { value: "1", label: "审核中" },
        { value: "2", label: "通过" },
        { value: "3", label: "未通过" },
      ],
      checkList: ["审核中", "通过", "未通过"],
      // 分页
      pageInit: {
        total: 1,
        cur_page: 1,
        pageSize: 10,
        pageSizes: ["10", "20", "30"],
      },
      // 表格
      tableData: [],
      detail: {},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 复制
    copy(index) {
      let that = this;
      var clipboard = new Clipboard(".img-box" + index);
      clipboard.on("success", (e) => {
        that.$message({
          message: "复制成功",
          type: "success",
        });
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 释放内存
        clipboard.destroy();
      });
    },
    // 查看
    openDetail(row) {
      this.detail = row;
      this.dialogVisible = true;
    },
    // 未通过
    refuse() {
      const params = {
        id: this.detail.id,
        type: 2,
      };
      Examine.check(params).then((res) => {
        if (res.data.code == 0) {
          this.dialogVisible = false;
        }
      });
    },
    // 通过
    agree() {
      const params = {
        id: this.detail.id,
        type: 1,
      };
      Examine.check(params).then((res) => {
        if (res.data.code == 0) {
          this.dialogVisible = false;
        }
      });
    },
    // 列表
    getList() {
      Examine.list(this.searchForm).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.body.content;
          this.tableData.forEach((ele) => {
            ele.auditResStatus = this.checkList[ele.auditRes];
          });
        }
      });
    },
    // 翻页
    handleSizeChange(e) {
      this.pageInit.pageSize = e;
      this.pageInit.cur_page = 1;
      // this.getData();
    },
    handleCurrentChange(e) {
      this.pageInit.cur_page = e;
      // this.getData();
    },
  },
};
</script>

<style scoped>
.margin {
  margin-bottom: 20px;
}
.line {
  display: flex;
}
.line-title {
  width: 80px;
}
.line-content {
  flex: 1;
}
.img-ul {
  display: flex;
  flex-wrap: wrap;
}
.img-box {
  width: 150px;
  margin-right: 30px;
  margin-bottom: 20px;
  text-align: center;
}
.avatar-size {
  width: 150px;
  height: 150px;
}
</style>
