<template>
  <d2-container>
    <div class="target-box">
      <div class="target">
        <label>目标数量：</label>
        <el-input
          v-model="targetNumber"
          placeholder="请输入目标数量"
          type="number"
        ></el-input>
      </div>
      <el-button type="primary" @click="upTarget">提交</el-button>
    </div>
    <div class="target-form">
      <el-card class="box-card">
        <el-tag>新增列表</el-tag>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="省份-姓名" prop="region">
            <el-select
              v-model="form.region"
              filterable
              placeholder="请选择省份-姓名"
              class="target-form-item"
            >
              <el-option
                v-for="item in selectArr"
                :key="item.key"
                :label="item.key"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间" prop="date">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              v-model="form.date"
              class="target-form-item"
              format="yyyy-MM-dd HH:mm"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">立即创建</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <div class="target-list">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column
          prop="number"
          label="排名"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="省份-姓名"
          width="120"
        ></el-table-column>
        <el-table-column prop="time" label="日期" width="120"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              @click="deleteItem(scope.row)"
              size="small"
              type="primary"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <pagination
      v-if="pagination.total > 0"
      :pageSizes="pagination.pageSizes"
      :total.sync="pagination.total"
      :page.sync="pagination.pageNum"
      :limit.sync="pagination.pageSize"
      @pagination="getList"
    />
  </d2-container>
</template>

<script>
// 分页
import Pagination from "@/views/components/Pagination/index";
// 引入接口
import ListManageApi from "@/model/modules/listManage/index";

export default {
  name: "listManage",
  components: {
    Pagination,
  },
  data() {
    return {
      targetNumber: 0,
      selectArr: [],
      tableData: [
        // 表格数据
        {
          number: 1,
          name: "",
          time: "",
        },
      ],
      pagination: {
        // 分页
        pageNum: 1,
        pageSize: 10,
        pageSizes: [3, 5, 10],
        total: 0,
      },
      form: {
        region: "",
        date: "",
      },
      rules: {
        region: [
          { required: true, message: "请选择省份-姓名", trigger: "change" },
        ],
        date: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.initList();
    this.initProvinceName();
  },
  methods: {
    upTarget() {
      const val = Number(this.targetNumber);
      // 更新数量
      ListManageApi.updateTarget(val).then((res) => {
        const { data } = res;
        if (data.code === 0) {
          this.$message({
            message: "更新成功！",
            type: "success",
          });
        } else {
          this.$message.error("更新失败！");
        }
      });
    },
    deleteItem(row) {
      ListManageApi.deleteList(row.id).then((res) => {
        const { data } = res;
        if (data.code === 0) {
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          if (this.tableData.length == 1 && this.pagination.pageNum > 1) {
            this.pagination.pageNum--;
          }
          this.getList();
        } else {
          this.$message.error("删除失败！");
        }
      });
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            options: this.form.region,
            wxTime: this.form.date.getTime().toString(),
          };
          // 添加新纪录
          ListManageApi.addRecord(params).then((res) => {
            const { data } = res;
            if (data.code === 0) {
              this.$message({
                message: "添加成功！",
                type: "success",
              });
              this.getList();
            } else {
              this.$message.error("添加失败！");
            }
          });
        } else {
          this.$message.error("添加失败！");
          return false;
        }
      });
    },
    // 初始化省-姓名
    initProvinceName() {
      ListManageApi.provinceName().then((res) => {
        this.selectArr = res.data.body;
      });
    },
    // 初始化表格
    initList() {
      this.pagination.pageNum = 1;
      this.getList();
    },
    // 分页选择器切换事件
    toPage(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getList();
    },
    // 表格数据
    async getList() {
      const params = {
        pageIndexB1: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
      };
      const { data } = await ListManageApi.getList(params);
      this.tableData = data.body.info;
      this.tableData.forEach((ele) => {
        ele.number = ele.no + 1;

        ele.time = this.$moment(Number(ele.wxTime)).format("YYYY.MM.DD hh:mm");
      });
      this.targetNumber = data.body.total;
      this.pagination.total = Number(data.body.amount);
    },
  },
};
</script>

<style scoped>
.target-box {
  display: flex;
}
.target-box > .el-button {
  margin-left: 40px;
}
.target {
  display: flex;
  align-items: center;
}
.target > .el-input {
  width: 400px;
}

.target-form {
  margin-top: 50px;
}
.target-form .box-card {
  width: 650px;
}
.target-form .el-tag {
  margin-bottom: 30px;
}
.target-form-item {
  width: 100%;
  max-width: 600px;
}

.target-list {
  margin-top: 50px;
}
</style>