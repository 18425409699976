<template>
  <d2-container>
    <div class="top">
      <div class="top-item">
        <span>课程名:</span>
        <span class="item-val">{{ this.queryForm.courseName }}</span>
      </div>
      <div class="top-item">
        <span>主讲人:</span>
        <span class="item-val">{{ this.queryForm.fullname }}</span>
      </div>
      <div class="top-item">
        <span>课程价格:</span>
        <span class="item-val">{{ this.queryForm.amount }}</span>
      </div>
      <div class="top-item">
        <span>课程状态:</span>
        <span class="item-val">{{ this.queryForm.status === 1 ?  '已上架' : '未上架'}}</span>
      </div>
    </div>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="6"
      :btnColspan="6"
      :labelWidth="'90px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
      <el-button type="primary" @click="onBackPage">返回上级</el-button>
    </search-config>
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        新增视频
      </el-button>
      <el-button
        type="success"
        size="mini"
        @click="onBatchDeleteClick"
      >
        批量删除
      </el-button>
    </div>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        @selection-change="handleSelectionChange"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:auditionStatus="{ row }">
          {{ row.auditionStatus == 1 ? "是" : "否" }}
        </template>
        <template v-slot:videoNo="{ row }">
          <el-input v-model="row.videoNo" placeholder="请输入内容" @change="onChangeClick({...row})"></el-input>
        </template>
        <template v-slot:singlePrice="{ row }">
          <el-input v-model="row.singlePrice" placeholder="请输入内容" @change="onChangePrice({...row})"></el-input>
        </template>
      </free-table>
    </div>

    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
    />
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'
// 引入页面层级组件
import AddSub from './components/addSub'

// 引入接口
import { getCourseVideoList, saveCourseVideo, updateVideoNoById, batchDelVideo } from '@/model/modules/course'

export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
    FreeTable,
    AddSub
  },
  data () {
    return {
      queryForm: {},
      titleSrc: '新增视频',
      selectId: [],

      // 搜索框渲染
      formSetData: {
        id: '',
        courseName: null,
        fullname: null,
        auditionStatus: null,
        pageIndexB1: Number,
        pageSize: Number,
        status: ''
      },
      searchConfigData: [
        {
          label: '视频名',
          formItemId: 0,
          placeholder: '请输入视频名',
          type: 'input',
          prop: 'courseName'
        },
        {
          label: '是否试听',
          formItemId: 1,
          placeholder: '选择是否试听',
          type: 'select',
          isApi: false,
          prop: 'auditionStatus',
          options: [
            {
              label: '否',
              value: 0
            },
            {
              label: '是',
              value: 1
            }
          ]
        }
        // {
        //   label: '主讲人姓名',
        //   formItemId: 1,
        //   placeholder: '请输入主讲人姓名',
        //   type: 'input',
        //   prop: 'fullname'
        // },
        // {
        //   label: '收费状态',
        //   formItemId: 2,
        //   placeholder: '选择收费状态',
        //   type: 'select',
        //   isApi: false,
        //   prop: 'auditionStatus',
        //   options: [
        //     {
        //       label: '收费',
        //       value: 1
        //     },
        //     {
        //       label: '免费',
        //       value: 2
        //     }
        //   ]
        // }
      ],

      // 表格数据渲染
      data: [],
      slotColumn: [
        { label: '选择', prop: 'selection', type: 'selection' },
        { label: 'ID', prop: 'id'},
        { label: '视频标题', prop: 'videoTitle' },
        { label: '视频名称', prop: 'courseName' },
        // { label: '主讲人', prop: 'fullname' },
        { label: '时长', prop: 'courseTime', sort: true },
        { label: '上传时间', prop: 'uploadDate', sort: true },
        { label: '是否试听', prop: 'auditionStatus', slotScope: true },
        { label: '排序', prop: 'videoNo', slotScope: true },
        { label: '单节价格', prop: 'singlePrice', slotScope: true },
        // { label: '免费/收费', prop: 'auditionStatus', slotScope: true },
        // { label: '价格', prop: 'price' },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.btnClick(1, scope.row)
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.btnClick(2, scope.row)
                  }}
                >
                  删除
                </el-button>
              </div>
            )
          }
        }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },

      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      },

      // 新增课程
      form: {
        videoUrl: '',
        videoNo: '',
        singlePrice:'',
        courseName: '',
        auditionStatus: '',
        price: '',
        courseTime: '',
        hours: '',
        minutes: '',
        seconds: '',
        soundUrl: '',
        videoTitle: ''
      },
      config: [
        {
          label: '视频链接',
          type: 'input',
          prop: 'videoUrl'
        },
        {
          label: '音频链接',
          type: 'input',
          prop: 'soundUrl'
        },
        {
          label: '视频标题',
          type: 'input',
          prop: 'videoTitle'
        },
        // {
        //   label: '序号',
        //   type: 'input',
        //   prop: 'videoNo'
        // },
        {
          label: '名称',
          type: 'input',
          prop: 'courseName'
        },
        {
          label: '是否可试听',
          type: 'select',
          prop: 'auditionStatus',
          isApi: true,
          options: [
            {
              label: '是',
              value: 1
            },
            {
              label: '否',
              value: 0
            }
          ]
        },
        // {
        //   label: '价格',
        //   type: 'input',
        //   disabled: false,
        //   prop: 'price'
        // },
        {
          label: '时长',
          type: '',
          prop: 'courseTime'
        },
        {
          label: '时',
          type: 'threeInput',
          prop: 'hours'
        },
        {
          label: '分',
          type: 'threeInput',
          prop: 'minutes'
        },
        {
          label: '秒',
          type: 'threeInput',
          prop: 'seconds'
        }
      ],
      // 表单验证
      rules: {
        videoTitle: [{ required: true, message: '请输入视频标题', trigger: 'blur' }],
        videoUrl: [{ required: true, message: '请输入视频链接', trigger: 'blur' }],
        videoNo: [{ required: true, message: '请输入序号', trigger: 'blur' }],
        courseName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        auditionStatus: [{ required: true, message: '请选择是否可试听', trigger: 'blur' }],
        price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        hours: [{ required: true, message: '请输入时', trigger: 'blur' }],
        minutes: [{ required: true, message: '请输入分', trigger: 'blur' }],
        seconds: [{ required: true, message: '请输入秒', trigger: 'blur' }]
      }
    }
  },
  watch: {
    'form.auditionStatus' (val) {
      // if (val === 2) {
      //   this.form.price = 0
      //   this.config[4].disabled = true
      // } else {
      //   this.config[4].disabled = false
      // }
    }
  },
  mounted () {
    this.queryForm = this.$route.query.form;
    if(this.queryForm.singleStatus == undefined||this.queryForm.singleStatus == 1){
      this.slotColumn.forEach((ele,index)=>{
        if(ele.prop == 'singlePrice'){
          this.slotColumn.splice(index,1);
        }
      })
    }
    this.getCourseVideoList()
  },
  methods: {
    // 列表数据
    async getCourseVideoList () {
      this.formSetData.pageIndexB1 = this.pagination.pageNum
      this.formSetData.pageSize = this.pagination.pageSize
      this.formSetData.id = this.queryForm.id
      const res = await getCourseVideoList(this.formSetData)
      this.data = res.data.body.content
      this.pagination.total = res.data.body.total * 1
    },

    // 选中
    handleSelectionChange(val) {
      this.selectId = []
      val.map(item => {
        this.selectId.push(item.id)
      })
    },

    // 表格查询按钮
    onQuery () {
      this.pagination.pageNum = 1
      this.getCourseVideoList()
    },
    // 返回上一级
    onBackPage () {
      this.$router.go(-1)
    },

    // 批量删除
    onBatchDeleteClick() {
      if (this.selectId.length > 0) {
      this.$confirm('确认批量删除课程么？删除课程后不可恢复。', '提示', {}).then(() => {
        batchDelVideo({id: this.selectId}).then(res => {
          if (res.data.code === 0) {
            this.getCourseVideoList()
          }
        })
      }).catch(() => {})
      } else {
        this.$message({
          message: '批量操作失败，请先勾选操作内容',
          type: 'warning'
        });
      }
    },

    // 新增课程
    onAdd () {
      this.titleSrc = '新增视频'
      this.form = {
        videoUrl: '',
        videoNo: '',
        singlePrice:'',
        courseName: '',
        auditionStatus: '',
        price: '',
        courseTime: '',
        hours: '',
        minutes: '',
        seconds: '',
        soundUrl: '',
        videoTitle: ''
      }
      this.$refs.addSubDialog.showDialog(this.form)
    },

    btnClick (index, e) {
      switch (index) {
        case 1: // 修改
          this.titleSrc = '修改视频'
          e.seconds = '0'
          e.minutes = '0'
          e.hours = '0'
          const time = e.courseTime.split(':')
          if (time.length === 3) {
            e.seconds = time[2]
            e.minutes = time[1]
            e.hours = time[0]
          } else if (time.length === 2) {
            e.seconds = time[1]
            e.minutes = time[0]
          } else if (time.length === 1) {
            e.seconds = time[0]
          }

          // 根据后端需求
          // if(e.auditionStatus === 3){
          //   e.auditionStatus = 1
          // }else{
          //   e.auditionStatus = 2
          // }

          this.form = {
            ...e
          }
          this.$refs.addSubDialog.showDialog(this.form)
          break
        case 2: // 删除
          let msg = "删除后该视频不可恢复且视频排序会发生改变,确认删除视频：("+ e.courseName +")么？"
          this.$confirm(msg, '提示', {})
            .then(() => {
              this.listLoading = true
              e.status = -1
              e.courseTime = e.courseTimeLong
              saveCourseVideo(e).then((res) => {
                if (res.data.code === 0) {
                  this.getCourseVideoList()
                  this.$message({
                    message: '操作成功',
                    type: 'success',
                    duration: 1500
                  })
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            })
            .catch(() => {})
          break
        default:
          this.$message.error('未定义')
          break
      }
    },

    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.getCourseVideoList()
    },

    handleQueryFocus (e) {},

    // 排序
    onChangeClick(e) {
      const data = {
        id: e.id,
        videoNo: e.videoNo
      }
      updateVideoNoById(data).then(res => {
        if (res.data.code === 0) {
          this.getCourseVideoList()
        }
      })
    },
    // 修改价格
    onChangePrice(e) {
      e.courseTime = e.courseTimeLong;
      saveCourseVideo(e).then((res) => {
        console.log(res.data.code)
        if (res.data.code === 0) {
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 1500
            })
          this.getCourseVideoList()
        }
      })
    },

    // 提交编辑表格行信息
    onEditSubmit (e) {
      e.form.pid = this.queryForm.id
      e.form.uid = this.queryForm.uid
      e.form.courseTime =
        parseFloat(e.form.hours * 3600) +
        parseFloat(e.form.minutes * 60) +
        parseFloat(e.form.seconds)
      saveCourseVideo(e.form).then((res) => {
        if (res.data.code === 0) {
          this.getCourseVideoList()
        }
      })
    },

    handleFocus (e) {},

    formatData (data, key, val) {
      const obj = {}
      for (const i in data) {
        const item = data[i]
        obj[item[key]] = {
          value: item[key] || '',
          label: item[val] || ''
        }
      }
      return obj
    }
  }
}
</script>

<style scoped>
.top {
  display: flex;
  margin: 30px 0;
}
.top-item {
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  margin-right: 70px;
}
.item-val {
  margin-left: 9px;
}

.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
