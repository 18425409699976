const apiUrl = {
  getPageList: `/service/role/list`, // get分页列表
  getMenuTree: `/service/menu/menuTree`, //权限菜单树
  updateRoleInfo: `/service/role/save`, // 编辑
  closeOrOpen: `/service/role/closeOrOpen`, //启用禁用
  getRoleMenuListBykey: `/service/roleMenu/menuIdListById/`, //根据id，查询分配的权限
  uriFetchMenuTree: `/service/menu/tree`, //根据id，查询分配的权限
  uriGrantMenu2Role: `/service/roleMenu/grant`, //分配权限
  uriUngrantMenuOfRole: `/service/roleMenu/ungrant`, //分配权限
}

export default apiUrl
 