<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="4"
      :labelWidth="'70px'"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>

    <el-form 
      :model="data" 
      label-width="90px" 
      label-position="left"
      ref="formData"
    >
      <el-row :gutter="20">
        <el-col :span="8" v-for="(item, index) in data.image" :key="index">
          <el-form-item label="" prop="image">
            <img :src="item">
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="药品批次：" prop="batchName">
            {{ data.batchName }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="生产日期：" prop="releaseTime">
            {{ data.releaseTime }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="有效期：" prop="indateTime">
            {{ data.indateTime }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="企业名：" prop="epName">
            {{ data.epName }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="生产地址：" prop="prodAdress">
            {{ data.prodAdress }}
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="注册地址：" prop="regAddress">
            {{ data.regAddress }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="企业电话：" prop="tel">
            {{ data.tel }}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业简介：" prop="">
            {{ data.enterpriseDesc }}
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
// 引入接口
import { getValidateInfo } from '@/model/modules/checkCode/index'

export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
  },
  data () {
    return {
      // 搜索框渲染
      formSetData: {
        sesCode: '',
        wxOpenId: '',
        wxUnionId: ''
      },
      searchConfigData: [
        {
          label: '防伪码',
          formItemId: 0,
          placeholder: '请输入药品的防伪码',
          type: 'input',
          prop: 'sesCode'
        }
      ],
      data: {
        image: [],                   //图
        batchName: '',               //药品批次
        releaseTime: '',             //生产日期
        indateTime: '',              //有效期
        epName: '',                  //企业名
        prodAdress: '',              //生产地址
        regAddress: '',              //注册地址
        tel: '',                     //企业电话
        enterpriseDesc: ''           //企业简介
      }
    }
  },

  mounted () {
  },
  methods: {
    async onQuery() {
      if (this.formSetData.sesCode === '') {
        this.$message.error('请输入防伪码')
        return
      }
      const res = await getValidateInfo(this.formSetData)
      if (res.data.code === 0 && res.data.body.res === 1) {
        const img = res.data.body.prodInfo.images.split(',')
        this.data.image = img,                   //图
        this.data.batchName = res.data.body.batchInfo.batchName,      //药品批次
        this.data.releaseTime = res.data.body.batchInfo.releaseTime,  //生产日期
        this.data.indateTime = res.data.body.prodInfo.effectiveOeriodInfo,    //有效期
        this.data.epName = res.data.body.batchInfo.epName,            //企业名
        this.data.prodAdress = res.data.body.enterpriseInfo.prodAdress, //生产地址
        this.data.regAddress = res.data.body.enterpriseInfo.regAddress,  //注册地址
        this.data.tel = res.data.body.enterpriseInfo.tel,                 //企业电话
        this.data.enterpriseDesc = res.data.body.enterpriseInfo.enterpriseDesc //企业简介

        this.formSetData.sesCode = ''
        this.$message({
          message: '查询成功',
          type: 'success',
          duration: 1500
        })
      } else {
        this.$message.error(res.data.body.msg)
      }
    }

  }
}
</script>

<style scoped>
</style>
