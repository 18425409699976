/**
 *  广告设置
 */
const apiUrl = {
  getPageList: '/erp/production/query',
  saveProduction: '/erp/production/save', //产品信息-保存产品
  enterpriseSelect: '/erp/enterprise/asOptions',  //企业信息-下拉列表
}

export default apiUrl
