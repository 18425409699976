/**
 * 组织管理相关的接口Api
 */
import { post, get, deletes, put } from '../../service'
import apiUrl from './api-url'

const { getPageList, insertInfo, updateInfo } = apiUrl

// 组织结构树
export const getOrgTree = (params) => {
  return post(getPageList, params)
}

// 保存实体
export const orgSave = (params) => {
  return put(insertInfo, params)
}

// 删除组织机构
export const delOrgId = (url, params) => {
  return deletes(url, params)
}

// 修改树形
export const toUpdate = (params) => {
  return post(updateInfo, params)
}
