export default class ListPageVo{

    /**
     * 页面标题.
     */
    pageTitle="";
    
    /**
     * 是否正在加载数据
     */
    loading= false;

    /**
     * 表格数据.
     */
    tableData=[];
    /**
     * 页码.
     */
    pageIndex=0;
    /**
     * 页面大小.
     */
    pageSize=20;
    /**
     * 总记录数.
     */
    pageTotal=0;
    /**
     * 备选页面大小.
     */
    pageSizeList = [10,20,50,100,1000]
}