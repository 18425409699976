var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d2-container',[_c('el-tabs',{on:{"tab-click":_vm.onTabClick},model:{value:(_vm.medicineType),callback:function ($$v) {_vm.medicineType=$$v},expression:"medicineType"}},[_c('el-tab-pane',{attrs:{"label":"中药单方","name":"1"}},[_c('search-config',{ref:"searchConfig_folk",attrs:{"configData":_vm.searchConfigData_folk,"formSet":_vm.formSetData,"colspan":5.8,"btnColspan":4,"labelWidth":'90px'}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onQuery}},[_vm._v("查询")])],1),_c('div',[_c('el-button',{attrs:{"type":"default","size":"mini","icon":"el-icon-circle-plus-outline"},on:{"click":_vm.onAdd}},[_vm._v(" 新增 ")])],1),_c('div',{staticClass:"mt20"},[_c('free-table',{attrs:{"border":"","data":_vm.data,"column":_vm.slotColumn_folk,"columnsProps":_vm.columnsProps,"pagination":"","auto-scroll":false,"pageSizes":_vm.pagination.pageSizes,"total":_vm.pagination.total,"page":_vm.pagination.pageNum,"limit":_vm.pagination.pageSize},on:{"update:total":function($event){return _vm.$set(_vm.pagination, "total", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "pageNum", $event)},"update:limit":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"pagination":_vm.getList},scopedSlots:_vm._u([{key:"recWeight",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"type":"number"},on:{"blur":function($event){return _vm.onChangeClick(row, row.recWeight)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return $event.target.blur()}},model:{value:(row.recWeight),callback:function ($$v) {_vm.$set(row, "recWeight", $$v)},expression:"row.recWeight"}})]}},{key:"weight",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"type":"number"},on:{"blur":function($event){return _vm.onChangeClick(row, row.weight)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return $event.target.blur()}},model:{value:(row.weight),callback:function ($$v) {_vm.$set(row, "weight", $$v)},expression:"row.weight"}})]}},{key:"ctm",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formData(row.ctm))+" ")]}},{key:"ctlId",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getLabelBySelectValue(_vm.dfCate, row.ctlId))+" ")]}}])})],1)],1),_c('el-tab-pane',{attrs:{"label":"中药组方","name":"2"}},[_c('search-config',{ref:"searchConfig_folk",attrs:{"configData":_vm.searchConfigData_group,"formSet":_vm.formSetData,"colspan":5.8,"btnColspan":4,"labelWidth":'90px'}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onQuery}},[_vm._v("查询")])],1),_c('div',[_c('el-button',{attrs:{"type":"default","size":"mini","icon":"el-icon-circle-plus-outline"},on:{"click":_vm.onAdd}},[_vm._v(" 新增 ")])],1),_c('div',{staticClass:"mt20"},[_c('free-table',{attrs:{"border":"","data":_vm.data,"column":_vm.slotColumn_group,"columnsProps":_vm.columnsProps,"pagination":"","auto-scroll":false,"pageSizes":_vm.pagination.pageSizes,"total":_vm.pagination.total,"page":_vm.pagination.pageNum,"limit":_vm.pagination.pageSize},on:{"update:total":function($event){return _vm.$set(_vm.pagination, "total", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "pageNum", $event)},"update:limit":function($event){return _vm.$set(_vm.pagination, "pageSize", $event)},"pagination":_vm.getList},scopedSlots:_vm._u([{key:"recommend",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"type":"number"},on:{"blur":function($event){return _vm.onChangeClick(row, row.recommend)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return $event.target.blur()}},model:{value:(row.recommend),callback:function ($$v) {_vm.$set(row, "recommend", $$v)},expression:"row.recommend"}})]}},{key:"weight",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"type":"number"},on:{"blur":function($event){return _vm.onChangeClick(row, row.weight)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return $event.target.blur()}},model:{value:(row.weight),callback:function ($$v) {_vm.$set(row, "weight", $$v)},expression:"row.weight"}})]}},{key:"ctm",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formData(row.ctm))+" ")]}},{key:"ctlId",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getLabelBySelectValue(_vm.zfCate, row.ctlId))+" ")]}}])})],1)],1)],1),_c('add-sub',{ref:"addSubDialog",attrs:{"width":"50%","align":"left","config":_vm.config,"form":_vm.form,"rules":_vm.rules,"titleSrc":_vm.titleSrc},on:{"on-edit-submit":_vm.onEditSubmit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }