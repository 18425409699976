// 秘籍管理
import { post, get, deletes } from '../../service'

// 秘籍管理
export default class MijiManageApi {
  // 获取列表
  static pageList (params = undefined) {
    return get('/service/cheats/manage/getCheatList', params)
  }

  // 新增/修改
  static saveRow (params = undefined) {
    return post('/service/cheats/manage/saveCheatDetailEntity', params)
  }
}

// 阅读物料管理
export class ReadMaterialManageApi {
  // 获取列表
  static pageList (params = undefined) {
    return get('/service/cheats/findMaterielList/' + params.id)
  }

  // 新增/修改
  static saveRow (params = undefined) {
    return post('/service/cheats/manage/saveCheatMaterielEntity', params)
  }

  // 删除
  static deleteRow (params = undefined) {
    return deletes('/service/cheats/deleteMateriel/'+params.key)
  }

  // 修改权重
  static changeWeight (params = undefined) {
    return get('/service/cheats/manage/updateWeight/' + params.id + '/' + params.weight)
  }
}
