
/**
 * 订单管理
 */
 const apiUrl ={
  consultHistory: '/service/consult/consultHistory', //咨询当前登录专家的诊所医生分页列表
  history: '/service/msg/history',
  updateOnlineStatus: '/service/msg/updateOnlineStatus', //修改在线状态
}

export default apiUrl