<template>
  <div>
    <el-dialog
      :title="titleSrc"
      :visible.sync="dialogFormVisible"
      v-bind="$attrs"
    >
      <el-form :model="form" label-width="100px" label-position="left">
        <div class="textBorderBottom">订单详情</div>
        <el-row :gutter="4">
          <el-col :span="24">
            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="订单号:" prop="orderNo">
                  {{ form.orderNo }}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="购课用户真实姓名:" prop="fullName" label-width="140px">
                  {{ form.fullName }}
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="下单时间:" prop="ctm">
                  {{ formData(form.ctm) }}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="订单状态:" prop="orderStatusStr">
                  {{ form.orderStatusStr }}
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="购课用户昵称:" prop="nickName">
                  {{ form.nickName }}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="付款时间:" prop="createPaymentTm">
                  {{ formData(form.createPaymentTm) }}
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="付款渠道:" prop="payWayStr">
                  {{ form.payWayStr }}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="购课用户手机号:" prop="tel"  label-width="120px">
                  {{ form.tel }}
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="完成时间:" prop="paymentTm">
                  {{ formData(form.paymentTm) }}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="应付金额:" prop="priceLabel">
                  {{ form.priceLabel }}
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="购买类型:" prop="buyType">
                  {{ form.buyType==0?"系列购买":"单节购买" }}
                </el-form-item>
              </el-col>
              <el-col :span="10">
 
              </el-col>
            </el-row>

            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="用券类型:" prop="couponType">
                  {{ form.couponType }}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="用券金额:" prop="couponPrice">
                  {{ form.couponPrice }}
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="三方手续费:" prop="serviceChange">
                  {{ form.serviceChange }}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="公司拟得金额:" prop="companyMoney">
                  {{ form.companyMoney }}
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <div class="textBorderBottom">课程信息</div>
        <el-row :gutter="4">
          <el-col :span="24">
            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="商品名称:" prop="goodsName">
                  {{ form.goodsName }}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="讲师名称:" prop="speakerName">
                  {{ form.speakerName }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="课程范围:" prop="courseRange">
                  {{ form.courseRange }}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="课程分类:" prop="courseClassify">
                  {{ form.courseClassify }}
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <div class="textBorderBottom" v-if="form.list">单节课程</div>
        <el-row :gutter="4" v-if="form.list">
          <el-col :span="24">
            <el-row :gutter="20" type="flex" justify="center" v-for="(item,index) in form.list" :key="index"> 
              <el-col :span="10">
                {{ item.videoName }}
              </el-col>
              <el-col :span="10"></el-col>
            </el-row>
          </el-col>
        </el-row>

      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onClose">确定</el-button>
        <el-button type="default" @click="onClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formData } from '@/utils/tool'

export default {
  name: "particulars",
  components: {},
  props: {
    titleSrc: String
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {}
    };
  },
  methods: {
    formData,
    showDialog(e) {
      this.dialogFormVisible = true;
      this.form = e;
    },
    onClose() {
      this.dialogFormVisible = false;
    }
  }
};
</script>

<style scoped>
.textBorderBottom {
  width: 100%;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  padding-left: 13px;
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 10px;
}

/deep/ .el-input__inner {
  width: 160px;
  font-size: 13px;
}
</style>
