<template>
  <d2-container>
    <!-- 查询 -->
    <el-form inline :model="searchForm">
      <el-form-item label="显示位置：">
        <el-select v-model="searchForm.pos" placeholder="请选择" clearable>
          <el-option
            v-for="item in optionList"
            :key="item.itemCode"
            :label="item.itemName"
            :value="item.itemCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-button type="primary" @click="onAdd">新增</el-button>
      <el-button @click="queryList">查询</el-button>
    </el-form>
    <!-- 添加导航按钮 -->
    <!-- <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        添加导航
      </el-button>
    </div> -->
    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:status="{ row }">
          {{ row.status == 1 ? "启用" : "禁用" }}
        </template>
        <template v-slot:imgUrl="{ row }">
          <div @click="showImg(row)" style="margin-top: 7px; cursor: pointer">
            <img :src="row.imgUrl" width="30" height="20" class="image" />
          </div>
        </template>
        <template v-slot:showPosition="{ row }">
          {{ row.showPositionName }}
        </template>
      </free-table>
    </div>
    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :config="config"
      :form="form"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
    />
    <el-dialog
      width="20%"
      class="el-dialog-col-2"
      :visible.sync="dialogVisible"
      :close-on-click-modal="true"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <!-- <el-button
      type="default"
      size="small"
      onClick={() => {
        this.upValidStatus(scope.row);
      }}
    >
      {scope.row.fValidaStatus == 0 ? "启用" : "禁用"}
    </el-button> -->
  </d2-container>
</template>
<script>
let id = 1000;
//引入查询条件配置组件
import SearchConfig from "@/views/components/searchConfigForm";
import FreeTable from "@/views/components/FreeTable";
//引入页面层级组件
import AddSub from "./components/addSub";
//引入接口
import NavServiceApi from "@/model/modules/nav";

export default {
  name: "roleinfo",
  components: {
    SearchConfig,
    FreeTable,
    AddSub,
  },
  // mixins: [TableData],
  data() {
    return {
      // 搜索条件
      searchForm: {
        pos: "",
      },
      // select
      optionList: [],
      fShowPositions: {},
      titleSrc: "幻灯片修改",
      searchConfigData: [
        {
          label: "导航名称",
          formItemId: 0,
          placeholder: "请输入导航名称",
          type: "input",
          prop: "fNaviName",
        },
        {
          label: "显示位置",
          formItemId: 1,
          placeholder: "请选择",
          type: "select",
          isApi: true,
          prop: "fShowPosition",
          options: [],
        },
      ],
      form: {
        naviName: "",
        imgUrl: "",
        linkUrl: "",
        showPosition: "",
        status: "",
      },
      config: [
        {
          label: "导航名称",
          type: "input",
          prop: "naviName",
        },
        {
          label: "显示位置",
          type: "select",
          prop: "showPosition",
          isApi: false,
          options: [],
        },
        {
          label: "链接地址",
          type: "input",
          prop: "linkUrl",
        },
        {
          label: "是否启用",
          type: "select",
          prop: "status",
          isApi: false,
          options: [
            {
              value: 1,
              label: "启用",
            },
            {
              value: 0,
              label: "禁用",
            },
          ],
        },
        {
          label: "导航图片",
          type: "uploadAvatar",
          prop: "imgUrl",
        },
      ],
      data: [], //表格数据渲染
      slotColumn: [
        //{ label: "选择", prop: "selection", type: "selection" },
        //  { label: '序号', prop: 'index', type: 'index' },
        { label: "名称", prop: "naviName" },
        { label: "图片", prop: "imgUrl", slotScope: true },
        { label: "链接", prop: "linkUrl" },
        { label: "显示位置", prop: "showPosition", slotScope: true },
        { label: "状态", prop: "status", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  编辑
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.handleDelete(scope.row);
                  }}
                >
                  {scope.row.status == 0 ? "启用" : "禁用"}
                </el-button>
              </div>
            );
          },
        },
      ],
      formSetData: {
        // 查询搜索内容
        pageIndexB1: Number,
        pageSize: Number,
      },
      columnsProps: {
        width: "auto",
        showOverflowTooltip: true,
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 1,
      },
      dialogImageUrl: "",
      dialogVisible: false,
      // 表单验证
      rules: {
        naviName: [
          { required: true, message: "请输入标题名称", trigger: "blur" },
        ],
        showPosition: [
          { required: true, message: "请输入显示位置", trigger: "blur" },
        ],
        linkUrl: [{ required: true, message: "请输链接地址", trigger: "blur" }],
        status: [{ required: true, message: "请选择状态", trigger: "blur" }],
        imgUrl: [
          { required: true, message: "请选择导航图片", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getPosList();
  },
  methods: {
    queryList(){
      this.pagination.pageNum = 1;
      this.pagination.pageSize = 10;
      this.getDataList();
    },
    getPosList() {
      NavServiceApi.getPos().then((res) => {
        if (res.data.code == 0) {
          this.optionList = res.data.body;
          this.searchForm.pos = this.optionList[0].itemCode;
          this.config[1].options = this.optionList.map((ele) => ({
            value: ele.itemCode,
            label: ele.itemName,
          }));
          this.getDataList();
        }
      });
    },
    //  图片放大
    showImg(row) {
      this.dialogImageUrl = row.imgUrl;
      this.dialogVisible = true;
    },
    formatData(data) {
      let obj = {};
      for (let i in data) {
        let item = data[i];
        item.fDicKey = item.fDicKey || "";
        if (!item.fDicKey) {
          continue;
        }
        obj[item.fDicKey] = {
          value: item.fDicKey || "",
          label: item.fDicValue || "",
        };
      }
      return obj;
    },
    //初始化表格渲染
    async getDataList() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      this.formSetData.showPos = this.searchForm.pos;
      let res = await NavServiceApi.pageList(this.formSetData);
      this.data = res.data.body.content;

      this.data.forEach(ele=>{
        ele.showPositionName = this.optionList.filter(item=>(item.itemCode == ele.showPosition))[0].itemName;
      })

      this.pagination.total = res.data.body.total * 1;
    },
    //提交编辑表格行信息
    async onEditSubmit(e) {
      if (this.titleSrc == "导航修改") {
        // let res = await dicList({type: 'HOS_NAVI_POSITION'})
        // let {data} = res.data
        // let arr = data.filter((item,index)=>{
        //   return e.form.fShowPosition == item.fDicValue
        // })
        // console.log(arr,"arr====")
        let form = {
          ...e.form,
          // fShowPosition:arr[0].fDicKey
        };
        NavServiceApi.editRow(form)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 1500,
              });
              this.getDataList();
            } else {
              console.error(res);
              this.$message.error("修改失败");
            }
          })
          .catch((err) => {
            console.error(err);
            this.$message.error("修改失败");
          });
      } else if (this.titleSrc == "导航添加") {
        this.form = e.form;
        NavServiceApi.addRow(this.form)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "添加成功",
                type: "success",
                duration: 1500,
              });
              this.pagination.pageNum = 1;
              (this.form = {
                naviName: "",
                imgUrl: "",
                linkUrl: "",
                showPosition: "",
                status: "",
              }),
                this.getDataList();
            } else {
              console.error(res);
              this.$message.error("添加失败");
            }
          })
          .catch((err) => {
            console.error(err);
            this.$message.error("添加失败");
          });
      }
    },
    //打开编辑弹窗
    openDialog({ row }) {
      this.form = {
        ...row,
        // fShowPosition:this.fShowPositions[row.fShowPosition].label
      };
      this.$refs.addSubDialog.showDialog(row);
      this.titleSrc = "导航修改";
    },
    onAdd() {
      this.form = {
        naviName: "",
        imgUrl: "",
        linkUrl: "",
        showPosition: "",
        status: "",
      };
      this.$refs.addSubDialog.showDialog(this.form);
      this.titleSrc = "导航添加";
    },
    handleDelete(row) {
      this.$confirm("确认禁用吗？", "提示", {})
        .then(() => {
          let params = {
            id: row.id,
          };

          NavServiceApi.deleteRow(params)
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1500,
                });
                this.getDataList();
              } else {
                console.error(res);
                this.$message.error("操作失败");
              }
            })
            .catch((err) => {
              console.error(err);
              this.$message.error("操作失败");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 启动/禁用
    upValidStatus(_dat) {
      let sts = _dat.fValidaStatus ? 0 : 1;
      let mess = _dat.fValidaStatus ? "确定禁用吗？" : "确定启用吗？";
      this.$confirm(mess, "提示", {}).then(() => {
        this.listLoading = true;
        let para = {
          fId: _dat.fId,
          fValidaStatus: sts,
        };
        NavServiceApi.changeStatus(para)
          .then((response) => {
            if (response.data.data > 0) {
              this.listLoading = false;
              _dat.fValidaStatus = _dat.fValidaStatus ? 0 : 1;
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
              });
            }
          })
          .catch((response) => {
            this.listLoading = false;
            console.error(response);
            this.$message.error("操作失败");
          });
      });
    },
    //表格查询按钮
    onQuery() {
      this.pagination.pageNum = 1;
      this.getDataList();
    },
    //分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getDataList();
    },
    handleFocus(e) {
      this.config[e.index].options = this.fShowPositions;
    },
    handleQueryFocus(e) {
      this.searchConfigData[e.index].options = this.fShowPositions;
    },
  },
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
