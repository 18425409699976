import { post, get, deletes } from '../../../service'

export default class couponManageListApi {
  // 获取列表
  static pageList (params = undefined) {
    return post('/service/coupon/findList', params)
  }

  // 新增/修改
  static saveRow (params = undefined) {
    return post('/service/coupon/save', params)
  }

  // 删除
  static deleteRow (params = undefined) {
    return get('/service/coupon/deleteById/'+params.key)
  }

  // 获取适用课程范围
  static getApplyCourse (params = undefined) {
    return post('/service/coupon/applyCourse', params)
  }

  // 获取优惠券类型
  static getCouponType (params = undefined) {
    return post('/service/coupon/couponType', params)
  }

  // 获取接受上限
  static getNum (params = undefined) {
    return post('/service/coupon/num', params)
  }

  // 获取时效
  static getDateTime (params = undefined) {
    return post('/service/coupon/dateTime', params)
  }

  // 获取适用课程分类
  // static getCourseType (params = undefined) {
  //   return post('/service/coupon/courseType', params)
  // }

  // 获取适用课程分类
  static getCourseType (params = undefined) {
    return post('/service/clg/tree', params)
  }

  // 获取发送角色
  static getSendRole (params = undefined) {
    return post('/service/coupon/sendRole', params)
  }

  // 启用禁用
  static getUpValidStatus (params = undefined) {
    return post('/service/coupon/disable', params)
  }
  // 适用课程
  static findCourseList(param = undefined){
    return post("/service/coupon/hold/findCourseList",param)
  }

  // 通用优惠券指定人搜索
  static findCommonCouponUsers(param = undefined){
    return post("/service/coupon/hold/findCommonCouponUsers",param)
  }
}
