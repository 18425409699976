<template>
  <d2-container>
    <div class="knowledgeCase">
    <el-tabs v-model="activeName">
      <el-tab-pane label="医书管理" name="YS">
        <tab-pane-item :type="activeName" v-if="activeName=='YS'"></tab-pane-item>
      </el-tab-pane>
      <el-tab-pane label="医案管理" name="YA">
        <tab-pane-item :type="activeName" v-if="activeName=='YA'"></tab-pane-item>
      </el-tab-pane>
      <el-tab-pane label="医药管理" name="YY">
        <tab-pane-item :type="activeName" v-if="activeName=='YY'"></tab-pane-item>
      </el-tab-pane>
    </el-tabs>
    </div>
  </d2-container>
</template>

<script>
import tabPaneItem from './components/tabPaneItem.vue'

export default {
  components: {
    tabPaneItem
  },
  data() {
    return {
      activeName: 'YS'
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
