<template>
  <d2-container>
    <!--查询条件配置组件-->
    <!-- <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="5"
      :labelWidth="'80px'"
    >
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </search-config> -->
    <!-- 新建角色按钮 -->
    <div>
      <el-button
        type="default"
        icon="el-icon-circle-plus-outline"
        @click="onAddRole"
      >
        新建角色
      </el-button>
    </div>
    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:status="{ row }">
          {{ row.status == 1 ? "启用" : "禁用" }}
        </template>
      </free-table>
    </div>

    <!--新建/查看角色-->
    <el-dialog
      :title="titleDialog"
      :visible.sync="dialogVisible"
      class="dialogClass"
    >
      <el-form
        :model="editForm"
        label-width="100px"
        :rules="rules"
        ref="formData"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="角色名称" prop="roleName">
              <el-input
                v-model="editForm.roleName"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="角色代码" prop="roleCode">
              <el-input
                v-model="editForm.roleCode"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="启动状态" prop="status">
              <el-select v-model="editForm.status">
                <el-option v-for="item in filterData.status" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="角色说明" prop="fUserMobile">
              <el-input
                v-model="editForm.roleDesc"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit()">提交</el-button>
      </div>
    </el-dialog>

    <!--分配权限界面-->
    <el-dialog
      title="分配角色权限"
      :visible.sync="treeVisble"
      :close-on-click-modal="true"
      :before-close="treeOnClose"
    >
      <el-tree
        check-on-click-node
        :expand-on-click-node="false"
        :highlight-current="true"
        :data="roleTreeData"
        :props="defaultProps"
        show-checkbox
        node-key="id"
        ref="tree"
        :default-expanded-keys="openTreeChecked"
        :default-checked-keys="defaultKeys"
        @check-change="onCheckChanged"
      >
      </el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="treeOnClose">关闭</el-button>
        <!--
        <el-button
          type="primary"
          @click.native="treeSubmit"
          :loading="treeLoading"
          >提交</el-button
        >
        -->
      </div>
    </el-dialog>
    
  </d2-container>
</template>
<script>
//引入查询条件配置组件
import SearchConfig from "@/views/components/searchConfigForm";
import FreeTable from "@/views/components/FreeTable";
//引入接口
import {
  getMenuTreeApi,
  pageList,
  queryUpdateRoleInfo,
  closeOrOpenInfo,
  roleMenuListBykey,
  postFetchMenuTree,
  postGrant,
  postUngrant,
} from "@/model/modules/role";
export default {
  name: "roleinfo",
  components: {
    SearchConfig,
    FreeTable
  },
  data() {
    return {
      filterData: {
        status: [
          { value: 1, label: '启用' },
          { value: 0, label: '禁用' }
        ]
      },
      rules: {
        // 表单验证
        roleName: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
        status: [{ required: true, message: "请输选择启动状态", trigger: "blur" }]
      },
      searchConfigData: [
        {
          label: "启用状态",
          formItemId: 0,
          placeholder: "请选择",
          type: "select",
          prop: "status",
          isApi: false,
          options: [
            {
              value: "1",
              label: "启用"
            },
            {
              value: "0",
              label: "禁用"
            }
          ]
        },
        {
          label: "角色名称",
          formItemId: 1,
          placeholder: "角色名称",
          type: "input",
          prop: "roleName"
        }
      ],
      data: [], //表格数据渲染
      slotColumn: [
        { label: "角色名称", prop: "roleName" },
        { label: "角色代码", prop: "roleCode" },
        { label: "角色描述", prop: "roleDesc" },
        { label: "启用状态", prop: "status", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.upValidStatus(scope.row);
                  }}
                >
                  {scope.row.status == 0 ? "启用" : "禁用"}
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.openDialog(scope);
                  }}
                >
                  编辑
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.treeOnopen(scope.row);
                  }}
                >
                  分配权限
                </el-button>
              </div>
            );
          }
        }
      ],
      formSetData: {
        // 查询搜索内容
        pageIndexB1: 0,
        pageSize: 0
      },
      columnsProps: {
        width: "auto"
      },
      total: 0,
      editForm: {
        roleName:"",
        roleDesc:"",
        status:""
      },
      titleDialog:"新增角色信息",
      dialogVisible: false,
      pagination: {
        // 分页
        pageNum: 1,
        pageSize: 10,
        //pageSizes: [2, 3, 4],
        total: 1
      },
      treeVisble: false, // 分配权限界面是否显示
      roleTreeData: [], // 树形结构数据
      defaultProps: {
        // 树形结构
        children: "children",
        label: "menuName"
      },
      defaultKeys: [], // 回滚菜单check
      openTreeChecked: [], // 打开被选中树的结构
      treeLoading: false, // tree提交按钮
      rowRoleinfo: {} // 一行角色数据
    };
  },
  mounted() {
    this.getDataList();
    this.getMenuTree()
  },
  methods: {
    async getDataList() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      let res = await pageList(this.formSetData);
      this.data = res.data.body.content;
      this.pagination.total = res.data.body.total * 1;
    },
    // 获取权限菜单
    getMenuTree(){
      getMenuTreeApi()
      .then(res => {
        if(res.data.code != 0) return
        this.roleTreeData = res.data.body
      }).catch(err => {
        console.log(err)
      })
    },
    editSubmit() {
      if (this.titleDialog == "新增角色信息") {
        this.$refs.formData.validate(valid => {
          if (!valid) return;
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            queryUpdateRoleInfo(this.editForm).then(res => {
              this.getDataList();
              this.dialogVisible = false;
            });
          }).catch(()=>{});
        });
      } else if (this.titleDialog == "编辑角色信息") {
        this.$refs.formData.validate(valid => {
          if (!valid) return;
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            queryUpdateRoleInfo(this.editForm).then(res => {
              this.getDataList();
              this.dialogVisible = false;
            });
          }).catch(()=>{});;
        });
      }
    },
    // 关闭菜单权限分配编辑弹窗
    treeOnClose() {
      this.treeVisble = false;
    },
    // 打开菜单权限分配编辑弹窗
    treeOnopen(_row) {
      this.rowRoleinfo = _row; // 一行角色数据
      _row.treeDialogLoading = true
      this.data.selectedRoleId = _row.id;
      postFetchMenuTree({ id: _row.id })
        .then(response => {
          _row.treeDialogLoading = false;
          if (!response.data.code===0) return;
          this.treeDialogLoading = false;
          this.treeVisble = true;
          const treeData = response.data.body;
          roleMenuListBykey({id:_row.id}).then(resp=>{
            this.roleTreeData = response.data.body;
            this.defaultKeys = resp.data.body;
          })
        })
        .catch(response => {});
    },
    // 提交分配权限
    treeSubmit() {
      this.$confirm("确认提交吗？", "提示", {}).then(() => {
        this.treeLoading = true;
        let treekey = this.$refs.tree.getCheckedKeys();
        queryUpdateRoleMenuInfo({
          fid: this.rowRoleinfo.fId,
          status: this.rowRoleinfo.fValidaStatus,
          menucode: treekey.join()
        })
          .then(response => {
            this.treeLoading = false;
            if (!response.data.success) return;
            this.treeVisble = false;
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500
            });
            this.getDataList();
          })
          .catch(response => {
            this.treeLoading = false;
            this.$message.error("操作失败");
          });
      });
    },
    // 启动/禁用
    upValidStatus(_dat) {
      let mess = _dat.status ? "确定禁用吗？" : "确定启用吗？";
      this.$confirm(mess, "提示", {}).then(() => {
        this.listLoading = true;
        let para = {
          roleCode: _dat.roleCode,
        };
        closeOrOpenInfo(para)
          .then(response => {
            if (response.data.code == 0) {
              this.listLoading = false;
              this.getDataList();
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500
              });
            } else {
              this.$message({
                message: "操作失败",
                type: "success",
                duration: 1500
              });
            }
          })
          .catch(response => {
            this.listLoading = false;
            console.error(response);
            this.$message.error("操作失败");
          });
      }).catch(err => {});
    },
    onSubmit() {
      this.pagination.pageNum = 1;
      this.getDataList();
    },
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getDataList();
    },
    openDialog({ row }) {
      this.editForm = {
        ...row,
      };
      this.dialogVisible = true;
      this.titleDialog = "编辑角色信息";
    },
    onAddRole() {
      this.editForm = {};
      this.dialogVisible = true;
      this.titleDialog = "新增角色信息";
    },
    onCheckChanged(menu, checked){
      if(checked){
        postGrant(this.data.selectedRoleId, menu.id)
      }else{
        postUngrant(this.data.selectedRoleId, menu.id)
      }
    }
  }
};
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
