import { postnoAuth } from '../../service'
import apiUrl from './api-url'
// 获取列表
export const pageList = (params = undefined) => {
  return postnoAuth(apiUrl.getPageList, params, '/bjl', {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  })
}
// 删除
export const deleteRow = (params = undefined) => {
  return postnoAuth(apiUrl.deleteInfo, params, '/bjl', {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  })
}

// 增加行
export const addRow = (params = undefined) => {
  return postnoAuth(apiUrl.insertInfo, params, '/bjl', {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  })
}

//
export const editRow = (params = undefined) => {
  return postnoAuth(apiUrl.updateInfo, params, '/bjl', {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  })
}

export const changeStatus = (params = undefined) => {
  return postnoAuth(apiUrl.updateStatus, params, '/bjl', {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  })
}
