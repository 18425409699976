<template>
    <d2-container>
        <div>
            <span class="title">诊所合伙人身份自动认证：</span>
            <el-switch
                v-model="value"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="apiChangeState"
            ></el-switch>
        </div>
    </d2-container>
</template>
<script>
import Api from '@/model/modules/audit_set'
export default {
    name: 'menu_audit_set',
    data() {
        return {
            value: false
        }
    },
    mounted() {
        this.apiGetState();
    },
    methods: {
        async apiGetState() {
            const { data } = await Api.loadState();
            this.value = Boolean(Number(data.body));

        },
        async apiChangeState() {
            await Api.loadChangeState(Number(this.value).toString());
            if (code !== 0) {
                this.value = !this.value
            }
        }
    },
}
</script>
<style>
.title {
    vertical-align: middle;
}
</style>