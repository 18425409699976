<template>
  <div>
    <el-dialog :title="titleSrc" :visible.sync="dialogFormVisible" v-bind="$attrs">
      <!--查询条件配置组件-->
      <search-config
            ref="searchConfig"
            :configData="searchConfigData"
            :formSet="formSetData"
            :rules="rules"
            :colspan="12"
            :labelWidth="'120px'"
            :btnColspan="24"
        >
      </search-config>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onEditSubmit">提交</el-button>
        <el-button type="default" @click="onClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 接口对接
import { addKeyDicPage } from '@/model/modules/dict'
import SearchConfig from '@/views/components/searchConfigForm'
export default {
  name: 'addSub',
  components: {
    SearchConfig
  },
  props: {
    searchConfigData: Array,
    form: Object,
    rules: Object,
    titleSrc: String
  },
  data () {
    return {
      type: '', // 类型 update为修改 detail详情
      dialogFormVisible: false,
      activeIndex: '1',
      formSetData: {}
    }
  },
  watch: {
    form(){
      this.formSetData = this.form
    }
  },
  methods: {
    showDialog () {
      this.dialogFormVisible = true
    },
    // showDialog (e = {}) {
    //   console.log(e, 'jdcehdvhduivh===')
    //   console.log(this.form)
    //   this.dialogFormVisible = true
    //   this.formSetData = e
    // },
    onEditSubmit () {
      this.$refs.searchConfig.$children[0].validate(valid => {
        if (!valid) return
        this.$confirm('确认提交吗？', '提示', {}).then(() => {
          this.$emit('on-edit-submit', { form: this.formSetData, visible: false })
          this.onClose()
        })
      })
    },
    onClose () {
      this.dialogFormVisible = false
    }
  }
}
</script>
<style scoped>
.mt20{
    margin-top:20px;
}
/deep/.el-dialog__body {
  padding: 5px 20px;
}
/deep/.el-menu--horizontal > .el-menu-item {
  height: 55px;
}
/deep/ .el-dialog {
  min-width: 1000px;
}
/deep/ .el-dialog__footer {
  text-align: center;
}
/deep/ .el-menu.el-menu--horizontal {
  border: none;
}
/deep/.el-dialog {
  margin-top: 8vh !important;
}
/deep/ .el-form-item__label {
  font-weight: bold;
  color: #606266 !important;
}
</style>
