import { get, post, deletes } from '../../service'

export default class Examine {
    // 列表
    static list(params = undefined) {
        return post('/service/case/ManageAuditCaseList', params)
    }
    // 审核
    static check(params = undefined) {
        return post('/service/case/caseAudit', params)
    }
}
