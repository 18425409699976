/**
 * 菜单管理相关的接口Api
 */
import { post, put, deletes } from '../../service'
import apiUrl from './api-url'

// 获取菜单列表
export const menuList = (params = undefined) => {
  return post(apiUrl.getMenuList, params, process.env.VUE_APP_API_URL_BASE, {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  })
}

// 提交
export const addSysMenu = (params = undefined) => {
  return put(apiUrl.insertSysMenu, params)
}

export const delMenu = (params = undefined) => {
  return deletes(apiUrl.delMenu + '/' + params.id, {}, process.env.VUE_APP_API_URL_BASE, {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  })
}
