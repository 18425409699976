<template>
  <div>
    <el-dialog
      :title="titleSrc"
      :visible.sync="dialogFormVisible"
      v-bind="$attrs"
    >
      <el-form :model="form" label-width="100px" label-position="left">
        <el-row :gutter="4" >
          <el-col :span="24">
            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="购买用户id:" prop="q">
                  {{form.q}}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="交易平台:" prop="q">
                  {{form.q}}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="购买用户名:" prop="q">
                  {{form.q}}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="支付渠道:" prop="q">
                  {{form.q}}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="购买金币个数:" prop="q">
                  {{form.q}}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="订单号:" prop="q">
                  {{form.q}}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="实际支付金额:" prop="q">
                  {{form.q}}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="创建时间:" prop="q">
                  {{form.q}}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="10">
                <el-form-item label="手续费:" prop="q">
                  {{form.q}}
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="完成时间:" prop="q">
                  {{form.q}}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="center">
              <el-col :span="20">
                <el-form-item label="订单状态:" prop="q">
                  {{form.q}}
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onClose">确定</el-button>
        <el-button type="default" @click="onClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'particulars',
  components: {

  },
  props: {
    titleSrc: String
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {}
    };
  },
  methods: {
    showDialog(e) {
      console.log("particulars:",e);
      this.dialogFormVisible = true;
      this.form = e;
    },
    onClose() {
      this.dialogFormVisible = false;
    }
  }
};
</script>

<style scoped>

.textBorderBottom {
  width: 100%;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  padding-left: 13px;
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 10px;
}

/deep/ .el-input__inner {
  width: 160px;
  font-size: 13px;
}

</style>
