<template>
  <div>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5.8"
      :btnColspan="5"
      :labelWidth="'100px'"
      @on-focus-select="handleFocus"
      @on-change-select="handleChange"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
      <el-button type="primary" @click="onResetFormClick()">重置</el-button>
    </search-config>
    <div>
      <el-button type="default" size="mini" @click="onExportClick">导出</el-button>
    </div>

     <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:status="{ row }">
           <el-switch
            v-model="row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="handleSwitchFocus(row)"
          >
          </el-switch>
        </template>
      </free-table>
    </div>

    <!--添加/修改弹窗-->
    <partculars ref="partculars" :form='form' />

    <!--菜单授权界面-->
    <el-dialog
      title="菜单授权"
      :visible.sync="showDiaGrantMenus"
      :close-on-click-modal="true"
    >
      <el-tree
        check-on-click-node
        :expand-on-click-node="false"
        :highlight-current="true"
        :data="allRoles"
        :props="roleTreeProps"
        show-checkbox
        node-key="id"
        ref="tree"
        :default-checked-keys="selectedUserRoleIds"
        @check-change="onCheckMenuChanged"
      >
      </el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="showDiaGrantMenus = false">关闭</el-button>
      </div>
    </el-dialog>


  </div>

</template>

<script>
// 引入接口
import { getUserVisitorInfo, getVisitorDetailInfo, enableDisableInfo, postAllRoles, getUserRoleIds, postGrantRole2User, postUngrantRoleOfUser, cancelUser, deposit } from '@/model/modules/userinfo'

import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'

import partculars from './components/particulars'

export default {
  name: 'visitor',
  components: {
    SearchConfig,
    FreeTable,
    partculars
  },
  data() {
    return {
      allRoles: [],
      selectedUserRoleIds: [],
      showDiaGrantMenus: false, //是否显示授权对话框
      roleTreeProps: {
        // 树形结构
        children: "children",
        label: "roleName"
      },
      // 搜索框渲染
      formSetData: {
        val: '',
        userNameVal: '',
        idVal: '',
        telVal: '',
        status: '0',
        pageIndexB1: Number,
        pageSize: Number
      },
      searchConfigData: [
        {
          label: '',
          formItemId: 0,
          type: 'multipleBoxes',
          configDatas: [
            {
              label: '',
              formItemId: 0,
              placeholder: '请选择',
              type: 'select',
              isApi: true,
              isApi1: true,
              prop: 'val',
              width: 130,
              options: [
                {
                  label: '用户名',
                  value: 'userNameVal'
                },
                {
                  label: 'ID',
                  value: 'idVal'
                },
                {
                  label: '电话',
                  value: 'telVal'
                },
              ]
            },
            {
              label: '',
              formItemId: 1,
              placeholder: '请输入',
              type: 'input',
              prop: '',
              width: 170,
            }
          ]
        },
        {
          label: '用户状态',
          formItemId: 1,
          placeholder: '请输选择用户状态',
          type: 'select',
          prop: 'status',
          isApi: false,
          options: [
            {
              label: '全部',
              value: '0'
            },
            {
              label: '启用',
              value: '1'
            },
            {
              label: '禁用',
              value: '2'
            },
          ]
        }
      ],

      // 表格数据渲染
      data: [
      ],
      slotColumn: [
        { label: '选择', prop: 'selection', type: 'selection' },
        { label: 'ID', prop: 'id' },
        { label: '用户名', prop: 'nickName' },
        { label: '电话', prop: 'tel' },
        { label: '康驰币', prop: 'kkc' },
        { label: '注册时间', prop: 'regTmStr', sort: true },
        { label: '用户状态', prop: 'status', slotScope: true },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '370px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onDetailsClick(scope.row)
                  }}
                >
                  详情
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={()=>{
                    this.onClickSetRoles(scope)
                  }}
                >
                  设置角色
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={()=>{
                    this.onClickDeposit(scope)
                  }}
                >
                  充值
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={()=>{
                    this.onCancelUser(scope)
                  }}
                >
                  注销用户
                </el-button>
              </div>
            )
          }
        }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },
      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      },

      form: {
        nickName: '',
        tel: '',
        remark: '',
        avatar: ''
      }

    }
  },
  mounted () {
    this.getUserVisitorInfo();
  },
  methods: {
    async getUserVisitorInfo() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum
      this.formSetData.pageSize = this.pagination.pageSize
      const res = await getUserVisitorInfo(this.formSetData);
      if (res.data.code === 0) {
        res.data.body.content.map((item, index) => {
          item.status = Number(item.status) === 0 ? false : true
        })
        this.data = res.data.body.content
        this.pagination.total = res.data.body.total * 1
      }

    },

    onCheckMenuChanged(role, checked){
      if(checked){
        postGrantRole2User(this.selectedUser.id, role.id)
      }else{
        postUngrantRoleOfUser(this.selectedUser.id, role.id)
      }
    },

    // 充值
    onClickDeposit({row}) {
      this.$prompt('请输入康驰币数量', '康驰币充值', {
        showInput: true,
        inputType: 'number',
        inputPattern: /(^[0-9]\d*$)/,
        inputErrorMessage: '请输入0或正整数'
      }).then((res) => {
        let params = {
          reChargeNum: Number(res.value),
          userId: row.id
        }

        deposit(params).then(res => {
          console.log(res);
          if(res.data.code == 0){
            this.$message({
              type: 'success',
              message: '充值成功',
              duration: 1500
            })
            this.getUserVisitorInfo()
          }else {
            this.$message({
              type: 'error',
              message: res.data.msg,
              duration: 1500
            })
          }
        })

      })
    },

    // 注销用户
    onCancelUser(data){
      let params = {
        tel: data.row.tel
      }
      this.$confirm('确认提交吗？', '提示', {}).then(() => {
        cancelUser(params).then(res => {
          if (res.data.code === 0) {
            this.getUserVisitorInfo()
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
      })
    },

    // 设置角色
    onClickSetRoles(scope){
      this.selectedUser=scope.row;
      this.selectedUserRoleIds = [];
      postAllRoles().then(resp=>{
        this.allRoles = resp.data.body.content;
        //console.log(scope.row)
        getUserRoleIds(scope.row.id).then(respRids=>{
            this.selectedUserRoleIds = respRids.data.body;
        }).finally(()=>{
          //打开角色授权对话框
          this.showDiaGrantMenus=true;
        })
      })
    },

    // 搜索框下拉
    handleFocus (e) {
    },
    handleChange(item) {
      let val = item.configDatas[1].prop
      if (val !== '') {
        this.formSetData[val] = ''
      }
      item.configDatas[1].prop = this.formSetData.val
    },
    // 查询
    onQuery() {
      this.pagination.pageNum = 1
      this.getUserVisitorInfo()
    },
    // 重置
    onResetFormClick() {
      this.formSetData = {
        val: '',
        userNameVal: '',
        idVal: '',
        telVal: '',
        status: '0',
        pageIndexB1: Number,
        pageSize: Number
      }
    },
    // 导出
    onExportClick() {

    },

    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.getUserVisitorInfo();
    },

    // 用户切换状态
    handleSwitchFocus(e) {
      enableDisableInfo({ userId: e.id }).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500
          })
          this.getUserVisitorInfo();
        }
      })
    },

    // 详情
    onDetailsClick(e) {
      getVisitorDetailInfo({ id: e.id }).then(res => {
        if (res.data.code === 0) {
          this.form = {
            ...res.data.body
          }
          this.$refs.partculars.showDialog(this.form)
        }
      })
    }
  }

}
</script>
<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>
