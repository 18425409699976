import { deletes, post, get } from "../../service";

export default class addressApi {
  static pageList(params = undefined) {
    return post("/service/address/findList", params);
  }

  // 省市区树
  static getAddressTrees(params = undefined) {
    return get("/service/address/getAreaTree", params);
  }

  // 新增修改
  static saveRow(params = undefined) {
    return post("/service/address/save", params);
  }

// 删除
static deleteRow(params = undefined) {
  return deletes("/service/address/delete/" + params.id)
}







  // 字典表
  static getDicListByCateCode(cateCode = undefined) {
    let params = {
      cateCode: cateCode,
      pageIndexB1: 1,
      pageSize: 50
    };
    return post("/service/Dict/listByCateCode", params);
  }



  // 详情
  static getDetailById(params = undefined) {
    let url = "";
    if (params.type == "YS") {
      url = "/service/knowledge/manage/getBookInfo/" + params.id;
    } else if (params.type == "YA") {
      url = "/service/knowledge/manage/getCaseInfo/" + params.id;
    } else if (params.type == "YY") {
      url = "/service/knowledge/manage/getDrugsInfo/" + params.id;
    }
    return get(url, params);
  }



  // 新增
  static add(params = undefined, type = undefined) {
    let url = "";
    if (type == "YS") {
      url = "/service/knowledge/manage/saveBook";
    } else if (type == "YA") {
      url = "/service/knowledge/manage/saveCase";
    } else if (type == "YY") {
      url = "/service/knowledge/manage/saveDrugs";
    }
    return post(url, params);
  }

  // 修改权重
  static changeWeight(params = undefined) {
    let url = "";
    if (params.type == "YS") {
      url = "/service/knowledge/manage/updateBookList/";
    } else if (params.type == "YA") {
      url = "/service/knowledge/manage/updateCaseList/";
    } else if (params.type == "YY") {
      url = "/service/knowledge/manage/updateDrugsList/";
    }
    return get(url + params.weight+'/'+params.id)
  }
}
