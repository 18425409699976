<template>
  <div>
    <el-form
      :inline="true"
      :model="formSet"
      ref="configData"
      :rules="rules"
      label-position="left"
      :label-width="labelWidth"
      class="demo-form-inline"
    >
      <el-row>
        <el-col :span="colspan" v-for="item in configData"  :key="item.formItemId">
          <el-form-item
            :label="item.label"
            :prop="item.prop"
          >
            <template v-if="item.type == 'input'">
              <el-input
                v-model="formSet[item.prop]"
                :placeholder="item.placeholder"
                :disabled="item.disabled?item.disabled:false"
              ></el-input>
            </template>
            <template v-if="item.type == 'select'">
              <el-select
                v-model="formSet[item.prop]"
                :placeholder="item.placeholder"
                :filterable="item.filterable"
                :multiple="item.multiple"
                @focus="handleFocus(item.isApi)"
              >
                <!--静态数据配置-->
                <template v-if="!item.isApi">
                  <el-option
                    :label="optItem.label"
                    :value="optItem.value"
                    v-for="(optItem, index) in item.options"
                    :key="index + 'option_selected'"
                  ></el-option>
                </template>
                <!--远端数据配置-->
                <template v-else>
                  <el-option
                    :label="optItem.label"
                    :value="optItem.value"
                    v-for="(optItem, index) in selectOptions"
                    :key="index + 'option_selected'"
                  ></el-option>
                </template>
              </el-select>
            </template>
            <template v-if="item.type == 'timePicker'">
              <el-date-picker
                v-model="formSet[item.prop]"
                align="right"
                :type="item.optionType"
                :placeholder="item.placeholder"
                :picker-options="item.options"
              >
              </el-date-picker>
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="btnColspan">
          <el-form-item>
            <slot></slot>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    configData: {
      type: Array
    },
    formSet: {
      type: Object
    },
    colspan:Number,
    btnColspan:Number,
    labelWidth:String,
    rules:Object
  },
  data() {
    return {
      selectOptions: []
    };
  },
  methods: {
    handleFocus(isApi) {
      if (!isApi) {
        return;
      } else {
        this.$emit("on-focus-select", this.selectOptions);
      }
    },
    onSubmit() {
      this.$emit("on-submit-select", this.selectOptions);
    },
    resetForm() {
      this.$nextTick(() => {
        this.$refs["configData"].resetFields();
      });
    }
  }
};
</script>
