/* eslint-disable indent */
/**
 * 用户信息相关的接口Api
 */
import { post, get } from '../../service'
import apiUrl from './api-url'

const { addSave, checkUserInfoUrl, getPC, getExpertType, enableDisable,
   getUserVisitorList, getVisitorDetail, getUserEntityNew, getAreaList, qualification,
   relieve, getCustomerList, bindCustomer, getLecturerType, getCancelUser
} = apiUrl

// 获取游客列表
export const getUserVisitorInfo = (params = undefined) => {
   return post(getUserVisitorList, params)
}
// 游客详情
export const getVisitorDetailInfo = (params = undefined) => {
   return post(getVisitorDetail, params)
}

// 获取用户信息
export const getUserEntityNewInfo = (params = undefined) => {
   return post(getUserEntityNew, params)
}
//启用-禁用
export const enableDisableInfo = (params = {}) => {
   return post(enableDisable, params)
}
//资质图片

export const qualificationInfo = (params = {}) => {
   return post(qualification, params)
}
// 新增用户
export const toAdd = (params = {}) => {
   return post(addSave, params)
}
//获取省市区域联动
export const getPCInfo = (params = {}) => {
   return post(getPC, params)
}
//获取专家用户类型
export const getExpertTypeInfo = (params = {}) => {
   return get(getExpertType, params)
}
//获取讲师用户类型
export const getLecturerTypeInfo = (params = {}) => {
   return get(getLecturerType, params)
}
//关联所属区域
export const getAreaListInfo = (params = {}) => {
   return post(getAreaList, params)
}
// 用户详情
export const checkUserInfo = (params = {}) => {
   return post(checkUserInfoUrl, params)
}
// 客户
export const getCustomerListInfo = (params = {}) => {
   return post(getCustomerList, params)
}
// 解除绑定
export const UnbindInfo = (params = {}) => {
   return post(relieve, params)
}
// 转移服务商
export const transferInfo = (params = {}) => {
   return post(relieve, params)
}
// 新增用户-绑定服务商
export const bindCustomerInfo = (params = {}) => {
   return post(bindCustomer, params)
}
//所有角色
export const postAllRoles=()=>{
   return post("/service/role/all", {});
}
//用户被授权的角色id
export const getUserRoleIds=(uid)=>{
   return get("/service/userRole/listRoleIds/"+uid, {});
}
//授权
export const postGrantRole2User=(uid, roleId)=>{
   return post("/service/userRole/grant", {userId:uid, roleId})
}
//授权
export const postUngrantRoleOfUser=(uid, roleId)=>{
   return post("/service/userRole/ungrant", {userId:uid, roleId})
}

// 注销用户
export const cancelUser = (params = {}) => {
   return post(getCancelUser, params)
}

// 充值康驰币
export const deposit = (params = {}) => {
  return post('/service/user/reChargeKkc', params)
}


