<template>
  <d2-container>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="服务商审核" name="first">
        <service-provider 
          :formSetData="formSetData"
          :columnsProps="columnsProps"
          :pagination="pagination"
          :data="data"
          @pagination="getList"
          @on-get-data="onGetData"
          @on-reset-Form="resetForm"
        />
      </el-tab-pane>

      <el-tab-pane label="讲师审核" name="second">
        <lecturer 
          :formSetData="formSetData"
          :columnsProps="columnsProps"
          :pagination="pagination"
          :data="data"
          @pagination="getList"
          @on-get-data="onGetData"
          @on-reset-Form="resetForm"
        />
      </el-tab-pane>

      <el-tab-pane label="诊所合伙人审核" name="third">
        <clinicPartner 
          :formSetData="formSetData"
          :columnsProps="columnsProps"
          :pagination="pagination"
          :data="data"
          @pagination="getList"
          @on-get-data="onGetData"
          @on-reset-Form="resetForm"
        />
      </el-tab-pane>

      <el-tab-pane label="专家审核" name="four">
        <expert-review 
          :formSetData="formSetData"
          :columnsProps="columnsProps"
          :pagination="pagination"
          :data="data"
          @pagination="getList"
          @on-get-data="onGetData"
          @on-reset-Form="resetForm"
        />
      </el-tab-pane>

    </el-tabs>

  </d2-container>
</template>
<script>
// 引入接口
import { getUserAudit } from '@/model/modules/audit_user'

// 引入查询条件配置组件
import serviceProvider from './serviceProvider/index.vue'
import lecturer from './lecturer/index.vue'
import clinicPartner from './clinicPartner/index.vue'
import expertReview from './expertReview/index.vue'

export default {
  name: 'roleinfo',
  components: {
    serviceProvider,
    lecturer,
    clinicPartner,
    expertReview
  },
  data () {
    return {
      activeName: 'first',
      currentIndex: 0,

      formSetData: {
        param: '',
        nickNameVal: '',
        idVal: '',
        telVal: '',
        fullNameVal: '',
        
        time: '',
        auditTmStartTime: '',
        auditTmEndTime: '',
        mtmStartTime: '',
        mtmEndTime: '',

        sourceType: '0',

        userRole: '',
        userRole1: '',

        type: '',
        pageIndexB1: Number,
        pageSize: Number
      },

      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },

      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      },

      data: []
    }
  },
  watch: {
    'formSetData.userRole1'() {
      if (this.formSetData.userRole1 != '') {
        let str = ''
        this.formSetData.userRole1.map((item, index) => {
          str += item+','
        })
        this.formSetData.userRole = str.substring(0,str.length-1)
      } else {
        this.formSetData.userRole = ''
      }
    }
  },
  mounted () {
    this.getUserAudit()
  },
  methods: {
    // 切换
    handleClick (tab, event) {
      this.formSetData = {
        param: '',
        nickNameVal: '',
        idVal: '',
        telVal: '',
        fullNameVal: '',
        
        time: '',
        auditTmStartTime: '',
        auditTmEndTime: '',
        mtmStartTime: '',
        mtmEndTime: '',

        sourceType: '0',

        userRole: '',
        userRole1: '',

        type: '',
        pageIndexB1: Number,
        pageSize: Number
      }
      this.currentIndex = tab.index
      this.pagination.pageNum = 1
      this.getUserAudit()
    },
    // 重置
    resetForm() {
      this.formSetData = {
        param: '',
        nickNameVal: '',
        idVal: '',
        telVal: '',
        fullNameVal: '',
        
        time: '',
        auditTmStartTime: '',
        auditTmEndTime: '',
        mtmStartTime: '',
        mtmEndTime: '',

        sourceType: '0',

        userRole: '',
        userRole1: '',

        type: '',
        pageIndexB1: Number,
        pageSize: Number
      }
    },
    // 查询数据
    onGetData() {
      this.getUserAudit()
    },
    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.getUserAudit()
    },

    // 列表信息
    async getUserAudit () {
      this.formSetData.pageIndexB1 = this.pagination.pageNum
      this.formSetData.pageSize = this.pagination.pageSize
      this.formSetData.type = this.currentIndex
      const res = await getUserAudit(this.formSetData)
      this.data = res.data.body.content
      this.pagination.total = res.data.body.total * 1
    },

  }
}
</script>
<style scoped>
.mt20 {
  margin-top: 20px;
}
</style>
