<template>
  <div>
    <el-form
      :model="formSet"
      ref="configData"
      :rules="rules"
      label-position="left"
      :label-width="labelWidth"
      class="demo-form-inline"
    >
      <!--  -->
      <el-row v-for="(n, i) in configData" :key="i">
        <el-col
          :span="item.colSpan"
          v-for="(item, index) in n"
          :key="item.formItemId"
        >
          <el-form-item
            :label="item.label"
            :prop="item.prop"
            style="margin-right: 20px"
          >
            <template v-if="item.type == 'timedateFormat'">
              <el-date-picker
                v-model="formSet[items.prop]"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </template>
            <template v-if="item.type == 'timePicker'">
              <el-date-picker
                clearable
                v-model="formSet[item.prop]"
                align="right"
                :type="item.optionType"
                :placeholder="item.placeholder"
                :start-placeholder="item.startPlaceholder"
                :end-placeholder="item.endPlaceholder"
                :picker-options="item.options"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="handleChange(item, item.isApi)"
              ></el-date-picker>
            </template>
            <template v-if="item.type == 'textarea'">
              <el-input
                type="textarea"
                :rows="item.rowSpan"
                v-model="formSet[item.prop]"
                placeholder="请输入内容">
              </el-input>
            </template>
            <template v-if="item.type == 'select'">
              <el-select
                clearable
                v-model="formSet[item.prop]"
                :placeholder="item.placeholder"
                :filterable="item.filterable"
                :multiple="item.multiple"
                :multiple-limit="item.multipleLimit"
                @focus="handleFocus(item, item.isApi)"
                @change="handleSelect(item.prop)"
              >
                <template>
                  <el-option
                    :label="optItem.label"
                    :value="optItem.value"
                    v-for="(optItem, index) in item.options"
                    :key="index + 'option_selected'"
                  ></el-option>
                </template>
              </el-select>
              <div v-if="item.isSlot">
                 <slot></slot>
              </div>
            </template>
            <template v-if="item.type == 'input'">
              <el-input
                clearable
                v-model="formSet[item.prop]"
                :placeholder="item.placeholder"
                :disabled="item.disabled ? item.disabled : false"
                :style="'width: ' + item.width + 'px'"
              ></el-input>
            </template>
            <template v-if="item.type == 'numberInput'">
              <el-input
                clearable
                type="number"
                v-model.number="formSet[item.prop]"
                :placeholder="item.placeholder"
                :disabled="item.disabled ? item.disabled : false"
                :style="'width: ' + item.width + 'px'"
              ></el-input>
            </template>
            <template v-if="item.type == 'threeInput'">
              <el-input
                clearable
                v-model="formSet[item.prop]"
                style="width: 90px; margin-right: 5px"
              >
              </el-input>
            </template>
            <template v-if="item.type == 'multipleBoxes'">
              <div v-for="(items, indexs) in item.configDatas" :key="indexs" style="display: inline-block">
                <template v-if="items.type == 'select'">
                  {{JSON.stringify(formSet[items.prop])}}
                  <el-select
                    clearable
                    v-model="formSet[items.prop]"
                    :placeholder="items.placeholder"
                    :filterable="items.filterable"
                    :multiple="items.multiple"
                    :disabled="items.disabled ? items.disabled : false"
                    @focus="handleFocus(index, items.isApi)"
                    @change="handleChange(item, item.isApi)"
                    :style="'width: ' + items.width + 'px'"
                  >
                    <template>
                      <el-option
                        :label="optItem.label"
                        :value="optItem.value"
                        v-for="(optItem, index) in items.options"
                        :key="index + 'option_selected'"
                      ></el-option>
                    </template>
                  </el-select>
                </template>
                <template v-if="items.type == 'timePicker'">
                  <el-date-picker
                    clearable
                    v-model="formSet[items.prop]"
                    align="right"
                    type="datetime"
                  >
                  </el-date-picker>
                </template>
                <template v-if="items.type == 'input'">
                  <el-input
                    clearable
                    v-model="formSet[items.prop]"
                    :placeholder="items.placeholder"
                    :disabled="items.disabled ? items.disabled : false"
                    :style="'width: ' + items.width + 'px'"
                  ></el-input>
                </template>
              </div>
            </template>
            <template v-if="item.type == 'cascader'">
              <el-cascader
                :placeholder="item.placeholder"
                v-model="formSet[item.prop]"
                :options="item.options"
                filterable
                clearable
              />
            </template>
             <template v-if="item.type == 'uploadAvatar'">
              <el-upload
                ref="upload_avatar"
                class="avatar-uploader"
                :action="'http://dev.kcguoyi.com/service/upload/huawei'"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="(file, fileList) => {handleImport(file, fileList, item)}"
              >
                <img
                  v-if="formSet[item.prop]"
                  :src="formSet[item.prop]"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </template>
            <template v-if="item.type == 'uploadList'">
              <el-upload
                action="'http://dev.kcguoyi.com/service/upload/huawei'"
                list-type="picture-card"
                :auto-upload="false"
                :on-change="handleImportList"
                :file-list="formSet[item.prop]"
              >
              <i class="el-icon-plus"></i>
            </el-upload>
            </template>
            <template v-if="item.type == 'ueditor'">
              <d2-ueditor v-model="formSet[item.prop]"></d2-ueditor>
            </template>
            <template v-if="item.type == 'quill'">
              <d2-quill
                v-model="formSet[item.prop]"
                ref="myQuillEditor"
                @text-change="onEditorBlur"
                @selection-change="onEditorFocus"
                @editor-change="onEditorChange"
              />
            </template>
          </el-form-item>

        </el-col>
      </el-row>
      <el-row>
        <el-col :span="btnColspan">
          <el-form-item>
            <slot></slot>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { uploadPic } from '@/model/modules/user'
 function _urlPic(formdata){
    uploadPic(formdata).then((res) => {
      console.log(res.data.body[0].fileRealpath,"vdovkkovko")
      return {
        url: res.data.body[0].fileRealpath
      }
    })
  }
export default {
  props: {
    configData: {
      type: Array
    },
    formSet: {
      type: Object
    },
    colspan: Number,
    btnColspan: Number,
    labelWidth: String,
    rules: Object
  },
  data () {
    return {
      disabled: false,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fPicUrl:[]
    }
  },
  methods: {
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    onEditorBlur (delta, oldDelta, source) {
      // 失去焦点事件
    },
    onEditorFocus (range, oldRange, source) {
      // 获得焦点事件
    },
    onEditorChange (eventName, ...args) {
      // 内容改变事件
    },
    handleSelect(name){
      this.$emit('on-handle-select', this.formSet[name])
    },
    handleChange (item, isApi) {
      if (!isApi) {
      } else {
        this.$emit('on-handle-change', item)
      }
    },
    handleFocus (item, isApi) {
      if (!isApi) {
      } else {
        this.$emit('on-focus-select', { item: item })
      }
    },
    resetForm () {
      this.$nextTick(() => {
        this.$refs.configData.resetFields()
      })
    },
    handleImport (file, fileList, item) {
      var propName = item.prop
      if (fileList.length > 1) {
        fileList.splice(0, 1)
      }
      const formdata = new FormData()
      formdata.append('file', file.raw)

      var loading = this.$loading({
        lock: true,
          text: '图片上传中',
      })

      uploadPic(formdata).then((res) => {
        // console.log(res, 'uploadPicuploadPicuploadPic=')
        var fileurl =
          URL.createObjectURL(file.raw) || res.data.body[0].filepath

        this.formSet.imgUrl = fileurl;

        this.$emit('on-avatar-success', {
          res: res.data.body[0],
          origin: this.formSet,
          propName: propName
        })

      }).finally(()=>{
        this.$nextTick(()=>{this.$forceUpdate()})
        loading.close();
      })
    },
    handleImportList (file, fileList) {
      let fPicUrl = []
      const formdata = new FormData()
      formdata.append('file', file.raw)
      fileList.map((item,index)=>{
        fPicUrl[index] ={}
        uploadPic(formdata).then((res) => {
          fPicUrl[index].url= res.data.body[0].fileRealpath
        })
      })
      this.$emit('on-avatar-success', { res:fPicUrl, origin: this.formSet})
    },
    // handleAvatarSuccess (res, file) {
    //   this.formSet.fPicUrl = URL.createObjectURL(file.raw) || res[0].filepath
    //   this.$emit('on-avatar-success', { res: res, origin: this.formSet })
    // },
    // beforeAvatarUpload (file) {
    //   // eslint-disable-next-line no-unused-vars
    //   const isJPG = file.type === 'image/jpeg'
    //   // eslint-disable-next-line no-unused-vars
    //   const isLt2M = file.size / 1024 / 1024 < 2
    //   // if (!isLt2M) {
    //   //  this.$message.error('上传头像图片大小不能超过 2MB!');
    //   // }
    //   // return isJPG && isLt2M;
    // }
  }
}
</script>
<style lang="scss">
.avatar-uploader-icon{
  width:148px !important;
  height:148px !important;
  line-height: 148px !important;
}
</style>
