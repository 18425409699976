import { post, get } from '../../service'

export default class CommonPopupSet {
    // 获取通用券弹窗信息
    static getInfo(params = undefined) {
        return get('/service/popup/getPopup', params)
    }

    // 保存通用券弹窗告信息
    static save(params = undefined) {
        return post('/service/popup/save', params)
    }
}
