
/**
 * 分润管理
 */
const apiUrl = {
  benefitQuery: '/service/Benefit/query',  //分页查询
  benefitAdd: '/service/Benefit/add',  //添加
  enableBenefit: '/service/Benefit/enableBenefit',  //分润
  benefitClone: '/service/Benefit/clone', //克隆

}

export default apiUrl
