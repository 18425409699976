<template>
  <d2-container>
     <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5.8"
      :btnColspan="4"
      :labelWidth="'90px'"
      @on-focus-select="handleQueryFocus"
      @on-change-select="handleChange"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        新增
      </el-button>
    </div>

    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:execute="{ row }" >
          <span style="color: red;">{{ row.enable === 1 ? '当前执行' : ''}}</span>
        </template>
        <template v-slot:enable="{ row }" >
          {{ row.enable === 1 ? '当前执行' : '未执行'}}
        </template>
        <template v-slot:activeTm="{ row }">
          {{ timeConvert(row.activeTm, 1) }}
        </template>
        <template v-slot:ctm="{ row }">
          {{ timeConvert(row.ctm, 1) }}
        </template>
      </free-table>
    </div>

    <add-sub
      ref="addSubDialog"
      :titleSrc="titleSrc"
      :form="form"
      @on-edit-submit="onEditSubmit"
    />
  </d2-container>
</template>
<script>
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from "@/views/components/FreeTable"
import AddSub from './components/addSub'

// 引入接口
import { benefitQuery, benefitAdd, enableBenefit, benefitClone } from '@/model/modules/benefitinfo'

export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
    FreeTable,
    AddSub
  },
  data () {
    return {
      titleSrc: '新增',
      // 搜索框渲染
      formSetData: {
        bili: '',
        ratePopMax: '',
        ratePopMin: '',
        rateServMax: '',
        rateServMin: '',
        time: '',
        activeTmMin: '',
        activeTmMax: '',
        ctmMin: '',
        ctmMax: '',
      
        pageIndexB1: Number,
        pageSize: Number
      },
      searchConfigData: [
        {
          label: '比例',
          formItemId: 0,
          type: 'multipleBoxes',
          configDatas: [
            {
              label: '',
              formItemId: 0,
              placeholder: '请选择',
              type: 'select',
              prop: 'bili',
              isApi: false,
              isApi1: true,
              filterable: false,
              multiple: false,
              width: 130,
              options: [
                {
                  label: '推广人比例',
                  value: 0
                },
                {
                  label: '服务商比例',
                  value: 1
                }
              ]
            },
            {
              label: '',
              formItemId: 1,
              placeholder: '输入最大百分比%',
              type: 'input',
              prop: '',
              width: 170
            },
            {
              label: '',
              formItemId: 2,
              placeholder: '输入最小百分比%',
              type: 'input',
              prop: '',
              width: 170
            }
          ]
        },
        {
          label: '时间',
          formItemId: 1,
          type: 'multipleBoxes',
          configDatas: [
            {
              label: '',
              formItemId: 0,
              placeholder: '请选择',
              type: 'select',
              prop: 'time',
              isApi: false,
              isApi1: true,
              filterable: false,
              multiple: false,
              width: 130,
              options: [
                {
                  label: '最近执行开始时间',
                  value: '0'
                },
                {
                  label: '创建时间',
                  value: '1'
                }
              ]
            },
            {
              label: '',
              formItemId: 1,
              placeholder: '开始时间',
              type: 'timePicker',
              prop: '',
              optionType: 'datetime',
              width: 170
            },
            {
              label: '',
              formItemId: 2,
              placeholder: '结束时间',
              type: 'timePicker',
              prop: '',
              optionType: 'datetime',
              width: 170
            }
          ]
        },
      ],

      // 表格数据渲染
      data: [],
      slotColumn: [
        { label: '', prop: 'execute', slotScope: true  },
        { label: '分润版本', prop: 'versionId' },
        { label: '推广人分润', prop: 'ratePop', sort: true },
        { label: '服务商分润', prop: 'rateServ', sort: true  },
        { label: '状态', prop: 'enable', slotScope: true },
        { label: '最近执行开始时间', prop: 'activeTm', slotScope: true, sort: true },
        { label: '创建时间', prop: 'ctm', slotScope: true, sort: true },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '370px',
          render: (h, scope) => {
            if (scope.row.enable === 1) {
              return (
                <div>
                  <el-button
                    type='default'
                    size='small'
                    onClick={() => {
                      this.cloneClick(scope.row)
                    }}
                  >
                    克隆
                  </el-button>
                </div>
              )
            } else {
              return (
                <div>
                  <el-button
                    type='default'
                    size='small'
                    onClick={() => {
                      this.cloneClick(scope.row)
                    }}
                  >
                    克隆
                  </el-button>
                  <el-button
                    type='default'
                    size='small'
                    onClick={() => {
                      this.editClick(scope.row)
                    }}
                  >
                    编辑
                  </el-button>
                </div>
              )
            }
          }
        }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },

      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      },

      // 新增课程
      form: {
        ratePop: '',
        rateServ: '',
        enable: 0,
        remark: ''
      },
    }
  },
  computed: {
    timeConvert() {//num:0 YYYY-MM-DD  num:1  YYYY-MM-DD hh:mm:ss // timestamp:时间戳 
      return function(timestamp, num) {
        if (timestamp === 0) {
          return '-'
        } else {
          timestamp = timestamp.length === 10  ? timestamp*1000 : timestamp;
          let date = new Date(timestamp);
          let y = date.getFullYear();  
          let m = date.getMonth() + 1;  
          m = m < 10 ? ('0' + m) : m;  
          let d = date.getDate();  
          d = d < 10 ? ('0' + d) : d;  
          let h = date.getHours();
          h = h < 10 ? ('0' + h) : h;
          let minute = date.getMinutes();
          let second = date.getSeconds();
          minute = minute < 10 ? ('0' + minute) : minute;  
          second = second < 10 ? ('0' + second) : second; 
          if(num==0){
            return y + '-' + m + '-' + d;  
          }else{
            return y + '-' + m + '-' + d +' '+ h +':'+ minute +':' + second;  
          }
        }
      }
    }
  },
  methods: {
    async initBenefitQuery() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum
      this.formSetData.pageSize = this.pagination.pageSize
      let res = await benefitQuery(this.formSetData)
      if (res.data.code === 0) {
        this.data = res.data.body.content
        this.pagination.total = res.data.body.total * 1
      }
    },

    async benefitAdd(e) {
      let res = await benefitAdd(e)
      if (res.data.code === 0) {
        // if (e.enable === 1) {
        //   const res = await enableBenefit(e)
        // }
        this.initBenefitQuery()
      }
    },

    // 查询下拉框
    handleQueryFocus() {

    },
    handleChange(item) {
      let arrStr = []
      if (item.formItemId === 0) {
        if (Number(this.formSetData.bili) === 0) {
          arrStr[0] = 'ratePopMax'
          arrStr[1] = 'ratePopMin'
          this.formSetData.rateServMax = ''
          this.formSetData.rateServMin = ''
        } else if (Number(this.formSetData.bili) === 1) {
          arrStr[0] = 'rateServMax'
          arrStr[1] = 'rateServMin'
          this.formSetData.ratePopMax = ''
          this.formSetData.ratePopMin = ''
        }
      } else if (item.formItemId === 1) {
        if (Number(this.formSetData.time) === 0) {
          arrStr[0] = 'activeTmMin'
          arrStr[1] = 'activeTmMax'
          this.formSetData.ctmMin = ''
          this.formSetData.ctmMin = ''
        } else if (Number(this.formSetData.time) === 1) {
          arrStr[0] = 'ctmMin'
          arrStr[1] = 'ctmMax'
          this.formSetData.activeTmMin = ''
          this.formSetData.activeTmMax = ''
        }
      }
      arrStr.map((i, j) => {
        item.configDatas[(j+1)].prop = i
      })
    },
    // 查询
    onQuery() {
      this.pagination.pageNum = 1
      this.initBenefitQuery()
    },
    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.initBenefitQuery()
    },

    // 新增课程
    onAdd () {
      this.titleSrc = '新增'
      this.form = {
        ratePop: '',
        rateServ: '',
        enable: 0,
        remark: ''
      }
      this.$refs.addSubDialog.showDialog(this.form)
    },

    //提交
    onEditSubmit(e) {
      this.benefitAdd({ ...e })
    },

    // 克隆
    cloneClick(e) {
      const id = e.id
      benefitClone({ id }).then(res => {
        if (res.data.code === 0) {
          this.initBenefitQuery()
        }
      })
    },

    // 编辑
    editClick(e) {
      this.titleSrc = '编辑'
      this.form = { ...e }
      this.$refs.addSubDialog.showDialog(this.form)
    }
  },
  mounted() {
    this.initBenefitQuery()
  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>