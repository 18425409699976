import { postnoAuth, post, get, deletes } from '../../service'

export default class MenuAppSlidesApi {
  // 获取列表
  static pageList (params = undefined) {
    return post('/service/banner/queryByPage', params)
  }

  // 获取显示位置
  static getShowPos (params = undefined) {
    return post('/service/banner/getBannerPos', params)
  }

  // 新增/修改
  static saveRow (params = undefined) {
    return post('/service/banner/save', params)
  }

  // 删除
  static deleteRow (params = undefined) {
    return deletes('/service/banner/id/'+params.key)
  }
}
