<template>
  <d2-container>
    <div class="address">
      <el-tabs v-model="activeName">
        <el-tab-pane label="收货地址" name="SHDZ">
          <tab-pane-item
            :type="activeName"
            v-if="activeName == 'SHDZ'"
          ></tab-pane-item>
        </el-tab-pane>
      </el-tabs>
    </div>
  </d2-container>
</template>

<script>
// 引入组件
import tabPaneItem from "./components/tabPaneItem.vue";

export default {
  components: {
    tabPaneItem
  },
  data() {
    return {
      activeName: "SHDZ"
    };
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped></style>
