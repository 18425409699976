<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="4"
      :labelWidth="'90px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <!--<div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        新增产品
      </el-button>
    </div>-->

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
      </free-table>
    </div>

    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :form="form"
      :config="config"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-focus-select="handleFocus"
      @on-edit-submit="onEditSubmit"
    />
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'
// 引入页面层级组件
import AddSub from './components/addSub'
// 引入接口
import { pageList, saveProduction, enterpriseSelect } from '@/model/modules/goods'
export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
    FreeTable,
    AddSub
  },
  data () {
    return {
      titleSrc: '新增产品',
      fShowEpSelect: [], //企业名称下拉

      // 搜索框渲染
      formSetData: {
        pageIndexB1: 0,
        pageSize: 0,
        epId:"",
        nameLike:""
      },
      searchConfigData: [
        // {
        //   label: '企业名称',
        //   formItemId: 1,
        //   placeholder: '请输入产品名称',
        //   type: 'select',
        //   isApi: false,
        //   prop: 'epId',
        //   options: [
        //   ]
        // },
        {
          label: '产品名称',
          formItemId: 1,
          placeholder: '请输入产品名称',
          type: 'input',
          prop: 'nameLike'
        }
        
      ],
      
      // 表格数据渲染
      data: [],
      slotColumn: [
        { label: '企业名称', prop: 'epName' },
        { label: '产品名称', prop: 'prodName' },
        { label: '有效日期中文说明', prop: 'effectiveOeriodInfo' },
        { label: '产品规格', prop: 'sku' },
        { label: '包装规格', prop: 'packingSku',  },
        { label: '原材料', prop: 'material' },
        { label: '产品配方', prop: 'prescription' },
        { label: '生产工艺', prop: 'technique' },
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '120px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onFactoryBatchClick(scope.row)
                  }}
                >
                  出厂批次
                </el-button>
                {/**
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onEditClick(scope.row)
                  }}
                >
                  编辑
                </el-button>
                */}
              </div>
            )
          }
        }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },

      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      },

      // 新增课程
      form: {
        
      },
      config: [
        [
          {
            colSpan: 12,
            label: '电话',
            type: 'input',
            prop: 'effectiveOeriodMs'
          },
          {
            colSpan: 12,
            label: '企业名称',
            type: 'select',
            prop: 'epId',
            isApi: false,
            options: []
          },
          {
            colSpan: 12,
            label: '产品名称',
            type: 'input',
            prop: 'prodName',
          },
          {
            colSpan: 12,
            label: '有效日期中文说明',
            type: 'input',
            prop: 'effectiveOeriodInfo'
          },
          {
            colSpan: 12,
            label: '产品规格',
            type: 'input',
            prop: 'sku'
          },
          {
            colSpan: 12,
            label: '包装规格',
            type: 'input',
            prop: 'packingSku'
          },
        ],
        [
          {
            colSpan:24,
            label: '说明图片',
            type: 'uploadList',
            prop: 'images'
          }
        ],
        [
          {
            colSpan: 24,
            label: '原材料',
            type: 'textarea',
            prop: 'material'
          }
        ],
        [
          {
            colSpan: 24,
            label: '产品配方',
            type: 'textarea',
            prop: 'prescription'
          }
        ],
        [
          {
            colSpan: 24,
            label: '保护范围',
            type: 'textarea',
            prop: 'protectedScope'
          }
        ],
        [
          {
            colSpan: 24,
            label: '生产工艺',
            type: 'textarea',
            prop: 'technique'
          }
        ],
        [
          {
            colSpan: 24,
            label: '质量特色',
            type: 'textarea',
            prop: 'quality'
          }
        ],
        [
          {
            colSpan: 24,
            label: '产品详情',
            type: 'textarea',
            prop: 'prodDesc'
          }
        ],
        [
          {
            colSpan: 24,
            label: '备注',
            type: 'textarea',
            prop: 'remark'
          }
        ],
      ],
      // 表单验证
      rules: {}
    }
  },

  mounted () {
    this.getDataList()
    this.enterpriseSelect()
  },

  methods: {
    //初始化表格渲染
    async getDataList() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      // if (this.$route.query.id) {
      //   this.formSetData.epId = this.$route.query.id
      // }
      this.formSetData.epId = '2c9380877ac7a2eb017ac81f8efd000b'
      let res = await pageList(this.formSetData);
      this.data = res.data.body.content;
     
      this.pagination.total = res.data.body.total * 1;
    },

    async enterpriseSelect() {
      const res = await enterpriseSelect();
      if (res.data.code === 0) {
        this.fShowEpSelect = this.formatData(res.data.body, 'code', 'label')
        this.searchConfigData[0].options = this.fShowEpSelect
        this.config[0][1].options = this.fShowEpSelect
      }
    },

    // 查询组件 下拉框
    handleQueryFocus(e) {

    },

    // 表格查询按钮
    onQuery () {
      this.pagination.pageNum = 1
      this.getDataList()
    },

    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.getDataList()
    },

    // 新增 下拉框
    handleFocus(e) {

    },
    // 新增课程
    onAdd () {
      this.form = {}
      this.$refs.addSubDialog.showDialog(this.form)
      this.titleSrc = '新增产品'
    },

    // 提交编辑表格行信息
    onEditSubmit (e) {
      for (const item of this.fShowEpSelect) {
        if (item.value === e.form.epId) {
          e.form.epName = item.label
          break;
        }
      }
      e.form.effectiveOeriodMs = Number(e.form.effectiveOeriodMs)
      const imgList = e.form.images
      let strImg =""
      imgList.map((item, index) => {
        strImg += item.url+','
      });
      strImg = strImg.substring(0,strImg.length-1);
      console.log(strImg,"shihvhieiv======++++")
      saveProduction({
        ...e.form,
        images:strImg
      }).then(res => {
        if (res.data.code === 0) {
          this.getDataList()
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },

    // 出厂批次
    onFactoryBatchClick(e) {
      const param = {
        epId: e.epId,
        epName: e.epName,
        id: e.id,
        prodName: e.prodName
      }
      localStorage.setItem("query",JSON.stringify(param))
      this.$router.push({
        path:`/goodsManage/factoryBatch`,
      })
    },
    // 编辑
    onEditClick(e) {
      let arr = []
      console.log(e,"scisjhsivj===")
      const imgList = e.images.split(',')
      imgList.map((item, index) => {
        arr[index] = {}
        arr[index].url = item
      })
      this.form = {
        ...e,
        images: arr
      }
      
      this.$refs.addSubDialog.showDialog(this.form)
      this.titleSrc = '编辑产品'
    },

    formatData (data, key, val) {
      const obj = []
      for (const i in data) {
        const item = data[i]
        obj[i] = {
          value: item[key] || '',
          label: item[val] || ''
        }
      }
      return obj
    },
  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
/deep/.el-tree-node__content > label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right: 0px;
}
/deep/.el-tree-node__content {
  padding: 5px 0px;
}
</style>