<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="7"
      :btnColspan="1"
      :labelWidth="'80px'"
      @on-focus-select="handleFocus"
    >
      <el-button type="primary" @click="onSubmit">搜索</el-button>
    </search-config>
    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        @selection-change="selectionChange"
        pagination
        :auto-scroll="false"
        :total="100"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      >
      </free-table>
    </div>
  </d2-container>
</template>
<script>
let id = 1000;
//引入查询条件配置组件
import SearchConfig from "@/views/components/searchConfigForm";
import FreeTable from "@/views/components/FreeTable";
export default {
  name: "roleinfo",
  components: {
    SearchConfig,
    FreeTable
  },
  // mixins: [TableData],
  data() {
    return {
      data2:  [{
        id: 1,
        label: '一级 1',
        children: [{
          id: 4,
          label: '二级 1-1',
          children: [{
            id: 9,
            label: '三级 1-1-1'
          }, {
            id: 10,
            label: '三级 1-1-2'
          }]
        }]
      }, {
        id: 2,
        label: '一级 2',
        children: [{
          id: 5,
          label: '二级 2-1'
        }, {
          id: 6,
          label: '二级 2-2'
        }]
      }, {
        id: 3,
        label: '一级 3',
        children: [{
          id: 7,
          label: '二级 3-1'
        }, {
          id: 8,
          label: '二级 3-2'
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      searchConfigData: [
        {
          label: "操作人",
          formItemId: 1,
          placeholder: "请输入操作人名称",
          type: "input",
          prop: "permissionName"
        }
      ],
      data: [
        {
          permissionName: "华北总经理",
          permissionIntroduce: "为诊所大夫的专业进行授课",
          permissionFounder: "admin",
          modifiedTime: "2021-5-13",
          modifiedTime1: "cdvdv",
          modifiedTime2: "dvdv"
        },
        {
          permissionName: "营销人员",
          permissionIntroduce: "为诊所大夫推荐app进行下载",
          permissionFounder: "seller",
          modifiedTime: "2021-5-13",
          modifiedTime1: "cdvdv",
          modifiedTime2: "dvdv"
        },
        {
          permissionName: "讲师",
          permissionIntroduce: "为诊所大夫的专业进行授课",
          permissionFounder: "speaker",
          modifiedTime: "2021-5-13",
          modifiedTime1: "cdvdv",
          modifiedTime2: "dvdv"
        }
      ],
      slotColumn: [
        // { label: "选择", prop: "selection", type: "selection" },
        { label: '序号', prop: 'index', type: 'index' },
        { label: "访问ip地址", prop: "permissionName" },
        { label: "操作人名称", prop: "permissionIntroduce" },
        { label: "操作类型", prop: "permissionFounder" },
        { label: "操作时间", prop: "modifiedTime" },
        { label: "操作内容", prop: "modifiedTime1" },
        { label: "操作状态", prop: "modifiedTime2" }
      ],
      formSetData: {
        permissionName: "",
        permissionIntroduce: "",
        permissionFounder: "",
        modifiedTime: "",
        modifiedTime1: "",
        modifiedTime2: ""
      },
      columnsProps: {
        width: "auto"
      },
      total: 0,
      listQuery: {
        page: 2,
        limit: 20
      },
      editForm: {},
      titleDialog: "新增功能权限配置",
      dialogVisible: false
    };
  },
  methods: {
    append(data) {
      const newChild = { id: id++, label: "testtest", children: [] };
      if (!data.children) {
        this.$set(data, "children", []);
      }
      data.children.push(newChild);
    },
    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d.id === data.id);
      children.splice(index, 1);
    },
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>{node.label}</span>
          <span>
            <el-button
              size="mini"
              type="text"
              on-click={() => this.append(data)}
            >
              Append
            </el-button>
            <el-button
              size="mini"
              type="text"
              on-click={() => this.remove(node, data)}
            >
              Delete
            </el-button>
          </span>
        </span>
      );
    },
    handleFocus(row) {
      row.switch = 2;
    },
    onSubmit() {
      console.log("submit!");
    },
    handleFocus() {
      //进行接口请求获取下拉选的选项值
    },
    selectionChange(rows) {
      console.log("selected", rows);
    },
    getList() {},

    openDialog({ row }) {
      this.editForm = Object.assign({}, row);
      console.log(this.editForm, "cjhehvhheh===");
      this.dialogVisible = true;
      this.titleDialog = "修改功能权限配置";
    },
    //
    handleAdd() {
      this.editForm = {};
      this.titleDialog = "新增功能权限配置";
      this.dialogVisible = true;
    }
  }
};
</script>

<style scoped>
.mt20{
  margin-top: 20px;
}
/deep/.el-tree {
  width:400px;
  position: relative;
  cursor: default;
  background: #FFF;
  color: #606266;
  border: 1px solid #dddddd;
  padding: 10px;
  border-radius: 5px;
}
/deep/.el-tree-node__content>label.el-checkbox {
  right: 15px !important;
  position: absolute;
  margin-right:0px;
}
/deep/.el-tree-node__content{
  padding: 5px 0px;
}
</style>
