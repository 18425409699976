import Page from "@/components/kcgy/Page"
import Http from "@/api/Http";

export default class ListPage extends Page {

    buildQueryContidions(){
        return null;
    }


    /**
     * 构建分页查询表单.
     * @returns 分页查询表单.
     */
    buildPagingQueryForm(){
        var _pageIndex = this.vo.pageIndex==0?this.vo.pageIndex+1:this.vo.pageIndex
        return {
            pageIndexB1:_pageIndex,
            pageSize: this.vo.pageSize,
            conditions: this.buildQueryContidions()
        };
    }

    onLoadData=function(){
        this.vo.loading = true;
        this.loadData(
            (data, total)=>{
                this.vo.tableData = data;
                this.vo.pageTotal = total;
            },
            (pageIndex, pageSize)=>{
                this.vo.pageIndex=pageIndex;
                this.vo.pageSize=pageSize;
            },
            (extInfo)=>{
                this.vo.loading = false;
            }
        );
    } 



};