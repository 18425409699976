import { deletes, get, put, post, postnoAuth } from '../../service'
import apiUrl from './api-url'

export default class factoryBatchService {
  // 生成防伪码
  static buildCode(params = undefined) {
    return post(apiUrl.buildCode, params) 
  }
  // 导出防伪码
  static exportCode(params = undefined) {
    return get(apiUrl.exportCode+params.batchId+'/'+params.scope) 
  }
  // 获取产品列表
  static getGoodsList(params = undefined) {
    return post(apiUrl.getGoodsList, params) 
  }
  // 获取批次列表
  static pageList(params = undefined) {
    return post(apiUrl.getPageList, params) 
  }
  static addRow(params = undefined) {
    return post(apiUrl.insertInfo, params)
  }
  static editRow(params = undefined)  {
    return post(apiUrl.updateInfo, params)
  }
  static changeStatus (params = undefined) {
    return postnoAuth(apiUrl.updateStatus, params, '/bjl', {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    })
  }
  static delete (params = undefined) {
    console.log(params)
    return deletes(apiUrl.deleteInfo+params.id)
  }
  static dicList(params = undefined) {
    return postnoAuth(apiUrl.getDicList, params, '/bjl', {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    })
  }
 }