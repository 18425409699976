<template>
  <d2-container>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="游客管理" name="first">
        <visitor />
      </el-tab-pane>

      <el-tab-pane label="服务商管理" name="second">
        <!--查询条件配置组件-->
        <search-config
          ref="searchConfig_service"
          :configData="searchConfigData"
          :formSet="formSetData"
          :colspan="4.8"
          :btnColspan="5"
          :labelWidth="'100px'"
          @on-focus-select="handleFocus"
          @on-change-select="handleChange"
        >
          <el-button type="primary" @click="onQuery">查询</el-button>
          <el-button type="primary" @click="resetForm">重置</el-button>
        </search-config>
        <div>
          <el-button type="default" size="mini" @click="handelAdd(0)"
            >新增</el-button
          >
          <el-button type="default" size="mini">导出</el-button>
        </div>
        <div class="mt20">
          <free-table
            border
            :data="data"
            :column="slotColumn_service"
            :columnsProps="columnsProps"
            @selection-change="selectionChange"
            pagination
            :auto-scroll="false"
            :pageSizes="pagination.pageSizes"
            :total.sync="pagination.total"
            :page.sync="pagination.pageNum"
            :limit.sync="pagination.pageSize"
            @pagination="getList"
          >
            <template v-slot:auditRes="{ row }">
              <span
                v-if="Number(row.auditRes) === 1"
                @click="onFailClick(row)"
                style="color: blue;"
                >未通过</span
              >
              <span v-else-if="Number(row.auditRes) === 2">通过</span>
              <span v-else>审核中</span>
            </template>
            <template v-slot:status="{ row }">
              <el-switch
                @change="handleSwitchFocus(row)"
                v-model="row.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </template>
          </free-table>
        </div>
      </el-tab-pane>

      <el-tab-pane label="讲师管理" name="third">
        <search-config
          ref="searchConfig_jiangshi"
          :configData="searchConfigData"
          :formSet="formSetData"
          :colspan="4.8"
          :btnColspan="5"
          :labelWidth="'100px'"
          @on-focus-select="handleFocus"
          @on-change-select="handleChange"
        >
          <el-button type="primary" @click="onQuery(0)">查询</el-button>
          <el-button type="primary" @click="resetForm">重置</el-button>
        </search-config>
        <div>
          <el-button type="default" size="mini" @click="handelAdd(1)"
            >新增</el-button
          >
          <el-button type="default" size="mini">导出</el-button>
        </div>
        <div class="mt20">
          <free-table
            border
            :data="data"
            :column="slotColumn_jiangshi"
            :columnsProps="columnsProps"
            @selection-change="selectionChange"
            pagination
            :auto-scroll="false"
            :pageSizes="pagination.pageSizes"
            :total.sync="pagination.total"
            :page.sync="pagination.pageNum"
            :limit.sync="pagination.pageSize"
            @pagination="getList"
          >
            <template v-slot:auditRes="{ row }">
              <span
                v-if="Number(row.auditRes) === 1"
                @click="onFailClick(row)"
                style="color: blue;"
                >未通过</span
              >
              <span v-else-if="Number(row.auditRes) === 2">通过</span>
              <span v-else>审核中</span>
            </template>
            <template v-slot:status="{ row }">
              <el-switch
                @change="handleSwitchFocus(row)"
                v-model="row.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </template>
          </free-table>
        </div>
      </el-tab-pane>

      <el-tab-pane label="诊所合伙人管理" name="fifth">
        <search-config
          ref="searchConfig_client"
          :configData="searchConfigData"
          :formSet="formSetData"
          :colspan="4.8"
          :btnColspan="5"
          :labelWidth="'100px'"
          @on-focus-select="handleFocus"
          @on-change-select="handleChange"
        >
          <el-button type="primary" @click="onQuery(2)">查询</el-button>
          <el-button type="primary" @click="resetForm">重置</el-button>
        </search-config>
        <div>
          <el-button type="default" size="mini" @click="handelAdd(2)"
            >新增</el-button
          >
          <el-button type="default" size="mini">导出</el-button>
        </div>
        <div class="mt20">
          <free-table
            border
            :data="data"
            :column="slotColumn_client"
            :columnsProps="columnsProps"
            @selection-change="selectionChange"
            pagination
            :auto-scroll="false"
            :pageSizes="pagination.pageSizes"
            :total.sync="pagination.total"
            :page.sync="pagination.pageNum"
            :limit.sync="pagination.pageSize"
            @pagination="getList"
          >
            <template v-slot:img="{ row }">
              <span style="color: blue;" @click="onLookClick(row)">查看</span>
            </template>
            <template v-slot:auditRes="{ row }">
              <span
                v-if="Number(row.auditRes) === 1"
                @click="onFailClick(row)"
                style="color: blue;"
                >未通过</span
              >
              <span v-else-if="Number(row.auditRes) === 2">通过</span>
              <span v-else>审核中</span>
            </template>
            <template v-slot:status="{ row }">
              <el-switch
                @change="handleSwitchFocus(row)"
                v-model="row.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </template>
          </free-table>
        </div>
      </el-tab-pane>

      <el-tab-pane label="专家用户" name="fourth">
        <search-config
          ref="searchConfig"
          :configData="searchConfigData_zhuanjia"
          :formSet="formSetData"
          :colspan="4.8"
          :btnColspan="5"
          :labelWidth="'100px'"
          @on-focus-select="handleFocus"
          @on-change-select="handleChange"
        >
          <el-button type="primary" @click="onQuery(1)">查询</el-button>
          <el-button type="primary" @click="resetForm">重置</el-button>
        </search-config>
        <div>
          <el-button type="default" size="mini" @click="handelAdd(3)"
            >新增</el-button
          >
          <el-button type="default" size="mini">导出</el-button>
        </div>
        <div class="mt20">
          <free-table
            border
            :data="data"
            :column="slotColumn_zhuanjia"
            :columnsProps="columnsProps"
            @selection-change="selectionChange"
            pagination
            :auto-scroll="false"
            :pageSizes="pagination.pageSizes"
            :total.sync="pagination.total"
            :page.sync="pagination.pageNum"
            :limit.sync="pagination.pageSize"
            @pagination="getList"
          >
            <template v-slot:auditRes="{ row }">
              <span
                v-if="Number(row.auditRes) === 1"
                @click="onFailClick(row)"
                style="color: blue;"
                >未通过</span
              >
              <span v-else-if="Number(row.auditRes) === 2">通过</span>
              <span v-else>审核中</span>
            </template>
            <template v-slot:status="{ row }">
              <el-switch
                @change="handleSwitchFocus(row)"
                v-model="row.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </template>
          </free-table>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 新增弹窗 -->
    <add-sub
      ref="addsub"
      :form="form"
      :title="titleChange"
      :fShowProvince="fShowProvince"
      :fShowCity="fShowCity"
      :fShowExpertType="fShowExpertType"
      @on-handle-change="addSubHandleChange"
      @on-add-submit="handleAddSubmit"
    ></add-sub>

    <!-- 客户 -->
    <customer ref="customer" :title="titleChange" />

    <!--菜单授权界面-->
    <el-dialog
      title="菜单授权"
      :visible.sync="showDiaGrantMenus"
      :close-on-click-modal="true"
    >
      <el-tree
        check-on-click-node
        :expand-on-click-node="false"
        :highlight-current="true"
        :data="allRoles"
        :props="roleTreeProps"
        show-checkbox
        node-key="id"
        ref="tree"
        :default-checked-keys="selectedUserRoleIds"
        @check-change="onCheckMenuChanged"
      >
      </el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="closeDiaGrantMenus">关闭</el-button>
      </div>
    </el-dialog>

    <!-- 资质图片 -->
    <el-dialog title="资质图片" :visible.sync="qualificationPicture">
      <div>
        <el-row v-for="(item, index) in qualificationsImg" :key="index">
          <el-image :src="item" fit="contain"></el-image>
        </el-row>
      </div>
    </el-dialog>
  </d2-container>
</template>
<script>
// 引入接口
import {
  getUserEntityNewInfo,
  enableDisableInfo,
  qualificationInfo,
  toAdd,
  getPCInfo,
  getExpertTypeInfo,
  getLecturerTypeInfo,
  checkUserInfo,
  postAllRoles,
  getUserRoleIds,
  postGrantRole2User,
  postUngrantRoleOfUser,
  cancelUser,
  deposit
} from "@/model/modules/userinfo";

// 引入查询条件配置组件
import visitor from "./visitor";
import SearchConfig from "@/views/components/searchConfigForm";
import FreeTable from "@/views/components/FreeTable";

import AddSub from "./components/addSub/index.vue";
import customer from "./components/customer/index.vue";

export default {
  name: "roleinfo",
  components: {
    visitor,
    SearchConfig,
    FreeTable,
    AddSub,
    customer
  },
  data() {
    return {
      form: [],
      /**
       * 选中用户打开窗体时角色选中列表.
       */
      showDiaGrantMenus: false, //是否显示授权对话框
      allRoles: [],
      roleTreeProps: {
        // 树形结构
        children: "children",
        label: "roleName"
      },
      selectedUserRoleIds: [],

      titleChange: "用户新增",
      qualificationPicture: false,
      qualificationsImg: {},
      activeName: "first",
      currentIndex: 0,
      fShowProvince: [],
      fShowCity: [],
      fShowExpertType: [],

      formSetData: {
        param: "",
        nickNameVal: "",
        idVal: "",
        telVal: "",
        fullNameVal: "",

        time: "",
        regTmStartTime: "",
        regTmEndTime: "",
        auditTmStartTime: "",
        auditTmEndTime: "",

        status: "0",
        auditStatus: "0",
        sourceType: "0",
        userRole: "",
        userRole1: "",

        type: "",
        pageIndexB1: Number,
        pageSize: Number
      },
      searchConfigData: [
        {
          label: "",
          formItemId: 1,
          type: "multipleBoxes",
          configDatas: [
            {
              label: "",
              formItemId: 0,
              placeholder: "请选择",
              type: "select",
              prop: "param",
              isApi: false,
              isApi1: true,
              filterable: false,
              multiple: false,
              width: 130,
              options: [
                {
                  label: "用户昵称",
                  value: "nickNameVal"
                },
                {
                  label: "ID",
                  value: "idVal"
                },
                {
                  label: "电话",
                  value: "telVal"
                },
                {
                  label: "真实姓名",
                  value: "fullNameVal"
                }
              ]
            },
            {
              label: "",
              formItemId: 1,
              placeholder: "请输入",
              type: "input",
              prop: "",
              width: 170
            }
          ]
        },
        {
          label: "时间",
          formItemId: 2,
          type: "multipleBoxes",
          configDatas: [
            {
              label: "",
              formItemId: 0,
              placeholder: "请选择",
              type: "select",
              prop: "time",
              isApi: false,
              isApi1: true,
              filterable: false,
              multiple: false,
              width: 130,
              options: [
                {
                  label: "最后申请时间",
                  value: "0"
                },
                {
                  label: "最后审核时间",
                  value: "1"
                }
              ]
            },
            {
              label: "",
              formItemId: 1,
              placeholder: "请选择开始时间",
              type: "timePicker",
              prop: "",
              optionType: "datetime",
              width: 170
            },
            {
              label: "",
              formItemId: 2,
              placeholder: "请选择结束时间",
              type: "timePicker",
              prop: "",
              optionType: "datetime",
              width: 170
            }
          ]
        },
        {
          label: "用户状态",
          formItemId: 3,
          placeholder: "请选择",
          type: "select",
          prop: "status",
          isApi: false,
          filterable: false,
          multiple: false,
          options: [
            {
              label: "全部",
              value: "0"
            },
            {
              label: "启用",
              value: "1"
            },
            {
              label: "禁用",
              value: "2"
            }
          ]
        },
        {
          label: "审核状态",
          formItemId: 4,
          placeholder: "请选择",
          type: "select",
          prop: "auditStatus",
          isApi: false,
          filterable: false,
          multiple: false,
          options: [
            {
              label: "全部",
              value: "0"
            },
            {
              label: "已通过",
              value: "1"
            },
            {
              label: "待审核",
              value: "2"
            },
            {
              label: "未通过",
              value: "3"
            }
          ]
        },
        {
          label: "来源",
          formItemId: 5,
          placeholder: "请选择",
          type: "select",
          prop: "sourceType",
          isApi: false,
          filterable: false,
          multiple: false,
          options: []
        }
      ],
      searchConfigData_zhuanjia: [
        {
          label: "",
          formItemId: 1,
          type: "multipleBoxes",
          configDatas: [
            {
              label: "",
              formItemId: 0,
              placeholder: "请选择",
              type: "select",
              prop: "param",
              isApi: false,
              isApi1: true,
              filterable: false,
              multiple: false,
              width: 130,
              options: [
                {
                  label: "用户昵称",
                  value: "nickNameVal"
                },
                {
                  label: "ID",
                  value: "idVal"
                },
                {
                  label: "电话",
                  value: "telVal"
                },
                {
                  label: "真实姓名",
                  value: "fullNameVal"
                }
              ]
            },
            {
              label: "",
              formItemId: 1,
              placeholder: "请输入",
              type: "input",
              prop: "",
              width: 170
            }
          ]
        },
        {
          label: "时间",
          formItemId: 2,
          type: "multipleBoxes",
          configDatas: [
            {
              label: "",
              formItemId: 0,
              placeholder: "请选择",
              type: "select",
              prop: "time",
              isApi: false,
              isApi1: true,
              filterable: false,
              multiple: false,
              width: 130,
              options: [
                {
                  label: "最后申请时间",
                  value: "0"
                },
                {
                  label: "最后审核时间",
                  value: "1"
                }
              ]
            },
            {
              label: "",
              formItemId: 1,
              placeholder: "请选择开始时间",
              type: "timePicker",
              prop: "",
              optionType: "datetime",
              width: 170
            },
            {
              label: "",
              formItemId: 2,
              placeholder: "请选择结束时间",
              type: "timePicker",
              prop: "",
              optionType: "datetime",
              width: 170
            }
          ]
        },
        {
          label: "用户状态",
          formItemId: 3,
          placeholder: "请选择",
          type: "select",
          prop: "status",
          isApi: false,
          filterable: false,
          multiple: false,
          options: [
            {
              label: "全部",
              value: "0"
            },
            {
              label: "启用",
              value: "1"
            },
            {
              label: "禁用",
              value: "2"
            }
          ]
        },
        {
          label: "审核状态",
          formItemId: 4,
          placeholder: "请选择",
          type: "select",
          prop: "auditStatus",
          isApi: false,
          filterable: false,
          multiple: false,
          options: [
            {
              label: "全部",
              value: "0"
            },
            {
              label: "已通过",
              value: "1"
            },
            {
              label: "待审核",
              value: "2"
            },
            {
              label: "未通过",
              value: "3"
            }
          ]
        },
        {
          label: "用户角色",
          formItemId: 5,
          placeholder: "请选择",
          type: "select",
          prop: "userRole1",
          isApi: false,
          filterable: false,
          multiple: true,
          options: [
            {
              label: "课程专家",
              value: "COMP_SPEAKER"
            },
            {
              label: "直播专家",
              value: "COMP_COUNSELOR"
            },
            {
              label: "问诊专家",
              value: "COMP_BROADCASTER"
            }
          ]
        }
      ],

      data: [],
      slotColumn_service: [
        { label: "选择", prop: "selection", type: "selection" },
        { label: "ID", prop: "id" },
        { label: "用户名", prop: "nickName" },
        { label: "来源", prop: "sourceTypeStr" },
        { label: "真实姓名", prop: "fullname" },
        { label: "电话", prop: "tel" },
        { label: "康驰币", prop: "kkc" },
        { label: "所属区域", prop: "divisionStr" },
        { label: "注册时间", prop: "regTmStr", sort: true },
        { label: "最后审核时间", prop: "auditTmStr", sort: true },
        { label: "审核状态", prop: "auditRes", slotScope: true },
        { label: "用户状态", prop: "status", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: "370px",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onReviseClick(scope.row);
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onClientClick(scope.row);
                  }}
                >
                  客户
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onClickSetRoles(scope);
                  }}
                >
                  设置角色
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onClickDeposit(scope);
                  }}
                >
                  充值
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onCancelUser(scope);
                  }}
                >
                  注销用户
                </el-button>
              </div>
            );
          }
        }
      ],
      slotColumn_jiangshi: [
        { label: "选择", prop: "selection", type: "selection" },
        { label: "ID", prop: "id" },
        { label: "用户名", prop: "nickName" },
        { label: "来源", prop: "sourceTypeStr" },
        { label: "真实姓名", prop: "fullname" },
        { label: "电话", prop: "tel" },
        { label: "康驰币", prop: "kkc" },
        { label: "所属区域", prop: "divisionStr" },
        { label: "注册时间", prop: "regTmStr", sort: true },
        { label: "最后审核时间", prop: "auditTmStr", sort: true },
        { label: "审核状态", prop: "auditRes", slotScope: true },
        { label: "用户状态", prop: "status", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: "370px",
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onReviseClick(scope.row);
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onClickSetRoles(scope);
                  }}
                >
                  设置角色
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onClickDeposit(scope);
                  }}
                >
                  充值
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onCancelUser(scope);
                  }}
                >
                  注销用户
                </el-button>
              </div>
            );
          }
        }
      ],
      slotColumn_client: [
        { label: "选择", prop: "selection", type: "selection" },
        { label: "ID", prop: "id" },
        { label: "用户名称", prop: "nickName" },
        { label: "来源", prop: "sourceTypeStr" },
        { label: "真实姓名", prop: "fullname" },
        { label: "电话", prop: "tel" },
        { label: "康驰币", prop: "kkc" },
        { label: "服务商真实姓名", prop: "customerName" },
        { label: "资质图片", prop: "img", slotScope: true },
        { label: "注册时间", prop: "regTmStr" },
        { label: "最后审核时间", prop: "auditTmStr" },
        { label: "审核状态", prop: "auditRes", slotScope: true },
        { label: "用户状态", prop: "status", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: 450,
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.toBasicSettings(scope);
                  }}
                >
                  基础设置
                </el-button>
                <el-button
                  icon="el-icon-edit-outline"
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onReviseClick(scope.row);
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onClickSetRoles(scope);
                  }}
                >
                  设置角色
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onClickDeposit(scope);
                  }}
                >
                  充值
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onCancelUser(scope);
                  }}
                >
                  注销用户
                </el-button>
              </div>
            );
          }
        }
      ],
      slotColumn_zhuanjia: [
        { label: "选择", prop: "selection", type: "selection" },
        { label: "ID", prop: "id" },
        { label: "用户名称", prop: "nickName" },
        { label: "真实姓名", prop: "fullname" },
        { label: "用户电话", prop: "tel" },
        { label: "康驰币", prop: "kkc" },
        { label: "注册时间", prop: "regTmStr" },
        { label: "最后审核时间", prop: "auditTmStr" },
        { label: "审核状态", prop: "auditRes", slotScope: true },
        { label: "用户状态", prop: "status", slotScope: true },
        {
          label: "操作",
          prop: "cation",
          align: "center",
          width: 400,
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  icon="el-icon-edit-outline"
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onReviseClick(scope.row);
                  }}
                >
                  修改
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onClickSetRoles(scope);
                  }}
                >
                  设置角色
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onClickDeposit(scope);
                  }}
                >
                  充值
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onCancelUser(scope);
                  }}
                >
                  注销用户
                </el-button>
              </div>
            );
          }
        }
      ],

      columnsProps: {
        width: "auto",
        showOverflowTooltip: true
      },
      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      }
    };
  },
  filters: {
    time: function time(value) {
      var now = new Date(Number(value));
      var y = now.getFullYear();
      var m = now.getMonth() + 1;
      var d = now.getDate();
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        now.toTimeString().substr(0, 8)
      );
    }
  },
  watch: {
    "formSetData.userRole1"() {
      if (this.formSetData.userRole1 != "") {
        let str = "";
        this.formSetData.userRole1.map((item, index) => {
          str += item + ",";
        });
        this.formSetData.userRole = str.substring(0, str.length - 1);
      } else {
        this.formSetData.userRole = "";
      }
    },

    data: {
      handler(newValue, oldValue) {
        for (let i = 0; i < newValue.length; i++) {
          if (newValue[i].status == 1) {
            newValue[i].status = true;
          } else {
            newValue[i].status = false;
          }
        }
      }
    }

    // 'formSetData.province' () {
    //   let configData = ''
    //   if (this.currentIndex === 0) {
    //     configData = this.searchConfigData
    //   } else if (this.currentIndex === 1) {
    //     configData = this.searchConfigData_zhuanjia
    //   } else if (this.currentIndex === 2) {
    //     configData = this.searchConfigData_client
    //   }
    //   configData.map((item, index) => {
    //     if (item.label === '所属区域') {
    //       this.formSetData.city = ''
    //       if (this.formSetData.province === '') {
    //         item.configDatas[1].disabled = true
    //       } else {
    //         item.configDatas[1].disabled = false
    //         this.getPCInfo(item.configDatas[1], this.formSetData.province)
    //       }
    //     }
    //   })
    // }
  },
  mounted() {
    // if (this.$route.query.activeName) {
    //   this.activeName = this.$route.query.activeName;
    // }

    if (this.$route.params.activeName) {
      this.activeName = this.$route.params.activeName;
    }

    this.getUserEntityNewInfo();
    this.getPCInfo();
  },
  methods: {
    async getUserEntityNewInfo() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      this.formSetData.type = this.currentIndex;
      const res = await getUserEntityNewInfo(this.formSetData);
      if (res.data.code === 0) {
        this.data = res.data.body.content;
        this.pagination.total = res.data.body.total * 1;
      }
    },
    async getPCInfo(param = "") {
      const res = await getPCInfo({ parentCode: param });
      if (res.data.code === 0) {
        if (param === "") {
          this.fShowProvince = this.formatData(
            res.data.body,
            "itemCode",
            "itemName"
          );
          this.fShowCity = [];
        } else {
          this.fShowCity = this.formatData(
            res.data.body,
            "itemCode",
            "itemName"
          );
        }
      }
    },
    async getExpertTypeInfo() {
      let res;
      if (this.currentIndex == 3) {
        res = await getExpertTypeInfo();
      } else if (this.currentIndex == 1) {
        res = await getLecturerTypeInfo();
      }
      if (res != undefined && res.data.code === 0) {
        this.fShowExpertType = this.formatData(
          res.data.body,
          "itemCode",
          "itemName"
        );
      }
    },

    // 充值
    onClickDeposit({ row }) {
      this.$prompt("请输入康驰币数量", "康驰币充值", {
        showInput: true,
        inputType: "number",
        inputPattern: /(^[0-9]\d*$)/,
        inputErrorMessage: "请输入0或正整数"
      }).then(res => {
        let params = {
          reChargeNum: Number(res.value),
          userId: row.id
        };

        deposit(params).then(res => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "充值成功",
              duration: 1500
            });
            this.getUserEntityNewInfo();
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
              duration: 1500
            });
          }
        });
      });
    },

    handleClick(tab, event) {
      this.formSetData = {
        param: "",
        nickNameVal: "",
        idVal: "",
        telVal: "",
        fullNameVal: "",

        time: "",
        regTmStartTime: "",
        regTmEndTime: "",
        auditTmStartTime: "",
        auditTmEndTime: "",

        status: "0",
        auditStatus: "0",
        sourceType: "0",
        userRole: "",
        userRole1: "",

        type: "",
        pageIndexB1: Number,
        pageSize: Number
      };
      if (tab.index != 0) {
        this.currentIndex = tab.index - 1;
        this.pagination.pageNum = 1;
        this.getUserEntityNewInfo();
        this.getExpertTypeInfo();
      }

      if (tab.index != 3) {
        this.searchConfigData[4].options = [
          {
            label: "全部",
            value: "0"
          },
          {
            label: "App",
            value: "1"
          },
          {
            label: "管理后台",
            value: "2"
          }
        ];
      } else {
        this.searchConfigData[4].options = [
          {
            label: "全部",
            value: "0"
          },
          {
            label: "App",
            value: "1"
          },
          {
            label: "管理后台",
            value: "2"
          },
          {
            label: "灵源活动",
            value: "3"
          },
          {
            label: "散寒活动",
            value: "4"
          }
        ];
      }
    },

    // 查询下拉框
    handleFocus(e) {},
    handleChange(item) {
      if (item.formItemId === 1) {
        let val = item.configDatas[1].prop;
        if (val !== "") {
          this.formSetData[val] = "";
        }
        item.configDatas[1].prop = this.formSetData.param;
      } else if (item.formItemId === 2) {
        let arrStr = [];
        if (Number(this.formSetData.time) === 0) {
          arrStr[0] = "mtmStartTime";
          arrStr[1] = "mtmEndTime";
          this.formSetData.auditTmStartTime = "";
          this.formSetData.auditTmEndTime = "";
        } else if (Number(this.formSetData.time) === 1) {
          arrStr[0] = "auditTmStartTime";
          arrStr[1] = "auditTmEndTime";
          this.formSetData.mtmStartTime = "";
          this.formSetData.mtmEndTime = "";
        }
        arrStr.map((i, j) => {
          item.configDatas[j + 1].prop = i;
        });
      }
    },

    // 注销用户
    onCancelUser(data) {
      let params = {
        tel: data.row.tel
      };
      this.$confirm("确认提交吗？", "提示", {}).then(() => {
        cancelUser(params).then(res => {
          if (res.data.code === 0) {
            this.getUserEntityNewInfo();
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      });
    },

    // 表格查询按钮
    onQuery() {
      this.pagination.pageNum = 1;
      this.getUserEntityNewInfo();
    },

    // 重置
    resetForm() {
      this.formSetData = {
        param: "",
        nickNameVal: "",
        idVal: "",
        telVal: "",
        fullNameVal: "",

        time: "",
        regTmStartTime: "",
        regTmEndTime: "",
        auditTmStartTime: "",
        auditTmEndTime: "",

        status: "0",
        auditStatus: "0",
        sourceType: "0",
        userRole: "",
        userRole1: "",

        type: "",
        pageIndexB1: Number,
        pageSize: Number
      };
    },

    // 分页选择器切换事件
    getList(e) {
      this.pagination.pageNum = e.page;
      this.pagination.pageSize = e.limit;
      this.getUserEntityNewInfo();
    },

    // 用户状态
    handleSwitchFocus(row) {
      enableDisableInfo({ userId: row.id }).then(res => {
        if (res.data.code === 0) {
          this.getUserEntityNewInfo();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500
          });
        }
      });
    },
    // 未通过
    onFailClick(e) {
      this.$alert(e.auditReason, "驳回原因：", {
        confirmButtonText: "确定"
      }).catch(() => {});
    },
    // 查看资质图片
    onLookClick(e) {
      qualificationInfo({ id: e.id }).then(res => {
        if (res.data.code === 0) {
          this.qualificationPicture = true;
          let img = [];
          img.push(res.data.body.gspImg);
          img.push(res.data.body.idCordImgInfo);
          img.push(res.data.body.idCardImgNationalEmblen);
          img.push(res.data.body.bizLicImg);
          this.qualificationsImg = img;
        }
      });
    },
    // 选择
    selectionChange(e) {},

    // 内部用户新增
    handelAdd(index) {
      this.titleChange = "用户新增";
      this.$refs.addsub.showDialog(index);
    },
    addSubHandleChange(param) {
      this.getPCInfo(param);
    },

    // 针对下拉选择器的提前限制，当value有对应的key时，正常显示；当value无对应的key时，则设置为空
    // curValue: 当前value值；selectList：下拉数组；labelName：下拉数组中label对应的名字；valueName：下拉数组中value对应的名字
    checkSelect(
      curValue,
      selectList,
      labelName = "label",
      valueName = "value"
    ) {
      let curLabel = "";
      selectList.map(item => {
        if (item[valueName] == curValue) {
          curLabel = item[labelName];
        }
      });
      return curLabel;
    },

    // 关闭设置角色对话框
    closeDiaGrantMenus() {
      this.getUserEntityNewInfo();
      this.showDiaGrantMenus = false;
    },

    // 修改
    onReviseClick(e) {
      this.titleChange = "用户修改";
      checkUserInfo({ id: e.id }).then(res => {
        if (res.data.code === 0) {
          const data = res.data.body;
          if (data.location !== undefined) {
            let locationVal = data.location.split(",");
            if (locationVal[0] == "undefined" || !locationVal[0]) {
              data.province = "";
            } else {
              data.province = locationVal[0];
            }

            this.addSubHandleChange(data.province);
            if (locationVal[1] == "undefined" || !locationVal[1]) {
              data.city = "";
            } else {
              data.city = locationVal[1];
            }
            // data.locationStr = data.location.split(',')
          }
          if (data.roleCode !== undefined) {
            data.roleCode1 = data.roleCode.split(",");
          }
          if (data.adminDivision !== undefined) {
            data.adminDivisionStr = data.adminDivision.split(",");
          }

          // 对下拉选择器中的值进行验证
          data.userType = this.checkSelect(data.userType, this.fShowExpertType);

          const info = {
            ...data,
            id: e.id
          };
          this.$refs.addsub.showDialog(this.currentIndex, info);
        }
      });
    },
    // 提交
    handleAddSubmit(e) {
      toAdd({
        ...e,
        type: this.currentIndex
      }).then(res => {
        if (res.data.code === 0) {
          this.getUserEntityNewInfo();
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 客户
    onClientClick(e) {
      this.$refs.customer.showDialog({ ...e });
    },

    // 设置角色
    onClickSetRoles(scope) {
      this.selectedUser = scope.row;
      this.selectedUserRoleIds = [];
      postAllRoles().then(resp => {
        this.allRoles = resp.data.body.content;
        getUserRoleIds(scope.row.id)
          .then(respRids => {
            this.selectedUserRoleIds = respRids.data.body;
          })
          .finally(() => {
            //打开角色授权对话框
            this.showDiaGrantMenus = true;
          });
      });
    },
    onCheckMenuChanged(role, checked) {
      if (checked) {
        postGrantRole2User(this.selectedUser.id, role.id);
      } else {
        postUngrantRoleOfUser(this.selectedUser.id, role.id);
      }
    },

    formatData(data, key, val) {
      const obj = [];
      for (const i in data) {
        const item = data[i];
        obj[i] = {
          value: item[key] || "",
          label: item[val] || ""
        };
      }
      return obj;
    },

    // 跳转到基础设置页面
    toBasicSettings(scope) {
      this.$router.push({
        path: "/appconsole/userinfo/address",
        query: {
          activeName: this.activeName,
          id: scope.row.id
        }
      });
    }
  }
};
</script>
<style scoped>
.mt20 {
  margin-top: 20px;
}
</style>
