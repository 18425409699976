import { deletes, post, get } from '../../service'

export default class SpeakerManageApi {
  // 获取列表
  static pageList (params = undefined) {
    return post('/service/speaker/findSpeakerList', params)
  }

  // 新增/修改
  static saveRow (params = undefined) {
    return post('/service/speaker/save', params)
  }

  // 删除
  static deleteRow (params = undefined) {
    return deletes('/service/speaker/id/'+params.id)
  }

  // 获取专家用户
  static getSpeakerList (params = undefined) {
    return post('/service/speaker/expert/asOptions', params)
  }
 }
