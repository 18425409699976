<template>
    <div>
        <el-dialog
            :title="titleCode"
            :visible.sync="dialogFormVisible"
            v-bind="$attrs"
        >
            <div class="flex_wrap">
                <div class="flex_item">
                    <div class="dis_margin">
                        商品信息 id：<span class="color_red">17</span>
                        名称： <span class="color_red">老李卤豆干炸卤豆腐干*250g</span>
                    </div>
                    <div class="dis_margin">
                        出场批次信息 id：<span class="color_red">17</span>
                        名称： <span class="color_red">20210716000008</span>
                    </div>
                    <div class="dis_margin">
                        <div class="flex_label">生成个数</div> 
                        <el-input-number style="margin-left:10px;"  v-model="count" @change="handleChange" :min="1" :max="1000" label="生成个数"></el-input-number>
                       
                    </div>
                    <div class="dis_margin">
                        <div class="flex_label">生成位数</div>
                        <el-input-number style="margin-left:10px;" v-model="num" @change="handleChange" :min="1" :max="10" label="生成位数"></el-input-number>
                    </div>
                    <div class="dis_margin">
                        <div class="flex_label">生成规则</div>
                        <el-radio-group v-model="radio" style="margin-left:10px;">
                            <el-radio :label="3">字母与数字混合</el-radio>
                            <el-radio :label="6">纯字母</el-radio>
                            <el-radio :label="9">纯数字</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="dis_margin">
                        <div class="flex_label">区分大小写</div>
                        <el-radio-group v-model="capitalRadio" style="margin-left:10px;">
                            <el-radio :label="1">不区分</el-radio>
                            <el-radio :label="2">区分</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <slider-cmp v-if="isSliderVisible" :dataRatio="dataRatio"></slider-cmp>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="handleCreating">开始生成</el-button>
                <el-button type="danger" @click="onStopCreate">停止</el-button>
                <el-button type="default" @click="onClose">关闭</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
    import sliderCmp from "@/views/components/sliderCmp/index.vue"
    export default {
        data(){
            return {
                count:1000,
                num:10,
                dialogFormVisible:false,
                radio: 3,
                capitalRadio:1,
                isSliderVisible:false,
                dataRatio:{}
            }
        },
        props:{
            titleCode:String
        },
        components:{
            sliderCmp
        },
        methods:{
            showDialog(e){
                this.dialogFormVisible = true
            },
            //开始生成
            handleCreating(){
                this.isSliderVisible = true
            },
            //停止
            onStopCreate(){
                this.isSliderVisible = false

            },
            onClose(){
                this.dialogFormVisible = false
            }
        }
    }
</script>

<style lang="scss" scoped>
.flex_wrap{
    display:flex;
    .flex_item{
        flex:1;
        .color_red{
          color:red;
          margin-right:10px;  
        }
    }
}
.dis_margin{
    margin-bottom:22px;
    font-size:14px;
    display: flex;
    .flex_label{
        flex:0 0 100px;
        width:100px;
    }
}
</style>