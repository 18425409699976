import { get, post, deletes } from '../../service'

export default class Version {
    // 列表
    static versionList(params = undefined) {
        return post('/service/version/findVersionList', params)
    }
    // 保存
    static saveVersion(params = undefined) {
        return post('/service/version/save', params)
    }

    // 删除
    static deleteVersion(params = undefined) {
        return deletes('/service/version/id/' + params.id)
    }
}
