<template>
  <d2-container>
    <el-tabs @tab-click="onTabClick" v-model="searchForm.type">
      <el-tab-pane label="讲师管理" name="1">
        <!-- 查询 -->
        <el-form inline :model="searchForm">
          <el-form-item label="姓名">
            <el-input
              clearable
              v-model="searchForm.name"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户类别">
            <el-select v-model="searchForm.userType" placeholder="请选择" clearable>
              <el-option
                v-for="item in cur_userTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearchSubmit">查询</el-button>
          </el-form-item>
        </el-form>

        <!-- 表格 -->
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            prop="name"
            label="姓名"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="userType"
            label="用户类别"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="consultedNum"
            label="被询问次数"
          ></el-table-column>
          <el-table-column
            prop="voiceNum"
            label="语音接通次数"
          ></el-table-column>
          <el-table-column
            prop="voiceRate"
            label="语音接通率"
          ></el-table-column>
          <el-table-column
            prop="videoNum"
            label="视频接通次数"
          ></el-table-column>
          <el-table-column
            prop="videoRate"
            label="视频接通率"
          ></el-table-column>
          <el-table-column
            prop="unAnswerNum"
            label="未解答次数"
          ></el-table-column>
          <el-table-column
            prop="quickAnswerTime"
            label="最快回答时长"
          ></el-table-column>
          <el-table-column
            prop="slowAnswerTime"
            label="最慢回答时长"
          ></el-table-column>
          <el-table-column
            prop="lastAnswerTime"
            label="最后解答时间"
          ></el-table-column>
          <el-table-column prop="weight" label="排序">
            <template slot-scope="scope">
                <el-input v-model="scope.row.weight" @keyup.enter.native="onInputChange(scope.row)" @change="onInputChange(scope.row)"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="c" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="openDialog(scope.row)"
                >咨询问卷</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination
          style="margin-top: 20px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageInit.cur_page"
          :page-sizes="pageInit.pageSizes"
          :page-size="pageInit.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInit.total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="中医名家管理" name="2">
        <!-- 查询 -->
        <el-form inline :model="searchForm">
          <el-form-item label="姓名">
            <el-input
              clearable
              v-model="searchForm.name"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户类别">
            <el-select v-model="searchForm.userType" placeholder="请选择" clearable>
              <el-option
                v-for="item in cur_userTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearchSubmit">查询</el-button>
          </el-form-item>
        </el-form>

        <!-- 表格 -->
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            prop="name"
            label="姓名"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="userType"
            label="用户类别"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="consultedNum"
            label="被询问次数"
          ></el-table-column>
          <el-table-column
            prop="voiceNum"
            label="语音接通次数"
          ></el-table-column>
          <el-table-column
            prop="voiceRate"
            label="语音接通率"
          ></el-table-column>
          <el-table-column
            prop="videoNum"
            label="视频接通次数"
          ></el-table-column>
          <el-table-column
            prop="videoRate"
            label="视频接通率"
          ></el-table-column>
          <el-table-column
            prop="unAnswerNum"
            label="未解答次数"
          ></el-table-column>
          <el-table-column
            prop="quickAnswerTime"
            label="最快回答时长"
          ></el-table-column>
          <el-table-column
            prop="slowAnswerTime"
            label="最慢回答时长"
          ></el-table-column>
          <el-table-column
            prop="lastAnswerTime"
            label="最后解答时间"
          ></el-table-column>
          <el-table-column prop="weight" label="排序">
            <template slot-scope="scope">
                <el-input v-model="scope.row.weight" @keyup.enter.native="onInputChange(scope.row)" @change="onInputChange(scope.row)"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="c" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="openDialog(scope.row)"
                >咨询问卷</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination
          style="margin-top: 20px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageInit.cur_page"
          :page-sizes="pageInit.pageSizes"
          :page-size="pageInit.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInit.total"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>

    <!-- 咨询问卷弹窗 -->
    <el-dialog title="咨询问卷记录" :visible.sync="dialogVisible" width="50%">
      <el-row v-if="imgs.length > 0">
        <el-col :span="6" v-for="item in imgs" :key="item.nickName">
          <div style="text-align: center">
            <el-image
              :src="item.url"
              class="item_img"
              :preview-src-list="imgSrcList"
            ></el-image>
          </div>
          <div style="text-align: center; margin: 10px 0">
            <span>咨询人：</span>
            <span>{{ item.nickName }}</span>
          </div>
        </el-col>
      </el-row>

      <el-row v-else>
        <div style="color: #999; text-align: center">暂无数据</div>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </d2-container>
</template>
<script>
import AnswerUserManage from "@/model/modules/answerUserManage";

export default {
  data() {
    return {
      imgSrcList: [], // 咨询问卷图片数组
      imgs: [], //咨询问卷列表
      cur_tabName: "1", //当前选中用户类型
      cur_userTypeList: [], //当前用户类别数组
      lecturerType: [], //讲师用户类别
      expertType: [
        //中医名家用户类别
        {
          label: " 名老中医",
          value: 1,
        },
        {
          label: " 国家级名中医",
          value: 2,
        },
        {
          label: " 三甲医院教授",
          value: 3,
        },
        {
          label: " 高校名医教授",
          value: 4,
        },
        {
          label: " 省级名中医",
          value: 5,
        },
        {
          label: " 民间名中医",
          value: 6,
        },
        {
          label: " 医学世家传承人",
          value: 7,
        },
      ],
      searchForm: {
        name: "",
        type: "1", //sheet页类型 1 讲师 2 中医名家
        userType: "", //用户类别
        pageIndexB1: 1,
        pageSize: 10,
      },
      tableData: [],
      pageInit: {
        total: 1,
        cur_page: 1,
        pageSize: 10,
        pageSizes: ["10", "20", "30"],
      },
      dialogVisible: false,
    };
  },

  mounted() {
    this.getDataList();
    this.getLecturerType();
    this.cur_userTypeList = this.lecturerType;
  },

  methods: {
    getDataList() {
      this.searchForm.pageIndexB1 = this.pageInit.cur_page;
      this.searchForm.pageSize = this.pageInit.pageSize;
      AnswerUserManage.pageList(this.searchForm).then((res) => {
        if (!res.data.code === 0) return;
        this.tableData = res.data.body.content;
        this.pageInit.total = res.data.body.total;
      });
    },

    // 获取讲师用户类别
    getLecturerType() {
      AnswerUserManage.getLecturerType().then((res) => {
        if (!res.data.code == 0) reture;
        res.data.body.map((item) => {
          var obj = {};
          obj.label = item.itemName;
          obj.value = item.itemCode;
          this.lecturerType.push(obj);
        });
      });
    },

    // 查询
    onSearchSubmit() {
      this.pageInit.cur_page = 1;
      this.getDataList();
    },

    // 切换标签
    onTabClick(tab, e) {
      this.pageInit.cur_page = 1;
      this.pageInit.pageSize = 10;
      this.searchForm = {
        name: "",
        type: tab.name, //sheet页类型 1 讲师 2 中医名家
        userType: "", //用户类别
        pageIndexB1: 1,
        pageSize: 10,
      };
      if (this.searchForm.type == "1") {
        this.cur_userTypeList = this.lecturerType;
      } else {
        this.cur_userTypeList = this.expertType;
      }
      this.getDataList();
    },

    // 权重输入框@change触发
    onInputChange(row){
        console.log(row)
        var params = {
            id: row.id,
            weight: row.weight
        }
        AnswerUserManage.setUpUserWeight(params)
          .then(res => {
              if(!res.data.code == 0) return
              this.getDataList()
          })

    },

    // 打开弹窗
    openDialog(row) {
      var params = {
        id: row.id,
      };
      AnswerUserManage.getQuestionnaire(params).then((res) => {
        console.log(res);
        if (!res.data.code == 0) return;
        this.imgs = res.data.body;
        this.imgs.map(item => {
          this.imgSrcList.push(item.url)
        })
      });
      this.dialogVisible = true;
    },

    handleSizeChange(e) {
      console.log(e);
      this.pageInit.pageSize = e;
      this.pageInit.cur_page = 1;
      this.getDataList();
    },

    handleCurrentChange(e) {
      console.log(e);
      this.pageInit.cur_page = e;
      this.getDataList();
    },
  },
};
</script>

<style scoped>
.item_img{
  width: 150px; 
  height: 200px;
  border: 1px dashed #d9d9d9;
}

.item_img:hover{
  border-color: #409eff;
}
</style>