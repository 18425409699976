<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="4"
      :labelWidth="'70px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <div>
      <el-button
        type="default"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="onAdd"
      >
        新增企业
      </el-button>
    </div>

    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
      </free-table>
    </div>

    <!--添加/修改弹窗-->
    <add-sub
      ref="addSubDialog"
      width="50%"
      align="left"
      :form="form"
      :config="config"
      :rules="rules"
      :titleSrc="titleSrc"
      @on-edit-submit="onEditSubmit"
      @on-focus-select="handleFocus"
    />
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'
// 引入页面层级组件
import AddSub from './components/addSub'
// 引入接口
import {
  pageList,
  insert
} from '@/model/modules/enterprise/index'
export default {
  name: 'roleinfo',
  components: {
    SearchConfig,
    FreeTable,
    AddSub
  },
  data () {
    return {
      titleSrc: '新增企业',
      rules: {
        epName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ]
      },
      // 搜索框渲染
      formSetData: {
        pageIndexB1: Number,
        pageSize: Number
      },
      searchConfigData: [
        {
          label: '企业名称',
          formItemId: 0,
          placeholder: '请输入企业名称',
          type: 'input',
          prop: 'epName'
        }
      ],

      // 表格数据渲染
      data: [],
      slotColumn: [
        // { label: '', prop: 'selection', type: 'selection' },
        { label: '序号', prop: 'index', type:'index' },
        { label: 'id', prop: 'id' },
        { label: '企业名称', prop: 'epName' },
        { label: '统一社会信用代码', prop: 'uniCode'},
        { label: '企业注册地址', prop: 'regAddress'},
        { label: '企业电话', prop: 'tel'},
        { label: '生产地址', prop: 'prodAdress'},
        { label: '生产范围', prop: 'busiScope'},
        {
          label: '操作',
          prop: 'cation',
          align: 'center',
          width: '200px',
          render: (h, scope) => {
            return (
              <div>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.gotoGoodsList(scope.row)
                  }}
                >
                  商品
                </el-button>
                <el-button
                  type="default"
                  size="small"
                  onClick={() => {
                    this.onEditClick(scope.row)
                  }}
                >
                  编辑
                </el-button>
              </div>
            )
          }
        }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },

      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 2],
        total: 1
      },

      // 新增课程
      form: {
        
      },
      config: [
        [
          {
            colSpan: 12,
            label: '企业名称',
            type: 'input',
            prop: 'epName'
          },
          {
            colSpan: 12,
            label: '统一社会信用代码',
            type: 'input',
            prop: 'uniCode'
          },
          {
            colSpan: 12,
            label: '企业注册地址',
            type: 'input',
            prop: 'regAddress'
          },
          {
            colSpan: 12,
            label: '企业电话',
            type: 'input',
            prop: 'tel'
          },
          {
            colSpan: 12,
            label: '生产地址',
            type: 'input',
            prop: 'prodAdress'
          },
          {
            colSpan: 12,
            label: '生产范围',
            type: 'input',
            prop: 'busiScope'
          }
        ],
        [
          {
            colSpan: 24,
            label: '企业详情',
            type: 'quill',
            prop: 'enterpriseDesc'
          }
        ],
        [
           {
            colSpan: 24,
            label: '企业荣誉',
            type: 'quill',
            prop: 'enterpriseHonors'
          }
        ]
      ]
    }
  },

  mounted () {
    this.getDataList()
  },
  methods: {
    //初始化表格渲染
    async getDataList() {
      this.formSetData.pageIndexB1 = this.pagination.pageNum;
      this.formSetData.pageSize = this.pagination.pageSize;
      this.formSetData ={
        ...this.formSetData,
        nameLike:this.formSetData.epName
      }
      let res = await pageList(this.formSetData);
      this.data = res.data.body.content;
      this.pagination.total = res.data.body.total * 1;
    },
    onQuery(){
      this.pagination.pageNum = 1
      this.getDataList()
    },
    gotoGoodsList({id}){
      this.$router.push({
        path:`/goodsManage/goodsList`,
        query:{
          id:id
        }
      })
    },
    // 新增课程
    onAdd () {
      this.form = {}
      this.$refs.addSubDialog.showDialog(this.form)
      this.titleSrc = '新增企业'
    },
    onEditClick(e){
      this.form = {
        ...e
      }
      this.$refs.addSubDialog.showDialog(this.form)
      this.titleSrc = '编辑企业'
    },
    //
    // 提交编辑表格行信息
    onEditSubmit (e) {
      console.log(e,"jevfheuhrhhbhu=====")
      insert(e.form).then(res=>{
        this.getDataList()
        console.log(res,"kidjvijvdvjidj======")
      })
    },
    // 分页选择器切换事件
    getList (e) {
      this.pagination.pageNum = e.page
      this.pagination.pageSize = e.limit
      this.getDataList()
    },
    handleFocus (e) {
      
    },
    handleQueryFocus (e) {
      
    }
  }
}
</script>

<style scoped>
.mt20 {
  margin-top: 20px;
}
</style>
