<template>
  <d2-container>
    <!--查询条件配置组件-->
    <search-config
      ref="searchConfig"
      :configData="searchConfigData"
      :formSet="formSetData"
      :colspan="5"
      :btnColspan="4"
      :labelWidth="'90px'"
      @on-focus-select="handleQueryFocus"
    >
      <el-button type="primary" @click="onQuery">查询</el-button>
    </search-config>
    <!--表格条件配置组件-->
    <div class="mt20">
      <free-table
        border
        :data="data"
        :column="slotColumn"
        :columnsProps="columnsProps"
        pagination
        :auto-scroll="false"
        :pageSizes="pagination.pageSizes"
        :total.sync="pagination.total"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        @pagination="getList"
      >
        <template v-slot:status="{ row }">
          {{ row.status == 1 ? "上架" : "下架" }}
        </template>
      </free-table>
    </div>
  </d2-container>
</template>

<script>
// 引入查询条件配置组件
import SearchConfig from '@/views/components/searchConfigForm'
import FreeTable from '@/views/components/FreeTable'
// 引入接口
export default {
  name: 'menuHealth',
  components: {
    SearchConfig,
    FreeTable
  },
  data () {
    return {
      fShowPositions: {},
      titleSrc: '新增课程',

      // 搜索框渲染
      formSetData: {
        courseName: null,
        fullname: null,
        pageIndexB1: Number,
        pageSize: Number,
        status: '',
        position: ''
      },
      searchConfigData: [
        {
          label: '专家名',
          formItemId: 0,
          placeholder: '请输入专家姓名',
          type: 'input',
          prop: 'courseName'
        },
        {
          label: '状态',
          formItemId: 1,
          placeholder: '请选择',
          type: 'select',
          options: [
            {
              label: '全部',
              value: '全部'
            },
            {
              label: '在线',
              value: '在线'
            },
            {
              label: '忙碌',
              value: '忙碌'
            },
            {
              label: '离线',
              value: '离线'
            }
          ],
          prop: 'fullname'
        }
      ],

      // 表格数据渲染
      data: [
      ],
      slotColumn: [
        { label: '序号', prop: 'courseName', type: 'index', width: '50px' },
        { label: '专家姓名', prop: 'courseName' },
        { label: '信息1', prop: 'fullname' },
        { label: '信息2', prop: 'amount' },
        { label: '状态', prop: 'uploadCourseCount', slotScope: true },
        { label: '问诊类型', prop: 'ctm' },
        { label: '连线方姓名', prop: 'status4' },
        { label: '所属诊所', prop: 'status1' },
        { label: '所属组织', prop: 'status2' },
        { label: '连线时长', prop: 'status3' }
      ],
      columnsProps: {
        width: 'auto',
        showOverflowTooltip: true
      },

      // 页码
      pagination: {
        pageNum: 1,
        pageSize: 2,
        pageSizes: [2, 3, 4],
        total: 1
      },

      // 新增课程
      form: {
        courseName: '',
        cover: '',
        uid: '',
        courseCount: '',
        amount: '',
        tag: '',
        courseText: ''
      },
      config: [
        [{
          colSpan: '12',
          label: '课程名',
          type: 'input',
          prop: 'courseName'
        },
        {
          colSpan: '12',
          label: '主讲人',
          type: 'select',
          prop: 'uid',
          isApi: true,
          options: []
        },
        {
          colSpan: '12',
          label: '课程章节数',
          type: 'input',
          prop: 'courseCount'
        },
        {
          colSpan: '12',
          label: '价格',
          type: 'input',
          prop: 'amount'
        },
        {
          colSpan: '12',
          label: '目录标签',
          type: 'select',
          prop: 'tag',
          isApi: true,
          multiple: true,
          options: []
        },
        {
          colSpan: '12',
          label: '优惠券使用范围',
          type: 'select',
          prop: 'courseCateId',
          isApi: true,
          options: []
        }],
        [{
          colSpan: 20,
          label: '课程封面',
          type: 'uploadAvatar',
          prop: 'cover'
        }],
        [{
          label: '课程介绍',
          type: 'quill',
          prop: 'courseText'
        }]
      ],
      // 表单验证
      rules: {
        courseName: [
          { required: true, message: '请输入课程名', trigger: 'blur' }
        ],
        cover: [
          { required: true, message: '请输入课程封面', trigger: 'blur' }
        ],
        uid: [
          { required: true, message: '请选择主讲人', trigger: 'blur' }
        ],
        courseCount: [
          { required: true, message: '请输入总章节数', trigger: 'blur' }
        ],
        amount: [
          { required: true, message: '请输入价格', trigger: 'blur' }
        ],
        tag: [
          { required: true, message: '请选择目录', trigger: 'blur' }
        ],
        courseCateId: [
          { required: true, message: '优惠券使用范围', trigger: 'blur' }
        ],
        courseText: [
          { required: true, message: '请输入课程介绍', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {},
  methods: {}
}
</script>
