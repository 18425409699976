<template>
  <d2-container>
    <el-card style="width: 500px; margin: 0 auto">
      <el-form label-width="80px" :model="data" :rules="rules" ref="data">
        <el-form-item label="标题" prop="title">
          <el-input v-model="data.title"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="context">
          <el-input v-model="data.context"></el-input>
        </el-form-item>
        <el-form-item label="停留时长(单位:s)" prop="stopDuration">
          <el-input type="number" v-model="data.stopDuration"></el-input>
        </el-form-item>
        <el-form-item label="跳转目标" prop="targetUrl">
          <el-input v-model="data.targetUrl"></el-input>
        </el-form-item>
        <el-form-item label="权重" prop="weight">
          <el-input type="number" v-model="data.weight"></el-input>
        </el-form-item>
        <el-form-item label="底色" prop="lowColor">
          <el-color-picker v-model="data.lowColor"></el-color-picker>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input v-model="data.remarks"></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="图片" prop="imageUrl">
              <el-row>
                <el-upload
                  class="avatar-uploader"
                  action="http://dev.kcguoyi.com/service/upload/huawei"
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-change="
                    (file, fileList) => {
                      handleAvatarChange(file, fileList, 'imageUrl');
                    }
                  "
                >
                  <el-image
                    fit="cover"
                    v-if="data.imageUrl"
                    :src="data.imageUrl"
                    class="avatar"
                    style="width: 120px; height: 120px"
                  ></el-image>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-row>

              <el-row style="text-align: center" v-if="data.imageUrl">
                <el-button type="text" @click="previewImg(data.imageUrl)">预览</el-button>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="背景图" prop="bgUrl">
              <el-row>
                <el-upload
                  class="avatar-uploader"
                  action="http://dev.kcguoyi.com/service/upload/huawei"
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-change="
                    (file, fileList) => {
                      handleAvatarChange(file, fileList, 'bgUrl');
                    }
                  "
                >
                  <el-image
                    fit="cover"
                    v-if="data.bgUrl"
                    :src="data.bgUrl"
                    class="avatar"
                    style="width: 120px; height: 120px"
                  ></el-image>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-row>

              <el-row style="text-align: center" v-if="data.bgUrl">
                <el-button type="text" @click="previewImg(data.bgUrl)">预览</el-button>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item style="text-align: center">
            <el-button type="primary" @click="onSubmit">提 交</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </el-card>

    <el-image-viewer v-if="showViewer" :on-close="() => {showViewer = false} " :url-list="[imgViewerSrc]">
    
    </el-image-viewer>
  </d2-container>
</template>

<script>
//引入接口
import StartAdvert from "@/model/modules/startAdvert";
import { uploadPic } from "@/model/modules/user";

// 引入组件 图片预览
import ElImageViewer from "element-ui/packages/image/src/image-viewer.vue"

export default {
  components: { ElImageViewer },

  data() {
    return {
      showViewer: false, //控制ElImageViewer的显示隐藏
      imgViewerSrc: '', //图片预览路径
      data: {
        bgUrl: "",
        context: "",
        imageUrl: "",
        lowColor: "",
        remarks: "",
        stopDuration: "",
        targetUrl: "",
        title: "",
        weight: "",
      },
      rules: {
        bgUrl: [{ required: true, message: "请选择图片", trigger: "blur" }],
        context: [{ required: true, message: "请输入内容", trigger: "blur" }],
        imageUrl: [{ required: true, message: "请选择图片", trigger: "blur" }],
        lowColor: [{ required: true, message: "请选择底色", trigger: "blur" }],
        remarks: [{ required: true, message: "请输入备注", trigger: "blur" }],
        stopDuration: [
          { required: true, message: "请输入停留时间", trigger: "blur" },
        ],
        targetUrl: [
          { required: true, message: "请输入跳转目标", trigger: "blur" },
        ],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        weight: [{ required: true, message: "请输入权重", trigger: "blur" }],
      },
    };
  },

  mounted() {
    this.getStartAdvertInfo();
  },

  methods: {
    // 预览图片
    previewImg(src){
      this.imgViewerSrc = src
      this.showViewer = true
    },

    // 获取开屏广告信息
    getStartAdvertInfo() {
      StartAdvert.getStartAdvertInfo().then((res) => {
        // console.log(res)
        if (!res.data.code === 0) return;
        this.data = res.data.body;
      });
    },

    // 上传图片
    handleAvatarChange(file, fileList, name) {
      console.log(file);
      console.log(fileList);
      console.log(name);

      const formdata = new FormData();
      formdata.append("file", file.raw);

      var loading = this.$loading({
        lock: true,
        text: "图片上传中",
      });

      uploadPic(formdata)
        .then((res) => {
          if (res.data.code == 0) {
            this.data[name] = res.data.body[0].filepath;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$nextTick(() => {
            this.$forceUpdate();
          });
          loading.close();
        });
    },

    // 提交表单
    onSubmit() {
      this.$refs.data.validate((valid) => {
        if (!valid) return;
        this.$confirm("确认提交吗？", "提示", {}).then(() => {
          StartAdvert.saveStartAdvert(this.data)
            .then((res) => {
              // console.log(res)
              if (res.data.code == 0) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 1500,
                });
              }
              this.getStartAdvertInfo();
            })
            .catch((res) => {
              console.error(response);
              this.$message.error("修改失败");
            });
        });
      });
    },
  },
};
</script>

<style scoped>
.avatar-uploader {
  width: 120px;
  height: 120px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  line-height: 120px;
  width: 120px;
  height: 120px;
}
</style>